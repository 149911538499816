<div class="box-header with-border">
    <button class="btn-add-customer" [disabled]="loading" (click)="cancelCreateCustomer()">
        <span class="box-title">
            <b><i class="fa fa-arrow-left"></i> Atrás</b>
        </span>
    </button>

    <div>
        <div class=" box-body m-1">
            <div class="row">
                <div class="col-md-4 offset-md-4">
                    <div class="mb-4 text-center">
                        <i class="fa fa-user circle" aria-hidden='true'></i>
                    </div>
                </div>
            </div>
            <form [formGroup]="form" (ngSubmit)="sendForm()">
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label class="label-lg">Tipo de Documento:</label>
                            <select formControlName="document_type" class="form-control m-input form-control-lg"
                                    (change)="onChangeDocument()"
                                    [ngClass]="{ 'is-invalid': submitted && f.document_type.errors }">
                                <option value="1">DNI</option>
                                <option value="6">RUC</option>
                                <option value="4">CARNET DE EXTRANJERÍA</option>
                                <option value="7">PASAPORTE</option>
                                <option value="A">CÉDULA DIPLOMÁTICA DE IDENTIDAD</option>
                                <option value="-">VARIOS - VENTAS MENORES A S/.700.00 Y OTROS</option>
                            </select>
                            <div *ngIf="submitted && f.document_type.errors" class="invalid-feedback">
                                <div *ngIf="f.document_type.errors.required">Tipo de documento obligatorio</div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="label-lg">N° de Documento:</label>
                            <input formControlName="document_number"
                                   class="form-control m-input form-control-lg"
                                   placeholder="N° de Documento"
                                   [ngClass]="{ 'is-invalid': submitted && f.document_number.errors }">
                            <div *ngIf="submitted && f.document_number.errors" class="invalid-feedback">
                                <div *ngIf="f.document_number.errors.required">N° de documento obligatorio</div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="label-lg">Nombre:</label>
                            <input formControlName="name" class="form-control m-input form-control-lg"
                                   placeholder="Nombre"
                                   [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
                            <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                <div *ngIf="f.name.errors.required">Nombre obligatorio</div>
                            </div>
                        </div>

                        <div class="form-group">
                            <label class="label-lg">Dirección:</label>
                            <input formControlName="address" class="form-control m-input form-control-lg"
                                   placeholder="Dirección"
                                   type="text"
                                   [ngClass]="{ 'is-invalid': submitted && f.address.errors }">
                            <div *ngIf="submitted && f.address.errors" class="invalid-feedback">
                                <div *ngIf="f.address.errors.required">Dirección obligatoria</div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="label-lg">Teléfono:</label>
                                    <input formControlName="phone" class="form-control m-input form-control-lg"
                                           placeholder="Teléfono"
                                           type="tel"
                                           [ngClass]="{ 'is-invalid': submitted && f.phone.errors }">
                                    <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                                        <div *ngIf="f.phone.errors.required">Teléfono obligatorio</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="label-lg">Email:</label>
                                    <input formControlName="email" class="form-control m-input form-control-lg"
                                           placeholder="Email"
                                           type="email"
                                           [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                        <div *ngIf="f.email.errors.required">Email obligatorio</div>
                                        <div *ngIf="f.email.errors.email">Formato inválido</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6"></div>
                    <div class="col-md-3">
                        <div class="form-group text-right">
                            <button type="button" class="btn btn-default btn-lg"
                                    [disabled]="loading" (click)="cancelCreateCustomer()">
                                Cancelar
                            </button>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group text-right">
                            <button type="submit" class="btn btn-primary btn-lg" [disabled]="loading"
                                    [ngClass]="{'m-loader m-loader--light m-loader--right': loading}">Guardar
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
