<ngx-spinner
        bdColor="rgba(51,51,51,0.62)"
        size="medium"
        color="#fff"
        type="ball-circus"
        [fullScreen]="true">
    <p style="color: white"> Cargando... </p>
</ngx-spinner>
<div class="m-grid m-grid--hor m-grid--root m-page app--full-height">
    <app-header></app-header>
    <div class="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body">
        <app-aside></app-aside>
        <div #mContentWrapper class="m-grid__item m-grid__item--fluid m-wrapper">
            <router-outlet></router-outlet>
        </div>
    </div>
    <app-footer></app-footer>
</div>
<!-- begin::Scroll Top -->
<div id="m_scroll_top" class="m-scroll-top">
    <i class="la la-arrow-up"></i>
</div>
