<!-- BEGIN: Subheader -->
<div class="m-subheader ">
    <div class="d-flex align-items-center">
        <div class="mr-auto">
            <h3 class="m-subheader__title">Comprobante de Pago</h3>
        </div>
    </div>
</div>

<div class="m-content">
    <div class="row">
        <div class="col-md-12">
            <div class="m-portlet m-portlet--mobile">
                <div class="m-portlet__head">
                    <div class="m-portlet__head-caption">
                        <div class="m-portlet__head-title">
                            <h3 class="m-portlet__head-text">
                                {{saleCreated?.voucher_type_text}} {{saleCreated?.voucher_number}}
                            </h3>
                        </div>
                    </div>
                </div>
                <div class="m-portlet__body">
                    <div class="row">
                        <div class="col-md-3 container-voucher-left">
                            <div class="container-voucher-customer">
                                <h4>CLIENTE</h4>
                                <div class="container-voucher-customer-data">
                                    <p>{{saleCreated?.customer.name}}</p>
                                    <p>{{saleCreated?.customer.document_number}}</p>
                                    <p *ngIf="saleCreated?.customer.address">{{saleCreated?.customer.address}}</p>
                                    <p *ngIf="saleCreated?.customer.email">{{saleCreated?.customer.email}}</p>
                                </div>
                            </div>
                            <div class="container-voucher-payment-method">
                                <p> Pago con {{saleCreated?.payment_method_text}}</p>
                            </div>
                        </div>
                        <div class="col-md-9">
                            <div class="container-voucher-customer pl-5">
                                <h4>PRODUCTOS</h4>
                                <div class="container-voucher-customer-data">
                                    <table class="table table-items">
                                        <tbody>
                                        <ng-container
                                                *ngFor="let item of saleCreated.details; let i = index; let last = last;">
                                            <tr>
                                                <td>{{item.name}}</td>
                                                <td class="text-right">{{item.quantity}}</td>
                                                <td class="text-right">{{((item.price - item.discount) * item.quantity) | currency: saleCreated.currency_symbol}}</td>
                                            </tr>
                                            <ng-container *ngIf="last">
                                                <tr class="table-items-last-tr">
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                            </ng-container>
                                        </ng-container>
                                        </tbody>
                                        <tfoot>
                                        <tr>
                                            <td colspan="2">Subtotal</td>
                                            <td class="text-right">{{(saleCreated.total - saleCreated.total_igv)| currency: saleCreated.currency_symbol}}</td>
                                        </tr>
                                        <tr class="table-items-last-tr">
                                            <td colspan="2">IGV {{saleCreated.igv_percentage}}%</td>
                                            <td class="text-right">{{saleCreated.total_igv | currency: saleCreated.currency_symbol}}</td>
                                        </tr>
                                        <tr class="table-items-totals">
                                            <td colspan="2">Total</td>
                                            <td class="text-right">{{saleCreated.total | currency: saleCreated.currency_symbol}}</td>
                                        </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-3"></div>
                <div class="col-md-2">
                    <button class="btn btn-print w-100" (click)="printVoucher()">Imprimir</button>
<!--                    <button class="btn btn-print w-100" (click)="downloadVoucher()">Imprimir</button>-->
                </div>
                <div class="col-md-2">
                    <button class="btn btn-email w-100" (click)="sendEmail()">Enviar a email</button>
                </div>
                <div class="col-md-2">
                    <button class="btn btn-new-sale w-100" (click)="resetForm()">Nueva venta</button>
                </div>
                <div class="col-md-3"></div>
            </div>
        </div>
    </div>
</div>
