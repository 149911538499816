import {Component, OnInit, ViewChild} from '@angular/core';
import {DataTableDirective} from 'angular-datatables';
import {Setting} from '../../models/setting';
import {ToastrService} from 'ngx-toastr';
import {MessageService} from '../../services/message.service';
import {SettingService} from '../../services/setting.service';
import {first} from 'rxjs/operators';
import {PromotionService} from '../../services/promotion.service';
import * as FileSaver from 'file-saver';

declare var swal: any;

@Component({
    selector: 'app-promotions',
    templateUrl: './promotions.component.html',
    styleUrls: ['./promotions.component.scss']
})
export class PromotionsComponent implements OnInit {

    dtOptions: DataTables.Settings = {};
    promotions: any[];
    @ViewChild(DataTableDirective) private dtElement;
    currentSettings: Setting;
    exporting = false;

    constructor(
        private toastrService: ToastrService,
        private messageService: MessageService,
        private settingService: SettingService,
        private promotionService: PromotionService,
    ) {
    }

    ngOnInit(): void {
        this.currentSettings = this.settingService.currentSettingsValue;

        this.dtOptions = {
            pagingType: 'full_numbers',
            pageLength: 10,
            serverSide: true,
            processing: true,
            ajax: (dataTablesParameters: any, callback) => {
                this.promotionService.datatable(dataTablesParameters)
                    .pipe(first())
                    .subscribe(response => {
                        this.promotions = response.data;
                        callback({
                            recordsTotal: response.recordsTotal,
                            recordsFiltered: response.recordsFiltered,
                            data: []
                        });
                    });
            },
            columns: [
                {data: 'code', name: 'code'},
                {data: 'name', name: 'name'},
                {data: 'description', name: 'description'},
                {data: 'discount', name: 'discount'},
                {data: 'price', name: 'price'},
                {data: 'photo_url', name: 'photo_url', orderable: false, searchable: false},
                {data: 'end_promotion', name: 'end_promotion'},
                {data: 'status', name: 'status'},
            ]
        };
    }

    private async reloadTable() {
        let dtInstance = await this.dtElement.dtInstance;
        dtInstance.ajax.reload();
    }

    delete(product: any): void {
        swal.fire({
            title: '¿Estás seguro que deseas eliminar este registro?',
            showCancelButton: true,
            confirmButtonText: 'Si, elimínalo!',
            showLoaderOnConfirm: true,
            preConfirm: (arg) => {
                return this.promotionService.delete(product.id)
                    .pipe(first())
                    .subscribe(response => {
                        swal.fire({
                            title: 'Elemento eliminado con éxito',
                            type: 'success'
                        });
                        this.reloadTable();
                    }, error => {
                        swal.fire({
                            title: error,
                            type: 'error'
                        });
                        return error;
                    });
            },
            allowOutsideClick: () => !swal.isLoading()
        }).then((result) => {
        });
    }

    exportList(): void {
        this.exporting = true;
        this.promotionService.export().subscribe(blob => {
            this.exporting = false;
            FileSaver.saveAs(blob, 'Promociones.xlsx');
        }, error1 => {
            this.exporting = false;
            this.toastrService.error(error1);
        });
    }
}
