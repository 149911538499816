import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {webServiceConfig} from '../config/web-service.config';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class TransferService {

    constructor(private http: HttpClient) {
    }

    store(data: any): Observable<any> {
        return this.http.post(`${webServiceConfig.webServiceUrl}transfers`, data)
            .pipe(map(response => {
                return response;
            }));
    }
}
