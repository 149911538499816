<!-- BEGIN: Subheader -->
<div class="m-subheader ">
    <div class="d-flex align-items-center">
        <div class="mr-auto">
            <h3 class="m-subheader__title m-subheader__title--separator">Usuarios</h3>
            <ul class="m-subheader__breadcrumbs m-nav m-nav--inline">
                <li class="m-nav__item m-nav__item--home">
                    <a [routerLink]="''" class="m-nav__link m-nav__link--icon">
                        <i class="m-nav__link-icon la la-home"></i>
                    </a>
                </li>
                <li class="m-nav__separator">-</li>
                <li class="m-nav__item">
                    <a [routerLink]="'/users'" class="m-nav__link">
                        <span class="m-nav__link-text">Usuarios</span>
                    </a>
                </li>
                <li class="m-nav__separator">-</li>
                <li class="m-nav__item">
                    <a *ngIf="user.id == null" [routerLink]="'/users/create'" class="m-nav__link">
                        <span class="m-nav__link-text">Crear Usuario</span>
                    </a>
                    <a *ngIf="user.id != null" [routerLink]="['/users/edit', user.id]" class="m-nav__link">
                        <span class="m-nav__link-text">Editar Usuario</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>

<div class="m-content">
    <div class="row">
        <div class="col-md-12">
            <!--begin::Portlet-->
            <div class="m-portlet m-portlet--mobile">
                <div class="m-portlet__head">
                    <div class="m-portlet__head-caption">
                        <div class="m-portlet__head-title">
                            <h3 *ngIf="user.id == null" class="m-portlet__head-text">
                                Crear Usuario
                            </h3>
                            <h3 *ngIf="user.id != null" class="m-portlet__head-text">
                                Editar Usuario - {{user.name}}
                            </h3>
                        </div>
                    </div>
                    <div class="m-portlet__head-tools">
                        <ul class="m-portlet__nav">
                            <li class="m-portlet__nav-item">
                                <a [routerLink]="'/users'"
                                   class="m-portlet__nav-link btn btn-lg btn-secondary  m-btn m-btn--outline-2x m-btn--air m-btn--icon m-btn--icon-only m-btn--pill  m-dropdown__toggle">
                                    <i class="flaticon-reply"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <form [formGroup]="form" (ngSubmit)="sendForm()" class="m-form m-form--label-align-right">
                    <app-message></app-message>
                    <div class="m-portlet__body">
                        <div class="m-form__section m-form__section--first">
                            <div class="m-form__heading">
                                <h3 class="m-form__heading-title">Información del Usuario:</h3>
                            </div>

                            <div class="form-group m-form__group row">
                                <label class="col-lg-2 col-form-label">Nombre:</label>
                                <div class="col-lg-6">
                                    <input formControlName="name" class="form-control m-input" placeholder="Nombre"
                                           [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
                                    <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                        <div *ngIf="f.name.errors.required">Nombre obligatorio</div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group m-form__group row">
                                <label class="col-lg-2 col-form-label">Email:</label>
                                <div class="col-lg-6">
                                    <input formControlName="email" class="form-control m-input" placeholder="Email"
                                           type="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" [readOnly]="!!id">
                                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                        <div *ngIf="f.email.errors.required">Email obligatorio</div>
                                        <div *ngIf="f.email.errors.email">Formato inválido</div>
                                    </div>
                                </div>
                            </div>

                            <ng-container *ngIf="!id">
                                <div class="form-group m-form__group row">
                                    <label class="col-lg-2 col-form-label">Rol:</label>
                                    <div class="col-lg-6">
                                        <select formControlName="role_id" class="form-control m-input m-input--square"
                                                [ngClass]="{ 'is-invalid': submitted && f.role_id.errors }">
                                            <option [ngValue]="''" disabled>Selecciona un rol</option>
                                            <option *ngFor="let rolVal of roles"
                                                    [value]="rolVal.id">{{rolVal.name}}</option>
                                        </select>
                                        <div *ngIf="submitted && f.role_id.errors" class="invalid-feedback">
                                            <div *ngIf="f.role_id.errors.required">Rol obligatorio</div>
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="currentSettings.kipu_mode == 'restaurant' && f.role_id.value == 3" class="form-group m-form__group row">
                                    <label class="col-lg-2 col-form-label">Código de mesero:</label>
                                    <div class="col-lg-6">
                                        <input formControlName="waiter_code" class="form-control m-input" placeholder="Código de Mesero"
                                               [ngClass]="{ 'is-invalid': submitted && f.waiter_code.errors }">
                                        <div *ngIf="submitted && f.waiter_code.errors" class="invalid-feedback">
                                            <div *ngIf="f.waiter_code.errors.required">Código de mesero obligatorio</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group m-form__group row">
                                    <label class="col-lg-2 col-form-label">Contraseña:</label>
                                    <div class="col-lg-6">
                                        <input formControlName="password" type="password" class="form-control m-input"
                                               placeholder="Contraseña"
                                               [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
                                        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                            <div *ngIf="f.password.errors.required">Contraseña obligatoria</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group m-form__group row">
                                    <label class="col-lg-2 col-form-label">Confirmar Contraseña:</label>
                                    <div class="col-lg-6">
                                        <input formControlName="password_confirmation" type="password" class="form-control m-input"
                                               placeholder="Confirmar Contraseña"
                                               [ngClass]="{ 'is-invalid': submitted && form.hasError('passwordsEqual') }">
                                        <div *ngIf="submitted && form.hasError('passwordsEqual')" class="invalid-feedback">
                                            <div>Confirmación de password incorrecta</div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="id">
                                <div *ngIf="currentSettings.kipu_mode == 'restaurant' && hasSeller" class="form-group m-form__group row">
                                    <label class="col-lg-2 col-form-label">Código de mesero:</label>
                                    <div class="col-lg-6">
                                        <input formControlName="waiter_code" class="form-control m-input" placeholder="Código de Mesero"
                                               [ngClass]="{ 'is-invalid': submitted && f.waiter_code.errors }">
                                        <div *ngIf="submitted && f.waiter_code.errors" class="invalid-feedback">
                                            <div *ngIf="f.waiter_code.errors.required">Código de mesero obligatorio</div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>


                            <div class="form-group m-form__group row">
                                <label class="col-lg-2 col-form-label">Foto:</label>
                                <div class="col-lg-6">
                                    <div class="custom-file">
                                        <input type="file" class="custom-file-input" id="customFile" accept="image/*"
                                               (change)="onSelectFile($event)">
                                        <label class="custom-file-label" for="customFile">{{pathFile}}</label>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group m-form__group row">
                                <label class="col-lg-2 col-form-label">Estado:</label>
                                <div class="col-lg-6">
                                    <select formControlName="status" class="form-control m-input"
                                            [ngClass]="{ 'is-invalid': submitted && f.status.errors }">
                                        <option [ngValue]="1">Activo</option>
                                        <option [ngValue]="0">Inactivo</option>
                                    </select>
                                    <div *ngIf="submitted && f.status.errors" class="invalid-feedback">
                                        <div *ngIf="f.status.errors.required">Estado obligatorio</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="m-portlet__foot m-portlet__foot--fit">
                        <div class="m-form__actions">
                            <div class="row">
                                <div class="col-2">
                                </div>
                                <div class="col-7">
                                    <button type="submit" [disabled]="loading"
                                            class="btn btn-accent m-btn m-btn--air m-btn--custom"
                                            [ngClass]="{'m-loader m-loader--light m-loader--right': loading}">
                                        Guardar Cambios
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
