<!-- BEGIN: Subheader -->
<div class="m-subheader ">
    <div class="d-flex align-items-center">
        <div class="mr-auto">
            <h3 class="m-subheader__title m-subheader__title--separator">Promociones</h3>
            <ul class="m-subheader__breadcrumbs m-nav m-nav--inline">
                <li class="m-nav__item m-nav__item--home">
                    <a [routerLink]="''" class="m-nav__link m-nav__link--icon">
                        <i class="m-nav__link-icon la la-home"></i>
                    </a>
                </li>
                <li class="m-nav__separator">-</li>
                <li class="m-nav__item">
                    <a [routerLink]="'/promotions'" class="m-nav__link">
                        <span class="m-nav__link-text">Promociones</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>

<div class="m-content">
    <div class="row">
        <div class="col-md-12">
            <!--begin::Portlet-->
            <div class="m-portlet m-portlet--mobile">
                <div class="m-portlet__head">
                    <div class="m-portlet__head-caption">
                        <div class="m-portlet__head-title">
                            <h3 class="m-portlet__head-text">
                                Promociones
                            </h3>
                        </div>
                    </div>
                    <div class="m-portlet__head-tools">
                        <ul class="m-portlet__nav">
                            <li class="m-portlet__nav-item">
                                <button (click)="exportList()"
                                        [disabled]="exporting"
                                        [ngClass]="{'m-loader m-loader--light m-loader--right': exporting}"
                                        class="btn btn-success m-btn m-btn--pill m-btn--custom m-btn--icon m-btn--air">
                                    <span>
                                       <i class="la la-file-excel-o"></i>
                                        <span>Exportar</span>
                                    </span>
                                </button>
                            </li>
                            <li class="m-portlet__nav-item">
                                <a [routerLink]="['create']"
                                   class="btn btn-primary m-btn m-btn--pill m-btn--custom m-btn--icon m-btn--air">
                                    <span>
                                        <i class="la la-plus"></i>
                                        <span>Nuevo Promoción</span>
                                    </span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <app-message></app-message>
                <div class="m-portlet__body">
                    <div class="table-responsive">
                        <!--begin: Datatable -->
                        <table datatable [dtOptions]="dtOptions"
                               class="table table-striped- table-bordered table-hover table-checkable">
                            <thead>
                            <tr>
                                <th>Código</th>
                                <th>Nombre</th>
                                <th>Descripción</th>
                                <th>Descuento</th>
                                <th>Precio</th>
                                <th>Foto</th>
                                <th>Fin de Promoción</th>
                                <th>Estado</th>
                                <th>Acciones</th>
                            </tr>
                            </thead>
                            <tbody *ngIf="promotions?.length != 0">
                            <tr *ngFor="let promotion of promotions">
                                <td>{{ promotion.code }}</td>
                                <td>{{ promotion.name }}</td>
                                <td>{{ promotion.description }}</td>
                                <td>{{ promotion.discount | currency: 'S/ '}}</td>
                                <td>{{ promotion.price | currency: 'S/ '}}</td>
                                <td>
                                    <img *ngIf="!promotion.photo_url" src="/assets/core/images/icons/product-icon.jpg"
                                         class="img-product" [alt]="promotion.name">
                                    <img *ngIf="promotion.photo_url"
                                         [defaultImage]="'/assets/core/images/icons/product-icon.jpg'"
                                         [lazyLoad]="promotion.photo_url" class="img-fluid img-product"
                                         [alt]="promotion.name">
                                </td>
                                <td>
                                    <span *ngIf="promotion.end_promotion">{{ promotion.end_promotion | date:'dd/MM/yyyy'}}</span>
                                </td>
                                <td>{{promotion.status_nice}}</td>
                                <td>
                                    <button [routerLink]="['edit', promotion.id]"
                                            class="m-portlet__nav-link btn m-btn m-btn--hover-accent m-btn--icon m-btn--icon-only m-btn--pill"
                                            title="Editar">
                                        <i class="la la-edit"></i>
                                    </button>
                                    <button (click)="delete(promotion)"
                                            class="m-portlet__nav-link btn m-btn m-btn--hover-danger m-btn--icon m-btn--icon-only m-btn--pill"
                                            title="Delete"><i class="la la-trash"></i></button>
                                </td>
                            </tr>
                            </tbody>
                            <tbody *ngIf="promotions?.length == 0">
                            <tr>
                                <td colspan="9" class="no-data-available">No data!</td>
                            </tr>
                            <tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
