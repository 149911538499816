<form class="pt-5">
    <ng-container *ngIf="documentTypesLength == 1">
        <div class="row">
            <div class="col-md-8">
                <div class="form-group">
                    <label class="label-lg">N° de RUC:</label>
                    <div class="input-group">
                        <input [(ngModel)]="customer.document_number" [ngModelOptions]="{standalone: true}"
                               [disabled]="searching || allInputsDisabled"
                               type="text" class="form-control m-input form-control-lg" placeholder="N° de RUC"
                               [ngClass]="{ 'is-invalid': submitted && !customer.document_number }">
                        <div class="input-group-append">
                            <button class="btn btn-search" (click)="searchCustomer()" type="button"
                                    [disabled]="searching || allInputsDisabled"
                                    [ngClass]="{'m-loader m-loader--light m-loader--right': searching}">
                                <i class="la la-search"></i>
                            </button>
                        </div>
                    </div>
                    <div *ngIf="submitted && !customer.document_number" class="invalid-feedback">
                        <div>N° de RUC obligatorio</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="form-group">
                    <label class="label-lg">Razón social:</label>
                    <input [(ngModel)]="customer.name" class="form-control m-input form-control-lg"
                           placeholder="Razón social" [ngModelOptions]="{standalone: true}"
                           [disabled]="searching || allInputsDisabled || !formLoaded"
                           [ngClass]="{ 'is-invalid': submitted && !customer.name }">
                    <div *ngIf="submitted && !customer.name" class="invalid-feedback">
                        <div>Razón social obligatoria</div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="documentTypesLength > 1">
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label class="label-lg">Tipo de Documento:</label>
                    <select [(ngModel)]="customer.document_type" class="form-control m-input form-control-lg"
                            [ngModelOptions]="{standalone: true}"
                            [disabled]="searching || allInputsDisabled"
                            [ngClass]="{ 'is-invalid': submitted && !customer.document_type }">
                        <option value="1">DNI</option>
                        <option value="6">RUC</option>
                        <option value="4">CARNET DE EXTRANJERÍA</option>
                        <option value="7">PASAPORTE</option>
                        <option value="A">CÉDULA DIPLOMÁTICA DE IDENTIDAD</option>
                        <option value="-">VARIOS - VENTAS MENORES A S/.700.00 Y OTROS</option>
                    </select>
                    <div *ngIf="submitted && !customer.document_type" class="invalid-feedback">
                        <div>Tipo de documento obligatorio</div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label class="label-lg">N° de Documento:</label>
                    <div class="input-group">
                        <input [(ngModel)]="customer.document_number" [ngModelOptions]="{standalone: true}"
                               [disabled]="searching || allInputsDisabled"
                               type="text" class="form-control m-input form-control-lg" placeholder="N° de Documento"
                               [ngClass]="{ 'is-invalid': submitted && !customer.document_number }">
                        <div class="input-group-append">
                            <button class="btn btn-search" (click)="searchCustomer()" type="button"
                                    [disabled]="searching || allInputsDisabled"
                                    [ngClass]="{'m-loader m-loader--light m-loader--right': searching}">
                                <i class="la la-search"></i>
                            </button>
                        </div>
                    </div>
                    <div *ngIf="submitted && !customer.document_number" class="invalid-feedback">
                        <div>N° de documento obligatorio</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="form-group">
                    <label class="label-lg">Nombre y apellidos:</label>
                    <input [(ngModel)]="customer.name" class="form-control m-input form-control-lg"
                           placeholder="Nombre y apellidos" [ngModelOptions]="{standalone: true}"
                           [disabled]="searching || allInputsDisabled || !formLoaded"
                           [ngClass]="{ 'is-invalid': submitted && !customer.name }">
                    <div *ngIf="submitted && !customer.name" class="invalid-feedback">
                        <div>Nombre y apellidos obligatorio</div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
    <div class="row">
        <div class="col-md-6">
            <div class="form-group">
                <label class="label-lg">Teléfono:</label>
                <input [(ngModel)]="customer.phone" class="form-control m-input form-control-lg"
                       placeholder="Teléfono" [disabled]="searching || allInputsDisabled || !formLoaded"
                       type="tel" [ngModelOptions]="{standalone: true}">
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <label class="label-lg">Email:</label>
                <input [(ngModel)]="customer.email" class="form-control m-input form-control-lg"
                       placeholder="Email" [disabled]="searching || allInputsDisabled || !formLoaded"
                       type="email" [ngModelOptions]="{standalone: true}">
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="form-group">
                <label class="label-lg">Dirección:</label>
                <input [(ngModel)]="customer.address" class="form-control m-input form-control-lg"
                       placeholder="Dirección" [disabled]="searching || allInputsDisabled || !formLoaded"
                       type="text" [ngModelOptions]="{standalone: true}"
                       [ngClass]="{ 'is-invalid': submitted && !customer.address && customer.document_type == 6}">
                <div *ngIf="submitted && !customer.address" class="invalid-feedback">
                    <div>Dirección obligatoria</div>
                </div>
            </div>
        </div>
    </div>
</form>
