import {Component, OnInit, ViewChild} from '@angular/core';
import {DaterangepickerDirective} from 'ngx-daterangepicker-material';
import moment from 'moment';
import {ChartDataSets} from 'chart.js';
import {Color, Label} from 'ng2-charts';
import {MessageService} from '../../../services/message.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {SaleService} from '../../../services/sale.service';
import {SettingService} from '../../../services/setting.service';

import {
    ApexNonAxisChartSeries,
    ApexResponsive,
    ApexChart,
    ApexDataLabels,
    ApexLegend,
    ApexStroke,
    ApexPlotOptions,
    ApexStates,
    ApexTheme,
    ApexTitleSubtitle,
    ChartComponent
} from 'ng-apexcharts';
import {ProductDetailComponent} from '../../products/product-detail/product-detail.component';
import {MatDialog} from '@angular/material/dialog';
import {ProductService} from '../../../services/product.service';
import {ToastrService} from 'ngx-toastr';

export type ChartOptions = {
    series: ApexNonAxisChartSeries;
    colors: string[];
    chart: ApexChart;
    responsive: ApexResponsive[];
    labels: string[];
    fill: any;
    stroke: ApexStroke;
    states: ApexStates;
    legend: ApexLegend;
    title: ApexTitleSubtitle;
    theme: ApexTheme;
    plotOptions: ApexPlotOptions;
    dataLabels: ApexDataLabels;
};

moment.locale('es');

@Component({
    selector: 'app-admin-dashboard',
    templateUrl: './admin-dashboard.component.html',
    styleUrls: ['./admin-dashboard.component.scss']
})
export class AdminDashboardComponent implements OnInit {

    public chartOptionsPaymentType: Partial<ChartOptions>;
    public chartOptionsSalesDay: Partial<ChartOptions>;
    public chartOptionsSalesPeriod: Partial<ChartOptions>;
    public chartOptionsVoucherType: Partial<ChartOptions>;
    public chartOptionsChannel: Partial<ChartOptions>;

    totalSaleDay = 0;
    totalGoalDay = 0;

    totalUtilityPeriod = 0;
    totalSalePeriod = 0;
    totalGoalPeriod = 0;


    averageSale = 0;
    cumulativeTotalSales = 0;
    numTotalSales = 0;
    numVoidedSales = 0;

    loadData = false;
    sellersRanking: any[] = [];
    productsRankingBestSellers: any[] = [];
    productsRankingBestInvoiced: any[] = [];
    productsLowStock: any[] = [];
    totalQuantitySold = 0;

    @ViewChild(DaterangepickerDirective) pickerDirective: DaterangepickerDirective;

    rangeSelected = {
        startDate: moment().subtract(29, 'days'),
        endDate: moment(),
    };

    ranges: any = {
        'Hoy': [moment(), moment()],
        'Ayer': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Útimos 7 días': [moment().subtract(6, 'days'), moment()],
        'Útimos 30 días': [moment().subtract(29, 'days'), moment()],
        'Este mes': [moment().startOf('month'), moment().endOf('month')],
        'Mes pasado': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    };

    locale = {
        daysOfWeek: moment.weekdaysMin(),
        monthNames: moment.monthsShort(),
        firstDay: moment.localeData().firstDayOfWeek(),
        applyLabel: 'Aplicar',
        format: 'DD/MM/YYYY'
    };

    lineChartData: ChartDataSets[] = [
        {data: [], label: 'Ventas'},
    ];

    lineChartLabels: Label[] = [];

    lineChartOptions = {
        responsive: true,
    };

    lineChartColors: Color[] = [
        {
            borderColor: '#0066ff',
            backgroundColor: 'rgba(0, 102, 255,0.28)',
        },
    ];

    lineChartLegend = true;
    lineChartPlugins = [];
    lineChartType = 'line';

    currentSettings = null;
    defaultCurrency = null;

    constructor(
        private dialog: MatDialog,
        private saleService: SaleService,
        private toastrService: ToastrService,
        private messageService: MessageService,
        private productService: ProductService,
        private settingService: SettingService,
        private ngxSpinnerService: NgxSpinnerService,
    ) {
        this.chartOptionsPaymentType = {
            series: [],
            colors: ['#0066FF', '#EF9D3F', '#9622DE'],
            chart: {
                width: 290,
                type: 'donut',
            },
            stroke: {
                width: 0
            },
            plotOptions: {
                pie: {
                    donut: {
                        labels: {
                            show: true,
                        }
                    }
                }
            },
            labels: ['Tarjeta', 'Efectivo', 'Wallet'],
            legend: {
                position: 'bottom',
            },
            dataLabels: {
                dropShadow: {
                    blur: 3,
                    opacity: 0.8,
                },
            },
            responsive: [
                {
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }
            ]
        };

        this.chartOptionsVoucherType = {
            series: [],
            colors: ['#0066FF', '#EF9D3F', '#9622DE'],
            chart: {
                width: 290,
                type: 'donut',
            },
            stroke: {
                width: 0
            },
            plotOptions: {
                pie: {
                    donut: {
                        labels: {
                            show: true,
                        }
                    }
                }
            },
            labels: ['Boleta', 'Factura', 'Recaudación'],
            legend: {
                position: 'bottom',
            },
            dataLabels: {
                dropShadow: {
                    blur: 3,
                    opacity: 0.8,
                },
            },
            responsive: [
                {
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }
            ]
        };

        this.chartOptionsChannel = {
            series: [],
            colors: ['#0066FF', '#EF9D3F'],
            chart: {
                width: 290,
                type: 'donut',
            },
            stroke: {
                width: 0
            },
            plotOptions: {
                pie: {
                    donut: {
                        labels: {
                            show: true,
                        }
                    }
                }
            },
            labels: ['En tienda', 'Delivery'],
            legend: {
                position: 'bottom',
            },
            dataLabels: {
                dropShadow: {
                    blur: 3,
                    opacity: 0.8,
                },
            },
            responsive: [
                {
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }
            ]
        };

        this.chartOptionsSalesDay = {
            series: [],
            colors: ['#0066FF'],
            chart: {
                height: 240,
                type: 'radialBar',
                foreColor: '#0066FF'
            },
            plotOptions: {
                radialBar: {
                    hollow: {
                        size: '75%'
                    }
                }
            },
            labels: ['Ventas del dia']
        };

        this.chartOptionsSalesPeriod = {
            series: [],
            colors: ['#0066FF'],
            chart: {
                height: 240,
                type: 'radialBar',
                foreColor: '#0066FF'
            },
            plotOptions: {
                radialBar: {
                    hollow: {
                        size: '75%'
                    }
                }
            },
            labels: ['Ventas del Periodo']
        };
    }

    exchangeRate = null;

    ngOnInit() {
        this.currentSettings = this.settingService.currentSettingsValue;
        this.defaultCurrency = this.currentSettings.setting_currencies.find(item => item.principal);
        this.getData(this.rangeSelected.startDate.format('YYYY-MM-DD'), this.rangeSelected.endDate.format('YYYY-MM-DD'));
    }

    rangeSelectedChange($event) {
        if (this.loadData) {
            this.getData($event.startDate.format('YYYY-MM-DD'), $event.endDate.format('YYYY-MM-DD'));
        }
        this.loadData = true;
    }

    getData(startDate: string = null, endDate: string = null) {
        this.ngxSpinnerService.show();
        this.saleService.getIndicatorsForAdmin({from_date: startDate, to_date: endDate}).subscribe(response => {
            this.exchangeRate = response.data.exchange_rate;
            // console.log(response.data);
            // console.log(this.defaultCurrency.currency);

            this.totalSaleDay = response.data.sale_day;
            this.totalGoalDay = response.data.day_total_goal;
            this.chartOptionsSalesDay.series = [];
            this.chartOptionsSalesDay.series = [
                Math.round((this.totalSaleDay / this.totalGoalDay) * 100)
            ];

            this.totalUtilityPeriod = response.data.total_sale - response.data.total_purchase;
            this.totalSalePeriod = response.data.total_sale;
            this.totalGoalPeriod = response.data.total_goal;
            this.chartOptionsSalesPeriod.series = [];
            this.chartOptionsSalesPeriod.series = [
                Math.round((this.totalSalePeriod / this.totalGoalPeriod) * 100)
            ];

            this.chartOptionsPaymentType.series = [];
            this.chartOptionsPaymentType.series = [
                response.data.num_sales_card,
                response.data.num_sales_cash,
                response.data.num_sales_virtual_wallet,
            ];

            this.chartOptionsVoucherType.series = [];
            this.chartOptionsVoucherType.series = [
                response.data.voucher_type_ticket,
                response.data.voucher_type_invoice,
                response.data.voucher_type_update,
            ];

            this.averageSale = response.data.average_sale;
            this.numTotalSales = response.data.num_total_sales;
            this.numVoidedSales = response.data.num_voided_sales;
            this.cumulativeTotalSales = response.data.cumulative_total_sales;

            this.lineChartLabels = response.data.sales_line.labels;
            this.lineChartData[0].data = response.data.sales_line.quantity;

            this.sellersRanking = response.data.sellers_ranking;
            this.productsRankingBestSellers = response.data.products_ranking_best_sellers;
            this.productsRankingBestInvoiced = response.data.products_ranking_best_invoiced;
            this.productsLowStock = response.data.products_low_stock;
            this.totalQuantitySold = response.data.total_quantity_items_sold;

            this.chartOptionsChannel.series = [];
            this.chartOptionsChannel.series = [
                response.data.quantity_shop,
                response.data.quantity_delivery,
            ];

            this.ngxSpinnerService.hide();
        }, error1 => {
            console.error(error1);
            this.messageService.error(error1);
            this.ngxSpinnerService.hide();
        });
    }

    showDetail(product: any): void {
        this.ngxSpinnerService.show();
        this.productService.productAllBranches(product.id).subscribe(response => {
            this.ngxSpinnerService.hide();
            this.dialog.open(ProductDetailComponent, {
                data: response.data,
                width: '50%'
            });
        }, error1 => {
            this.ngxSpinnerService.hide();
            this.toastrService.error(error1);
            this.messageService.error(error1);
        });
    }
}
