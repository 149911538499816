import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {MessageService} from '../../services/message.service';

@Component({
    selector: 'app-message',
    templateUrl: './message.component.html',
    styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit, OnDestroy {

    private subscription: Subscription;
    message: any;

    constructor(private messageService: MessageService) {
    }

    ngOnInit() {
        this.subscription = this.messageService.getAlert()
            .subscribe(message => {
                this.message = message;
            });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

}
