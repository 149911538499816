<h1 mat-dialog-title>Detalle</h1>
<mat-dialog-content>
    <div class="col-md-12">
        <div class="row">
            <div class="col-md-6">
                <div class="row">
                    <div class="col-md-6">
                        <p><b>Usuario:</b></p>
                        <p>{{data.opening_user.name}}</p>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="row">
                    <div class="col-md-6">
                        <p><b>Fecha de Apertura:</b></p>
                        <p>{{data.opening_date_time | date:'dd/MM/yyyy'}}</p>
                    </div>
                    <div class="col-md-6">
                        <p><b>Fecha de Cierre:</b></p>
                        <p>{{data.closing_date_time | date:'dd/MM/yyyy'}}</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="table-responsive">
            <table class="table table-stripped table-hover table-bordered">
                <thead>
                <tr>
                    <th>Descripción</th>
                    <th>S/ </th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>Apertura de Caja</td>
                    <td>{{data.starting_amount | currency: 'S/ '}}</td>
                </tr>
                <tr>
                    <td>Cobros con efectivo</td>
                    <td>{{(data.cash_total ? data.cash_total : 0)| currency: 'S/ '}}</td>
                </tr>
                <tr>
                    <td>Cobros con tarjeta</td>
                    <td>{{(data.card_total ? data.card_total : 0) | currency: 'S/ '}}</td>
                </tr>
                <tr>
                    <td>Cobros con transferencia bancaria</td>
                    <td>{{(data.wire_transfer_total ? data.wire_transfer_total : 0) | currency: 'S/ '}}</td>
                </tr>
                <tr>
                    <td>Cobros con Yape</td>
                    <td>{{(data.vw_yape_total ? data.vw_yape_total : 0)| currency: 'S/ '}}</td>
                </tr>
                <tr>
                    <td>Cobros con Plin</td>
                    <td>{{(data.vw_plin_total ? data.vw_plin_total : 0)| currency: 'S/ '}}</td>
                </tr>
                <tr>
                    <td>Cobros con Tunki</td>
                    <td>{{(data.vw_tunki_total ? data.vw_tunki_total : 0)| currency: 'S/ '}}</td>
                </tr>
                <tr>
                    <td>Cobros con Lukita</td>
                    <td>{{(data.vw_lukita_total ? data.vw_lukita_total : 0)| currency: 'S/ '}}</td>
                </tr>
                <tr>
                    <td>Cobros con otras wallet</td>
                    <td>{{(data.wallet_total ? data.wallet_total : 0)| currency: 'S/ '}}</td>
                </tr>
                <tr>
                    <td>Entradas de Efectivo</td>
                    <td>{{(data.movement_inflow ? data.movement_inflow : 0)| currency: 'S/ '}}</td>
                </tr>
                <tr>
                    <td>Salidas de Efectivo</td>
                    <td>{{(data.movement_outflow ? data.movement_outflow : 0)| currency: 'S/ '}}</td>
                </tr>
                </tbody>
                <tfoot>
                <tr>
                    <th>FALTANTE</th>
                    <th>{{(data.missing ? data.missing : 0) | currency: 'S/ '}}</th>
                </tr>
                <tr>
                    <th>SOBRANTE</th>
                    <th>{{(data.leftover ? data.leftover : 0) | currency: 'S/ '}}</th>
                </tr>
                <tr>
                    <th>TOTAL DE CIERRE</th>
                    <th>{{data.final_amount | currency: 'S/ '}}</th>
                </tr>
                <tr>
                    <th>TOTAL DE VENTAS</th>
                    <th>{{data.total_sale | currency: 'S/ '}}</th>
                </tr>
                <tr>
                    <th>TOTAL DE DESCUENTOS</th>
                    <th>{{data.total_discount | currency: 'S/ '}}</th>
                </tr>
                </tfoot>
            </table>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button class="btn btn-outline-dark mx-3" mat-dialog-close>Cerrar</button>
</mat-dialog-actions>
