import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {webServiceConfig} from '../config/web-service.config';
import {map} from 'rxjs/operators';
import {Setting} from '../models/setting';

@Injectable({
    providedIn: 'root'
})
export class SettingService {

    public currentSettingsSubject: BehaviorSubject<Setting>;

    constructor(private http: HttpClient) {
        this.currentSettingsSubject = new BehaviorSubject<Setting>(JSON.parse(localStorage.getItem('settings')));
    }

    updateCurrentSettings(settings): void {
        localStorage.setItem('settings', JSON.stringify(settings));
        this.currentSettingsSubject.next(settings);
    }

    public get currentSettingsValue(): Setting {
        return new Setting().deserialize(this.currentSettingsSubject.value);
    }

    currentSettingsServer(with_niubiz_settings = 0): Observable<any> {
        return this.http.get(`${webServiceConfig.webServiceUrl}settings?with_niubiz_settings=${with_niubiz_settings}`)
            .pipe(map(response => {
                return response;
            }));
    }

    updateParamServer(form: any): Observable<any> {
        return this.http.patch(`${webServiceConfig.webServiceUrl}settings/param`, form)
            .pipe(map((response: any) => {
                const newSettings = new Setting().deserialize(response.data);
                localStorage.setItem('settings', JSON.stringify(newSettings));
                this.currentSettingsSubject.next(newSettings);
                return response;
            }));
    }

    updateElectronicBillingParams(form: any): Observable<any> {
        return this.http.patch(`${webServiceConfig.webServiceUrl}settings/electronic-billing`, form)
            .pipe(map((response: any) => {
                const newSettings = new Setting().deserialize(response.data);
                localStorage.setItem('settings', JSON.stringify(newSettings));
                this.currentSettingsSubject.next(newSettings);
                return response;
            }));
    }

    updateQrNiubiz(form: any): Observable<any> {
        return this.http.patch(`${webServiceConfig.webServiceUrl}settings/qr-niubiz`, form)
            .pipe(map((response: any) => {
                const newSettings = new Setting().deserialize(response.data);
                localStorage.setItem('settings', JSON.stringify(newSettings));
                this.currentSettingsSubject.next(newSettings);
                return response;
            }));
    }

    updateVoucherParams(form: any): Observable<any> {
        return this.http.patch(`${webServiceConfig.webServiceUrl}settings/business`, form)
            .pipe(map((response: any) => {
                const newSettings = new Setting().deserialize(response.data);
                localStorage.setItem('settings', JSON.stringify(newSettings));
                this.currentSettingsSubject.next(newSettings);
                return response;
            }));
    }

    updateCurrencies(setting_currencies: any[]): Observable<any> {
        return this.http.patch(`${webServiceConfig.webServiceUrl}settings/currencies`, {setting_currencies})
            .pipe(map((response: any) => {
                const newSettings = new Setting().deserialize(response.data);
                localStorage.setItem('settings', JSON.stringify(newSettings));
                this.currentSettingsSubject.next(newSettings);
                return response;
            }));
    }
}
