import {HttpParams} from '@angular/common/http';

export class Util {
    static jsonToURL(obj) {
        let str = [];
        for (let key in obj) {
            if (obj.hasOwnProperty(key) && obj[key] != null) {
                str.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]));
            }
        }
        return str.join('&');
    }

    static objectToHttpParams(object): HttpParams {
        let httpParams = new HttpParams();
        Object.keys(object).forEach((key) => {
            if (object[key] != null) {
                httpParams = httpParams.append(key, object[key]);
            }
        });
        return httpParams;
    }

    static hasAnyRole(userRoles: any[], rolesRequired: any[]): boolean {
        let valid = false;
        rolesRequired.forEach(roleRequired => {
            if (userRoles.find(userRole => userRole.name == roleRequired)) {
                valid = true;
            }
        });
        return valid;
    }
}
