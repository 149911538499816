import {Component, OnInit, ViewChild} from '@angular/core';
import {DataTableDirective} from 'angular-datatables';
import {ProductService} from '../../services/product.service';
import {first} from 'rxjs/operators';
import {Setting} from '../../models/setting';
import {SettingService} from '../../services/setting.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {MessageService} from '../../services/message.service';
import {MatDialog} from '@angular/material/dialog';
import {ProductDetailComponent} from './product-detail/product-detail.component';
import {ToastrService} from 'ngx-toastr';
import * as FileSaver from 'file-saver';

declare var swal: any;

@Component({
    selector: 'app-products',
    templateUrl: './products.component.html',
    styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {

    dtOptions: DataTables.Settings = {};
    products: any[];
    @ViewChild(DataTableDirective) private dtElement;
    currentSettings: Setting;
    exporting = false;

    constructor(
        private dialog: MatDialog,
        private toastrService: ToastrService,
        private messageService: MessageService,
        private productService: ProductService,
        private settingService: SettingService,
        private ngxSpinnerService: NgxSpinnerService,
    ) {
    }

    ngOnInit(): void {
        this.currentSettings = this.settingService.currentSettingsValue;
        let columns = null;
        if (this.currentSettings.kipu_mode == 'retail') {
            columns = [
                {data: 'code', name: 'code'},
                {data: 'category_name'},
                {data: 'name', name: 'name'},
                {data: 'description', name: 'description'},
                {data: 'price', name: 'price'},
                {data: 'photo_url', name: 'photo_url', orderable: false, searchable: false},
                {data: 'status_nice', name: 'status_nice'},
            ];
        } else {
            columns = [
                {data: 'code', name: 'code'},
                {data: 'menu_name', name: 'menu_name'},
                {data: 'category_name'},
                {data: 'name', name: 'name'},
                {data: 'description', name: 'description'},
                {data: 'price', name: 'price'},
                {data: 'photo_url', name: 'photo_url', orderable: false, searchable: false},
                {data: 'status_nice', name: 'status_nice'},
            ];
        }

        this.dtOptions = {
            pagingType: 'full_numbers',
            pageLength: 10,
            serverSide: true,
            processing: true,
            ajax: (dataTablesParameters: any, callback) => {
                this.productService.datatable(dataTablesParameters)
                    .pipe(first())
                    .subscribe(response => {
                        this.products = response.data;
                        callback({
                            recordsTotal: response.recordsTotal,
                            recordsFiltered: response.recordsFiltered,
                            data: []
                        });
                    });
            },
            columns
        };
    }

    private async reloadTable() {
        let dtInstance = await this.dtElement.dtInstance;
        dtInstance.ajax.reload();
    }

    delete(product: any): void {
        swal.fire({
            title: '¿Estás seguro que deseas eliminar este registro?',
            showCancelButton: true,
            confirmButtonText: 'Si, elimínalo!',
            showLoaderOnConfirm: true,
            preConfirm: (arg) => {
                return this.productService.delete(product.id)
                    .pipe(first())
                    .subscribe(response => {
                        swal.fire({
                            title: 'Elemento eliminado con éxito',
                            type: 'success'
                        });
                        this.reloadTable();
                    }, error => {
                        swal.fire({
                            title: error,
                            type: 'error'
                        });
                        return error;
                    });
            },
            allowOutsideClick: () => !swal.isLoading()
        }).then((result) => {
        });
    }

    showDetail(product: any): void {
        this.ngxSpinnerService.show();
        this.productService.productAllBranches(product.id).subscribe(response => {
            this.ngxSpinnerService.hide();
            this.dialog.open(ProductDetailComponent, {
                data: response.data,
                width: '50%'
            });
        }, error1 => {
            this.ngxSpinnerService.hide();
            this.toastrService.error(error1);
            this.messageService.error(error1);
        });
    }

    exportList(): void {
        this.exporting = true;
        this.productService.export().subscribe(blob => {
            this.exporting = false;
            FileSaver.saveAs(blob, 'Productos.xlsx');
        }, error1 => {
            this.exporting = false;
            this.toastrService.error(error1);
        });
    }
}
