<h1 mat-dialog-title>Detalle de {{data.name}}</h1>
<mat-dialog-content>
    <div class="row">
        <div class="col-md-6">
            <div class="text-center">
                <img [defaultImage]="'/assets/core/images/icons/product-icon.jpg'"
                     [lazyLoad]="data.photo_url" class="img-fluid img-product"
                     [alt]="data.name">
            </div>
            <h5 class="text-center name-product">{{data.name}}</h5>
            <h5 class="text-center code-product">{{data.code}}</h5>
        </div>
        <div class="col-md-6">
            <div class="table-responsive">
                <table class="table table-hover table-bordered">
                    <thead>
                    <tr>
                        <th>Sucursal</th>
                        <th *ngIf="currentSettings.product_location">Ubicación</th>
                        <th>Stock</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let branchStock of data.branches_stock">
                        <td>{{branchStock.branch_name}}</td>
                        <th *ngIf="currentSettings.product_location">{{branchStock.location_name}}</th>
                        <td>{{branchStock.stock}} und.</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button class="btn btn-outline-dark mx-3" mat-dialog-close>Cerrar</button>
</mat-dialog-actions>
