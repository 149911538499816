<!-- BEGIN: Subheader -->
<div class="m-subheader ">
    <div class="d-flex align-items-center">
        <div class="mr-auto">
            <h3 class="m-subheader__title m-subheader__title--separator">Movimientos de Caja</h3>
            <ul class="m-subheader__breadcrumbs m-nav m-nav--inline">
                <li class="m-nav__item m-nav__item--home">
                    <a [routerLink]="''" class="m-nav__link m-nav__link--icon">
                        <i class="m-nav__link-icon la la-home"></i>
                    </a>
                </li>
                <li class="m-nav__separator">-</li>
                <li class="m-nav__item">
                    <a [routerLink]="'/finances'" class="m-nav__link">
                        <span class="m-nav__link-text">Caja Chica</span>
                    </a>
                </li>
                <li class="m-nav__separator">-</li>
                <li class="m-nav__item">
                    <a [routerLink]="'/finances/cash-movements'" class="m-nav__link">
                        <span class="m-nav__link-text">Movimientos de Caja Chica</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>

<div class="m-content">
    <div class="row">
        <div class="col-md-12">
            <div class="d-flex flex-row align-items-center mt-4">
                <div class="col-md-2">
                    <h2>{{smallBox.starting_amount | currency:'S/ '}}</h2>
                    <p>Monto Inicial en Caja</p>
                </div>
                <div class="col-md-2">
                    <h2>{{smallBox.cash_amount | currency:'S/ '}}</h2>
                    <p>Total de Ventas en Efectivo</p>
                </div>
                <div class="col-md-2">
                    <h2>{{smallBox.total_movements | currency:'S/ '}}</h2>
                    <p>Entrada / Salida en Caja</p>
                </div>
                <div class="col-md-2">
                    <h2>{{smallBox.current_amount | currency:'S/ '}}</h2>
                    <p>Monto Actual en Caja</p>
                </div>
                <div class="col-md-4">
                    <div class="text-right">
                        <button class="btn bg-info mr-3" (click)="openModalMovement('inflow')">Ingreso de dinero
                        </button>
                        <button class="btn bg-green" (click)="openModalMovement('outflow')">Salida de dinero</button>
                    </div>
                </div>
            </div>

            <div class="p-3 bg-white">
                <table class="table table-bordered bg-white">
                    <thead>
                    <tr>
                        <th>Tipo</th>
                        <th>Fecha</th>
                        <th>Motivo</th>
                        <th>Monto</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let item of smallBox.details">
                        <td>{{item.type == 'inflow' ? 'Entrada' : 'Salida'}}</td>
                        <td>{{item.created_at | date:'d/m/Y'}}</td>
                        <td>{{item.description}}</td>
                        <td>{{item.amount | currency: 'S/ '}}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
