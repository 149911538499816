import {Component, OnInit} from '@angular/core';
import {SettingService} from '../../services/setting.service';
import {Setting} from '../../models/setting';
import {first} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgxSpinnerService} from 'ngx-spinner';
import {CurrencyService} from '../../services/currency.service';
import {ResourceService} from "../../services/resource.service";

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

    formElectronicBilling: FormGroup;
    formQrNiubiz: FormGroup;
    formVoucher: FormGroup;
    submitted = false;
    loading = false;
    submittedFormQrNiubiz = false;
    loadingFormQrNiubiz = false;

    submittedFormVoucher = false;
    loadingFormVoucher = false;
    pathFile = 'Elegir Foto';

    setting: Setting = new Setting();
    igvIncludedUpdating = false;
    multiSitedUpdating = false;
    productLocationUpdating = false;
    salesOutOfStockUpdating = false;
    discountsEnabledAdminUpdating = false;
    discountsEnabledSellerUpdating = false;
    electronicBillingUpdating = false;
    qrNiubizUpdating = false;
    igvPercentageUpdating = false;

    currencies: any[] = [];

    settingCurrencies: any[] = [
        {
            currency_id: null
        }
    ];
    currencyUpdating = false;
    mandatoryProviderIdPurchasesUpdating = false;
    saleOfServicesUpdating = false;

    constructor(
        private formBuilder: FormBuilder,
        private toastrService: ToastrService,
        private settingService: SettingService,
        private currencyService: CurrencyService,
        private resourceService: ResourceService,
        private ngxSpinnerService: NgxSpinnerService,
    ) {
    }

    ngOnInit(): void {
        this.formElectronicBilling = this.formBuilder.group({
            serial_invoice: [null, Validators.required],
            last_number_invoice: [null, Validators.required],
            serial_credit_note_invoice: [null, Validators.required],
            last_number_credit_note_invoice: [null, Validators.required],
            serial_debit_note_invoice: [null, Validators.required],
            last_number_debit_note_invoice: [null, Validators.required],
            serial_ticket: [null, Validators.required],
            last_number_ticket: [null, Validators.required],
            serial_credit_note_ticket: [null, Validators.required],
            last_number_credit_note_ticket: [null, Validators.required],
            serial_debit_note_ticket: [null, Validators.required],
            last_number_debit_note_ticket: [null, Validators.required],
        });

        this.formQrNiubiz = this.formBuilder.group({
            qr_niubiz_username: [null, Validators.required],
            qr_niubiz_password: [null, Validators.required],
            qr_niubiz_merchant_id: [null, Validators.required],
        });

        this.formVoucher = this.formBuilder.group({
            merchant_logo: [null],
            merchant_logo_url: [null],
            business_name: [null],
            address: [null],
            email_contact: [null],
            department: [null],
            province: [null],
            district: [null],
        });

        this.ngxSpinnerService.show();
        this.settingService.currentSettingsServer(1).subscribe(response => {
            const settings = Object.assign({}, response.data);
            this.setting = Object.assign({}, settings);
            delete settings.qr_niubiz_username;
            delete settings.qr_niubiz_password;
            delete settings.qr_niubiz_merchant_id;
            this.settingService.updateCurrentSettings(settings);
            this.formElectronicBilling.patchValue(this.setting);
            this.formQrNiubiz.patchValue(this.setting);
            this.formVoucher.patchValue(this.setting);

            // if (this.setting.kipu_mode == 'vehicle') {
            this.currencyService.getAll().subscribe(responseCurrencies => {
                this.currencies = responseCurrencies.data;
                this.ngxSpinnerService.hide();
            }, error1 => {
                this.ngxSpinnerService.hide();
                this.toastrService.error(error1);
            });
            this.settingCurrencies = settings.setting_currencies;
            // } else {
            //     this.ngxSpinnerService.hide();
            // }
        }, error1 => {
            this.ngxSpinnerService.hide();
            this.toastrService.error(error1);
        });
    }

    get f() {
        return this.formElectronicBilling.controls;
    }

    get fQrNiubiz() {
        return this.formQrNiubiz.controls;
    }

    get fVoucher() {
        return this.formVoucher.controls;
    }

    updateParam(paramName): void {
        if (paramName == 'qr_niubiz') {
            if (!this.setting[paramName]) {
                this.setUpdating(paramName, true);
                this.settingService.updateParamServer({
                    name: paramName,
                    value: this.setting[paramName]
                }).pipe(first()).subscribe(response => {
                    this.setUpdating(paramName, false);
                    this.toastrService.success(response.message);
                }, error1 => {
                    this.setUpdating(paramName, false);
                    this.toastrService.error(error1);
                    this.setting[paramName] = !this.setting[paramName];
                });
            }
        } else {
            this.setUpdating(paramName, true);
            this.settingService.updateParamServer({name: paramName, value: this.setting[paramName]}).pipe(first()).subscribe(response => {
                this.setUpdating(paramName, false);
                this.toastrService.success(response.message);
            }, error1 => {
                this.setUpdating(paramName, false);
                this.toastrService.error(error1);
                this.setting[paramName] = !this.setting[paramName];
            });
        }
    }

    setUpdating(paramName, val): void {
        switch (paramName) {
            case 'igv_included':
                this.igvIncludedUpdating = val;
                break;
            case 'multi_site':
                this.multiSitedUpdating = val;
                break;
            case 'product_location':
                this.productLocationUpdating = val;
                break;
            case 'sales_out_of_stock':
                this.salesOutOfStockUpdating = val;
                break;
            case 'discounts_enabled_admin':
                this.discountsEnabledAdminUpdating = val;
                break;
            case 'discounts_enabled_seller':
                this.discountsEnabledSellerUpdating = val;
                break;
            case 'electronic_billing':
                this.electronicBillingUpdating = val;
                break;
            case 'igv_percentage':
                this.igvPercentageUpdating = val;
                break;
            case 'qr_niubiz':
                this.qrNiubizUpdating = val;
                break;
            case 'mandatory_provider_id_purchases':
                this.mandatoryProviderIdPurchasesUpdating = val;
                break;
            case 'sale_of_services':
                this.saleOfServicesUpdating = val;
                break;
        }
    }

    sendFormElectronicBilling(): void {
        this.submitted = true;
        if (!this.formElectronicBilling.valid) {
            return;
        }
        this.loading = true;
        this.settingService.updateElectronicBillingParams(this.formElectronicBilling.value).pipe(first()).subscribe(response => {
            this.toastrService.success(response.message);
            this.loading = false;
        }, error1 => {
            this.toastrService.error(error1);
            this.loading = false;
        });
    }

    sendFormQrNiubiz(): void {
        this.submittedFormQrNiubiz = true;
        if (!this.formQrNiubiz.valid) {
            return;
        }
        this.loadingFormQrNiubiz = true;
        this.settingService.updateQrNiubiz(this.formQrNiubiz.value).pipe(first()).subscribe(response => {
            this.toastrService.success(response.message);
            this.loadingFormQrNiubiz = false;
        }, error1 => {
            this.toastrService.error(error1);
            this.loadingFormQrNiubiz = false;
        });
    }

    addItemCurrency(i: number): void {
        this.settingCurrencies.splice(i + 1, 0, {
            currency_id: null
        });
    }

    removeItemCurrency(i: number): void {
        this.settingCurrencies.splice(i, 1);
    }

    updateCurrencies(): void {
        this.currencyUpdating = true;
        this.settingService.updateCurrencies(this.settingCurrencies).subscribe(response => {
            this.toastrService.success(response.message);
            this.currencyUpdating = false;
        }, error1 => {
            this.toastrService.error(error1);
            this.currencyUpdating = false;
        });
    }

    onSelectFile(fileInput: any) {
        let photoFile = <File> fileInput.target.files[0];
        if (photoFile) {
            const formData = new FormData();
            formData.append('file', photoFile);
            formData.append('path', 'images/merchants');
            this.ngxSpinnerService.show();
            this.resourceService.uploadFile(formData).subscribe(response => {
                this.formVoucher.controls.merchant_logo.setValue(response.file);
                this.formVoucher.controls.merchant_logo_url.setValue(response.file_url);
                this.setting['merchant_logo'] = response.file;
                this.setting['merchant_logo_url'] = response.file_url;
                this.pathFile = photoFile.name;
                this.ngxSpinnerService.hide();
            }, error => {
                this.ngxSpinnerService.hide();
                this.toastrService.error(error);
            });
        }
    }

    sendFormVoucher(): void {
        this.submittedFormVoucher = true;
        if (!this.formVoucher.valid) {
            return;
        }
        this.loadingFormVoucher = true;
        this.settingService.updateVoucherParams(this.formVoucher.value).subscribe(response => {
            this.toastrService.success(response.message);
            this.loadingFormVoucher = false;
        }, error1 => {
            this.toastrService.error(error1);
            this.loadingFormVoucher = false;
        });
    }
}
