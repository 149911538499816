import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute, Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {first} from 'rxjs/operators';
import {BranchBoxService} from '../../../services/branch-box.service';
import {BranchService} from '../../../services/branch.service';
import {Select2OptionData} from 'ng-select2';

@Component({
  selector: 'app-branch-box-add-edit',
  templateUrl: './branch-box-add-edit.component.html',
  styleUrls: ['./branch-box-add-edit.component.scss']
})
export class BranchBoxAddEditComponent implements OnInit {

    form: FormGroup;
    item: any = {};
    submitted = false;
    loading = false;
    id: number;
    // countRequests = 0;
    // maxRequest = 1;
    branches: Array<Select2OptionData> = [];
    branchText = '';

    constructor(
        private router: Router,
        private formBuilder: FormBuilder,
        private spinner: NgxSpinnerService,
        private toastrService: ToastrService,
        private branchService: BranchService,
        private activatedRoute: ActivatedRoute,
        private branchBoxService: BranchBoxService,
    ) {
    }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            branch_id: [null, Validators.required],
            name: ['', Validators.required],
            status: ['1', Validators.required],
        });
        this.id = this.activatedRoute.snapshot.params['id'];
        if (this.id) {
            // this.maxRequest = 2;
            this.spinner.show();
            this.branchBoxService.show(this.id).subscribe(response => {
                this.form.patchValue(response.data);
                this.item = response.data;
                // this.countRequests++;
                // if (this.countRequests == this.maxRequest) {
                //     this.spinner.hide();
                // }

                this.branchService.all(1, 0).subscribe(responseBranches => {
                    this.branches = responseBranches.data.map(item => {
                        return {...item, ...{text: item.name}};
                    });
                    const branchFound: any = this.branches.find(branch => branch.id == this.item.branch_id);
                    if (branchFound) {
                        this.branchText = branchFound.name;
                    }
                    this.spinner.hide();
                }, error1 => {
                    this.spinner.hide();
                    this.toastrService.error(error1);
                });
            }, error1 => {
                this.spinner.hide();
                this.router.navigate(['/']);
            });
        } else {
            this.branchService.all(1, 0).subscribe(response => {
                this.branches = response.data.map(item => {
                    return {...item, ...{text: item.name}};
                });
                this.spinner.hide();
            }, error1 => {
                this.spinner.hide();
                this.toastrService.error(error1);
            });
        }
    }

    get f() {
        return this.form.controls;
    }

    sendForm(): void {
        this.submitted = true;
        if (!this.form.valid) {
            return;
        }
        this.loading = true;
        let item = Object.assign({}, this.form.value);
        if (this.id != null) {
            item.id = this.id;
            this.branchBoxService.update(item).pipe(first()).subscribe(response => {
                this.loading = false;
                this.toastrService.success(response.message, 'Éxito!');
                this.router.navigate(['/branch-boxes']);
            }, error => {
                this.toastrService.error(error);
                this.loading = false;
            });
        } else {
            this.branchBoxService.store(item).pipe(first()).subscribe(response => {
                this.loading = false;
                this.toastrService.success(response.message, 'Éxito!');
                this.router.navigate(['/branch-boxes']);
            }, error => {
                this.toastrService.error(error);
                this.loading = false;
            });
        }
    }
}
