import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-cart-item',
    templateUrl: './cart-item.component.html',
    styleUrls: ['./cart-item.component.scss']
})
export class CartItemComponent implements OnInit {

    @Input() item;
    @Input() currentSettings;
    @Input() form;

    constructor() {
    }

    ngOnInit(): void {
    }

    get totalItem(): number {
        if (this.currentSettings.kipu_mode == 'restaurant') {
            return (this.item.price - this.item.discount) * this.item.quantity;
            // this.item.extras.forEach(extra => {
            //     if (extra.quantity) {
            //         subtotal += extra.price * extra.quantity;
            //     }
            // });
            // return subtotal;
        } else {
            return (this.item.price - this.item.discount) * this.item.quantity;
        }
    }
}
