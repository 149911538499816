import {Component, OnInit, ViewChild} from '@angular/core';
import {DataTableDirective} from 'angular-datatables';
import {SaleService} from '../../../services/sale.service';
import {first} from 'rxjs/operators';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {NgxSpinnerService} from 'ngx-spinner';
import {MessageService} from '../../../services/message.service';
import {ToastrService} from 'ngx-toastr';
import {MatDialog} from '@angular/material/dialog';
import {SaleDetailModalComponent} from './sale-detail-modal/sale-detail-modal.component';
import {AuthenticationService} from '../../../services/authentication.service';
import {Router} from '@angular/router';
import {webServiceConfig} from '../../../config/web-service.config';
import {BranchService} from '../../../services/branch.service';
import {BranchBoxService} from '../../../services/branch-box.service';
import {Select2OptionData} from 'ng-select2';
import * as FileSaver from 'file-saver';

declare var swal: any;

export const DATEPICKER_FORMAT = {
    parse: {
        dateInput: 'LL',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@Component({
    selector: 'app-sales-report',
    templateUrl: './sales-report.component.html',
    styleUrls: ['./sales-report.component.scss'],
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        },

        {provide: MAT_DATE_FORMATS, useValue: DATEPICKER_FORMAT},
    ]
})
export class SalesReportComponent implements OnInit {

    dtOptions: DataTables.Settings = {};
    items: any[];
    @ViewChild(DataTableDirective) private dtElement;

    form = {
        from_date: null,
        to_date: null,
        branch_id: '-',
        branch_box_id: '-',
    };

    branches: Array<Select2OptionData> = [];
    branchBoxes: Array<Select2OptionData> = [];
    firstLoadBranchSelect = false;
    // firstLoadBranchBoxSelect = false;
    exporting = false;
    exportingProducts = false;

    constructor(
        private router: Router,
        private dialog: MatDialog,
        private saleService: SaleService,
        private branchService: BranchService,
        private toastrService: ToastrService,
        private messageService: MessageService,
        private branchBoxService: BranchBoxService,
        private ngxSpinnerService: NgxSpinnerService,
        private authenticationService: AuthenticationService,
    ) {
    }

    ngOnInit(): void {
        this.ngxSpinnerService.show();
        this.branchService.all(1, 0).subscribe(response => {
            this.branches = response.data.map(item => {
                return {...item, ...{text: item.name}};
            });
            this.branches.unshift({id: '-', text: 'Todas las sucursales'});
            this.ngxSpinnerService.hide();
        }, error1 => {
            this.ngxSpinnerService.hide();
            this.toastrService.error(error1);
            this.messageService.error(error1);
        });

        this.dtOptions = {
            pagingType: 'full_numbers',
            pageLength: 10,
            serverSide: true,
            processing: true,
            order: [[0, 'desc']],
            ajax: (dataTablesParameters: any, callback) => {
                const form = Object.assign({}, this.form);
                if (form.branch_id == '-') {
                    form.branch_id = null;
                }
                if (form.branch_box_id == '-') {
                    form.branch_box_id = null;
                }
                if (form.from_date) {
                    form.from_date = form.from_date.format('YYYY-MM-DD');
                }
                if (form.to_date) {
                    form.to_date = form.to_date.format('YYYY-MM-DD');
                }
                dataTablesParameters = {...dataTablesParameters, ...form};
                this.saleService.datatable(dataTablesParameters)
                    .pipe(first())
                    .subscribe(response => {
                        this.items = response.data;
                        callback({
                            recordsTotal: response.recordsTotal,
                            recordsFiltered: response.recordsFiltered,
                            data: []
                        });
                    });
            },
            columns: [
                {data: 'created_at'},
                {data: 'seller_name'},
                {data: 'customer_name'},
                {data: 'customer_document_number'},
                {data: 'branch_name'},
                {data: 'payment_method_text'},
                {data: 'voucher_type_text'},
                {data: 'serial'},
                {data: 'correlative'},
                {data: 'total'},
                {data: 'status_text'},
                {data: 'electronic_voucher'},
            ]
        };
    }

    async reloadTable() {
        const dtInstance = await this.dtElement.dtInstance;
        dtInstance.ajax.reload();
    }

    cancelSale(item): void {
        swal.fire({
            title: '¿Estás seguro qué deseas anular esta venta?',
            input: 'text',
            inputAttributes: {
                autocapitalize: 'off'
            },
            type: 'question',
            showCancelButton: true,
            confirmButtonText: 'Anular',
            cancelButtonText: 'Cerrar',
            showLoaderOnConfirm: true,
            inputPlaceholder: '¿Por qué anula la venta?',
            preConfirm: (cancellation_reason) => {
                return fetch(`${webServiceConfig.webServiceUrl}sales/cancel/${item.id}`, {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        Authorization: `Bearer ${this.authenticationService.currentUserValue.token}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({cancellation_reason})
                }).then(response => {
                    if (response.status == 401) {
                        // logout
                        this.authenticationService.logout();
                        this.router.navigate(['login']);
                        location.reload();
                    }
                    if (!response.ok) {
                        response.json().then(result => {
                            swal.fire({
                                title: result.message,
                                type: 'error',
                            });
                        });
                        return false;
                    }
                    return response.json();
                }).catch(error => {
                    swal.fire({
                        title: error,
                        type: 'error'
                    });
                    return false;
                });
            },
            allowOutsideClick: () => !swal.isLoading()
        }).then((result) => {
            if (result.value) {
                swal.fire({
                    title: 'Éxito',
                    text: result.value.message,
                    type: 'success'
                });
                this.reloadTable();
            }
        });
    }

    showDetail(item): void {
        this.ngxSpinnerService.show();
        this.saleService.show(item.id).pipe(first()).subscribe(response => {
            this.ngxSpinnerService.hide();
            this.dialog.open(SaleDetailModalComponent, {
                data: response.data,
                width: '50%'
            });
        }, error1 => {
            this.ngxSpinnerService.hide();
            this.messageService.error(error1);
            this.toastrService.error(error1);
        });
    }

    onSelectBranchId(): void {
        if (this.firstLoadBranchSelect) {
            this.reloadTable();
            this.form.branch_box_id = '-';
            this.ngxSpinnerService.show();
            this.branchBoxService.getByBranchId(this.form.branch_id == '-' ? null : this.form.branch_id).subscribe(response => {
                this.branchBoxes = response.data.map(item => {
                    return {...item, ...{text: item.name}};
                });
                this.branchBoxes.unshift({id: '-', text: 'Todas las cajas'});
                this.ngxSpinnerService.hide();
            }, error1 => {
                this.ngxSpinnerService.hide();
                this.toastrService.error(error1);
                this.messageService.error(error1);
            });
        } else {
            this.firstLoadBranchSelect = true;
        }
    }

    exportList(): void {
        this.exporting = true;
        this.saleService.export().subscribe(blob => {
            this.exporting = false;
            FileSaver.saveAs(blob, 'Ventas.xlsx');
        }, error1 => {
            this.exporting = false;
            this.toastrService.error(error1);
        });
    }

    exportListByProducts(): void {
        this.exportingProducts = true;
        this.saleService.exportByProducts().subscribe(blob => {
            this.exportingProducts = false;
            FileSaver.saveAs(blob, 'Ventas por productos.xlsx');
        }, error1 => {
            this.exportingProducts = false;
            this.toastrService.error(error1);
        });
    }
}
