import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AuthGuard} from './guards/auth.guard';
import {LayoutComponent} from './components/layout/layout.component';
import {HomeComponent} from './components/home/home.component';
import {GuestGuard} from './guards/guest.guard';
import {LoginComponent} from './components/auth/login/login.component';
import {BranchesComponent} from './components/branches/branches.component';
import {BranchAddEditComponent} from './components/branches/branch-add-edit/branch-add-edit.component';
import {ProductsComponent} from './components/products/products.component';
import {ProductAddEditComponent} from './components/products/product-add-edit/product-add-edit.component';
import {CustomersComponent} from './components/customers/customers.component';
import {CustomerAddEditComponent} from './components/customers/customer-add-edit/customer-add-edit.component';
import {ProvidersComponent} from './components/providers/providers.component';
import {ProviderAddEditComponent} from './components/providers/provider-add-edit/provider-add-edit.component';
import {CategoriesComponent} from './components/categories/categories.component';
import {CategoryAddEditComponent} from './components/categories/category-add-edit/category-add-edit.component';
import {InventoryAddEditComponent} from './components/inventory/inventory-add-edit/inventory-add-edit.component';
import {CreditNoteComponent} from './components/sale/credit-note/credit-note.component';
import {SaleCreateComponent} from './components/sales/sale-create/sale-create.component';
import {FinancesComponent} from './components/finances/finances.component';
import {CashMovementsComponent} from './components/finances/cash-movements/cash-movements.component';
import {AssistanceComponent} from './components/assistance/assistance.component';
import {AssistanceReportComponent} from './components/assistance/assistance-report/assistance-report.component';
import {SalesReportComponent} from './components/reports/sales-report/sales-report.component';
import {InventoryReportComponent} from './components/reports/inventory-report/inventory-report.component';
import {PurchaseReportComponent} from './components/reports/purchase-report/purchase-report.component';
import {ReferralGuideComponent} from './components/reports/referral-guide/referral-guide.component';
import {CashReportComponent} from './components/reports/cash-report/cash-report.component';
import {SettingsComponent} from './components/settings/settings.component';
import {LocationsComponent} from './components/locations/locations.component';
import {LocationAddEditComponent} from './components/locations/location-add-edit/location-add-edit.component';
import {PurchaseCreateComponent} from './components/purchases/purchase-create/purchase-create.component';
import {TransferCreateComponent} from './components/transfers/transfer-create/transfer-create.component';
import {BranchBoxesComponent} from './components/branch-boxes/branch-boxes.component';
import {BranchBoxAddEditComponent} from './components/branch-boxes/branch-box-add-edit/branch-box-add-edit.component';
import {UsersComponent} from './components/users/users.component';
import {UserAddEditComponent} from './components/users/user-add-edit/user-add-edit.component';
import {SalonsComponent} from './components/salons/salons.component';
import {SalonAddEditComponent} from './components/salons/salon-add-edit/salon-add-edit.component';
import {MenusComponent} from './components/menus/menus.component';
import {MenuAddEditComponent} from './components/menus/menu-add-edit/menu-add-edit.component';
import {SalonTablesComponent} from './components/salon-tables/salon-tables.component';
import {SalonTableAddEditComponent} from './components/salon-tables/salon-table-add-edit/salon-table-add-edit.component';
import {PurchasesReportComponent} from './components/reports/purchases-report/purchases-report.component';
import {PromotionsComponent} from './components/promotions/promotions.component';
import {PromotionAddEditComponent} from './components/promotions/promotion-add-edit/promotion-add-edit.component';
import {WarehouseComponent} from './components/warehouse/warehouse.component';
import {PurchasePaymentMethodsComponent} from './components/purchase-payment-methods/purchase-payment-methods.component';
import {PurchasePaymentMethodAddEditComponent} from './components/purchase-payment-methods/purchase-payment-method-add-edit/purchase-payment-method-add-edit.component';
import {ExchangeRatesComponent} from './components/exchange-rates/exchange-rates.component';
import {ExchangeRateAddComponent} from './components/exchange-rates/exchange-rate-add/exchange-rate-add.component';
import {ExchangeRateEditComponent} from './components/exchange-rates/exchange-rate-edit/exchange-rate-edit.component';

const routes: Routes = [
    {
        path: '', component: LayoutComponent,
        canActivate: [AuthGuard],
        children: [
            {path: '', component: HomeComponent},

            //branches
            {path: 'branches', component: BranchesComponent},
            {path: 'branches/create', component: BranchAddEditComponent},
            {path: 'branches/edit/:id', component: BranchAddEditComponent},

            //branch-boxes
            {path: 'branch-boxes', component: BranchBoxesComponent},
            {path: 'branch-boxes/create', component: BranchBoxAddEditComponent},
            {path: 'branch-boxes/edit/:id', component: BranchBoxAddEditComponent},

            //locations
            {path: 'locations', component: LocationsComponent},
            {path: 'locations/create', component: LocationAddEditComponent},
            {path: 'locations/edit/:id', component: LocationAddEditComponent},

            //products
            {path: 'products', component: ProductsComponent},
            {path: 'products/create', component: ProductAddEditComponent},
            {path: 'products/edit/:id', component: ProductAddEditComponent},
            {path: 'products/warehouse', component: WarehouseComponent},

            //products
            {path: 'promotions', component: PromotionsComponent},
            {path: 'promotions/create', component: PromotionAddEditComponent},
            {path: 'promotions/edit/:id', component: PromotionAddEditComponent},

            //clients
            {path: 'customers', component: CustomersComponent},
            {path: 'customers/create', component: CustomerAddEditComponent},
            {path: 'customers/edit/:id', component: CustomerAddEditComponent},

            //providers
            {path: 'providers', component: ProvidersComponent},
            {path: 'providers/create', component: ProviderAddEditComponent},
            {path: 'providers/edit/:id', component: ProviderAddEditComponent},

            //categories
            {path: 'categories', component: CategoriesComponent},
            {path: 'categories/create', component: CategoryAddEditComponent},
            {path: 'categories/edit/:id', component: CategoryAddEditComponent},

            //salons
            {path: 'salons', component: SalonsComponent},
            {path: 'salons/create', component: SalonAddEditComponent},
            {path: 'salons/edit/:id', component: SalonAddEditComponent},

            //salon-tables
            {path: 'salon-tables', component: SalonTablesComponent},
            {path: 'salon-tables/create', component: SalonTableAddEditComponent},
            {path: 'salon-tables/edit/:id', component: SalonTableAddEditComponent},

            //salons
            {path: 'menus', component: MenusComponent},
            {path: 'menus/create', component: MenuAddEditComponent},
            {path: 'menus/edit/:id', component: MenuAddEditComponent},

            //inventory
            {path: 'inventory', component: InventoryAddEditComponent},
            {path: 'inventory/create', component: InventoryAddEditComponent},
            {path: 'inventory/edit/:id', component: InventoryAddEditComponent},

            //purchase-payment-methods
            {path: 'purchase-payment-methods', component: PurchasePaymentMethodsComponent},
            {path: 'purchase-payment-methods/create', component: PurchasePaymentMethodAddEditComponent},
            {path: 'purchase-payment-methods/edit/:id', component: PurchasePaymentMethodAddEditComponent},

            //purchases
            {path: 'purchases/create', component: PurchaseCreateComponent},

            //transfers
            {path: 'transfers/create', component: TransferCreateComponent},

            //Finances
            {path: 'small-box/create-close', component: FinancesComponent},
            {path: 'small-box/cash-movements', component: CashMovementsComponent},

            //assistance
            {path: 'assistance', component: AssistanceComponent},
            {path: 'assistance/assistance-report', component: AssistanceReportComponent},

            //reports
            {path: 'report/inventory-report', component: InventoryReportComponent},
            {path: 'reports/sales', component: SalesReportComponent},
            {path: 'reports/purchases', component: PurchasesReportComponent},
            {path: 'report/purchase-report', component: PurchaseReportComponent},
            {path: 'report/referral-guide', component: ReferralGuideComponent},
            {path: 'report/cash-report', component: CashReportComponent},

            //sales
            {path: 'sales/create', component: SaleCreateComponent},
            {path: 'creditNote', component: CreditNoteComponent},

            //Settings
            {path: 'settings', component: SettingsComponent},

            //Users
            {path: 'users', component: UsersComponent},
            {path: 'users/create', component: UserAddEditComponent},
            {path: 'users/edit/:id', component: UserAddEditComponent},

            //Exchange Rates
            {path: 'exchange-rates', component: ExchangeRatesComponent},
            {path: 'exchange-rates/create', component: ExchangeRateAddComponent},
            {path: 'exchange-rates/edit/:id', component: ExchangeRateEditComponent},
        ]
    },
    {
        path: 'login',
        component: LoginComponent,
        canActivate: [GuestGuard]
    },
    // otherwise redirect to home
    {path: '**', redirectTo: ''}
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
