import {AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {webServiceConfig} from '../../../../config/web-service.config';
import {User} from '../../../../models/user';
import {Setting} from '../../../../models/setting';
import {GridUserProductService} from '../../../../services/grid-user-product.service';
import {AuthenticationService} from '../../../../services/authentication.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {SmallBoxService} from '../../../../services/small-box.service';
import {MessageService} from '../../../../services/message.service';
import {SettingService} from '../../../../services/setting.service';
import {ProductService} from '../../../../services/product.service';
import {ToastrService} from 'ngx-toastr';
import {SaleService} from '../../../../services/sale.service';
import {MatDialog} from '@angular/material/dialog';
import {Util} from '../../../../helpers/util';
import {ProductDetailComponent} from '../../../products/product-detail/product-detail.component';
import {SaleSelectCurrencyModalComponent} from '../sale-select-currency-modal/sale-select-currency-modal.component';
import {ProductCartModalComponent} from '../product-cart-modal/product-cart-modal.component';
import {SaleItemServiceModalComponent} from '../sale-item-service-modal/sale-item-service-modal.component';

declare var swal: any;

@Component({
    selector: 'app-sale-create-retail',
    templateUrl: './sale-create-retail.component.html',
    styleUrls: ['./sale-create-retail.component.scss']
})
export class SaleCreateRetailComponent implements OnInit, AfterViewInit, OnDestroy {

    grid: any = [];
    searchText = null;
    categories: any = [];
    promotionsSelected = false;
    categorySelected = null;
    favoritesSelected = true;
    arrPromotions: any[] = [];

    //
    defaultCustomer = null;
    disabledDefaultCustomer = false;

    // @ViewChild('select2Search') select2Search: any;
    optionsSelect2 = {
        width: '100%',
        language: 'es',
        delay: 250,
        // placeholder: 'Busca productos, categorías y promociones..',
        placeholder: 'Busca productos y categorías',
        allowClear: true,
        ajax: {
            headers: {
                Authorization: `Bearer ${this.authenticationService.currentUserValue.token}`,
            },
            method: 'get',
            url: `${webServiceConfig.webServiceUrl}products/sale/search`,
            dataType: 'json'
        },
        escapeMarkup: (markup) => {
            return markup;
        },
        templateResult: (data) => {
            return `${data.text} <small class="cursive" style="font-style: italic">${data.comment ? data.comment : ''}</small>`;
        },
    };

    @ViewChild('contentSale') contentSale: ElementRef;
    @ViewChild('cartItems') cartItems: ElementRef;

    form: any = {
        customer_id: null,
        customer: null,
        items: [],
        subtotal: 0,
        igv_amount: 0,
        total: 0,
        payment_method: 1,
        voucher_type: 0,
        cash_paid: null,
        cash_returned: null,
        from_device: 1,
        currentStep: 1,
        //Combined
        cash_amount: 0,
        card_amount: 0,
        yape_amount: 0,
        plin_amount: 0,
        tunki_amount: 0,
        lukita_amount: 0,
        wire_transfer_amount: 0,

        operation_number: null, // Se usa para guardar el número de operación con transferencia bancaria

        //Payment Method Component
        //Cash
        cashAmountSelected: 0,
        cashAmountReturnedSelected: 0,
        otherAmountCash: false,
        otherAmountCashValue: null,

        //currency
        currency_id: null,
        currency_alphabetic_code: null,
        currency_numeric_code: null,
        currency_name: null,
        currency_symbol: null,
    };

    inDataCustomer = true;

    currentUser: User;
    currentSettings: Setting;
    withDiscount = false;
    saleCreated = null;
    businessDataVoucher = null;

    textsBySteps = {
        // 1: 'Continuar con la compra',
        // 2: 'Continuar con la compra',
        1: 'Continuar',
        2: 'Continuar',
        3: 'Emitir comprobante',
    };

    documentTypesTicket = {
        1: 'DNI',
        6: 'RUC',
        4: 'CARNET DE EXTRANJERÍA',
        7: 'PASAPORTE',
        A: 'CÉDULA DIPLOMÁTICA DE IDENTIDAD',
    };

    documentTypesRuc = {
        6: 'RUC',
    };
    submittedCustomer = false;
    barCodeReaderText = '';
    barCodeReaderListener = null;
    barCodeReaderSearching = false;

    constructor(
        private dialog: MatDialog,
        private saleService: SaleService,
        private toastrService: ToastrService,
        private productService: ProductService,
        private settingService: SettingService,
        private messageService: MessageService,
        private smallBoxService: SmallBoxService,
        private ngxSpinnerService: NgxSpinnerService,
        private authenticationService: AuthenticationService,
        private gridUserProductService: GridUserProductService,
    ) {
    }

    ngOnInit(): void {
        this.settingService.currentSettingsSubject.asObservable().subscribe((settings: Setting) => {
            this.currentSettings = settings;
        });
        this.loadCurrencies();

        this.currentUser = this.authenticationService.currentUserValue;
        this.getGrid(1);
        let rolesDiscount = [];
        if (this.currentSettings.discounts_enabled_admin) {
            rolesDiscount.push('admin');
        }
        if (this.currentSettings.discounts_enabled_seller) {
            rolesDiscount.push('seller');
        }
        this.withDiscount = Util.hasAnyRole(this.currentUser.roles, rolesDiscount);

        // this.barCodeReaderListener = setInterval(() => {
        //     this.barCodeReaderText = '';
        // }, 2000);
    }

    ngOnDestroy(): void {
        // if (this.barCodeReaderListener) {
        //     clearInterval(this.barCodeReaderListener);
        // }
    }

    getGrid(getCategories = 0): void {
        this.ngxSpinnerService.show();
        this.gridUserProductService.getByUser(getCategories).subscribe(response => {
            this.ngxSpinnerService.hide();
            // this.loadGrid(response.data.grid);
            this.categories = response.data.categories;
            if (this.categories.length > 0) {
                this.selectCategory(this.categories[0]);
            }
            if (response.data.default_customer) {
                this.defaultCustomer = response.data.default_customer;
                this.setDefaultCustomerSelected();
            }
            this.arrPromotions = response.data.promotions;
            // console.log(response.data);
        }, error1 => {
            this.ngxSpinnerService.hide();
            this.messageService.error(error1);
            this.toastrService.error(error1);
        });
    }

    setDefaultCustomerSelected(): void {
        this.form.customer_id = this.defaultCustomer.id;
        this.form.customer = this.defaultCustomer;
    }

    /*loadGrid(gridResponse = null): void {
        this.grid = [];
        if (gridResponse) {
            for (let i = 0; i < 20; ++i) {
                const existItem = gridResponse.find(item => item.position == i);
                if (existItem) {
                    this.grid.push(existItem);
                } else {
                    this.grid.push({
                        id: null,
                        selected: false,
                        result_type: null,
                        name: null,
                        price: null,
                        class_color: null,
                        position: i,
                    });
                }
            }
        } else {
            for (let i = 0; i < 20; ++i) {
                this.grid.push({
                    id: null,
                    selected: false,
                    result_type: null,
                    name: null,
                    price: null,
                    class_color: null,
                    position: i,
                });
            }
        }
    }*/

    ngAfterViewInit(): void {
        // jQuery(this.select2Search._element.nativeElement).on('select2:select', (e: any) => {
        //     const item = e.params.data;
        //     // if (this.editGrid) {
        //     //     const itemExist = this.grid.find(gridItem => gridItem.id_value == item.id_value && gridItem.result_type == item.result_type);
        //     //     if (itemExist) {
        //     //         this.resetSearchInput();
        //     //         return;
        //     //     }
        //     //     let itemSelected = this.grid.find(gridItem => gridItem.selected);
        //     //     if (itemSelected) {
        //     //         itemSelected.id = item.id;
        //     //         itemSelected.id_value = item.id_value;
        //     //         itemSelected.selected = false;
        //     //         itemSelected.result_type = item.result_type;
        //     //         itemSelected.name = item.name;
        //     //         itemSelected.price = item.price;
        //     //         itemSelected.class_color = item.class_color;
        //     //     }
        //     // } else {
        //     switch (item.result_type) {
        //         case 'category':
        //             const categorySelected = this.categories.find(category => category.id == item.id_value);
        //             this.selectCategory(categorySelected);
        //             break;
        //         case 'product':
        //             this.addItemToSale(item);
        //             break;
        //         case 'promotion':
        //             this.addItemToSale(item);
        //             break;
        //     }
        //     // }
        //     // this.resetSearchInput();
        // });
    }

    // resetSearchInput(): void {
    //     setTimeout(() => {
    //         this.searchText = null;
    //     }, 300);
    // }

    selectItemSearch(itemGrid): void {
        // if (this.editGrid) {
        //     const gridItemSelected = this.grid.find(gridItem => gridItem.selected);
        //     if (gridItemSelected) {
        //         gridItemSelected.selected = false;
        //     }
        //     itemGrid.selected = true;
        //     this.currentItemSelected = itemGrid;
        // } else {
        switch (itemGrid.result_type) {
            // case 'category':
            //     this.selectCategory(itemGrid);
            //     break;
            case 'product':
            case 'promotion':
                this.addItemToSale(itemGrid);
                break;
        }
        // }
    }

    showDetail(itemGrid): void {
        switch (itemGrid.result_type) {
            // case 'category':
            //     break;
            case 'product':
                this.ngxSpinnerService.show();
                this.productService.productAllBranches(itemGrid.id_value).subscribe(response => {
                    this.dialog.open(ProductDetailComponent, {
                        data: response.data,
                        width: '50%'
                    });
                    this.ngxSpinnerService.hide();
                }, error1 => {
                    this.ngxSpinnerService.hide();
                    this.toastrService.error(error1);
                    this.messageService.error(error1);
                });
                break;
        }
    }

    addItemToSale(item, quantity = 1): void {
        const itemFound = this.form.items.find(itemAdded => itemAdded.id == item.id);
        if (itemFound) {
            itemFound.quantity++;
            // @ts-ignore
            this.cartItems.calculateTotals();
        } else {
            if (this.currentSettings.product_location) {
                this.ngxSpinnerService.show();
                this.productService.productAllBranches(item.id_value).subscribe(response => {
                    this.ngxSpinnerService.hide();

                    item.quantity = quantity;
                    item.discount = 0;

                    const dialogRef = this.dialog.open(ProductCartModalComponent, {
                        data: {
                            item: JSON.parse(JSON.stringify(item)),
                            withDiscount: this.withDiscount,
                            kipuMode: this.currentSettings.kipu_mode,
                            currentSettings: this.currentSettings,
                            form: this.form,
                            branches_stock: response.data.branches_stock,
                        },
                        width: '50%',
                        disableClose: true
                    });

                    dialogRef.afterClosed().subscribe(resp => {
                        if (resp?.item) {
                            this.form.items.push(resp.item);
                            // @ts-ignore
                            this.cartItems.calculateTotals();
                        }
                    });
                }, error1 => {
                    this.ngxSpinnerService.hide();
                    this.toastrService.error(error1);
                    this.messageService.error(error1);
                });
            } else {
                item.quantity = quantity;
                item.discount = 0;
                this.form.items.push(item);
                // @ts-ignore
                this.cartItems.calculateTotals();
            }
        }
    }

    selectCategory(category): void {
        this.favoritesSelected = false;
        this.promotionsSelected = false;
        this.grid = JSON.parse(JSON.stringify(category.products));
        this.grid = this.grid.filter(gridItem => gridItem.currency_id == this.form.currency_id);
        this.categorySelected = category;
    }

    selectPromotions(): void {
        this.categorySelected = null;
        this.promotionsSelected = true;
        this.grid = JSON.parse(JSON.stringify(this.arrPromotions));
    }

    nextStep(): void {
        if (this.form.currentStep == 1) {
            if (this.form.items == 0) {
                swal.fire({
                    title: 'Aún no has agregado productos',
                    type: 'info'
                });
                return;
            }
            if (this.form.total >= this.currentSettings.max_amount_sunat_ticket) {
                this.disabledDefaultCustomer = true;
                this.inDataCustomer = false;
            } else {
                this.disabledDefaultCustomer = false;
                this.inDataCustomer = true;
            }
            this.setCustomer();
            // this.setDisableDefaultCustomer();

            this.form.currentStep++;
        } else if (this.form.currentStep == 2) {
            this.submittedCustomer = true;
            if (
                !this.form.customer_id &&
                (
                    !this.form.customer ||
                    !this.form.customer.name ||
                    !this.form.customer.document_type ||
                    !this.form.customer.document_number ||
                    (this.form.customer.document_type == 6 && !this.form.customer.address)
                )) {
                swal.fire({
                    title: 'Complete los datos del cliente',
                    type: 'info'
                });
                return;
            }

            // this.submittedCustomer = false;
            this.form.currentStep++;
        } else if (this.form.currentStep == 3) {
            if (this.form.payment_method == 1) {
                if (this.form.otherAmountCash) {
                    if (!this.form.otherAmountCashValue) {
                        swal.fire({
                            title: 'Debe ingresar un monto',
                            type: 'info'
                        });
                        return;
                    }
                    if (this.form.otherAmountCashValue < this.form.total) {
                        swal.fire({
                            title: 'Monto insuficiente',
                            type: 'info'
                        });
                        return;
                    }
                    this.form.cash_paid = Math.round(this.form.otherAmountCashValue * 100) / 100;
                } else {
                    if (!this.form.cashAmountSelected) {
                        swal.fire({
                            title: 'Debe ingresar un monto',
                            type: 'info'
                        });
                        return;
                    }

                    if (this.form.cashAmountSelected < this.form.total) {
                        swal.fire({
                            title: 'Monto insuficiente',
                            type: 'info'
                        });
                        return;
                    }
                    this.form.cash_paid = Math.round(this.form.cashAmountSelected * 100) / 100;
                }
                this.form.cash_returned = Math.round(this.form.cashAmountReturnedSelected * 100) / 100;
                this.form.cash_amount = this.form.total;
                this.storeSale();
            } else if (this.form.payment_method == 3) {
                let amountEntered = 0;
                if (this.form.cash_amount && this.form.cash_amount > 0) {
                    amountEntered += Number(this.form.cash_amount);
                }
                if (this.form.card_amount && this.form.card_amount > 0) {
                    amountEntered += Number(this.form.card_amount);
                }
                if (this.form.wire_transfer_amount && this.form.wire_transfer_amount > 0) {
                    amountEntered += Number(this.form.wire_transfer_amount);
                }
                if (this.form.yape_amount && this.form.yape_amount > 0) {
                    amountEntered += Number(this.form.yape_amount);
                }
                if (this.form.plin_amount && this.form.plin_amount > 0) {
                    amountEntered += Number(this.form.plin_amount);
                }
                if (this.form.tunki_amount && this.form.tunki_amount > 0) {
                    amountEntered += Number(this.form.tunki_amount);
                }
                if (this.form.lukita_amount && this.form.lukita_amount > 0) {
                    amountEntered += Number(this.form.lukita_amount);
                }
                if (amountEntered < this.form.total) {
                    swal.fire({
                        title: 'Monto insuficiente',
                        type: 'info'
                    });
                    return;
                }
                this.form.cash_amount = this.form.cash_amount ? Number(this.form.cash_amount) : 0;
                this.form.card_amount = this.form.card_amount ? Number(this.form.card_amount) : 0;
                this.form.wire_transfer_amount = this.form.wire_transfer_amount ? Number(this.form.wire_transfer_amount) : 0;
                this.form.yape_amount = this.form.yape_amount ? Number(this.form.yape_amount) : 0;
                this.form.plin_amount = this.form.plin_amount ? Number(this.form.plin_amount) : 0;
                this.form.tunki_amount = this.form.tunki_amount ? Number(this.form.tunki_amount) : 0;
                this.form.lukita_amount = this.form.lukita_amount ? Number(this.form.lukita_amount) : 0;
                this.storeSale();
            } else {
                this.storeSale();
            }
        }
    }

    prevStep(): void {
        this.form.currentStep--;
    }

    setCustomer(): void {
        if (this.inDataCustomer) {
            this.form.customer_id = this.defaultCustomer.id;
            this.form.customer = this.defaultCustomer;
        } else {
            this.form.customer_id = null;
            this.form.customer = {
                document_type: 1
            };
        }
    }

    setVoucherType(voucherType): void {
        this.form.voucher_type = voucherType;
        if (this.form.voucher_type == 0 || this.form.voucher_type == 2) {
            if (this.form.total < this.currentSettings.max_amount_sunat_ticket) {
                this.form.customer = this.defaultCustomer;
                this.form.customer_id = this.defaultCustomer.id;
            }
        } else {
            this.form.customer = {
                document_type: 6
            };
            this.form.customer_id = null;
        }
    }

    loadCurrencies(): void {
        if (this.currentSettings.setting_currencies.length > 1) {
            const dialogRef = this.dialog.open(SaleSelectCurrencyModalComponent, {
                data: {
                    setting_currencies: this.currentSettings.setting_currencies,
                    form: this.form,
                },
                width: '70%',
                disableClose: true
            });
            dialogRef.afterClosed().subscribe(resp => {
                this.filterCurrency();
            });
        } else {
            this.form.currency_id = this.currentSettings.setting_currencies[0].currency_id;
            this.form.currency_alphabetic_code = this.currentSettings.setting_currencies[0].currency.alphabetic_code;
            this.form.currency_numeric_code = this.currentSettings.setting_currencies[0].currency.numeric_code;
            this.form.currency_name = this.currentSettings.setting_currencies[0].currency.name;
            this.form.currency_symbol = this.currentSettings.setting_currencies[0].currency.symbol;
            this.filterCurrency();
        }
    }

    filterCurrency(): void {
        this.grid = this.grid.filter(gridItem => gridItem.currency_id == this.form.currency_id);
    }

    storeSale(): void {
        let form = JSON.parse(JSON.stringify(this.form));
        form.items.forEach(item => {
            item.id = item.id_value;
        });

        this.ngxSpinnerService.show();
        this.saleService.store(form).subscribe(response => {
            this.ngxSpinnerService.hide();
            this.messageService.success(response.message);
            setTimeout(() => this.messageService.clear(), 4000);
            this.toastrService.success(response.message);

            this.saleCreated = response.sale;
            this.businessDataVoucher = response.business;
            this.form.id = response.sale_id;

            this.form.currentStep++;
        }, error1 => {
            this.messageService.error(error1);
            this.toastrService.error(error1);
            this.ngxSpinnerService.hide();
        });
    }

    resetForm(): void {
        this.form = {
            customer_id: null,
            customer: null,
            items: [],
            subtotal: 0,
            igv_amount: 0,
            total: 0,
            payment_method: 1,
            voucher_type: 0,
            cash_paid: null,
            cash_returned: null,
            from_device: 1,
            currentStep: 1,
            //Combined
            cash_amount: 0,
            card_amount: 0,
            yape_amount: 0,
            plin_amount: 0,
            tunki_amount: 0,
            lukita_amount: 0,
            wire_transfer_amount: 0,

            operation_number: null, // Se usa para guardar el número de operación con transferencia bancaria

            //Payment Method Component
            //Cash
            cashAmountSelected: 0,
            cashAmountReturnedSelected: 0,
            otherAmountCash: false,
            otherAmountCashValue: null,

            //currency
            currency_id: null,
            currency_alphabetic_code: null,
            currency_numeric_code: null,
            currency_name: null,
            currency_symbol: null,
        };

        this.saleCreated = null;
        this.inDataCustomer = true;
        this.submittedCustomer = false;

        this.setDefaultCustomerSelected();
        this.getGrid(1);
        this.loadCurrencies();
    }

    /*@HostListener('document:keypress', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        if (this.form.currentStep == 1) {
            const target: any = event.target;
            if (target.getAttribute('class') != 'select2-search__field') {
                if (event.key == 'Enter') {
                    this.searchProductByCode();
                } else {
                    this.barCodeReaderText += event.key;
                }
            }
        }
    }*/


    searchProductByCode(): void {
        // if (!this.barCodeReaderSearching) {
        //     this.barCodeReaderSearching = true;
        //     this.ngxSpinnerService.show();
        //     const text = this.barCodeReaderText;
        //     this.barCodeReaderText = '';
        //     this.productService.getByCode(text).subscribe(response => {
        //         this.ngxSpinnerService.hide();
        //         if (response.data) {
        //             this.addItemToSale(response.data);
        //         }
        //         this.barCodeReaderSearching = false;
        //     }, error1 => {
        //         this.barCodeReaderSearching = false;
        //         this.ngxSpinnerService.hide();
        //         console.error(error1);
        //     });
        // }
        this.ngxSpinnerService.show();
        this.productService.getByCode(this.searchText).subscribe(response => {
            this.ngxSpinnerService.hide();
            if (response.data) {
                this.addItemToSale(response.data);
            }
            this.searchText = null;
        }, error1 => {
            this.ngxSpinnerService.hide();
            console.error(error1);
            this.searchText = null;
        });
    }

    searchProducts(): void {
        if (this.searchText) {
            let result = [];
            let promotionResult = this.arrPromotions.filter(o => o.text.toLowerCase().includes(this.searchText.toLowerCase()));
            result = result.concat(promotionResult);

            this.categories.forEach(category => {
                const resultProducts = category.products.filter(o => o.text.toLowerCase().includes(this.searchText.toLowerCase()));
                if (resultProducts.length > 0) {
                    result = result.concat(resultProducts);
                }
            });
            this.grid = JSON.parse(JSON.stringify(result));
        } else {
            if (this.categories.length > 0) {
                this.selectCategory(this.categories[0]);
            }
        }
    }

    addService(): void {
        const dialogRef = this.dialog.open(SaleItemServiceModalComponent, {
            data: {
                currentSettings: this.currentSettings,
                form: this.form
            },
            width: '100%'
        });
        dialogRef.afterClosed().subscribe(resp => {
            if (resp?.item) {
                this.form.items.push(resp.item);
                // @ts-ignore
                this.cartItems.calculateTotals();
            }
        });
    }
}
