<div class="m-5">
    <div class="d-flex flex-row align-items-center justify-content-between">
        <h3>Reporte de Guías de Remisión</h3>
        <div>
            <button class="btn bg-green">Ruc de Cliente:</button>
        </div>
    </div>
    <div class="mt-4">
        <form action="">
            <div class="row">
                <div class="form-group col-md-3">
                    <label for="inputCity">Ruc de Proveedor:</label>
                    <input type="text" class="form-control">
                </div>
                <div class="form-group col-md-3">
                    <label for="inputCity">Desde</label>
                    <div class="input-group date" id="reservationdate" data-target-input="nearest">
                        <input type="text" class="form-control datetimepicker-input" data-target="#reservationdate">
                        <div class="input-group-append" data-target="#reservationdate" data-toggle="datetimepicker">
                            <div class="input-group-text"><i class="fa fa-calendar"></i></div>
                        </div>
                    </div>
                </div>
                <div class="form-group col-md-3">
                    <label for="inputCity">Hasta</label>
                    <div class="input-group date" id="reservationdate" data-target-input="nearest">
                        <input type="text" class="form-control datetimepicker-input" data-target="#reservationdate">
                        <div class="input-group-append" data-target="#reservationdate" data-toggle="datetimepicker">
                            <div class="input-group-text"><i class="fa fa-calendar"></i></div>
                        </div>
                    </div>
                </div>
                <div class="mt-4"><button class="btn btn-primary">Buscar</button></div>
            </div>
        </form>
    </div>
</div>