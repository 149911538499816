import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {User} from '../models/user';
import {HttpClient} from '@angular/common/http';
import {webServiceConfig} from '../config/web-service.config';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {

    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('user')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    login(data): Observable<any> {
        return this.http.post<any>(`${webServiceConfig.webServiceUrl}auth/login`, {...data, ...{device: 'web'}})
            .pipe(map(response => {
                response.user['token'] = response.token;
                response.user['token_type'] = response.token_type;
                localStorage.setItem('user', JSON.stringify(response.user));
                this.currentUserSubject.next(response.user);
                return response;
            }));
    }

    logout(): void {
        this.http.post<any>(`${webServiceConfig.webServiceUrl}auth/logout`, {}).subscribe(response => {
        });
        // remove user from local storage to log user out
        localStorage.clear();
        this.currentUserSubject.next(null);
    }

    isLogged(): boolean {
        return !!(this.currentUserValue);
    }
}
