import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute, Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {first} from 'rxjs/operators';
import {CustomerService} from '../../../services/customer.service';

@Component({
    selector: 'app-client-add-edit',
    templateUrl: './customer-add-edit.component.html',
    styleUrls: ['./customer-add-edit.component.scss']
})
export class CustomerAddEditComponent implements OnInit {

    form: FormGroup;
    client: any = {};
    submitted = false;
    loading = false;
    id: number;

    constructor(
        private router: Router,
        private formBuilder: FormBuilder,
        private spinner: NgxSpinnerService,
        private toastrService: ToastrService,
        private activatedRoute: ActivatedRoute,
        private customerService: CustomerService,
    ) {
    }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            document_type: ['1', Validators.required],
            document_number: ['', Validators.required],
            address: [null],
            name: ['', Validators.required],
            phone: [''],
            email: ['', [Validators.email]],
            status: [1, Validators.required],
        });
        this.id = this.activatedRoute.snapshot.params['id'];
        if (this.id) {
            this.spinner.show();
            this.customerService.show(this.id).pipe(first()).subscribe(response => {
                this.spinner.hide();
                this.form.patchValue(response.data);
                this.client = response.data;
                this.onChangeDocument();
            }, error1 => {
                this.spinner.hide();
                this.router.navigate(['/']);
            });
        }
    }

    get f() {
        return this.form.controls;
    }

    sendForm(): void {
        this.submitted = true;
        if (!this.form.valid) {
            return;
        }
        this.loading = true;
        let client = Object.assign({}, this.form.value);
        if (this.id != null) {
            client.id = this.id;
            this.customerService.update(client).pipe(first()).subscribe(response => {
                this.loading = false;
                this.toastrService.success(response.message, 'Éxito!');
                this.router.navigate(['/customers']);
            }, error => {
                this.toastrService.error(error);
                this.loading = false;
            });
        } else {
            this.customerService.store(client).pipe(first()).subscribe(response => {
                this.loading = false;
                this.toastrService.success(response.message, 'Éxito!');
                this.router.navigate(['/customers']);
            }, error => {
                this.toastrService.error(error);
                this.loading = false;
            });
        }
    }

    onChangeDocument(): void {
        if (this.f.document_type.value == '6') {
            this.f.address.setValidators([Validators.required]);
            this.f.address.reset();
        } else {
            this.f.address.setValidators([]);
        }
    }
}
