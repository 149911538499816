import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-sale-detail-modal',
    templateUrl: './sale-detail-modal.component.html',
    styleUrls: ['./sale-detail-modal.component.scss']
})
export class SaleDetailModalComponent implements OnInit {

    constructor(
        private dialogRef: MatDialogRef<SaleDetailModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
    }

    ngOnInit(): void {
    }

}
