import {AfterViewInit, Component, ElementRef, HostBinding, OnInit, ViewChild} from '@angular/core';
import {SplashScreenService} from '../../services/splash-screen.service';
import {SettingService} from '../../services/setting.service';
import {first} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {AuthenticationService} from '../../services/authentication.service';

@Component({
    selector: 'app-splash-screen',
    templateUrl: './splash-screen.component.html',
    styleUrls: ['./splash-screen.component.scss']
})
export class SplashScreenComponent implements OnInit, AfterViewInit {

    @HostBinding('style') style: any;
    @HostBinding('class') classes: any = '';

    @ViewChild('splashScreen', {read: ElementRef, static: true})
    splashScreen: ElementRef;
    splashScreenImage: string;

    constructor(
        private authenticationService: AuthenticationService,
        private splashScreenService: SplashScreenService,
        private settingService: SettingService,
        private toastrService: ToastrService,
    ) {
        this.splashScreenImage = './assets/core/images/logos/logo_splash.gif';
    }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
        if (this.splashScreen) {
            this.splashScreenService.init(this.splashScreen.nativeElement, false);
            if (this.authenticationService.isLogged()) {
                this.settingService.currentSettingsServer().pipe(first()).subscribe(response => {
                    this.splashScreenService.hide();
                    this.settingService.updateCurrentSettings(response.data);
                }, error1 => {
                    this.splashScreenService.hide();
                    this.toastrService.error('Error al obtener la configuración actual');
                });
            } else {
                this.splashScreenService.hide();
            }
        }
    }

}
