import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {AuthenticationService} from '../services/authentication.service';
import {Router} from '@angular/router';

@Injectable()
export class ErrorAuthInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService,
                private router: Router) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            let urlTree = this.router.parseUrl(this.router.url);
            let urlWithoutParams;
            if (urlTree.root.children['primary']) {
                urlWithoutParams = urlTree.root.children['primary'].segments.map(it => it.path).join('/');
            }
            if (!urlWithoutParams || (urlWithoutParams && urlWithoutParams != 'login')) {
                if (err.status === 401) {
                    // auto logout if 401 response returned from api
                    this.authenticationService.logout();
                    this.router.navigate(['login']);
                    location.reload();
                    return;
                }
            }
            const error = err.error.message || err.error.error || err.statusText;
            return throwError(error);
        }));
    }
}
