import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';
import {NgSelect2Module} from 'ng-select2';
import {ToastrModule} from 'ngx-toastr';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgxSpinnerModule} from 'ngx-spinner';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {DataTablesModule} from 'angular-datatables';
import {AppRoutingModule} from './app-routing.module';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {LazyLoadImageModule} from 'ng-lazyload-image';
import {registerLocaleData} from '@angular/common';
import localePy from '@angular/common/locales/es-PY';
import {NgxDaterangepickerMd} from 'ngx-daterangepicker-material';
import {ChartsModule} from 'ng2-charts';

registerLocaleData(localePy, 'es');

//Interceptors
import {JwtAuthInterceptor} from './interceptors/jwt-auth.interceptor';
import {ErrorAuthInterceptor} from './interceptors/error-auth.interceptor';

//Directives
import {CurrencyInputDirective} from './directives/currency-input.directive';
import {OnlyIntNumberDirective} from './directives/only-int-number.directive';

//Components
import {AppComponent} from './app.component';
import {HeaderComponent} from './components/layout/header/header.component';
import {AsideComponent} from './components/layout/aside/aside.component';
import {FooterComponent} from './components/layout/footer/footer.component';
import {HomeComponent} from './components/home/home.component';
import {LoginComponent} from './components/auth/login/login.component';
import {MessageComponent} from './components/message/message.component';
import {LayoutComponent} from './components/layout/layout.component';
import {SplashScreenComponent} from './components/splash-screen/splash-screen.component';
import {BranchesComponent} from './components/branches/branches.component';
import {BranchAddEditComponent} from './components/branches/branch-add-edit/branch-add-edit.component';
import {CategoriesComponent} from './components/categories/categories.component';
import {CategoryAddEditComponent} from './components/categories/category-add-edit/category-add-edit.component';
import {ProvidersComponent} from './components/providers/providers.component';
import {ProviderAddEditComponent} from './components/providers/provider-add-edit/provider-add-edit.component';
import {ProductsComponent} from './components/products/products.component';
import {ProductAddEditComponent} from './components/products/product-add-edit/product-add-edit.component';
import {CustomersComponent} from './components/customers/customers.component';
import {CustomerAddEditComponent} from './components/customers/customer-add-edit/customer-add-edit.component';
import {InventoryComponent} from './components/inventory/inventory.component';
import {InventoryAddEditComponent} from './components/inventory/inventory-add-edit/inventory-add-edit.component';
import {SaleComponent} from './components/sale/sale.component';
import {CreditNoteComponent} from './components/sale/credit-note/credit-note.component';
import {SaleCreateComponent} from './components/sales/sale-create/sale-create.component';
import {HasRolePipe} from './pipes/has-role.pipe';
import {FinancesComponent} from './components/finances/finances.component';
import {CashMovementsComponent} from './components/finances/cash-movements/cash-movements.component';
import {AssistanceComponent} from './components/assistance/assistance.component';
import {AssistanceReportComponent} from './components/assistance/assistance-report/assistance-report.component';
import {SalesReportComponent} from './components/reports/sales-report/sales-report.component';
import {InventoryReportComponent} from './components/reports/inventory-report/inventory-report.component';
import {PurchaseReportComponent} from './components/reports/purchase-report/purchase-report.component';
import {ReferralGuideComponent} from './components/reports/referral-guide/referral-guide.component';
import {CashReportComponent} from './components/reports/cash-report/cash-report.component';
import {SettingsComponent} from './components/settings/settings.component';
import {SaleDetailModalComponent} from './components/reports/sales-report/sale-detail-modal/sale-detail-modal.component';
import {LocationsComponent} from './components/locations/locations.component';
import {LocationAddEditComponent} from './components/locations/location-add-edit/location-add-edit.component';
import {PurchaseCreateComponent} from './components/purchases/purchase-create/purchase-create.component';
import {CustomerFragmentAddComponent} from './components/customers/customer-fragment-add/customer-fragment-add.component';
import {TransferCreateComponent} from './components/transfers/transfer-create/transfer-create.component';
import {CloseSmallBoxModalComponent} from './components/finances/close-small-box-modal/close-small-box-modal.component';
import {AdminDashboardComponent} from './components/home/admin-dashboard/admin-dashboard.component';
import {SellerDashboardComponent} from './components/home/seller-dashboard/seller-dashboard.component';
import {CashMovementModalComponent} from './components/finances/cash-movements/cash-movement-modal/cash-movement-modal.component';
import {BranchBoxesComponent} from './components/branch-boxes/branch-boxes.component';
import {BranchBoxAddEditComponent} from './components/branch-boxes/branch-box-add-edit/branch-box-add-edit.component';
import {SmallBoxOpenCloseComponent} from './components/finances/small-box-open-close/small-box-open-close.component';
import {ToNumberPipe} from './pipes/to-number.pipe';
import {ProductCartModalComponent} from './components/sales/sale-create/product-cart-modal/product-cart-modal.component';
import {CustomerSaleFragmentComponent} from './components/customers/customer-sale-fragment/customer-sale-fragment.component';
import {SaleSendEmailComponent} from './components/sales/sale-create/sale-send-email/sale-send-email.component';
import {UsersComponent} from './components/users/users.component';
import {UserAddEditComponent} from './components/users/user-add-edit/user-add-edit.component';
import {SalonsComponent} from './components/salons/salons.component';
import {SalonAddEditComponent} from './components/salons/salon-add-edit/salon-add-edit.component';
import {MenusComponent} from './components/menus/menus.component';
import {MenuAddEditComponent} from './components/menus/menu-add-edit/menu-add-edit.component';
import {SaleCreateRetailComponent} from './components/sales/sale-create/sale-create-retail/sale-create-retail.component';
import {SaleCreateRestaurantComponent} from './components/sales/sale-create/sale-create-restaurant/sale-create-restaurant.component';
import {SalonTablesComponent} from './components/salon-tables/salon-tables.component';
import {SalonTableAddEditComponent} from './components/salon-tables/salon-table-add-edit/salon-table-add-edit.component';
import {CartItemsComponent} from './components/sales/sale-create/cart-items/cart-items.component';
import {CartItemComponent} from './components/sales/sale-create/cart-items/cart-item/cart-item.component';
import {PurchasesReportComponent} from './components/reports/purchases-report/purchases-report.component';
import {PurchaseDetailModalComponent} from './components/reports/purchases-report/purchase-detail-modal/purchase-detail-modal.component';
import {ProductDetailComponent} from './components/products/product-detail/product-detail.component';
import {PromotionsComponent} from './components/promotions/promotions.component';
import {PromotionAddEditComponent} from './components/promotions/promotion-add-edit/promotion-add-edit.component';
import {SalePaymentMethodComponent} from './components/sales/sale-create/sale-payment-method/sale-payment-method.component';
import {SaleResultComponent} from './components/sales/sale-create/sale-result/sale-result.component';
import {WarehouseComponent} from './components/warehouse/warehouse.component';
import {CashReportDetailComponent} from './components/reports/cash-report/cash-report-detail/cash-report-detail.component';
import {SaleSelectCurrencyModalComponent} from './components/sales/sale-create/sale-select-currency-modal/sale-select-currency-modal.component';
import {PurchasePaymentMethodsComponent} from './components/purchase-payment-methods/purchase-payment-methods.component';
import {PurchasePaymentMethodAddEditComponent} from './components/purchase-payment-methods/purchase-payment-method-add-edit/purchase-payment-method-add-edit.component';
import {NgApexchartsModule} from 'ng-apexcharts';
import {ExchangeRatesComponent} from './components/exchange-rates/exchange-rates.component';
import {ExchangeRateAddComponent} from './components/exchange-rates/exchange-rate-add/exchange-rate-add.component';
import {ExchangeRateEditComponent} from './components/exchange-rates/exchange-rate-edit/exchange-rate-edit.component';
import { SaleItemServiceModalComponent } from './components/sales/sale-create/sale-item-service-modal/sale-item-service-modal.component';


@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        AsideComponent,
        FooterComponent,
        HomeComponent,
        LoginComponent,
        MessageComponent,
        LayoutComponent,
        SplashScreenComponent,
        BranchesComponent,
        BranchAddEditComponent,
        CategoriesComponent,
        CategoryAddEditComponent,
        ProvidersComponent,
        ProviderAddEditComponent,
        ProductsComponent,
        ProductAddEditComponent,
        CustomersComponent,
        CustomerAddEditComponent,
        InventoryComponent,
        InventoryAddEditComponent,
        SaleComponent,
        CurrencyInputDirective,
        OnlyIntNumberDirective,
        CreditNoteComponent,
        SaleCreateComponent,
        HasRolePipe,
        FinancesComponent,
        CashMovementsComponent,
        AssistanceComponent,
        AssistanceReportComponent,
        SalesReportComponent,
        InventoryReportComponent,
        PurchaseReportComponent,
        ReferralGuideComponent,
        CashReportComponent,
        SettingsComponent,
        SaleDetailModalComponent,
        LocationsComponent,
        LocationAddEditComponent,
        PurchaseCreateComponent,
        CustomerFragmentAddComponent,
        TransferCreateComponent,
        CloseSmallBoxModalComponent,
        AdminDashboardComponent,
        SellerDashboardComponent,
        CashMovementModalComponent,
        BranchBoxesComponent,
        BranchBoxAddEditComponent,
        SmallBoxOpenCloseComponent,
        ToNumberPipe,
        ProductCartModalComponent,
        CustomerSaleFragmentComponent,
        SaleSendEmailComponent,
        UsersComponent,
        UserAddEditComponent,
        SalonsComponent,
        SalonAddEditComponent,
        MenusComponent,
        MenuAddEditComponent,
        SaleCreateRetailComponent,
        SaleCreateRestaurantComponent,
        SalonTablesComponent,
        SalonTableAddEditComponent,
        CartItemsComponent,
        CartItemComponent,
        PurchasesReportComponent,
        PurchaseDetailModalComponent,
        ProductDetailComponent,
        PromotionsComponent,
        PromotionAddEditComponent,
        SalePaymentMethodComponent,
        SaleResultComponent,
        WarehouseComponent,
        CashReportDetailComponent,
        SaleSelectCurrencyModalComponent,
        PurchasePaymentMethodsComponent,
        PurchasePaymentMethodAddEditComponent,
        ExchangeRatesComponent,
        ExchangeRateAddComponent,
        ExchangeRateEditComponent,
        SaleItemServiceModalComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        NgxSpinnerModule,
        FormsModule,
        ReactiveFormsModule,
        ToastrModule.forRoot({
            progressBar: true,
            progressAnimation: 'increasing',
            preventDuplicates: true
        }),
        DataTablesModule,
        NgSelect2Module,
        MatButtonModule,
        MatIconModule,
        MatDialogModule,
        MatDatepickerModule,
        LazyLoadImageModule,
        NgxDaterangepickerMd.forRoot(),
        ChartsModule,
        NgApexchartsModule
    ],
    providers: [
        {provide: LOCALE_ID, useValue: 'es-PY'},
        {provide: HTTP_INTERCEPTORS, useClass: JwtAuthInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: ErrorAuthInterceptor, multi: true},
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
