import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SaleService} from '../../../../services/sale.service';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-sale-send-email',
    templateUrl: './sale-send-email.component.html',
    styleUrls: ['./sale-send-email.component.scss']
})
export class SaleSendEmailComponent implements OnInit {

    form: FormGroup;
    submitted = false;
    loading = false;

    constructor(
        private formBuilder: FormBuilder,
        private saleService: SaleService,
        private toastrService: ToastrService,
        private dialogRef: MatDialogRef<SaleSendEmailComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
    }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            email: ['', [Validators.email]],
        });
        this.f.email.setValue(this.data.sale.customer.email);
    }

    get f() {
        return this.form.controls;
    }

    sendForm(): void {
        this.submitted = true;
        if (!this.form.valid) {
            return;
        }
        this.loading = true;
        this.saleService.sendEmail(this.data.sale.id, this.form.value).subscribe(response => {
            this.loading = false;
            this.toastrService.success(response.message);
            this.dialogRef.close();
        }, error1 => {
            this.toastrService.error(error1);
            this.loading = false;
        });
    }

}
