import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {SmallBoxService} from '../../../services/small-box.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-small-box-open-close',
    templateUrl: './small-box-open-close.component.html',
    styleUrls: ['./small-box-open-close.component.scss']
})
export class SmallBoxOpenCloseComponent implements OnInit {

    form: FormGroup;
    submitted = false;
    loading = false;

    constructor(
        private formBuilder: FormBuilder,
        private toastrService: ToastrService,
        private smallBoxService: SmallBoxService,
        private dialogRef: MatDialogRef<SmallBoxOpenCloseComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
    }

    ngOnInit(): void {
        if (this.data.box.opened) {
            this.form = this.formBuilder.group({
                final_amount: [0, Validators.required],
            });
        } else {
            this.form = this.formBuilder.group({
                starting_amount: [0, Validators.required],
            });
        }
    }

    get f() {
        return this.form.controls;
    }

    sendForm(): void {
        this.submitted = true;
        if (!this.form.valid) {
            return;
        }
        this.loading = true;
        if (this.data.box.opened) {
            this.smallBoxService.close(this.form.value).subscribe(response => {
                this.loading = false;
                this.dialogRef.close({
                    closed: true
                });
                this.toastrService.success(response.message);
            }, error1 => {
                this.loading = false;
                this.toastrService.error(error1);
            });
        } else {
            this.smallBoxService.store({...this.form.value, ...{branch_box_id: this.data.box.id}}).subscribe(response => {
                this.loading = false;
                this.dialogRef.close({
                    closed: true
                });
                this.toastrService.success(response.message);
            }, error1 => {
                this.loading = false;
                this.toastrService.error(error1);
            });
        }
    }
}
