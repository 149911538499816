import {Component, OnInit} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {SettingService} from '../../../services/setting.service';
import {Setting} from '../../../models/setting';
import {SmallBoxService} from '../../../services/small-box.service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-sale-create',
    templateUrl: './sale-create.component.html',
    styleUrls: ['./sale-create.component.scss']
})
export class SaleCreateComponent implements OnInit {

    currentSettings: Setting = null;
    firstLoaded = false;

    constructor(
        private smallBoxService: SmallBoxService,
        private settingService: SettingService,
        private toastrService: ToastrService,
        private router: Router,
    ) {
    }

    ngOnInit(): void {
        this.currentSettings = this.settingService.currentSettingsValue;
        if (!this.smallBoxService.currentSettingsValue.id) {
            this.router.navigate(['/small-box/create-close']);
            this.toastrService.info('Debe abrir caja primero');
            return;
        } else {
            this.firstLoaded = true;
        }
    }
}
