import {DeserializableInterface} from '../interfaces/deserializable.interface';

export class Setting implements DeserializableInterface {

    electronic_billing: boolean;
    igv_included: boolean;
    igv_percentage: number;
    multi_site: boolean;
    product_location: boolean;
    sales_out_of_stock: boolean;
    discounts_enabled_seller: boolean;
    discounts_enabled_admin: boolean;
    max_amount_sunat_ticket: number;
    qr_niubiz: boolean;
    qr_niubiz_username: string;
    qr_niubiz_password: string;
    qr_niubiz_merchant_id: string;
    setting_currencies: any[] = [];
    kipu_mode: string;
    mandatory_provider_id_purchases: boolean;
    sale_of_services: boolean;

    merchant_logo: string;
    merchant_logo_url: string;
    business_name: string;
    address: string;
    email_contact: string;
    department: string;
    province: string;
    district: string;

    deserialize(input: any): this {
        return Object.assign(this, input);
    }
}
