import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {webServiceConfig} from '../config/web-service.config';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ResourceService {

    constructor(private http: HttpClient) {
    }

    uploadFile(formData: FormData): Observable<any> {
        return this.http.post(`${webServiceConfig.webServiceUrl}resources/file/upload`, formData)
            .pipe(map(response => {
                return response;
            }));
    }
}
