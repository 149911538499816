import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute, Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {MenuService} from '../../../services/menu.service';
import {ResourceService} from '../../../services/resource.service';

@Component({
  selector: 'app-menu-add-edit',
  templateUrl: './menu-add-edit.component.html',
  styleUrls: ['./menu-add-edit.component.scss']
})
export class MenuAddEditComponent implements OnInit {

    form: FormGroup;
    menu: any = {};
    submitted = false;
    loading = false;
    id: number;
    pathFile = 'Elegir Foto';

    constructor(
        private router: Router,
        private formBuilder: FormBuilder,
        private menuService: MenuService,
        private spinner: NgxSpinnerService,
        private toastrService: ToastrService,
        private activatedRoute: ActivatedRoute,
        private resourceService: ResourceService,
    ) {
    }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            name: ['', Validators.required],
            photo: [null],
            photo_url: [null],
            status: ['1', Validators.required],
        });
        this.id = this.activatedRoute.snapshot.params['id'];
        if (this.id) {
            this.spinner.show();
            this.menuService.show(this.id).subscribe(response => {
                this.spinner.hide();
                this.form.patchValue(response.data);
                this.menu = response.data;
            }, error1 => {
                this.spinner.hide();
                this.router.navigate(['/']);
            });
        }
    }

    get f() {
        return this.form.controls;
    }

    onSelectFile(fileInput: any) {
        let photoFile = <File> fileInput.target.files[0];
        if (photoFile) {
            const formData = new FormData();
            formData.append('file', photoFile);
            formData.append('path', 'images/menus');
            this.spinner.show();
            this.resourceService.uploadFile(formData).subscribe(response => {
                this.f.photo.setValue(response.file);
                this.f.photo_url.setValue(response.file_url);
                this.menu['photo'] = response.file;
                this.menu['photo_url'] = response.file_url;
                this.pathFile = photoFile.name;
                this.spinner.hide();
            }, error => {
                this.spinner.hide();
                this.toastrService.error(error);
            });
        }
    }

    sendForm(): void {
        this.submitted = true;
        if (!this.form.valid) {
            return;
        }
        this.loading = true;
        let menu = Object.assign({}, this.form.value);
        if (this.id != null) {
            menu.id = this.id;
            this.menuService.update(menu).subscribe(response => {
                this.loading = false;
                this.toastrService.success(response.message, 'Éxito!');
                this.router.navigate(['/menus']);
            }, error => {
                this.toastrService.error(error);
                this.loading = false;
            });
        } else {
            this.menuService.store(menu).subscribe(response => {
                this.loading = false;
                this.toastrService.success(response.message, 'Éxito!');
                this.router.navigate(['/menus']);
            }, error => {
                this.toastrService.error(error);
                this.loading = false;
            });
        }
    }
}
