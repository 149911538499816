<div *ngIf="form.currentStep == 1" class="container-restaurant-1">
    <div class="container-restaurant-2">
        <div class="m-content py-0">
            <div class="row">
                <div class="col-md-2"></div>
                <div class="col-md-8">
                    <div class="text-center pt-5">
                        <button class="btn btn-salon btn-lg mx-4" *ngFor="let salon of salons"
                                [ngClass]="{ 'active': salon.active }"
                                (click)="setSalonSelected(salon)">
                            {{salon.name}}
                        </button>
                    </div>
                </div>
            </div>
            <div class="row pt-rest">
                <div class="col-md-1"></div>
                <div class="col-md-10">
                    <div class="row">
                        <div class="col-md-3 pb-3" *ngFor="let table of form.salon_obj?.salon_tables">
                            <div class="table align-v-center" (click)="selectTable(table)"
                                 [ngClass]="{
                                'table--attention_status_0': table.attention_status == 0,
                                'table--attention_status_1': table.attention_status == 1,
                                'table--attention_status_2': table.attention_status == 2,
                                'table--attention_status_3': table.attention_status == 3
                            }">
                                <div>
                                    <h5>{{table.name}}</h5>
                                    <h6>{{table.attention_status_nice}}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="m-content py-0" *ngIf="form.currentStep == 2 || form.currentStep == 3 || form.currentStep == 4">
    <div class="row">
        <div class="col-md-8">
            <div class="container-sale-left">
                <ng-container *ngIf="form.currentStep == 2">
                    <div class="row">
                        <ng-container *ngIf="form.menu_obj == null">
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="float-left">
                                            <h3 class="m-portlet__head-text title">
                                                <a (click)="prevStep()"
                                                   class="m-portlet__nav-link btn btn-lg btn-secondary btn-back m-btn m-btn&#45;&#45;outline-2x m-btn&#45;&#45;icon m-btn&#45;&#45;icon-only m-btn&#45;&#45;pill">
                                                    <i class="la la-angle-left"></i>
                                                </a>
                                                Carta
                                            </h3>
                                        </div>

                                    </div>
                                </div>
                                <div class="m-portlet__body pt-4">
                                    <div class="row">
                                        <div class="col-md-3 mb-3" *ngFor="let menu of menus">
                                            <div class="menu" [style.background-image]="'url(' + menu.photo_url + ')'"
                                                 (click)="selectMenu(menu)">
                                                <div class="menu-content align-v-center">
                                                    <div>
                                                        <h5>{{menu.name}}</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="form.menu_obj != null">
                            <div class="col-md-8">
                                <div class="float-left">
                                    <h3 class="m-portlet__head-text title">
                                        <a (click)="form.menu_obj = null"
                                           class="m-portlet__nav-link btn btn-lg btn-secondary btn-back m-btn m-btn--outline-2x m-btn--icon m-btn--icon-only m-btn--pill">
                                            <i class="la la-angle-left"></i>
                                        </a>
                                        {{form.menu_obj.name}}
                                    </h3>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-md-8">
                                        <div class="filters">
                                            <span class="text-filters">Filtros:</span>
                                            <button (click)="selectCategory(null)"
                                                    class="btn btn-info-kipu"
                                                    [ngClass]="{ 'active': form.category_obj == null }">Todos
                                            </button>

                                            <button *ngFor="let category of form.menu_obj.categories"
                                                    (click)="selectCategory(category)"
                                                    [ngClass]="{ 'active': category.id == form.category_obj?.id }"
                                                    class="btn btn-info-kipu">{{category.name}}</button>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">

                                        </div>
                                    </div>
                                </div>
                                <div class="row pt-5">
                                    <ng-container *ngIf="form.category_obj == null">
                                        <ng-container *ngFor="let category of form.menu_obj.categories">
                                            <div class="col-md-4 pb-5" *ngFor="let product of category.products">
                                                <div class="card-product" (click)="selectItemSearch(product)">
                                                    <div class="card-product-img" *ngIf="!product.photo_url"
                                                         [style.background-image]="'url(/assets/core/images/icons/product-icon.jpg)'"></div>
                                                    <div class="card-product-img" *ngIf="product.photo_url"
                                                         [style.background-image]="'url(' + product.photo_url + ')'"></div>
                                                    <div class="card-product-description">
                                                        <h5>{{product.name}}</h5>
                                                        <h6 class="card-product-description-price">{{product.price | currency: 'S/ '}}</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </ng-container>
                                    <ng-container *ngIf="form.category_obj != null">
                                        <div class="col-md-4 pb-5" *ngFor="let product of form.category_obj.products">
                                            <div class="card-product" (click)="selectItemSearch(product)">
                                                <div class="card-product-img" *ngIf="!product.photo_url"
                                                     [style.background-image]="'url(/assets/core/images/icons/product-icon.jpg)'"></div>
                                                <div class="card-product-img" *ngIf="product.photo_url"
                                                     [style.background-image]="'url(' + product.photo_url + ')'"></div>
                                                <div class="card-product-description">
                                                    <h5>{{product.name}}</h5>
                                                    <h6 class="card-product-description-price">{{product.price | currency: 'S/ '}}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
                <ng-container *ngIf="form.currentStep == 3">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="float-left">
                                <h3 class="m-portlet__head-text title">
                                    <a (click)="resetForm()"
                                       class="m-portlet__nav-link btn btn-lg btn-secondary btn-back m-btn m-btn--outline-2x m-btn--icon m-btn--icon-only m-btn--pill">
                                        <i class="la la-angle-left"></i>
                                    </a>
                                    Comprobante de Venta
                                </h3>
                            </div>
                            <div class="float-right">
                                <button class="btn btn-secondary btn-lg btn-voucher-type mx-2"
                                        (click)="setVoucherType(0)"
                                        [ngClass]="{ 'active': form.voucher_type == 0 }">Recaudación
                                </button>
                                <button class="btn btn-secondary btn-lg btn-voucher-type mx-2"
                                        (click)="setVoucherType(2)"
                                        [ngClass]="{ 'active': form.voucher_type == 2 }">Boleta
                                </button>
                                <button class="btn btn-secondary btn-lg btn-voucher-type mx-2"
                                        (click)="setVoucherType(1)"
                                        [ngClass]="{ 'active': form.voucher_type == 1 }">Factura
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="m-portlet__body pt-4">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="m-portlet m-portlet--mobile m-portlet-no-default-styles m-portlet-no-default-styles-customer">
                                    <ng-container *ngIf="form.voucher_type == 0 || form.voucher_type == 2">
                                        <div class="m-portlet__body">
                                            <div class="from-group">
                                                <div class="m-radio-inline">
                                                    <label class="m-radio m-radio--solid m-radio--info m-radio--kipu ">
                                                        <input type="radio" [(ngModel)]="inDataCustomer"
                                                               [disabled]="disabledDefaultCustomer"
                                                               (ngModelChange)="setCustomer()"
                                                               [value]="true"> Boleta Simple
                                                        <span></span>
                                                    </label>
                                                    <label class="m-radio m-radio--solid m-radio--info m-radio--kipu">
                                                        <input type="radio" [(ngModel)]="inDataCustomer"
                                                               (ngModelChange)="setCustomer()"
                                                               [value]="false"> Boleta con DNI
                                                        <span></span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <app-customer-sale-fragment
                                                            [allInputsDisabled]="inDataCustomer"
                                                            [documentTypes]="documentTypesTicket"
                                                            [customer]="form.customer"
                                                            [submitted]="submittedCustomer"
                                                    ></app-customer-sale-fragment>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="form.voucher_type == 1">
                                        <div class="m-portlet__body">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <app-customer-sale-fragment
                                                            [customer]="form.customer"
                                                            [submitted]="submittedCustomer"
                                                            [documentTypes]="documentTypesRuc"
                                                    ></app-customer-sale-fragment>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="form.currentStep == 4">
                    <h3 class="m-portlet__head-text title">
                        <a (click)="prevStep()"
                           class="m-portlet__nav-link btn btn-lg btn-secondary btn-back m-btn m-btn--outline-2x m-btn--icon m-btn--icon-only m-btn--pill">
                            <i class="la la-angle-left"></i>
                        </a>
                        Método de pago
                    </h3>
                    <div class="m-portlet__body pt-4">
                        <app-sale-payment-method [form]="form"></app-sale-payment-method>
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="col-md-4 section-products">
            <div class="container-sale-right">
                <app-cart-items #cartItems
                                [currentUser]="currentUser" [form]="form" [currentSettings]="currentSettings"
                                [textsBySteps]="textsBySteps" (nextStepEvent)="nextStep()"
                                (updateOrderEvent)="updateSale()">
                </app-cart-items>
            </div>
        </div>
    </div>
</div>

<ng-container *ngIf="form.currentStep == 5" class="m-content">
    <app-sale-result [saleCreated]="saleCreated" (resetFormEvent)="resetForm()"></app-sale-result>
</ng-container>
