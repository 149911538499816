import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'app-sale-item-service-modal',
    templateUrl: './sale-item-service-modal.component.html',
    styleUrls: ['./sale-item-service-modal.component.scss']
})
export class SaleItemServiceModalComponent implements OnInit {

    form: FormGroup;
    submitted = false;

    constructor(
        private formBuilder: FormBuilder,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<SaleItemServiceModalComponent>,
    ) {
    }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            name: ['', [Validators.required, Validators.maxLength(255)]],
            quantity: [1, Validators.required],
            price: [0, Validators.required],
            cost: [0],
            discount: [0],
            result_type: ['service']
        });
        if (this.data?.item) {
            this.form.patchValue(this.data.item);
        }
    }

    get f() {
        return this.form.controls;
    }

    sendForm(): void {
        this.submitted = true;
        if (!this.form.valid) {
            return;
        }
        this.dialogRef.close({
            item: this.form.value,
            index: this.data?.index
        });
    }
}
