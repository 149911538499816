import {Component, OnInit, ViewChild} from '@angular/core';
import {DataTableDirective} from 'angular-datatables';
import {ProductService} from '../../services/product.service';
import {ProductDetailComponent} from '../products/product-detail/product-detail.component';
import {MatDialog} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
import {MessageService} from '../../services/message.service';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
    selector: 'app-warehouse',
    templateUrl: './warehouse.component.html',
    styleUrls: ['./warehouse.component.scss']
})
export class WarehouseComponent implements OnInit {

    dtOptions: DataTables.Settings = {};
    products: any[];
    @ViewChild(DataTableDirective) private dtElement;
    currentUser: any;

    constructor(
        private dialog: MatDialog,
        private toastrService: ToastrService,
        private messageService: MessageService,
        private productService: ProductService,
        private ngxSpinnerService: NgxSpinnerService,
    ) {
    }

    ngOnInit(): void {

        this.dtOptions = {
            pagingType: 'full_numbers',
            pageLength: 10,
            serverSide: true,
            processing: true,
            ajax: (dataTablesParameters: any, callback) => {
                dataTablesParameters['with_stock'] = true;
                this.productService.datatable(dataTablesParameters)
                    .subscribe(response => {
                        this.products = response.data;
                        callback({
                            recordsTotal: response.recordsTotal,
                            recordsFiltered: response.recordsFiltered,
                            data: []
                        });
                    });
            },
            columns: [
                {data: 'code', name: 'code'},
                {data: 'category_name', name: 'category_name'},
                {data: 'name', name: 'name'},
                {data: 'description', name: 'description'},
                {data: 'photo_url', name: 'photo_url', orderable: false, searchable: false},
                {data: 'status_nice', name: 'status_nice'},
                {data: 'current_branch_stock', name: 'current_branch_stock'},
            ]
        };
    }

    private async reloadTable() {
        let dtInstance = await this.dtElement.dtInstance;
        dtInstance.ajax.reload();
    }

    showDetail(product: any): void {
        this.ngxSpinnerService.show();
        this.productService.productAllBranches(product.id).subscribe(response => {
            this.ngxSpinnerService.hide();
            this.dialog.open(ProductDetailComponent, {
                data: response.data,
                width: '50%'
            });
        }, error1 => {
            this.ngxSpinnerService.hide();
            this.toastrService.error(error1);
            this.messageService.error(error1);
        });
    }
}
