import {DeserializableInterface} from '../interfaces/deserializable.interface';

export class SmallBox implements DeserializableInterface {

    id: number;
    branch_box_id: number;
    opening_user_id: number;
    closing_user_id?: number;
    starting_amount: number;
    final_amount?: number;
    status?: number;
    opening_date_time: string;
    closing_date_time?: string;
    missing?: number;
    leftover?: number;
    current_amount: number;
    total_movements: number;
    cash_amount: number;
    card_amount: number;
    total_sales: number;
    total_money_income: number;
    expenses: number;
    total_transactions: number;

    deserialize(input: any): this {
        return Object.assign(this, input);
    }
}
