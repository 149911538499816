import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {webServiceConfig} from '../../../config/web-service.config';
import {AuthenticationService} from '../../../services/authentication.service';
import {ProductService} from '../../../services/product.service';
import {SettingService} from '../../../services/setting.service';
import {Setting} from '../../../models/setting';
import {PurchaseService} from '../../../services/purchase.service';
import {MessageService} from '../../../services/message.service';
import {ToastrService} from 'ngx-toastr';
import {first} from 'rxjs/operators';
import {PurchasePaymentMethodService} from '../../../services/purchase-payment-method.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {Select2OptionData} from 'ng-select2';
import {
    SaleSelectCurrencyModalComponent
} from "../../sales/sale-create/sale-select-currency-modal/sale-select-currency-modal.component";
import {MatDialog} from "@angular/material/dialog";
import {CategoryService} from "../../../services/category.service";
import {BranchService} from "../../../services/branch.service";

declare var swal: any;

export const DATEPICKER_FORMAT = {
    parse: {
        dateInput: 'LL',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@Component({
    selector: 'app-purchase-create',
    templateUrl: './purchase-create.component.html',
    styleUrls: ['./purchase-create.component.scss'],
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        },
        {provide: MAT_DATE_FORMATS, useValue: DATEPICKER_FORMAT},
    ]
})
export class PurchaseCreateComponent implements OnInit, AfterViewInit {

    form: any = {
        date: null,
        provider_id: null,
        invoice_number: null,
        waybill: null,
        purchase_payment_method_id: null,
        items: [],
        subtotal: 0,
        igv_amount: 0,
        total: 0,

        //currency
        currency_id: null,
        currency_alphabetic_code: null,
        currency_numeric_code: null,
        currency_name: null,
        currency_symbol: null,

        category_id: '',

        //
        branch_id: '',
        location_id: '',
    };
    submitted = false;
    loading = false;
    countRequests = 0;
    maxCountRequests = 3;

    @ViewChild('select2ProviderSearch') select2ProviderSearch: any;
    optionsSelect2Provider = {
        width: '100%',
        language: 'es',
        delay: 250,
        placeholder: 'Busca un proveedor',
        allowClear: true,
        ajax: {
            headers: {
                Authorization: `Bearer ${this.authenticationService.currentUserValue.token}`,
            },
            method: 'get',
            url: `${webServiceConfig.webServiceUrl}providers/search`,
            dataType: 'json'
        }
    };

    @ViewChild('select2ProductSearch') select2ProductSearch: any;
    optionsSelect2Product = {
        width: '100%',
        language: 'es',
        delay: 250,
        placeholder: 'Busca productos',
        allowClear: true,
        ajax: {
            headers: {
                Authorization: `Bearer ${this.authenticationService.currentUserValue.token}`,
            },
            method: 'get',
            url: `${webServiceConfig.webServiceUrl}products/search`,
            dataType: 'json',
            data: (params) => {
                return {
                    term: params.term,
                    currency_id: this.form.currency_id,
                    category_id: this.form.category_id,
                };
            }
        },
    };

    categories: Array<Select2OptionData> = [];
    branches: Array<Select2OptionData> = [];
    locations: Array<Select2OptionData> = [];

    productSearchText = '';
    currentSettings: Setting;
    purchasePaymentMethods: Array<Select2OptionData> = [];

    constructor(
        private dialog: MatDialog,
        private branchService: BranchService,
        private toastrService: ToastrService,
        private messageService: MessageService,
        private productService: ProductService,
        private settingService: SettingService,
        private categoryService: CategoryService,
        private purchaseService: PurchaseService,
        private ngxSpinnerService: NgxSpinnerService,
        private authenticationService: AuthenticationService,
        private purchasePaymentMethodService: PurchasePaymentMethodService,
    ) {
    }

    ngOnInit(): void {
        this.settingService.currentSettingsSubject.asObservable().subscribe((settings: Setting) => {
            this.currentSettings = settings;
        });
        this.ngxSpinnerService.show();
        this.purchasePaymentMethodService.all().subscribe(response => {
            this.purchasePaymentMethods = response.data.map(item => {
                return {...item, ...{text: item.name}};
            });
            this.countRequests++;
            this.ngxSpinnerService.hide();
            // if (this.countRequests >= this.maxCountRequests) {
            //     this.ngxSpinnerService.hide();
            // }

        }, error1 => {
            this.ngxSpinnerService.hide();
            this.toastrService.error(error1);
        });

        this.categoryService.all().subscribe({
            next: response => {
                this.categories = response.data.map(item => {
                    return {...item, ...{text: item.name}};
                });
                this.countRequests++;
                this.ngxSpinnerService.hide();
                // if (this.countRequests >= this.maxCountRequests) {
                //     this.ngxSpinnerService.hide();
                // }
            },
            error: error => {
                this.ngxSpinnerService.hide();
                this.toastrService.error(error);
            }
        });

        if (this.currentSettings.multi_site) {
            this.branchService.all(1, null, 1).subscribe(response => {
                this.branches = response.data.map(item => {
                    return {...item, ...{text: item.name}};
                });
                this.countRequests++;
                this.ngxSpinnerService.hide();
                // if (this.countRequests >= this.maxCountRequests) {
                //     this.ngxSpinnerService.hide();
                // }

            }, error1 => {
                this.toastrService.error(error1, 'Error');
            });
        } else if (this.currentSettings.product_location) {
            this.branchService.getDefault(1).subscribe(response => {
                // this.branches = [response.data];
                console.log(response.data);
                this.countRequests++;
                this.ngxSpinnerService.hide();
                // if (this.countRequests >= this.maxCountRequests) {
                //     this.ngxSpinnerService.hide();
                // }
            }, error1 => {
                this.toastrService.error(error1, 'Error');
            });
        }
        this.loadCurrencies();
    }

    ngAfterViewInit(): void {
        jQuery(this.select2ProductSearch._element.nativeElement).on('select2:select', (e: any) => {
            const newItem = e.params.data;
            const itemFound = this.form.items.find(item => item.id == newItem.id);
            if (itemFound) {
                itemFound.quantity++;
            } else {
                newItem.quantity = 1;
                this.form.items.push(newItem);
            }
            this.calculateTotals();
            setTimeout(() => {
                this.productSearchText = null;
            }, 300);
        });
    }

    loadCurrencies(): void {
        if (this.currentSettings.setting_currencies.length > 1) {
            const dialogRef = this.dialog.open(SaleSelectCurrencyModalComponent, {
                data: {
                    setting_currencies: this.currentSettings.setting_currencies,
                    form: this.form,
                },
                width: '70%',
                disableClose: true
            });
            dialogRef.afterClosed().subscribe(resp => {
                // this.filterCurrency();
                // console.log(this.form);
            });
        } else {
            this.form.currency_id = this.currentSettings.setting_currencies[0].currency_id;
            this.form.currency_alphabetic_code = this.currentSettings.setting_currencies[0].currency.alphabetic_code;
            this.form.currency_numeric_code = this.currentSettings.setting_currencies[0].currency.numeric_code;
            this.form.currency_name = this.currentSettings.setting_currencies[0].currency.name;
            this.form.currency_symbol = this.currentSettings.setting_currencies[0].currency.symbol;
            // this.filterCurrency();
        }
    }

    onSelectBranch(): void {
        if (this.form.branch_id) {
            this.form.location_id = '';
            const branchSelected: any = this.branches.find(branch => branch.id == this.form.branch_id);
            this.locations = branchSelected.locations.map(item => {
                return {...item, ...{text: item.name}};
            });
        }
    }

    deleteItem(i: number): void {
        this.form.items.splice(i, 1);
        this.calculateTotals();
    }

    calculateTotals(): void {
        this.form.subtotal = 0;
        this.form.igv_amount = 0;
        this.form.total = 0;
        this.form.items.forEach(item => {
            let subtotalItem = item.cost * item.quantity;
            this.form.subtotal += subtotalItem;
        });

        let igvFactor = this.currentSettings.igv_percentage / 100;
        this.form.igv_amount = this.form.subtotal * igvFactor;
        this.form.total = this.form.subtotal + this.form.igv_amount;
    }

    validateForm(): boolean {
        if (!this.form.date) {
            swal.fire({
                title: 'Complete la fecha',
                type: 'error'
            });
            return false;
        }
        if (this.currentSettings.mandatory_provider_id_purchases && !this.form.provider_id) {
            swal.fire({
                title: 'Selecciona un proveedor',
                type: 'error'
            });
            return false;
        }
        if (this.form.items.length == 0) {
            swal.fire({
                title: 'Debe agregar productos',
                type: 'error'
            });
            return false;
        }
        let valid = true;
        this.form.items.forEach(item => {
            if (!item.cost || !item.quantity || item.cost <= 0 || item.quantity <= 0) {
                valid = false;
            }
        });
        if (!valid) {
            swal.fire({
                title: 'Complete el costo y cantidad de los productos',
                type: 'error'
            });
        }
        return valid;
    }

    sendForm(): void {
        this.submitted = true;
        if (this.validateForm()) {
            this.loading = true;
            const form = Object.assign({}, this.form);
            form.date = this.form.date.format('YYYY-MM-DD');
            this.purchaseService.store(form).pipe(first()).subscribe(response => {
                this.loading = false;
                this.resetForm();
                this.messageService.success(response.message);
                this.toastrService.success(response.message);
                setTimeout(() => this.messageService.clear(), 4000);
            }, error1 => {
                this.loading = false;
                this.messageService.error(error1);
                this.toastrService.error(error1);
            });
        }
    }

    resetForm(): void {
        this.form = {
            date: null,
            provider_id: null,
            invoice_number: null,
            waybill: null,
            items: [],
            subtotal: 0,
            igv_amount: 0,
            total: 0,

            //currency
            currency_id: null,
            currency_alphabetic_code: null,
            currency_numeric_code: null,
            currency_name: null,
            currency_symbol: null,

            category_id: '',
        };
        this.submitted = false;
        this.loadCurrencies();
    }
}
