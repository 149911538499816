import {Component, OnInit, ViewChild} from '@angular/core';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DataTableDirective} from 'angular-datatables';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
import {MessageService} from '../../../services/message.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {PurchaseService} from '../../../services/purchase.service';
import {first} from 'rxjs/operators';
import {PurchaseDetailModalComponent} from './purchase-detail-modal/purchase-detail-modal.component';
import * as FileSaver from 'file-saver';

export const DATEPICKER_FORMAT = {
    parse: {
        dateInput: 'LL',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@Component({
    selector: 'app-purchases-report',
    templateUrl: './purchases-report.component.html',
    styleUrls: ['./purchases-report.component.scss'],
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        },

        {provide: MAT_DATE_FORMATS, useValue: DATEPICKER_FORMAT},
    ]
})
export class PurchasesReportComponent implements OnInit {

    dtOptions: DataTables.Settings = {};
    items: any[];
    @ViewChild(DataTableDirective) private dtElement;

    form = {
        from_date: null,
        to_date: null,
    };
    exporting = false;

    constructor(
        private router: Router,
        private dialog: MatDialog,
        private toastrService: ToastrService,
        private messageService: MessageService,
        private purchaseService: PurchaseService,
        private ngxSpinnerService: NgxSpinnerService,
    ) {
    }

    ngOnInit(): void {
        this.dtOptions = {
            pagingType: 'full_numbers',
            pageLength: 10,
            serverSide: true,
            processing: true,
            order: [[0, 'desc']],
            ajax: (dataTablesParameters: any, callback) => {
                dataTablesParameters = {...dataTablesParameters, ...this.form};
                this.purchaseService.datatable(dataTablesParameters)
                    .pipe(first())
                    .subscribe(response => {
                        this.items = response.data;
                        callback({
                            recordsTotal: response.recordsTotal,
                            recordsFiltered: response.recordsFiltered,
                            data: []
                        });
                    });
            },
            columns: [
                {data: 'date'},
                {data: 'user_name'},
                {data: 'provider_ruc'},
                {data: 'provider_business_name'},
                {data: 'invoice_number'},
                {data: 'waybill_number'},
                {data: 'total'},
            ]
        };
    }

    async reloadTable() {
        const dtInstance = await this.dtElement.dtInstance;
        dtInstance.ajax.reload();
    }

    showDetail(item): void {
        this.ngxSpinnerService.show();
        this.purchaseService.show(item.id).pipe(first()).subscribe(response => {
            this.ngxSpinnerService.hide();
            this.dialog.open(PurchaseDetailModalComponent, {
                data: response.data,
                width: '50%'
            });
        }, error1 => {
            this.ngxSpinnerService.hide();
            this.messageService.error(error1);
            this.toastrService.error(error1);
        });
    }

    exportList(): void {
        this.exporting = true;
        this.purchaseService.export().subscribe(blob => {
            this.exporting = false;
            FileSaver.saveAs(blob, 'Compras.xlsx');
        }, error1 => {
            this.exporting = false;
            this.toastrService.error(error1);
        });
    }
}
