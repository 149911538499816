import {Component, OnInit, ViewChild} from '@angular/core';
import {DataTableDirective} from 'angular-datatables';
import {CategoryService} from '../../services/category.service';
import {first} from 'rxjs/operators';
import {SettingService} from '../../services/setting.service';
import {Setting} from '../../models/setting';
import * as FileSaver from 'file-saver';
import {ToastrService} from 'ngx-toastr';

declare var swal: any;

@Component({
    selector: 'app-categories',
    templateUrl: './categories.component.html',
    styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {

    dtOptions: DataTables.Settings = {};
    categories: any[];
    @ViewChild(DataTableDirective) private dtElement;
    currentSettings: Setting = new Setting();
    exporting = false;

    constructor(
        private toastrService: ToastrService,
        private settingService: SettingService,
        private categoryService: CategoryService,
    ) {
    }

    ngOnInit(): void {
        this.currentSettings = this.settingService.currentSettingsValue;
        let columns = null;
        if (this.currentSettings.kipu_mode == 'restaurant') {
            columns = [
                {data: 'id'},
                {data: 'menu.name', name: 'menu.name'},
                {data: 'name'},
                {data: 'description'},
                {data: 'status'},
            ];
        } else {
            columns = [
                {data: 'id'},
                {data: 'name'},
                {data: 'description'},
                {data: 'status'},
            ];
        }
        this.dtOptions = {
            pagingType: 'full_numbers',
            pageLength: 10,
            serverSide: true,
            processing: true,
            ajax: (dataTablesParameters: any, callback) => {
                this.categoryService.datatable(dataTablesParameters)
                    .pipe(first())
                    .subscribe(response => {
                        this.categories = response.data;
                        callback({
                            recordsTotal: response.recordsTotal,
                            recordsFiltered: response.recordsFiltered,
                            data: []
                        });
                    });
            },
            columns
        };
    }

    private async reloadTable() {
        let dtInstance = await this.dtElement.dtInstance;
        dtInstance.ajax.reload();
    }

    delete(category: any): void {
        swal.fire({
            title: '¿Estás seguro que deseas eliminar este registro?',
            showCancelButton: true,
            confirmButtonText: 'Si, elimínalo!',
            showLoaderOnConfirm: true,
            preConfirm: (arg) => {
                return this.categoryService.delete(category.id)
                    .subscribe(response => {
                        swal.fire({
                            title: 'Elemento eliminado con éxito',
                            type: 'success'
                        });
                        this.reloadTable();
                    }, error => {
                        swal.fire({
                            title: error,
                            type: 'error'
                        });
                        return error;
                    });
            },
            allowOutsideClick: () => !swal.isLoading()
        }).then((result) => {
        });
    }

    exportList(): void {
        this.exporting = true;
        this.categoryService.export().subscribe(blob => {
            this.exporting = false;
            FileSaver.saveAs(blob, 'Categorías.xlsx');
        }, error1 => {
            this.exporting = false;
            this.toastrService.error(error1);
        });
    }
}
