import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {webServiceConfig} from '../config/web-service.config';
import {map} from 'rxjs/operators';
import {DataTablesResponse} from '../models/data-tables-response';

@Injectable({
    providedIn: 'root'
})
export class CustomerService {

    constructor(private http: HttpClient) {
    }

    getAll(): Observable<any> {
        return this.http.get(`${webServiceConfig.webServiceUrl}customers`).pipe(map(response => {
            return response;
        }));
    }

    datatable(dataTablesParameters): Observable<any> {
        return this.http.post<DataTablesResponse>(`${webServiceConfig.webServiceUrl}customers/datatable`, dataTablesParameters, {})
            .pipe(map(response => {
                return response;
            }));
    }

    show(id: number): Observable<any> {
        return this.http.get(`${webServiceConfig.webServiceUrl}customers/${id}`).pipe(map(response => {
            return response;
        }));
    }

    getDefault(): Observable<any> {
        return this.http.get(`${webServiceConfig.webServiceUrl}customers/default`).pipe(map(response => {
            return response;
        }));
    }

    searchDocumentNumber(document_number: string, document_type: string = ''): Observable<any> {
        return this.http.get(`${webServiceConfig.webServiceUrl}customers/search/document-number`, {
            params: {
                document_number,
                document_type
            }
        }).pipe(map(response => {
            return response;
        }));
    }

    store(client: any): Observable<any> {
        return this.http.post(`${webServiceConfig.webServiceUrl}customers`, client)
            .pipe(map(response => {
                return response;
            }));
    }

    update(client: any): Observable<any> {
        return this.http.put(`${webServiceConfig.webServiceUrl}customers/${client.id}`, client)
            .pipe(map(response => {
                return response;
            }));
    }

    delete(id: number): Observable<any> {
        return this.http.delete(`${webServiceConfig.webServiceUrl}customers/${id}`)
            .pipe(map(response => {
                return response;
            }));
    }

    export(): Observable<Blob> {
        return this.http.get(`${webServiceConfig.webServiceUrl}customers/export`, {responseType: 'blob'});
    }
}
