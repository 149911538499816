import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from '../../services/authentication.service';
import {User} from '../../models/user';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

    user: User;

    constructor(
        private authenticationService: AuthenticationService,
    ) {
    }

    ngOnInit() {
       this.user = this.authenticationService.currentUserValue;
    }
}
