import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {webServiceConfig} from '../config/web-service.config';
import {map} from 'rxjs/operators';
import {DataTablesResponse} from '../models/data-tables-response';

@Injectable({
    providedIn: 'root'
})
export class BranchBoxService {

    constructor(private http: HttpClient) {
    }

    all(): Observable<any> {
        return this.http.get(`${webServiceConfig.webServiceUrl}branch-boxes`).pipe(map(response => {
            return response;
        }));
    }


    getByBranchId(branchId): Observable<any> {
        return this.http.get(`${webServiceConfig.webServiceUrl}branch-boxes/branch/${branchId}`).pipe(map(response => {
            return response;
        }));
    }

    datatable(dataTablesParameters): Observable<any> {
        return this.http.post<DataTablesResponse>(`${webServiceConfig.webServiceUrl}branch-boxes/datatable`, dataTablesParameters, {})
            .pipe(map(response => {
                return response;
            }));
    }

    show(id: number): Observable<any> {
        return this.http.get(`${webServiceConfig.webServiceUrl}branch-boxes/${id}`).pipe(map(response => {
            return response;
        }));
    }

    store(obj: any): Observable<any> {
        return this.http.post(`${webServiceConfig.webServiceUrl}branch-boxes`, obj)
            .pipe(map(response => {
                return response;
            }));
    }

    update(obj: any): Observable<any> {
        return this.http.put(`${webServiceConfig.webServiceUrl}branch-boxes/${obj.id}`, obj)
            .pipe(map(response => {
                return response;
            }));
    }

    delete(id: number): Observable<any> {
        return this.http.delete(`${webServiceConfig.webServiceUrl}branch-boxes/${id}`)
            .pipe(map(response => {
                return response;
            }));
    }

    export(): Observable<Blob> {
        return this.http.get(`${webServiceConfig.webServiceUrl}branch-boxes/export`, {responseType: 'blob'});
    }
}
