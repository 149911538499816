import {Component, OnInit} from '@angular/core';
import {User} from '../../../models/user';
import {AuthenticationService} from '../../../services/authentication.service';
import {SettingService} from '../../../services/setting.service';
import {Setting} from '../../../models/setting';

@Component({
    selector: 'app-aside',
    templateUrl: './aside.component.html',
    styleUrls: ['./aside.component.scss']
})
export class AsideComponent implements OnInit {

    currentUser: User;
    sidebar: any[] = [];
    hasMultiSite = false;
    hasProductLocation = false;
    hasElectronicBilling = false;
    currentSettings = null;

    sidebarModes = {
        retail: [
            {
                hasChildren: true,
                icon: 'la la-database',
                name: 'Datos',
                roles: ['admin'],
                children: [
                    {
                        route: '/branches',
                        name: 'Sucursales',
                        require_multi_site: true
                    },
                    {
                        route: '/locations',
                        name: 'Ubicaciones',
                        require_product_location: true
                    },
                    {
                        route: '/branch-boxes',
                        name: 'Cajas'
                    },
                    {
                        route: '/categories',
                        name: 'Categorias'
                    },
                    {
                        route: '/products',
                        name: 'Productos'
                    },
                    {
                        route: '/promotions',
                        name: 'Promociones'
                    },
                    {
                        route: '/customers',
                        name: 'Clientes'
                    },
                    {
                        route: '/providers',
                        name: 'Provedores'
                    }
                ]
            },
            {
                hasChildren: true,
                icon: 'la la-inbox',
                name: 'Caja',
                children: [
                    {
                        route: '/small-box/create-close',
                        name: 'Abrir/cerrar'
                    },
                    {
                        route: '/small-box/cash-movements',
                        name: 'Movimientos'
                    }
                ]
            },
            {
                hasChildren: true,
                icon: 'la la-money',
                name: 'Ventas',
                children: [
                    {
                        route: '/sales/create',
                        name: 'Registrar Venta'
                    },
                    {
                        route: '/creditNote',
                        name: 'Generar nota de crédito',
                        require_electronic_billing: true
                    }
                ]
            },
            {
                hasChildren: true,
                icon: 'la la-cart-arrow-down',
                name: 'Compras',
                // roles: ['admin', 'seller'],
                children: [
                    {
                        route: '/purchases/create',
                        name: 'Registrar compras'
                    }
                ]
            },
            {
                hasChildren: true,
                icon: 'la la-truck',
                name: 'Inventario',
                children: [
                    {
                        route: '/transfers/create',
                        name: 'Nuevo Traslado',
                        require_multi_site: true,
                    },
                    {
                        route: '/products/warehouse',
                        name: 'Almacén'
                    }
                ]
            },
            /*{
                hasChildren: true,
                icon: 'la la-truck',
                name: 'Traslados',
                require_multi_site: true,
                children: [
                    {
                        route: '/transfers/create',
                        name: 'Nuevo Traslado'
                    }
                ]
            },*/
            {
                hasChildren: true,
                icon: 'la la-pie-chart',
                name: 'Reportes',
                children: [
                    {
                        route: '/reports/sales',
                        name: 'Ventas'
                    },
                    {
                        route: '/reports/purchases',
                        name: 'Compras'
                    },
                    {
                        route: '/report/cash-report',
                        name: 'Apertura y Cierre de caja',
                        roles: ['admin'],
                    }
                ]
            },
            {
                hasChildren: true,
                icon: 'la la-cogs',
                name: 'Administración',
                roles: ['admin'],
                children: [
                    {
                        route: '/settings',
                        name: 'Configuración'
                    },
                    {
                        route: '/users',
                        name: 'Usuarios'
                    }
                ]
            }
        ],
        restaurant: [
            {
                hasChildren: true,
                icon: 'la la-database',
                name: 'Datos',
                roles: ['admin'],
                children: [
                    {
                        route: '/branches',
                        name: 'Sucursales',
                        require_multi_site: true
                    },
                    {
                        route: '/branch-boxes',
                        name: 'Cajas'
                    },
                    {
                        route: '/salons',
                        name: 'Salones'
                    },
                    {
                        route: '/salon-tables',
                        name: 'Mesas'
                    },
                    {
                        route: '/menus',
                        name: 'Menús'
                    },
                    {
                        route: '/categories',
                        name: 'Categorias'
                    },
                    {
                        route: '/products',
                        name: 'Productos'
                    },
                    {
                        route: '/customers',
                        name: 'Clientes'
                    },
                ]
            },
            {
                hasChildren: true,
                icon: 'la la-inbox',
                name: 'Caja',
                children: [
                    {
                        route: '/small-box/create-close',
                        name: 'Abrir/cerrar'
                    },
                    {
                        route: '/small-box/cash-movements',
                        name: 'Movimientos'
                    }
                ]
            },
            {
                hasChildren: true,
                icon: 'la la-money',
                name: 'Ventas',
                children: [
                    {
                        route: '/sales/create',
                        name: 'Registrar Venta'
                    },
                    {
                        route: '/creditNote',
                        name: 'Generar nota de crédito',
                        require_electronic_billing: true
                    }
                ]
            },
            {
                hasChildren: true,
                icon: 'la la-pie-chart',
                name: 'Reportes',
                children: [
                    {
                        route: '/reports/sales',
                        name: 'Ventas'
                    }
                ]
            },
            {
                hasChildren: true,
                icon: 'la la-cogs',
                name: 'Administración',
                roles: ['admin'],
                children: [
                    {
                        route: '/settings',
                        name: 'Configuración'
                    },
                    {
                        route: '/users',
                        name: 'Usuarios'
                    }
                ]
            }
        ],
        vehicle: [
            {
                hasChildren: true,
                icon: 'la la-database',
                name: 'Datos',
                roles: ['admin'],
                children: [
                    {
                        route: '/branches',
                        name: 'Sucursales',
                        require_multi_site: true
                    },
                    {
                        route: '/locations',
                        name: 'Ubicaciones',
                        require_product_location: true
                    },
                    {
                        route: '/branch-boxes',
                        name: 'Cajas'
                    },
                    {
                        route: '/categories',
                        name: 'Categorias'
                    },
                    {
                        route: '/products',
                        name: 'Productos'
                    },
                    {
                        route: '/promotions',
                        name: 'Promociones'
                    },
                    {
                        route: '/customers',
                        name: 'Clientes'
                    },
                    {
                        route: '/providers',
                        name: 'Provedores'
                    },
                    {
                        route: '/purchase-payment-methods',
                        name: 'Métodos de Pago para Compras'
                    }
                ]
            },
            {
                hasChildren: true,
                icon: 'la la-inbox',
                name: 'Caja',
                children: [
                    {
                        route: '/small-box/create-close',
                        name: 'Abrir/cerrar'
                    },
                    {
                        route: '/small-box/cash-movements',
                        name: 'Movimientos'
                    }
                ]
            },
            {
                hasChildren: true,
                icon: 'la la-money',
                name: 'Ventas',
                children: [
                    {
                        route: '/sales/create',
                        name: 'Registrar Venta'
                    },
                    {
                        route: '/creditNote',
                        name: 'Generar nota de crédito',
                        require_electronic_billing: true
                    }
                ]
            },
            {
                hasChildren: true,
                icon: 'la la-cart-arrow-down',
                name: 'Compras',
                roles: ['admin'],
                children: [
                    {
                        route: '/purchases/create',
                        name: 'Registrar compras'
                    }
                ]
            },
            {
                hasChildren: true,
                icon: 'la la-truck',
                name: 'Inventario',
                children: [
                    {
                        route: '/transfers/create',
                        name: 'Nuevo Traslado',
                        require_multi_site: true,
                    },
                    {
                        route: '/products/warehouse',
                        name: 'Almacén'
                    }
                ]
            },
            {
                hasChildren: true,
                icon: 'la la-pie-chart',
                name: 'Reportes',
                children: [
                    {
                        route: '/reports/sales',
                        name: 'Ventas'
                    },
                    {
                        route: '/reports/purchases',
                        name: 'Compras'
                    },
                    {
                        route: '/report/cash-report',
                        name: 'Apertura y Cierre de caja',
                        roles: ['admin'],
                    }
                ]
            },
            {
                hasChildren: true,
                icon: 'la la-cogs',
                name: 'Administración',
                roles: ['admin'],
                children: [
                    {
                        route: '/settings',
                        name: 'Configuración'
                    },
                    {
                        route: '/exchange-rates',
                        name: 'Tipos de Cambio'
                    },
                    {
                        route: '/users',
                        name: 'Usuarios'
                    }
                ]
            }
        ]
    };

    constructor(
        private settingService: SettingService,
        private authenticationService: AuthenticationService,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
    }

    ngOnInit(): void {
        this.settingService.currentSettingsSubject.asObservable().subscribe((settings: Setting) => {
            this.hasMultiSite = settings.multi_site;
            this.hasProductLocation = settings.product_location;
            this.hasElectronicBilling = settings.electronic_billing;
            this.currentSettings = settings;
            this.sidebar = this.sidebarModes[this.currentSettings.kipu_mode];
        });
    }
}
