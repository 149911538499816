import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-exchange-rate-edit',
  templateUrl: './exchange-rate-edit.component.html',
  styleUrls: ['./exchange-rate-edit.component.scss']
})
export class ExchangeRateEditComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
