import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {webServiceConfig} from '../config/web-service.config';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class CurrencyService {

    constructor(private http: HttpClient) {
    }

    getAll(): Observable<any> {
        return this.http.get(`${webServiceConfig.webServiceUrl}currencies`).pipe(map(response => {
            return response;
        }));
    }
}
