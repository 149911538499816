import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-sale-payment-method',
    templateUrl: './sale-payment-method.component.html',
    styleUrls: ['./sale-payment-method.component.scss']
})
export class SalePaymentMethodComponent implements OnInit {

    @Input() form: any;

    constructor() {
    }

    ngOnInit(): void {
    }

    selectPaymentMethod(paymentMethod): void {
        this.form.payment_method = paymentMethod;
    }

    selectAmountPaid(amountPaidType, amountPaid: number = null): void {
        if (amountPaidType == 'exact_more') {
            this.form.cashAmountSelected = amountPaid;
            this.form.cashAmountReturnedSelected = amountPaid - this.form.total;
            this.form.otherAmountCash = false;
        } else {
            this.form.otherAmountCash = true;
            this.form.cashAmountSelected = 0;
            this.form.cashAmountReturnedSelected = 0;
        }
    }

    calculateCashReturnedOther(): void {
        this.form.cashAmountReturnedSelected = this.form.otherAmountCashValue - this.form.total;
    }
}
