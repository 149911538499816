import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute, Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {first} from 'rxjs/operators';
import {ProviderService} from '../../../services/provider.service';

@Component({
    selector: 'app-provider-add-edit',
    templateUrl: './provider-add-edit.component.html',
    styleUrls: ['./provider-add-edit.component.scss']
})
export class ProviderAddEditComponent implements OnInit {

    form: FormGroup;
    provider: any = {};
    submitted = false;
    loading = false;
    id: number;

    constructor(
        private formBuilder: FormBuilder,
        private providerService: ProviderService,
        private toastrService: ToastrService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private spinner: NgxSpinnerService,
    ) {
    }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            ruc: ['', [Validators.required, Validators.minLength(11), Validators.maxLength(11)]],
            business_name: ['', Validators.required],
            contact_name: ['', Validators.required],
            address: ['', Validators.required],
            phone: [''],
            email: ['', [Validators.email]],
            status: ['1', Validators.required],
        });
        this.id = this.activatedRoute.snapshot.params['id'];
        if (this.id) {
            this.spinner.show();
            this.providerService.show(this.id).pipe(first()).subscribe(response => {
                this.spinner.hide();
                this.form.patchValue(response.data);
                this.provider = response.data;
            }, error1 => {
                this.spinner.hide();
                this.router.navigate(['/']);
            });
        }
    }

    get f() {
        return this.form.controls;
    }

    sendForm(): void {
        this.submitted = true;
        if (!this.form.valid) {
            return;
        }
        this.loading = true;
        let provider = Object.assign({}, this.form.value);
        if (this.id != null) {
            provider.id = this.id;
            this.providerService.update(provider).pipe(first()).subscribe(response => {
                this.loading = false;
                this.toastrService.success(response.message, 'Éxito!');
                this.router.navigate(['/providers']);
            }, error => {
                this.toastrService.error(error);
                this.loading = false;
            });
        } else {
            this.providerService.store(provider).pipe(first()).subscribe(response => {
                this.loading = false;
                this.toastrService.success(response.message, 'Éxito!');
                this.router.navigate(['/providers']);
            }, error => {
                this.toastrService.error(error);
                this.loading = false;
            });
        }
    }
}
