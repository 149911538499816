<p class="name">{{item.name}}</p>
<p class="quantity">
    {{item.quantity}} und
    <span class="price">{{totalItem | currency: form.currency_symbol}}</span>
</p>
<ng-container *ngIf="currentSettings.kipu_mode == 'restaurant'">
    <div class="pt-2" *ngIf="item.extras && item.extras.length > 0">
        <ng-container *ngFor="let extra of item.extras">
            <div class="row" *ngIf="extra?.quantity > 0">
                <div class="col-sm-5">
                    <p class="extra-name">{{extra.name}}</p>
                </div>
                <div class="col-sm-3">
                    <p class="extra-quantity">{{extra.quantity}}</p>
                </div>
                <div class="col-sm-4">
                    <p class="extra-price">{{(extra.quantity * extra.price) | currency: form.currency_symbol}}</p>
                </div>
            </div>
        </ng-container>
    </div>
</ng-container>
