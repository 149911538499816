import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {first} from 'rxjs/operators';
import {SmallBoxService} from '../../../services/small-box.service';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-close-small-box-modal',
    templateUrl: './close-small-box-modal.component.html',
    styleUrls: ['./close-small-box-modal.component.scss']
})
export class CloseSmallBoxModalComponent implements OnInit {

    form: FormGroup;
    submitted = false;
    loading = false;

    constructor(
        private formBuilder: FormBuilder,
        private toastrService: ToastrService,
        private smallBoxService: SmallBoxService,
        private dialogRef: MatDialogRef<CloseSmallBoxModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
    }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            final_amount: [0, Validators.required],
        });
    }

    get f() {
        return this.form.controls;
    }

    sendForm(): void {
        this.submitted = true;
        if (!this.form.valid) {
            return;
        }
        this.loading = true;
        this.smallBoxService.close(this.form.value).pipe(first()).subscribe(response => {
            this.loading = false;
            this.dialogRef.close({
                closed: true
            });
            this.toastrService.success(response.message);
        }, error1 => {
            this.loading = false;
            this.toastrService.error(error1);
        });
    }
}
