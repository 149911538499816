<!-- BEGIN: Subheader -->
<div class="m-subheader ">
    <div class="d-flex align-items-center">
        <div class="mr-auto">
            <h3 class="m-subheader__title m-subheader__title--separator" *ngIf="client.id">Editar Cliente</h3>
            <h3 class="m-subheader__title m-subheader__title--separator" *ngIf="!client.id">Nueva Cliente</h3>
            <ul class="m-subheader__breadcrumbs m-nav m-nav--inline">
                <li class="m-nav__item m-nav__item--home">
                    <a [routerLink]="''" class="m-nav__link m-nav__link--icon">
                        <i class="m-nav__link-icon la la-home"></i>
                    </a>
                </li>
                <li class="m-nav__separator">-</li>
                <li class="m-nav__item">
                    <a [routerLink]="'/customers'" class="m-nav__link">
                        <span class="m-nav__link-text">Clientes</span>
                    </a>
                </li>
                <li class="m-nav__separator">-</li>
                <li class="m-nav__item">
                    <a *ngIf="client.id == null" [routerLink]="'/customers/create'" class="m-nav__link">
                        <span class="m-nav__link-text">Nueva Cliente</span>
                    </a>
                    <a *ngIf="client.id != null" [routerLink]="['/customers/edit', client.id]" class="m-nav__link">
                        <span class="m-nav__link-text">Editar Cliente</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>

<div class="m-content">
    <div class="row">
        <div class="col-md-12">
            <!--begin::Portlet-->
            <div class="m-portlet m-portlet--mobile">
                <div class="m-portlet__head">
                    <div class="m-portlet__head-caption">
                        <div class="m-portlet__head-title">
                            <h3 *ngIf="client.id == null" class="m-portlet__head-text">
                                Nuevo Cliente
                            </h3>
                            <h3 *ngIf="client.id != null" class="m-portlet__head-text">
                                Editar Cliente - {{client.name}}
                            </h3>
                        </div>
                    </div>
                    <div class="m-portlet__head-tools">
                        <ul class="m-portlet__nav">
                            <li class="m-portlet__nav-item">
                                <a [routerLink]="'/customers'"
                                   class="m-portlet__nav-link btn btn-lg btn-secondary m-btn m-btn--outline-2x m-btn--air m-btn--icon m-btn--icon-only m-btn--pill  m-dropdown__toggle">
                                    <i class="flaticon-reply"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <form [formGroup]="form" (ngSubmit)="sendForm()" class="m-form m-form--label-align-right">
                    <div class="m-portlet__body">
                        <div class="m-form__section m-form__section--first">
                            <div class="form-group m-form__group row">
                                <label class="col-lg-2 col-form-label">Tipo de Documento:</label>
                                <div class="col-lg-6">
                                    <select formControlName="document_type" class="form-control m-input"
                                            (change)="onChangeDocument()"
                                            [ngClass]="{ 'is-invalid': submitted && f.document_type.errors }">
                                        <option value="1">DNI</option>
                                        <option value="6">RUC</option>
                                        <option value="4">CARNET DE EXTRANJERÍA</option>
                                        <option value="7">PASAPORTE</option>
                                        <option value="A">CÉDULA DIPLOMÁTICA DE IDENTIDAD</option>
                                        <option value="-">VARIOS - VENTAS MENORES A S/.700.00 Y OTROS</option>
                                    </select>
                                    <div *ngIf="submitted && f.document_type.errors" class="invalid-feedback">
                                        <div *ngIf="f.document_type.errors.required">Tipo de documento obligatorio</div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group m-form__group row">
                                <label class="col-lg-2 col-form-label">N° de Documento:</label>
                                <div class="col-lg-6">
                                    <input formControlName="document_number" class="form-control m-input" placeholder="N° de Documento"
                                           [ngClass]="{ 'is-invalid': submitted && f.document_number.errors }">
                                    <div *ngIf="submitted && f.document_number.errors" class="invalid-feedback">
                                        <div *ngIf="f.document_number.errors.required">N° de documento obligatorio</div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group m-form__group row">
                                <label class="col-lg-2 col-form-label">Nombre:</label>
                                <div class="col-lg-6">
                                    <input formControlName="name" class="form-control m-input" placeholder="Nombre"
                                           [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
                                    <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                        <div *ngIf="f.name.errors.required">Nombre obligatorio</div>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group m-form__group row">
                                <label class="col-lg-2 col-form-label">Teléfono</label>
                                <div class="col-lg-6">
                                    <input formControlName="phone" class="form-control m-input" placeholder="Teléfono"
                                           type="tel"
                                           [ngClass]="{ 'is-invalid': submitted && f.phone.errors }">
                                    <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                                        <div *ngIf="f.phone.errors.required">Teléfono obligatorio</div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group m-form__group row">
                                <label class="col-lg-2 col-form-label">Email</label>
                                <div class="col-lg-6">
                                    <input formControlName="email" class="form-control m-input" placeholder="Email"
                                           type="email"
                                           [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                        <div *ngIf="f.email.errors.required">Email obligatorio</div>
                                        <div *ngIf="f.email.errors.email">Formato inválido</div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group m-form__group row">
                                <label class="col-lg-2 col-form-label">Dirección</label>
                                <div class="col-lg-6">
                                    <input formControlName="address" class="form-control m-input" placeholder="Dirección"
                                           type="text"
                                           [ngClass]="{ 'is-invalid': submitted && f.address.errors }">
                                    <div *ngIf="submitted && f.address.errors" class="invalid-feedback">
                                        <div *ngIf="f.address.errors.required">Dirección obligatoria</div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group m-form__group row">
                                <label class="col-lg-2 col-form-label">Estado:</label>
                                <div class="col-lg-6">
                                    <select formControlName="status" class="form-control m-input"
                                            [ngClass]="{ 'is-invalid': submitted && f.status.errors }">
                                        <option [ngValue]="1">Activo</option>
                                        <option [ngValue]="0">Inactivo</option>
                                    </select>
                                    <div *ngIf="submitted && f.status.errors" class="invalid-feedback">
                                        <div *ngIf="f.status.errors.required">Estado obligatorio</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="m-portlet__foot m-portlet__foot--fit">
                        <div class="m-form__actions">
                            <div class="row">
                                <div class="col-2">
                                </div>
                                <div class="col-7">
                                    <button type="submit" [disabled]="loading"
                                            class="btn btn-accent m-btn m-btn--air m-btn--custom"
                                            [ngClass]="{'m-loader m-loader--light m-loader--right': loading}">
                                        Guardar Cambios
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
