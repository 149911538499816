import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SmallBoxService} from '../../../../services/small-box.service';
import {first} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-cash-movement-modal',
    templateUrl: './cash-movement-modal.component.html',
    styleUrls: ['./cash-movement-modal.component.scss']
})
export class CashMovementModalComponent implements OnInit {

    title = '';
    form: FormGroup;
    submitted = false;
    loading = false;

    constructor(
        private formBuilder: FormBuilder,
        private toastrService: ToastrService,
        private smallBoxService: SmallBoxService,
        private dialogRef: MatDialogRef<CashMovementModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        if (this.data.type == 'inflow') {
            this.title = 'Ingreso de Dinero';
        } else {
            this.title = 'Salida de Dinero';
        }
    }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            type: [null, Validators.required],
            amount: [null, [Validators.required, Validators.min(0.1)]],
            description: [null],
        });
        this.f.type.setValue(this.data.type);
    }

    get f() {
        return this.form.controls;
    }

    sendForm(): void {
        this.submitted = true;
        if (!this.form.valid) {
            return;
        }
        this.loading = true;
        const movement = Object.assign({}, this.form.value);
        this.smallBoxService.storeMovement(movement).pipe(first()).subscribe(response => {
            this.loading = false;
            this.toastrService.success(response.message, 'Éxito!');
            this.dialogRef.close(true);
        }, error => {
            this.toastrService.error(error);
            this.loading = false;
        });
    }
}
