<button class="m-aside-left-close  m-aside-left-close--skin-dark " id="m_aside_left_close_btn"><i
        class="la la-close"></i></button>
<div id="m_aside_left" class="m-grid__item	m-aside-left  m-aside-left--skin-dark ">

    <!-- BEGIN: Aside Menu -->
    <div id="m_ver_menu" class="m-aside-menu  m-aside-menu--skin-dark m-aside-menu--submenu-skin-dark "
         m-menu-vertical="1" m-menu-scrollable="1" m-menu-dropdown-timeout="500" style="position: relative;">
        <ul class="m-menu__nav  m-menu__nav--dropdown-submenu-arrow ">
            <li class="m-menu__section ">
<!--                <h4 class="m-menu__section-text text-module">Módulos:</h4>-->
                <h4 class="m-menu__section-text text-module">{{currentUser.branch.name}}</h4>
                <i class="m-menu__section-icon flaticon-more-v2"></i>
            </li>
            <li class="m-menu__item  m-menu__item--active" aria-haspopup="true">
                <a [routerLink]="''" class="m-menu__link ">
                    <i class="m-menu__link-icon la la-line-chart"></i>
                    <span class="m-menu__link-title">
                        <span class="m-menu__link-wrap">
                            <span class="m-menu__link-text">Resumen</span>
                            <!--<span class="m-menu__link-badge">
                                <span class="m-badge m-badge&#45;&#45;danger">2</span>
                            </span>-->
                        </span>
                    </span>
                </a>
            </li>
            <li *ngFor="let item of sidebar" class="m-menu__item  m-menu__item--submenu" aria-haspopup="true"
                m-menu-submenu-toggle="hover">
                <ng-container
                        *ngIf="(item.roles ? (currentUser.roles | hasRole : item.roles) : true) && (item.require_multi_site ? hasMultiSite : true) && (item.require_product_location ? hasProductLocation : true)">
                    <a [routerLink]="item.route ? item.route : null" class="m-menu__link m-menu__toggle">
                        <i class="m-menu__link-icon" [ngClass]="item.icon"></i>
                        <span class="m-menu__link-text">{{item.name}}</span>
                        <i class="m-menu__ver-arrow la la-angle-right" *ngIf="!item.route"></i>
                    </a>
                    <div class="m-menu__submenu" *ngIf="item.children">
                        <span class="m-menu__arrow"></span>
                        <ul class="m-menu__subnav">
                            <li class="m-menu__item " aria-haspopup="true" *ngFor="let child of item.children">
                                <a [routerLink]="child.route ? child.route : null"
                                   *ngIf="(child.roles ? (currentUser.roles | hasRole : child.roles) : true) && (child.require_multi_site ? hasMultiSite : true) && (child.require_product_location ? hasProductLocation : true) && (child.require_electronic_billing ? hasElectronicBilling : true)"
                                   [ngClass]="child.children ? 'm-menu__link m-menu__toggle' : 'm-menu__link'">
                                    <i class="m-menu__link-mabullet m-menu__link-bullet--dot"><span></span></i>
                                    <span class="m-menu__link-text">{{child.name}}</span>
                                    <i *ngIf="child.children" class="m-menu__ver-arrow la la-angle-right"></i>
                                </a>
                                <div class="m-menu__submenu " *ngIf="child.children">
                                    <span class="m-menu__arrow"></span>
                                    <ul class="m-menu__subnav">
                                        <li class="m-menu__item " aria-haspopup="true"
                                            *ngFor="let child_sub of child.children">
                                            <a [routerLink]="child_sub.route" class="m-menu__link"
                                               *ngIf="(child_sub.roles ? (currentUser.roles | hasRole : child_sub.roles) : true) && (child_sub.require_multi_site ? hasMultiSite : true) && (child_sub.require_product_location ? hasProductLocation : true) && (child_sub.require_electronic_billing ? hasElectronicBilling : true)">
                                                <i class="m-menu__link-bullet m-menu__link-bullet--dot">
                                                    <span></span>
                                                </i>
                                                <span class="m-menu__link-text">{{child_sub.name}}</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                </ng-container>
            </li>
        </ul>
    </div>
</div>
<!-- END: Aside Menu -->
