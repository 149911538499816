<h1 mat-dialog-title>{{title}}</h1>
<form [formGroup]="form" (ngSubmit)="sendForm()" class="m-form m-form--label-align-right">
    <mat-dialog-content>
        <div class="form-group m-form__group row">
            <label class="col-lg-2 col-form-label">Ingresar Monto:</label>
            <div class="col-lg-6">
                <input formControlName="amount" class="form-control m-input" placeholder="Monto"
                       appCurrencyInput
                       [ngClass]="{ 'is-invalid': submitted && f.amount.errors }"/>
                <div *ngIf="submitted && f.amount.errors" class="invalid-feedback">
                    <div *ngIf="f.amount.errors.required">Monto obligatorio</div>
                    <div *ngIf="f.amount.errors.min">El amount mínimo es 0.1</div>
                </div>
            </div>
        </div>
        <div class="form-group m-form__group row">
            <label class="col-lg-2 col-form-label">Descripción:</label>
            <div class="col-lg-6">
                <textarea formControlName="description" rows="10" class="form-control m-input"></textarea>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button type="button" [disabled]="loading" class="btn btn-outline-dark mx-3" mat-dialog-close>Cerrar</button>
        <button type="submit" [disabled]="loading" class="btn btn-success"
                [ngClass]="{'m-loader m-loader--light m-loader--right': loading}">Guardar
        </button>
    </mat-dialog-actions>
</form>
