import {Component, OnInit, ViewChild} from '@angular/core';
import {DataTableDirective} from 'angular-datatables';
import {ExchangeRateService} from '../../services/exchange-rate.service';
import {SettingService} from '../../services/setting.service';

declare var swal: any;

@Component({
    selector: 'app-exchange-rates',
    templateUrl: './exchange-rates.component.html',
    styleUrls: ['./exchange-rates.component.scss']
})
export class ExchangeRatesComponent implements OnInit {

    dtOptions: DataTables.Settings = {};
    items: any[];
    @ViewChild(DataTableDirective) private dtElement;
    currentSettings: any = {};
    principalCurrency: any = {};

    constructor(
        private settingService: SettingService,
        private exchangeRateService: ExchangeRateService,
    ) {
    }

    ngOnInit(): void {
        this.currentSettings = this.settingService.currentSettingsValue;
        this.principalCurrency = this.currentSettings.setting_currencies.find(item => item.principal).currency;
        this.dtOptions = {
            pagingType: 'full_numbers',
            pageLength: 10,
            serverSide: true,
            processing: true,
            ajax: (dataTablesParameters: any, callback) => {
                this.exchangeRateService.datatable(dataTablesParameters)
                    .subscribe(response => {
                        this.items = response.data;
                        callback({
                            recordsTotal: response.recordsTotal,
                            recordsFiltered: response.recordsFiltered,
                            data: []
                        });
                    });
            },
            columns: [
                {data: 'date'},
                {data: 'currency.name'},
                {data: 'sale'},
                {data: 'purchase'},
            ]
        };
    }

    private async reloadTable() {
        let dtInstance = await this.dtElement.dtInstance;
        dtInstance.ajax.reload();
    }

    delete(item: any): void {
        swal.fire({
            title: '¿Estás seguro que deseas eliminar este registro?',
            showCancelButton: true,
            confirmButtonText: 'Si, elimínalo!',
            showLoaderOnConfirm: true,
            preConfirm: (arg) => {
                return this.exchangeRateService.delete(item.id)
                    .subscribe(response => {
                        swal.fire({
                            title: 'Elemento eliminado con éxito',
                            type: 'success'
                        });
                        this.reloadTable();
                    }, error => {
                        swal.fire({
                            title: error,
                            type: 'error'
                        });
                        return error;
                    });
            },
            allowOutsideClick: () => !swal.isLoading()
        }).then((result) => {
        });
    }

}
