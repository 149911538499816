<h1 mat-dialog-title>Detalle</h1>
<mat-dialog-content>
    <div class="col-md-12">
        <div class="table-responsive">
            <table class="table table-stripped table-hover table-bordered">
                <thead>
                <tr>
                    <th>#</th>
                    <th>Producto</th>
                    <th>Costo</th>
                    <th>Cantidad</th>
                    <th>Total de Línea</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let item of data.details; let i = index">
                    <td>{{i+1}}</td>
                    <td>{{item.name}}</td>
                    <td>{{item.cost | currency: 'S/ '}}</td>
                    <td>{{item.quantity}}</td>
                    <td>{{(item.cost * item.quantity) | currency: 'S/ '}}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button class="btn btn-outline-dark mx-3" mat-dialog-close>Cerrar</button>
</mat-dialog-actions>
