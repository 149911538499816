<!-- BEGIN: Subheader -->
<div class="m-subheader ">
    <div class="d-flex align-items-center">
        <div class="mr-auto">
            <h3 class="m-subheader__title m-subheader__title--separator">Abrir/cerrar Caja</h3>
            <ul class="m-subheader__breadcrumbs m-nav m-nav--inline">
                <li class="m-nav__item m-nav__item--home">
                    <a [routerLink]="''" class="m-nav__link m-nav__link--icon">
                        <i class="m-nav__link-icon la la-home"></i>
                    </a>
                </li>
                <li class="m-nav__separator">-</li>
                <li class="m-nav__item">
                    <a [routerLink]="'/small-box/create-close'" class="m-nav__link">
                        <span class="m-nav__link-text">Cajas</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>

<div class="m-content">
    <div class="row">
        <ng-container *ngIf="loaded && boxes.length == 0">
            <div class="col-md-4"></div>
            <div class="col-md-4">
                <div class="m-portlet m-portlet--mobile">
                    <div class="text-center py-5">
                        <h3>No hay cajas registradas para esta sucursal</h3>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="loaded && boxes.length > 0">
            <div class="col-md-3" *ngFor="let box of boxes">
                <div class="m-portlet m-portlet--mobile">
                    <div class="m-portlet__body box-card" (click)="selectBox(box)">
                        <div class="box-icon">
                            <span class="box-icon-circle box-icon-circle-closed" *ngIf="!box.opened">
                                <i class="fa fa-lock"></i>
                            </span>
                            <span class="box-icon-circle box-icon-circle-opened" *ngIf="box.opened">
                                <i class="fa fa-lock-open"></i>
                            </span>
                        </div>
                        <div class="text-center">
                            <img src="../../../assets/core/images/icons/cashier-machine.png" alt="Caja" class="box-img"/>
                        </div>
                        <div class="small-box-body">
                            <h4>{{box.name}}
                                <span *ngIf="currentUser.id == box.user_id">(Mi Caja)</span>
                                <span *ngIf="box.user_id && currentUser.id != box.user_id">({{box.user.name}})</span>
                            </h4>
                            <p>{{box.opened ? 'ABIERTO' : 'CERRADO'}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>
