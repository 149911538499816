<!-- BEGIN: Subheader -->
<div class="m-subheader ">
    <div class="d-flex align-items-center">
        <div class="mr-auto">
            <h3 class="m-subheader__title m-subheader__title--separator" *ngIf="promotion.id">Editar Promoción</h3>
            <h3 class="m-subheader__title m-subheader__title--separator" *ngIf="!promotion.id">Nuevo Promoción</h3>
            <ul class="m-subheader__breadcrumbs m-nav m-nav--inline">
                <li class="m-nav__item m-nav__item--home">
                    <a [routerLink]="''" class="m-nav__link m-nav__link--icon">
                        <i class="m-nav__link-icon la la-home"></i>
                    </a>
                </li>
                <li class="m-nav__separator">-</li>
                <li class="m-nav__item">
                    <a [routerLink]="'/promotions'" class="m-nav__link">
                        <span class="m-nav__link-text">Promociones</span>
                    </a>
                </li>
                <li class="m-nav__separator">-</li>
                <li class="m-nav__item">
                    <a *ngIf="promotion.id == null" [routerLink]="'/promotions/create'" class="m-nav__link">
                        <span class="m-nav__link-text">Nueva Promoción</span>
                    </a>
                    <a *ngIf="promotion.id != null" [routerLink]="['/promotions/edit', promotion.id]"
                       class="m-nav__link">
                        <span class="m-nav__link-text">Editar Promoción</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>


<div class="m-content">
    <div class="row">
        <div class="col-md-12">
            <!--begin::Portlet-->
            <div class="m-portlet m-portlet--mobile">
                <div class="m-portlet__head">
                    <div class="m-portlet__head-caption">
                        <div class="m-portlet__head-title">
                            <h3 *ngIf="promotion.id == null" class="m-portlet__head-text">
                                Nuevo Promoción
                            </h3>
                            <h3 *ngIf="promotion.id != null" class="m-portlet__head-text">
                                Editar Promoción - {{promotion.name}}
                            </h3>
                        </div>
                    </div>
                    <div class="m-portlet__head-tools">
                        <ul class="m-portlet__nav">
                            <li class="m-portlet__nav-item">
                                <a [routerLink]="'/promotions'"
                                   class="m-portlet__nav-link btn btn-lg btn-secondary m-btn m-btn--outline-2x m-btn--air m-btn--icon m-btn--icon-only m-btn--pill  m-dropdown__toggle">
                                    <i class="flaticon-reply"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <form [formGroup]="form" (ngSubmit)="sendForm()" class="m-form m-form--label-align-right">
                    <div class="m-portlet__body">
                        <div class="m-form__section m-form__section--first">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Código:</label>
                                        <input formControlName="code" class="form-control m-input" placeholder="Código"
                                               [ngClass]="{ 'is-invalid': submitted && f.code.errors }" maxlength="20">
                                        <div *ngIf="submitted && f.code.errors" class="invalid-feedback">
                                            <div *ngIf="f.code.errors.required">Código obligatorio</div>
                                            <div *ngIf="f.code.errors.maxlength">Solo se permiten 5 caracteres</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Nombre:</label>
                                        <input formControlName="name" class="form-control m-input" placeholder="Nombre"
                                               [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
                                        <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                            <div *ngIf="f.name.errors.required">Nombre obligatorio</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Descripción:</label>
                                        <textarea formControlName="description" class="form-control m-input"
                                                  placeholder="Descripción"
                                                  [ngClass]="{ 'is-invalid': submitted && f.description.errors }"
                                                  rows="5"></textarea>
                                        <div *ngIf="submitted && f.description.errors" class="invalid-feedback">
                                            <div *ngIf="f.description.errors.required">Descripción obligatoria</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Fecha de fin:</label>
                                        <input formControlName="end_promotion" class="form-control m-input"
                                               placeholder="Fecha"
                                               [matDatepicker]="datePicker" (click)="datePicker.open()">
                                        <mat-datepicker #datePicker></mat-datepicker>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Estado:</label>
                                        <select formControlName="status" class="form-control m-input"
                                                [ngClass]="{ 'is-invalid': submitted && f.status.errors }">
                                            <option [ngValue]="1">Activo</option>
                                            <option [ngValue]="0">Inactivo</option>
                                        </select>
                                        <div *ngIf="submitted && f.status.errors" class="invalid-feedback">
                                            <div *ngIf="f.status.errors.required">Estado obligatorio</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Foto:</label>
                                        <div class="custom-file">
                                            <input type="file" class="custom-file-input" id="customFile"
                                                   accept="image/*"
                                                   (change)="onSelectFile($event)">
                                            <label class="custom-file-label" for="customFile">{{pathFile}}</label>
                                        </div>
                                        <div *ngIf="submitted && f.photo.errors" class="invalid-feedback"
                                             style="display: block">
                                            <div *ngIf="f.photo.errors.required">Foto obligatoria</div>
                                        </div>
                                        <div *ngIf="promotion.photo_url" class="text-center" style="padding-top: 1rem">
                                            <img [src]="promotion.photo_url" [alt]="promotion.name" class="img-fluid">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>Busca productos</label>
                                        <ng-select2
                                            #select2ProductSearch [(ngModel)]="productSearchText"
                                            [ngModelOptions]="{standalone: true}"
                                            [options]="optionsSelect2Product">
                                        </ng-select2>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <table class="table table-bordered table-hover">
                                            <thead>
                                            <tr>
                                                <th style="width: 10%">Código</th>
                                                <th style="width: 10%">Nombre</th>
                                                <th style="width: 10%">Descripción</th>
                                                <th style="width: 25%">Precio</th>
                                                <th style="width: 25%">Cantidad</th>
                                                <th style="width: 10%">Precio x Línea</th>
                                                <th style="width: 10%">Acciones</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr *ngFor="let item of items; let i = index">
                                                <td>{{item.code}}</td>
                                                <td>{{item.name}}</td>
                                                <td>{{item.description}}</td>
                                                <td>{{item.price | currency:'S/ '}}</td>
                                                <td>
                                                    <input [(ngModel)]="item.quantity" class="form-control m-input"
                                                           (ngModelChange)="calculateTotals()"
                                                           [ngModelOptions]="{standalone: true}"
                                                           appCurrencyInput placeholder="Cantidad">
                                                </td>
                                                <td>{{(item.price * item.quantity) | currency: 'S/ '}}</td>
                                                <td>
                                                    <button (click)="deleteItem(i)" class="btn btn-danger">
                                                        <i class="fa fa-trash" aria-hidden="true"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                            </tbody>
                                            <tfoot>
                                            <tr>
                                                <th colspan="6" class="text-right">Descuento</th>
                                                <th>
                                                    <input [(ngModel)]="discount" class="form-control m-input"
                                                           (ngModelChange)="calculateTotals()"
                                                           [ngModelOptions]="{standalone: true}"
                                                           appCurrencyInput placeholder="Descuento">
                                                </th>
                                            </tr>
                                            <tr>
                                                <th colspan="6" class="text-right">Total</th>
                                                <th>{{total | currency: 'S/ '}}</th>
                                            </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="m-portlet__foot m-portlet__foot--fit">
                        <div class="m-form__actions">
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button type="submit" [disabled]="loading"
                                            class="btn btn-accent m-btn m-btn--air m-btn--custom"
                                            [ngClass]="{'m-loader m-loader--light m-loader--right': loading}">
                                        Guardar Cambios
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
