<ng-container *ngIf="data.kipuMode == 'retail' || data.kipuMode == 'vehicle'">
    <h1 mat-dialog-title>
        Modificar Producto
    </h1>
    <mat-dialog-content>
        <div class="row">
            <div class="col-md-12">
                <div class="form-group">
                    <h4>{{data.item.name}} {{data.item.price | currency: data.form.currency_symbol}}</h4>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3">
                <div class="form-group">
                    <label class="label-lg">Cantidad</label>
                    <input type="number" [(ngModel)]="data.item.quantity" min="1"
                           class="form-control form-control-lg">
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label class="label-lg">Descuento</label>
                    <input type="text" *ngIf="data.withDiscount && data.item.result_type == 'product'"
                           [(ngModel)]="data.item.discount" min="1"
                           appCurrencyInput
                           class="form-control form-control-lg">
                    <input type="text" *ngIf="!data.withDiscount || data.item.result_type == 'promotion'"
                           value="0" readonly
                           class="form-control form-control-lg">
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label class="label-lg">Precio final</label>
                    <input type="text"
                           [value]="((data.item.price - data.item.discount) * data.item.quantity)"
                           min="1"
                           readonly
                           class="form-control form-control-lg">
                </div>
            </div>
            <div *ngIf="!data.branches_stock" class="col-md-3">
                <div class="pt-5">
                    <button class="btn btn-sale btn-block btn-lg"
                            (click)="storeItem()">
                        <span class="text-center">Continuar</span>
                    </button>
                </div>
            </div>
        </div>
        <ng-container *ngIf="data.branches_stock">
            <div class="row">
                <div class="col-md-12">
                    <div class="table-responsive">
                        <table class="table table-bordered table-hover">
                            <thead>
                            <tr>
                                <th>Sucursal</th>
                                <th>Ubicación</th>
                                <th>Stock</th>
                                <th>Usar</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let branchStock of data.branches_stock">
                                <td>{{branchStock.branch_name}}</td>
                                <th>{{branchStock.location_name}}</th>
                                <td>{{branchStock.stock}} und.</td>
                                <td>
                                    <label class="m-radio">
                                        <input type="radio" [value]="branchStock.id" [(ngModel)]="locationSelected">
                                        <span></span>
                                    </label>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="pt-5">
                        <button class="btn btn-danger btn-block btn-lg"
                                (click)="closeModal()">
                            <span class="text-center">Cerrar</span>
                        </button>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="pt-5">
                        <button class="btn btn-sale btn-block btn-lg"
                                (click)="storeItem()">
                            <span class="text-center">Continuar</span>
                        </button>
                    </div>
                </div>
            </div>
        </ng-container>
    </mat-dialog-content>
</ng-container>

<ng-container *ngIf="data.kipuMode == 'restaurant'">
    <h1 mat-dialog-title>
        Personalizar pedido
    </h1>
    <mat-dialog-content>
        <div class="row">
            <div class="col-md-3">
                <div class="img-product" [style.background-image]="'url(' + data.item.photo_url + ')'"></div>
            </div>
            <div class="col-md-9">
                <div class="desc-product-detail">
                    <div>
                        <div class="desc-product">
                            <h5>{{data.item.name}} {{data.item.price | currency: 'S/ '}}</h5>
                        </div>
                        <div class="desc-quantity-product">
                            <button class="bg-transparent border-0 mr-2" (click)="removeQuantity()">
                                <i class="i-plus-minus fas fa-minus-circle"></i>
                            </button>
                            <span class="text-black mr-2">{{data.item.quantity}}</span>
                            <button class="bg-transparent border-0" (click)="addQuantity()">
                                <i class="i-plus-minus fas fa-plus-circle"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="details-extras">
                    <h6 class="title-detail-extra">Detalles</h6>
                    <div class="m-checkbox-inline">
                        <label *ngFor="let detail of data.item.details"
                               class="m-checkbox m-checkbox--solid m-checkbox--state-kipu">
                            <input type="checkbox" [(ngModel)]="detail.added"> {{detail.name}}
                            <span></span>
                        </label>
                    </div>
                    <div class="pt-4">
                        <div class="form-group">
                            <label class="label-notes">Notas</label>
                            <textarea [(ngModel)]="data.item.notes" class="form-control m-input"
                                      placeholder="Descripción"></textarea>
                        </div>
                    </div>
                    <div class="pt-3">
                        <h6 class="title-detail-extra">Agregar extras</h6>
                        <div class="row">
                            <div class="col-md-3" *ngFor="let extra of data.item.extras">
                                <div class="item-extra">
                                    <div class="row">
                                        <div class="col-7">
                                            <h6 class="extra-title-name">{{extra.name}}</h6>
                                            <h6 class="extra-title-price">{{extra.price | currency: 'S/ '}}</h6>
                                        </div>
                                        <div class="col-5">
                                            <div class="item-extra--buttons-quantity">
                                                <button class="bg-transparent border-0 mr-2"
                                                        (click)="removeQuantityExtra(extra)">
                                                    <i class="i-plus-minus fas fa-minus-circle"></i>
                                                </button>
                                                <span class="text-black mr-2">{{extra.quantity}}</span>
                                                <button class="bg-transparent border-0"
                                                        (click)="addQuantityExtra(extra)">
                                                    <i class="i-plus-minus fas fa-plus-circle"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6"></div>
            <div class="col-md-6">
                <div class="row">
                    <div class="col-6">
                        <div class="pt-3">
                            <div class="row">
                                <div class="col-4"><h5 class="text-total">Total</h5></div>
                                <div class="col-8">
                                    <h5 class="text-total text-right"><span>{{totalItem | currency: 'S/ '}}</span></h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <button class="btn btn-sale btn-block btn-lg" (click)="storeItem()">
                            <span class="text-center">Continuar</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </mat-dialog-content>
</ng-container>
