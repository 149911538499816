<!-- BEGIN: Subheader -->
<div class="m-subheader ">
    <div class="d-flex align-items-center">
        <div class="mr-auto">
            <h3 class="m-subheader__title m-subheader__title--separator">Registrar Compra</h3>
            <ul class="m-subheader__breadcrumbs m-nav m-nav--inline">
                <li class="m-nav__item m-nav__item--home">
                    <a [routerLink]="''" class="m-nav__link m-nav__link--icon">
                        <i class="m-nav__link-icon la la-home"></i>
                    </a>
                </li>
                <li class="m-nav__separator">-</li>
                <li class="m-nav__item">
                    <a [routerLink]="'/purchases/create'" class="m-nav__link">
                        <span class="m-nav__link-text">Registrar Compra</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>

<div class="m-content">
    <div class="row">
        <div class="col-md-12">
            <!--begin::Portlet-->
            <div class="m-portlet m-portlet--mobile">
                <div class="m-portlet__head">
                    <div class="m-portlet__head-caption">
                        <div class="m-portlet__head-title">
                            <h3 class="m-portlet__head-text">
                                Registrar Compra
                            </h3>
                        </div>
                    </div>
                </div>
                <app-message></app-message>
                <div class="m-portlet__body">
                    <div class="m-form__section m-form__section--first">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Fecha:</label>
                                    <input [(ngModel)]="form.date" class="form-control m-input" placeholder="Fecha"
                                           [matDatepicker]="datePicker" (click)="datePicker.open()">
                                    <mat-datepicker #datePicker></mat-datepicker>
                                    <div *ngIf="submitted && !form.date" class="invalid-feedback">
                                        <div *ngIf="!form.date">Fecha obligatoria</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Proveedor:</label>
                                    <ng-select2
                                            [(ngModel)]="form.provider_id"
                                            #select2ProviderSearch
                                            [options]="optionsSelect2Provider">
                                    </ng-select2>
                                    <ng-container *ngIf="currentSettings.mandatory_provider_id_purchases">
                                        <div *ngIf="submitted && !form.provider_id" class="invalid-feedback">
                                            <div *ngIf="!form.provider_id">Proveedor obligatorio</div>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Número de factura:</label>
                                    <input [(ngModel)]="form.invoice_number" class="form-control m-input"
                                           placeholder="Número de factura">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Guía de Remisión:</label>
                                    <input [(ngModel)]="form.waybill" class="form-control m-input"
                                           placeholder="Guía de Remisión">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Método de Pago:</label>
                                    <ng-select2
                                            [(ngModel)]="form.purchase_payment_method_id"
                                            [data]="purchasePaymentMethods"
                                            [width]="'100%'"
                                            [placeholder]="'Selecciona una método de pago'">
                                    </ng-select2>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Categoría:</label>
                                    <ng-select2
                                            [(ngModel)]="form.category_id"
                                            [data]="categories"
                                            [width]="'100%'"
                                            [placeholder]="'Selecciona una  categoría'">
                                    </ng-select2>
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="currentSettings.multi_site || currentSettings.product_location">
                            <div class="col-md-6" *ngIf="currentSettings.multi_site">
                                <div class="form-group">
                                    <label>Sede/Almacén:</label>
                                    <ng-select2
                                            [(ngModel)]="form.branch_id"
                                            (ngModelChange)="onSelectBranch()"
                                            [data]="branches"
                                            [width]="'100%'"
                                            [placeholder]="'Selecciona una sede/almacén'">
                                    </ng-select2>
                                </div>
                            </div>
                            <div class="col-md-6" *ngIf="currentSettings.product_location">
                                <div class="form-group">
                                    <label>Ubicación:</label>
                                    <ng-select2
                                            [(ngModel)]="form.location_id"
                                            [data]="locations"
                                            [width]="'100%'"
                                            [placeholder]="'Selecciona una  ubicación'">
                                    </ng-select2>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label>Busca productos</label>
                                    <ng-select2
                                            [(ngModel)]="productSearchText"
                                            #select2ProductSearch
                                            [options]="optionsSelect2Product">
                                    </ng-select2>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <table class="table table-bordered table-hover">
                                        <thead>
                                        <tr>
                                            <th style="width: 10%">Código</th>
                                            <th style="width: 10%">Nombre</th>
                                            <th style="width: 10%">Descripción</th>
                                            <th style="width: 25%">Costo Unitario</th>
                                            <th style="width: 25%">Cantidad</th>
                                            <th style="width: 10%">Costo x Línea</th>
                                            <th style="width: 10%">Acciones</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr *ngFor="let item of form.items; let i = index">
                                            <td>{{item.code}}</td>
                                            <td>{{item.name}}</td>
                                            <td>{{item.description}}</td>
                                            <td>
                                                <input [(ngModel)]="item.cost" class="form-control m-input"
                                                       (ngModelChange)="calculateTotals()" appCurrencyInput
                                                       placeholder="Costo">
                                            </td>
                                            <td>
                                                <input [(ngModel)]="item.quantity" class="form-control m-input"
                                                       (ngModelChange)="calculateTotals()" appCurrencyInput
                                                       placeholder="Cantidad">
                                            </td>
                                            <td>{{(item.cost * item.quantity) | currency: form.currency_symbol}}</td>
                                            <td>
                                                <button (click)="deleteItem(i)" class="btn btn-danger">
                                                    <i class="fa fa-trash" aria-hidden="true"></i>
                                                </button>
                                            </td>
                                        </tr>
                                        </tbody>
                                        <tfoot>
                                        <tr>
                                            <th colspan="6" class="text-right">Subtotal</th>
                                            <th>{{form.subtotal | currency: form.currency_symbol}}</th>
                                        </tr>
                                        <tr>
                                            <th colspan="6" class="text-right">
                                                IGV ({{currentSettings.igv_percentage}} %)
                                            </th>
                                            <th>{{form.igv_amount | currency: form.currency_symbol}}</th>
                                        </tr>
                                        <tr>
                                            <th colspan="6" class="text-right">Total</th>
                                            <th>{{form.total | currency: form.currency_symbol}}</th>
                                        </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group text-center">
                                    <button class="btn btn-success" [disabled]="loading"
                                            (click)="sendForm()"
                                            [ngClass]="{'m-loader m-loader--light m-loader--right': loading}">
                                        Guardar Compra
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
