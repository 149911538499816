import {Component, OnInit, ViewChild} from '@angular/core';
import {DataTableDirective} from 'angular-datatables';
import {ProviderService} from '../../services/provider.service';
import {first} from 'rxjs/operators';
import * as FileSaver from 'file-saver';
import {ToastrService} from 'ngx-toastr';

declare var swal: any;

@Component({
    selector: 'app-provider',
    templateUrl: './providers.component.html',
    styleUrls: ['./providers.component.scss']
})
export class ProvidersComponent implements OnInit {

    dtOptions: DataTables.Settings = {};
    providers: any[];
    @ViewChild(DataTableDirective) private dtElement;
    exporting = false;

    constructor(
        private toastrService: ToastrService,
        private providerService: ProviderService
    ) {
    }

    ngOnInit(): void {
        this.dtOptions = {
            pagingType: 'full_numbers',
            pageLength: 10,
            serverSide: true,
            processing: true,
            ajax: (dataTablesParameters: any, callback) => {
                this.providerService.datatable(dataTablesParameters)
                    .pipe(first())
                    .subscribe(response => {
                        this.providers = response.data;
                        callback({
                            recordsTotal: response.recordsTotal,
                            recordsFiltered: response.recordsFiltered,
                            data: []
                        });
                    });
            },
            columns: [
                {data: 'ruc'},
                {data: 'business_name'},
                {data: 'contact_name'},
                {data: 'address'},
                {data: 'phone'},
                {data: 'email'},
                {data: 'status'},
            ]
        };
    }

    private async reloadTable() {
        let dtInstance = await this.dtElement.dtInstance;
        dtInstance.ajax.reload();
    }


    delete(local: any): void {
        swal.fire({
            title: '¿Estás seguro que deseas eliminar este registro?',
            showCancelButton: true,
            confirmButtonText: 'Si, elimínalo!',
            showLoaderOnConfirm: true,
            preConfirm: (arg) => {
                return this.providerService.delete(local.id)
                    .pipe(first())
                    .subscribe(response => {
                        swal.fire({
                            title: 'Elemento eliminado con éxito',
                            type: 'success'
                        });
                        this.reloadTable();
                    }, error => {
                        swal.fire({
                            title: error,
                            type: 'error'
                        });
                        return error;
                    });
            },
            allowOutsideClick: () => !swal.isLoading()
        }).then((result) => {
        });
    }

    exportList(): void {
        this.exporting = true;
        this.providerService.export().subscribe(blob => {
            this.exporting = false;
            FileSaver.saveAs(blob, 'Proveedores.xlsx');
        }, error1 => {
            this.exporting = false;
            this.toastrService.error(error1);
        });
    }
}
