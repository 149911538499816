<!-- BEGIN: Subheader -->
<div class="m-subheader ">
    <div class="d-flex align-items-center">
        <div class="mr-auto">
            <h3 class="m-subheader__title m-subheader__title--separator">Configuración</h3>
            <ul class="m-subheader__breadcrumbs m-nav m-nav--inline">
                <li class="m-nav__item m-nav__item--home">
                    <a [routerLink]="''" class="m-nav__link m-nav__link--icon">
                        <i class="m-nav__link-icon la la-home"></i>
                    </a>
                </li>
                <li class="m-nav__separator">-</li>
                <li class="m-nav__item">
                    <a [routerLink]="'/settings'" class="m-nav__link">
                        <span class="m-nav__link-text">Configuración</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>

<div class="m-content">
    <div class="row">
        <div class="col-md-12">
            <!--begin::Portlet-->
            <div class="m-portlet m-portlet--mobile">
                <div class="m-portlet__head">
                    <div class="m-portlet__head-caption">
                        <div class="m-portlet__head-title">
                            <h3 class="m-portlet__head-text">
                                Configuración
                            </h3>
                        </div>
                    </div>
                </div>
                <div class="m-form m-form--label-align-right">
                    <div class="m-portlet__body">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="m-form__section m-form__section--first">
                                            <h5 class="subtitle">Productos</h5>
                                            <div class="form-group m-form__group row">
                                                <label class="col-lg-6 col-form-label">
                                                    Incluir IGV
                                                    <small>(Venta)</small>
                                                    :
                                                </label>
                                                <div class="col-lg-6">
                                                    <span class="m-switch m-switch--icon m-switch--info ">
                                                        <label>
                                                            <input type="checkbox" [disabled]="igvIncludedUpdating"
                                                                   (change)="updateParam('igv_included')"
                                                                   [(ngModel)]="setting.igv_included">
                                                            <span></span>
                                                        </label>
                                                    </span>
                                                    <div *ngIf="igvIncludedUpdating"
                                                         class="m-loader m-loader--info loader-switch"></div>
                                                </div>
                                            </div>
                                            <div class="form-group m-form__group row">
                                                <label class="col-lg-6 col-form-label">Porcentaje de IGV:</label>
                                                <div class="col-lg-6">
                                                    <div class="input-group">
                                                        <input type="text" class="form-control"
                                                               placeholder="Porcentaje de IGV"
                                                               appCurrencyInput [disabled]="igvPercentageUpdating"
                                                               [(ngModel)]="setting.igv_percentage">
                                                        <div class="input-group-append">
                                                            <button class="btn btn-primary btn-kipu" type="button"
                                                                    (click)="updateParam('igv_percentage')"
                                                                    [ngClass]="{'m-loader m-loader--light m-loader--right': igvPercentageUpdating}">
                                                                Actualizar
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="m-form__section m-form__section--first">
                                            <h5 class="subtitle">Tiendas</h5>
                                            <div class="form-group m-form__group row">
                                                <label class="col-lg-6 col-form-label">Multi Sede:</label>
                                                <div class="col-lg-6">
                                                    <span class="m-switch m-switch--icon m-switch--info ">
                                                        <label>
                                                            <input type="checkbox" [disabled]="multiSitedUpdating"
                                                                   (change)="updateParam('multi_site')"
                                                                   [(ngModel)]="setting.multi_site">
                                                            <span></span>
                                                        </label>
                                                    </span>
                                                    <div *ngIf="multiSitedUpdating"
                                                         class="m-loader m-loader--info loader-switch"></div>
                                                </div>
                                            </div>
                                            <ng-container
                                                    *ngIf="setting.kipu_mode == 'retail' || setting.kipu_mode == 'vehicle'">
                                                <div class="form-group m-form__group row">
                                                    <label class="col-lg-6 col-form-label">Varias Ubicaciones por
                                                        productos:
                                                    </label>
                                                    <div class="col-lg-6">
                                                        <span class="m-switch m-switch--icon m-switch--info ">
                                                            <label>
                                                                <input type="checkbox" [disabled]="multiSitedUpdating"
                                                                       (change)="updateParam('product_location')"
                                                                       [(ngModel)]="setting.product_location">
                                                                <span></span>
                                                            </label>
                                                        </span>
                                                        <div *ngIf="productLocationUpdating"
                                                             class="m-loader m-loader--info loader-switch"></div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="m-form__section m-form__section--first">
                                            <h5 class="subtitle">Ventas</h5>
                                            <ng-container
                                                    *ngIf="setting.kipu_mode == 'retail' || setting.kipu_mode == 'vehicle'">
                                                <div class="form-group m-form__group row">
                                                    <label class="col-lg-6 col-form-label">Ventas sin Stock:</label>
                                                    <div class="col-lg-6">
                                                        <span class="m-switch m-switch--icon m-switch--info ">
                                                            <label>
                                                                <input type="checkbox"
                                                                       [disabled]="salesOutOfStockUpdating"
                                                                       (change)="updateParam('sales_out_of_stock')"
                                                                       [(ngModel)]="setting.sales_out_of_stock">
                                                                <span></span>
                                                            </label>
                                                        </span>
                                                        <div *ngIf="salesOutOfStockUpdating"
                                                             class="m-loader m-loader--info loader-switch"></div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                            <div class="form-group m-form__group row">
                                                <label class="col-lg-6 col-form-label">Descuentos con
                                                    administrador</label>
                                                <div class="col-lg-6">
                                                    <span class="m-switch m-switch--icon m-switch--info ">
                                                        <label>
                                                            <input type="checkbox"
                                                                   [disabled]="discountsEnabledAdminUpdating"
                                                                   (change)="updateParam('discounts_enabled_admin')"
                                                                   [(ngModel)]="setting.discounts_enabled_admin">
                                                            <span></span>
                                                        </label>
                                                    </span>
                                                    <div *ngIf="discountsEnabledAdminUpdating"
                                                         class="m-loader m-loader--info loader-switch"></div>
                                                </div>
                                            </div>
                                            <div class="form-group m-form__group row">
                                                <label class="col-lg-6 col-form-label">Descuentos con vendedor</label>
                                                <div class="col-lg-6">
                                                    <span class="m-switch m-switch--icon m-switch--info ">
                                                        <label>
                                                            <input type="checkbox"
                                                                   [disabled]="discountsEnabledSellerUpdating"
                                                                   (change)="updateParam('discounts_enabled_seller')"
                                                                   [(ngModel)]="setting.discounts_enabled_seller">
                                                            <span></span>
                                                        </label>
                                                    </span>
                                                    <div *ngIf="discountsEnabledSellerUpdating"
                                                         class="m-loader m-loader--info loader-switch"></div>
                                                </div>
                                            </div>
                                            <div class="form-group m-form__group row">
                                                <label class="col-lg-6 col-form-label">Facturación Electrónica:</label>
                                                <div class="col-lg-6">
                                                    <span class="m-switch m-switch--icon m-switch--info ">
                                                        <label>
                                                            <input type="checkbox"
                                                                   [disabled]="electronicBillingUpdating"
                                                                   (change)="updateParam('electronic_billing')"
                                                                   [(ngModel)]="setting.electronic_billing">
                                                            <span></span>
                                                        </label>
                                                    </span>
                                                    <div *ngIf="electronicBillingUpdating"
                                                         class="m-loader m-loader--info loader-switch"></div>
                                                </div>
                                            </div>
                                            <div class="form-group m-form__group row">
                                                <label class="col-lg-6 col-form-label">Venta de Servicios:</label>
                                                <div class="col-lg-6">
                                                    <span class="m-switch m-switch--icon m-switch--info ">
                                                        <label>
                                                            <input type="checkbox"
                                                                   [disabled]="saleOfServicesUpdating"
                                                                   (change)="updateParam('sale_of_services')"
                                                                   [(ngModel)]="setting.sale_of_services">
                                                            <span></span>
                                                        </label>
                                                    </span>
                                                    <div *ngIf="saleOfServicesUpdating"
                                                         class="m-loader m-loader--info loader-switch"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-md-12" *ngIf="setting.electronic_billing && !electronicBillingUpdating">
                                <div class="m-form__section m-form__section--first mt-5">
                                    <h5 class="subtitle mb-5">Datos para Facturación Electrónica</h5>
                                    <form [formGroup]="formElectronicBilling" (ngSubmit)="sendFormElectronicBilling()"
                                          class="m-form m-form--label-align-right">
                                        <div class="form-group m-form__group row">
                                            <label class="col-lg-2 col-form-label">Serie de Factura:</label>
                                            <div class="col-lg-10">
                                                <input formControlName="serial_invoice" class="form-control m-input"
                                                       placeholder="Nombre" maxlength="4" minlength="4"
                                                       [ngClass]="{ 'is-invalid': submitted && f.serial_invoice.errors }">
                                                <div *ngIf="submitted && f.serial_invoice.errors"
                                                     class="invalid-feedback">
                                                    <div *ngIf="f.serial_invoice.errors.required">Campo obligatorio
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group m-form__group row">
                                            <label class="col-lg-2 col-form-label">Último Número de Factura:</label>
                                            <div class="col-lg-10">
                                                <input formControlName="last_number_invoice"
                                                       class="form-control m-input"
                                                       appOnlyIntNumber
                                                       placeholder="Último Número de Factura" maxlength="8"
                                                       [ngClass]="{ 'is-invalid': submitted && f.last_number_invoice.errors }">
                                                <div *ngIf="submitted && f.last_number_invoice.errors"
                                                     class="invalid-feedback">
                                                    <div *ngIf="f.last_number_invoice.errors.required">Campo
                                                        obligatorio
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group m-form__group row">
                                            <label class="col-lg-2 col-form-label">Serie Nota de Crédito de
                                                Factura</label>
                                            <div class="col-lg-10">
                                                <input formControlName="serial_credit_note_invoice"
                                                       class="form-control m-input"
                                                       placeholder="Serie Nota de Crédito de Factura" maxlength="4"
                                                       minlength="4"
                                                       [ngClass]="{ 'is-invalid': submitted && f.serial_credit_note_invoice.errors }">
                                                <div *ngIf="submitted && f.serial_credit_note_invoice.errors"
                                                     class="invalid-feedback">
                                                    <div *ngIf="f.serial_credit_note_invoice.errors.required">Campo
                                                        obligatorio
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group m-form__group row">
                                            <label class="col-lg-2 col-form-label">Último Número de Nota de Crédito de
                                                Factura:</label>
                                            <div class="col-lg-10">
                                                <input formControlName="last_number_credit_note_invoice"
                                                       class="form-control m-input"
                                                       appOnlyIntNumber
                                                       placeholder="Último Número de factura" maxlength="8"
                                                       [ngClass]="{ 'is-invalid': submitted && f.last_number_credit_note_invoice.errors }">
                                                <div *ngIf="submitted && f.last_number_credit_note_invoice.errors"
                                                     class="invalid-feedback">
                                                    <div *ngIf="f.last_number_credit_note_invoice.errors.required">Campo
                                                        obligatorio
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group m-form__group row">
                                            <label class="col-lg-2 col-form-label">Serie Nota de Débito de
                                                Factura</label>
                                            <div class="col-lg-10">
                                                <input formControlName="serial_debit_note_invoice"
                                                       class="form-control m-input"
                                                       placeholder="Serie Nota de Débito de Factura" maxlength="4"
                                                       minlength="4"
                                                       [ngClass]="{ 'is-invalid': submitted && f.serial_debit_note_invoice.errors }">
                                                <div *ngIf="submitted && f.serial_debit_note_invoice.errors"
                                                     class="invalid-feedback">
                                                    <div *ngIf="f.serial_debit_note_invoice.errors.required">Campo
                                                        obligatorio
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group m-form__group row">
                                            <label class="col-lg-2 col-form-label">Último Número de Nota de Débito de
                                                Factura:</label>
                                            <div class="col-lg-10">
                                                <input formControlName="last_number_debit_note_invoice"
                                                       class="form-control m-input"
                                                       appOnlyIntNumber
                                                       placeholder="Último Número Débito de factura" maxlength="8"
                                                       [ngClass]="{ 'is-invalid': submitted && f.last_number_debit_note_invoice.errors }">
                                                <div *ngIf="submitted && f.last_number_debit_note_invoice.errors"
                                                     class="invalid-feedback">
                                                    <div *ngIf="f.last_number_debit_note_invoice.errors.required">Campo
                                                        obligatorio
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group m-form__group row">
                                            <label class="col-lg-2 col-form-label">Serie de Boleta:</label>
                                            <div class="col-lg-10">
                                                <input formControlName="serial_ticket" class="form-control m-input"
                                                       placeholder="Serie de Boleta" maxlength="4" minlength="4"
                                                       [ngClass]="{ 'is-invalid': submitted && f.serial_ticket.errors }">
                                                <div *ngIf="submitted && f.serial_ticket.errors"
                                                     class="invalid-feedback">
                                                    <div *ngIf="f.serial_ticket.errors.required">Campo obligatorio</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group m-form__group row">
                                            <label class="col-lg-2 col-form-label">Último Número de Boleta:</label>
                                            <div class="col-lg-10">
                                                <input formControlName="last_number_ticket" class="form-control m-input"
                                                       appOnlyIntNumber
                                                       placeholder="Último Número de Boleta" maxlength="8"
                                                       [ngClass]="{ 'is-invalid': submitted && f.last_number_ticket.errors }">
                                                <div *ngIf="submitted && f.last_number_ticket.errors"
                                                     class="invalid-feedback">
                                                    <div *ngIf="f.last_number_ticket.errors.required">Campo
                                                        obligatorio
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group m-form__group row">
                                            <label class="col-lg-2 col-form-label">Serie Nota de Crédito de
                                                Boleta:</label>
                                            <div class="col-lg-10">
                                                <input formControlName="serial_credit_note_ticket"
                                                       class="form-control m-input"
                                                       placeholder="Serie Nota de Crédito de Boleta" maxlength="4"
                                                       minlength="4"
                                                       [ngClass]="{ 'is-invalid': submitted && f.serial_credit_note_ticket.errors }">
                                                <div *ngIf="submitted && f.serial_credit_note_ticket.errors"
                                                     class="invalid-feedback">
                                                    <div *ngIf="f.serial_credit_note_ticket.errors.required">Campo
                                                        obligatorio
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group m-form__group row">
                                            <label class="col-lg-2 col-form-label">Último Número de Nota de Crédito de
                                                Boleta:</label>
                                            <div class="col-lg-10">
                                                <input formControlName="last_number_credit_note_ticket"
                                                       class="form-control m-input"
                                                       appOnlyIntNumber
                                                       placeholder="Último Número de Boleta" maxlength="8"
                                                       [ngClass]="{ 'is-invalid': submitted && f.last_number_credit_note_ticket.errors }">
                                                <div *ngIf="submitted && f.last_number_credit_note_ticket.errors"
                                                     class="invalid-feedback">
                                                    <div *ngIf="f.last_number_credit_note_ticket.errors.required">Campo
                                                        obligatorio
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group m-form__group row">
                                            <label class="col-lg-2 col-form-label">Serie Nota de Débito de
                                                Boleta</label>
                                            <div class="col-lg-10">
                                                <input formControlName="serial_debit_note_ticket"
                                                       class="form-control m-input"
                                                       placeholder="Serie Nota de Débito de Boleta" maxlength="4"
                                                       minlength="4"
                                                       [ngClass]="{ 'is-invalid': submitted && f.serial_debit_note_ticket.errors }">
                                                <div *ngIf="submitted && f.serial_debit_note_ticket.errors"
                                                     class="invalid-feedback">
                                                    <div *ngIf="f.serial_debit_note_ticket.errors.required">Campo
                                                        obligatorio
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group m-form__group row">
                                            <label class="col-lg-2 col-form-label">Último Número de Nota de Débito de
                                                Boleta:</label>
                                            <div class="col-lg-10">
                                                <input formControlName="last_number_debit_note_ticket"
                                                       class="form-control m-input"
                                                       appOnlyIntNumber
                                                       placeholder="Último Número Débito de Boleta" maxlength="8"
                                                       [ngClass]="{ 'is-invalid': submitted && f.last_number_debit_note_ticket.errors }">
                                                <div *ngIf="submitted && f.last_number_debit_note_ticket.errors"
                                                     class="invalid-feedback">
                                                    <div *ngIf="f.last_number_debit_note_ticket.errors.required">Campo
                                                        obligatorio
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="m-form__actions">
                                            <div class="row">
                                                <div class="col-12 text-center">
                                                    <button type="submit" [disabled]="loading"
                                                            class="btn btn-info btn-kipu m-btn m-btn--air m-btn--custom"
                                                            [ngClass]="{'m-loader m-loader--light m-loader--right': loading}">
                                                        Guardar Cambios
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="m-form__section m-form__section--first">
                                            <h5 class="subtitle">Métodos de Pago</h5>
                                            <div class="form-group m-form__group row">
                                                <label class="col-lg-6 col-form-label">Pagos con QR:</label>
                                                <div class="col-lg-6">
                                                    <span class="m-switch m-switch--icon m-switch--info ">
                                                        <label>
                                                            <input type="checkbox"
                                                                   [disabled]="qrNiubizUpdating"
                                                                   [(ngModel)]="setting.qr_niubiz"
                                                                   (ngModelChange)="updateParam('qr_niubiz')">
                                                            <span></span>
                                                        </label>
                                                    </span>
                                                    <div *ngIf="qrNiubizUpdating"
                                                         class="m-loader m-loader--info loader-switch"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-2" *ngIf="setting.qr_niubiz">
                            <div class="col-md-12">
                                <div class="m-form__section m-form__section--first mt-5">
                                    <h5 class="subtitle mb-5">Datos para Pagos con QR Niubiz</h5>
                                    <form [formGroup]="formQrNiubiz" (ngSubmit)="sendFormQrNiubiz()"
                                          class="m-form m-form--label-align-right">
                                        <div class="form-group m-form__group row">
                                            <label class="col-lg-2 col-form-label">Nombre de Usuario:</label>
                                            <div class="col-lg-10">
                                                <input formControlName="qr_niubiz_username" class="form-control m-input"
                                                       placeholder="Nombre de Usuario"
                                                       [ngClass]="{ 'is-invalid': submittedFormQrNiubiz && fQrNiubiz.qr_niubiz_username.errors }">
                                                <div *ngIf="submittedFormQrNiubiz && fQrNiubiz.qr_niubiz_username.errors"
                                                     class="invalid-feedback">
                                                    <div *ngIf="fQrNiubiz.qr_niubiz_username.errors.required">
                                                        Nombre de Usuario obligatorio
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group m-form__group row">
                                            <label class="col-lg-2 col-form-label">Contraseña:</label>
                                            <div class="col-lg-10">
                                                <input formControlName="qr_niubiz_password" class="form-control m-input"
                                                       placeholder="Contraseña" type="password"
                                                       [ngClass]="{ 'is-invalid': submittedFormQrNiubiz && fQrNiubiz.qr_niubiz_password.errors }">
                                                <div *ngIf="submittedFormQrNiubiz && fQrNiubiz.qr_niubiz_password.errors"
                                                     class="invalid-feedback">
                                                    <div *ngIf="fQrNiubiz.qr_niubiz_password.errors.required">
                                                        Contraseña obligatoria
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group m-form__group row">
                                            <label class="col-lg-2 col-form-label">Código de Comercio:</label>
                                            <div class="col-lg-10">
                                                <input formControlName="qr_niubiz_merchant_id"
                                                       class="form-control m-input"
                                                       placeholder="Código de Comercio"
                                                       [ngClass]="{ 'is-invalid': submittedFormQrNiubiz && fQrNiubiz.qr_niubiz_merchant_id.errors }">
                                                <div *ngIf="submittedFormQrNiubiz && fQrNiubiz.qr_niubiz_merchant_id.errors"
                                                     class="invalid-feedback">
                                                    <div *ngIf="fQrNiubiz.qr_niubiz_merchant_id.errors.required">
                                                        Código de Comercio obligatorio
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="m-form__actions">
                                            <div class="row">
                                                <div class="col-12 text-center">
                                                    <button type="submit" [disabled]="loadingFormQrNiubiz"
                                                            class="btn btn-info btn-kipu m-btn m-btn--air m-btn--custom"
                                                            [ngClass]="{'m-loader m-loader--light m-loader--right': loadingFormQrNiubiz}">
                                                        Guardar Cambios
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-md-8">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="m-form__section m-form__section--first">
                                            <h5 class="subtitle">Monedas</h5>
                                            <div class="form-group m-form__group row">
                                                <label class="col-lg-6 col-form-label">
                                                    Monedas para productos y ventas:
                                                </label>
                                                <div class="col-lg-6">
                                                    <div class="table-responsive">
                                                        <table class="table bordered table-hover">
                                                            <thead>
                                                            <tr>
                                                                <th>Moneda</th>
                                                                <th>Principal</th>
                                                                <th>Acciones</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            <tr *ngFor="let settingCurrency of settingCurrencies; let i = index">
                                                                <td>
                                                                    <select [(ngModel)]="settingCurrency.currency_id"
                                                                            class="form-control m-input">
                                                                        <option [ngValue]="null">Seleccionar Moneda
                                                                        </option>
                                                                        <option *ngFor="let currency of currencies"
                                                                                [ngValue]="currency.id">{{currency.name}}
                                                                            ({{currency.alphabetic_code}})
                                                                        </option>
                                                                    </select>
                                                                </td>
                                                                <td>{{settingCurrency.principal ? 'Si' : 'No'}}</td>
                                                                <td>
                                                                    <button class="btn btn-accent mr-2" title="Agregar"
                                                                            (click)="addItemCurrency(i)">
                                                                        <i class="la la-plus"></i>
                                                                    </button>
                                                                    <button class="btn btn-danger" title="Borrar"
                                                                            *ngIf="i != 0"
                                                                            (click)="removeItemCurrency(i)">
                                                                        <i class="la la-trash"></i>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="form-group text-right">
                                            <button class="btn btn-primary btn-kipu" type="button"
                                                    (click)="updateCurrencies()"
                                                    [disabled]="currencyUpdating"
                                                    [ngClass]="{'m-loader m-loader--light m-loader--right': currencyUpdating}">
                                                Actualizar
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="m-form__section m-form__section--first">
                                            <h5 class="subtitle">Compras</h5>

                                            <div class="form-group m-form__group row">
                                                <label class="col-lg-6 col-form-label">
                                                    Proveedor obligatorio
                                                </label>
                                                <div class="col-lg-6">
                                                    <span class="m-switch m-switch--icon m-switch--info ">
                                                        <label>
                                                            <input type="checkbox"
                                                                   [disabled]="mandatoryProviderIdPurchasesUpdating"
                                                                   (change)="updateParam('mandatory_provider_id_purchases')"
                                                                   [(ngModel)]="setting.mandatory_provider_id_purchases">
                                                            <span></span>
                                                        </label>
                                                    </span>
                                                    <div *ngIf="mandatoryProviderIdPurchasesUpdating"
                                                         class="m-loader m-loader--info loader-switch"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-md-12">
                                <div class="m-form__section m-form__section--first mt-5">
                                    <h5 class="subtitle mb-5">Datos para Comprobante Electrónica</h5>
                                    <form [formGroup]="formVoucher" (ngSubmit)="sendFormVoucher()"
                                          class="m-form m-form--label-align-right">

                                        <div class="form-group m-form__group row">
                                            <label class="col-lg-4 col-form-label">Razón Social</label>
                                            <div class="col-lg-4">
                                                <input formControlName="business_name" class="form-control m-input"
                                                       placeholder="Razón Social"
                                                       [ngClass]="{ 'is-invalid': submitted && fVoucher.business_name.errors }">
                                                <div *ngIf="submitted && fVoucher.business_name.errors"
                                                     class="invalid-feedback">
                                                    <div *ngIf="fVoucher.business_name.errors.required">
                                                        Campo obligatorio
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group m-form__group row">
                                            <label class="col-lg-4 col-form-label">Dirección</label>
                                            <div class="col-lg-4">
                                                <input formControlName="address" class="form-control m-input"
                                                       placeholder="Dirección"
                                                       [ngClass]="{ 'is-invalid': submitted && fVoucher.address.errors }">
                                                <div *ngIf="submitted && fVoucher.address.errors"
                                                     class="invalid-feedback">
                                                    <div *ngIf="fVoucher.address.errors.required">
                                                        Campo obligatorio
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group m-form__group row">
                                            <label class="col-lg-4 col-form-label">Email</label>
                                            <div class="col-lg-4">
                                                <input formControlName="email_contact" class="form-control m-input"
                                                       placeholder="Email" type="email"
                                                       [ngClass]="{ 'is-invalid': submitted && fVoucher.email_contact.errors }">
                                                <div *ngIf="submitted && fVoucher.email_contact.errors"
                                                     class="invalid-feedback">
                                                    <div *ngIf="fVoucher.email_contact.errors.required">
                                                        Campo obligatorio
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group m-form__group row">
                                            <label class="col-lg-4 col-form-label">Departamento</label>
                                            <div class="col-lg-4">
                                                <input formControlName="department" class="form-control m-input"
                                                       placeholder="Departamento"
                                                       [ngClass]="{ 'is-invalid': submitted && fVoucher.department.errors }">
                                                <div *ngIf="submitted && fVoucher.department.errors"
                                                     class="invalid-feedback">
                                                    <div *ngIf="fVoucher.department.errors.required">
                                                        Campo obligatorio
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group m-form__group row">
                                            <label class="col-lg-4 col-form-label">Provincia</label>
                                            <div class="col-lg-4">
                                                <input formControlName="province" class="form-control m-input"
                                                       placeholder="Provincia"
                                                       [ngClass]="{ 'is-invalid': submitted && fVoucher.province.errors }">
                                                <div *ngIf="submitted && fVoucher.province.errors"
                                                     class="invalid-feedback">
                                                    <div *ngIf="fVoucher.province.errors.required">
                                                        Campo obligatorio
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group m-form__group row">
                                            <label class="col-lg-4 col-form-label">Distrito</label>
                                            <div class="col-lg-4">
                                                <input formControlName="district" class="form-control m-input"
                                                       placeholder="Distrito"
                                                       [ngClass]="{ 'is-invalid': submitted && fVoucher.district.errors }">
                                                <div *ngIf="submitted && fVoucher.district.errors"
                                                     class="invalid-feedback">
                                                    <div *ngIf="fVoucher.district.errors.required">
                                                        Campo obligatorio
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group m-form__group row">
                                            <label class="col-lg-4 col-form-label">Logo:</label>
                                            <div class="col-lg-4">
                                                <div class="custom-file">
                                                    <input type="file" class="custom-file-input" id="customFile" accept="image/*"
                                                           (change)="onSelectFile($event)">
                                                    <label class="custom-file-label" for="customFile">{{pathFile}}</label>
                                                </div>
                                                <div *ngIf="submitted && fVoucher.merchant_logo.errors" class="invalid-feedback"
                                                     style="display: block">
                                                    <div *ngIf="fVoucher.merchant_logo.errors.required">Foto obligatoria</div>
                                                </div>
                                                <div *ngIf="setting.merchant_logo_url" class="text-center" style="padding-top: 1rem">
                                                    <img [src]="setting.merchant_logo_url" [alt]="setting.business_name" class="img-fluid">
                                                </div>
                                            </div>
                                        </div>

                                        <div class="m-form__actions">
                                            <div class="row">
                                                <div class="col-12 text-center">
                                                    <button type="submit" [disabled]="loadingFormVoucher"
                                                            class="btn btn-info btn-kipu m-btn m-btn--air m-btn--custom"
                                                            [ngClass]="{'m-loader m-loader--light m-loader--right': loadingFormVoucher}">
                                                        Guardar Cambios
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
