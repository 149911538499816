<!-- BEGIN: Subheader -->
<div class="m-subheader ">
    <div class="d-flex align-items-center">
        <div class="mr-auto">
            <h3 class="m-subheader__title m-subheader__title--separator" *ngIf="product.id">Editar Producto</h3>
            <h3 class="m-subheader__title m-subheader__title--separator" *ngIf="!product.id">Nuevo Producto</h3>
            <ul class="m-subheader__breadcrumbs m-nav m-nav--inline">
                <li class="m-nav__item m-nav__item--home">
                    <a [routerLink]="''" class="m-nav__link m-nav__link--icon">
                        <i class="m-nav__link-icon la la-home"></i>
                    </a>
                </li>
                <li class="m-nav__separator">-</li>
                <li class="m-nav__item">
                    <a [routerLink]="'/products'" class="m-nav__link">
                        <span class="m-nav__link-text">Productos</span>
                    </a>
                </li>
                <li class="m-nav__separator">-</li>
                <li class="m-nav__item">
                    <a *ngIf="product.id == null" [routerLink]="'/products/create'" class="m-nav__link">
                        <span class="m-nav__link-text">Nuevo Producto</span>
                    </a>
                    <a *ngIf="product.id != null" [routerLink]="['/products/edit', product.id]" class="m-nav__link">
                        <span class="m-nav__link-text">Editar Producto</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>

<div class="m-content">
    <div class="row">
        <div class="col-md-12">
            <!--begin::Portlet-->
            <div class="m-portlet m-portlet--mobile">
                <div class="m-portlet__head">
                    <div class="m-portlet__head-caption">
                        <div class="m-portlet__head-title">
                            <h3 *ngIf="product.id == null" class="m-portlet__head-text">
                                Nuevo Producto
                            </h3>
                            <h3 *ngIf="product.id != null" class="m-portlet__head-text">
                                Editar Producto - {{product.name}}
                            </h3>
                        </div>
                    </div>
                    <div class="m-portlet__head-tools">
                        <ul class="m-portlet__nav">
                            <li class="m-portlet__nav-item">
                                <a [routerLink]="'/products'"
                                   class="m-portlet__nav-link btn btn-lg btn-secondary m-btn m-btn--outline-2x m-btn--air m-btn--icon m-btn--icon-only m-btn--pill  m-dropdown__toggle">
                                    <i class="flaticon-reply"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <form [formGroup]="form" (ngSubmit)="sendForm()" class="m-form m-form--label-align-right">
                    <div class="m-portlet__body">
                        <div class="m-form__section m-form__section--first">
                            <div *ngIf="setting.kipu_mode == 'restaurant'" class="form-group m-form__group row">
                                <label class="col-lg-2 col-form-label">Menús</label>
                                <div class="col-lg-6">
                                    <ng-select2
                                            [data]="menus"
                                            [width]="'100%'"
                                            [placeholder]="'Selecciona un menú'"
                                            formControlName="menu_id"
                                            (ngModelChange)="getCategoriesByMenuId()">
                                    </ng-select2>
                                    <div *ngIf="submitted && f.menu_id.errors != null" class="invalid-feedback"
                                         style="display: block">
                                        <div *ngIf="f.menu_id.errors.required">Menú obligatoria</div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group m-form__group row">
                                <label class="col-lg-2 col-form-label">Categoría</label>
                                <div class="col-lg-6">
                                    <ng-select2
                                            [data]="categories"
                                            [width]="'100%'"
                                            [placeholder]="'Selecciona una categoría'"
                                            formControlName="category_id">
                                    </ng-select2>
                                    <div *ngIf="submitted && f.category_id.errors != null" class="invalid-feedback"
                                         style="display: block">
                                        <div *ngIf="f.category_id.errors.required">Categoría obligatoria</div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group m-form__group row">
                                <label class="col-lg-2 col-form-label">Código:</label>
                                <div class="col-lg-6">
                                    <input formControlName="code" class="form-control m-input" placeholder="Código"
                                           [ngClass]="{ 'is-invalid': submitted && f.code.errors }" maxlength="20">
                                    <div *ngIf="submitted && f.code.errors" class="invalid-feedback">
                                        <div *ngIf="f.code.errors.required">Código obligatorio</div>
                                        <div *ngIf="f.code.errors.maxlength">Solo se permiten 5 caracteres</div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group m-form__group row">
                                <label class="col-lg-2 col-form-label">Nombre:</label>
                                <div class="col-lg-6">
                                    <input formControlName="name" class="form-control m-input" placeholder="Nombre"
                                           [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
                                    <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                        <div *ngIf="f.name.errors.required">Nombre obligatorio</div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group m-form__group row">
                                <label class="col-lg-2 col-form-label">Etiqueta Extra: <br>
                                    <small>(Sirve como dato extra para la búsqueda en las ventas)</small>
                                </label>
                                <div class="col-lg-6">
                                    <input formControlName="comment" class="form-control m-input"
                                           placeholder="Etiqueta Extra">
                                </div>
                            </div>
                            <div *ngIf="setting.kipu_mode == 'vehicle'" class="form-group m-form__group row">
                                <label class="col-lg-2 col-form-label">Tipo:</label>
                                <div class="col-lg-6">
                                    <select formControlName="type" class="form-control m-input"
                                            [ngClass]="{ 'is-invalid': submitted && f.type.errors }">
                                        <option [ngValue]="'replacement'">Repuesto</option>
                                        <option [ngValue]="'vehicle'">Vehículo</option>
                                    </select>
                                    <div *ngIf="submitted && f.type.errors" class="invalid-feedback">
                                        <div *ngIf="f.type.errors.required">Tipo obligatorio</div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group m-form__group row">
                                <label class="col-lg-2 col-form-label">Descripción:</label>
                                <div class="col-lg-6">
                                    <textarea formControlName="description" class="form-control m-input"
                                              placeholder="Descripción"
                                              [ngClass]="{ 'is-invalid': submitted && f.description.errors }"
                                              rows="5"></textarea>
                                    <div *ngIf="submitted && f.description.errors" class="invalid-feedback">
                                        <div *ngIf="f.description.errors.required">Descripción obligatoria</div>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="setting.setting_currencies.length > 1 && !this.id" class="form-group m-form__group row">
                                <label class="col-lg-2 col-form-label">Moneda:</label>
                                <div class="col-lg-6">
                                    <select formControlName="currency_id" class="form-control m-input"
                                            [ngClass]="{ 'is-invalid': submitted && f.currency_id.errors }">
                                        <option [ngValue]="currency.currency_id" *ngFor="let currency of setting.setting_currencies">
                                            {{currency.currency.name}} ({{currency.currency.alphabetic_code}})
                                        </option>
                                    </select>
                                    <div *ngIf="submitted && f.currency_id.errors" class="invalid-feedback">
                                        <div *ngIf="f.currency_id.errors.required">Tipo obligatorio</div>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group m-form__group row">
                                <label class="col-lg-2 col-form-label">Precio:</label>
                                <div class="col-lg-6">
                                    <input formControlName="price" class="form-control m-input" placeholder="Precio"
                                           appCurrencyInput
                                           [ngClass]="{ 'is-invalid': submitted && f.price.errors }"/>
                                    <div *ngIf="submitted && f.price.errors" class="invalid-feedback">
                                        <div *ngIf="f.price.errors.required">Precio obligatorio</div>
                                        <div *ngIf="f.price.errors.min">El precio mínimo es 0.1</div>
                                    </div>
                                </div>
                            </div>
                            <ng-container *ngIf="setting.kipu_mode == 'vehicle'">
                                <ng-container *ngIf="f.type.value == 'replacement'">
                                    <div class="form-group m-form__group row">
                                        <label class="col-lg-2 col-form-label">Marca:</label>
                                        <div class="col-lg-6">
                                            <input formControlName="brand_name" class="form-control m-input" placeholder="Marca"/>
                                        </div>
                                    </div>
                                    <div class="form-group m-form__group row">
                                        <label class="col-lg-2 col-form-label">Procedencia:</label>
                                        <div class="col-lg-6">
                                            <input formControlName="origin_name" class="form-control m-input" placeholder="Procedencia"/>
                                        </div>
                                    </div>
                                    <div class="form-group m-form__group row">
                                        <label class="col-lg-2 col-form-label">Stock Mínimo:</label>
                                        <div class="col-lg-6">
                                            <input formControlName="minimum_stock" class="form-control m-input" appCurrencyInput placeholder="Stock Mínimo"/>
                                        </div>
                                    </div>
                                    <div class="form-group m-form__group row">
                                        <label class="col-lg-2 col-form-label">Unidad de Medida:</label>
                                        <div class="col-lg-6">
                                            <input formControlName="unit_of_measurement" class="form-control m-input" placeholder="Unidad de Medida"/>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="f.type.value == 'vehicle'">
                                    <div class="form-group m-form__group row">
                                        <label class="col-lg-2 col-form-label">Modelo:</label>
                                        <div class="col-lg-6">
                                            <input formControlName="model_name" class="form-control m-input" placeholder="Modelo"/>
                                        </div>
                                    </div>
                                    <div class="form-group m-form__group row">
                                        <label class="col-lg-2 col-form-label">Marca:</label>
                                        <div class="col-lg-6">
                                            <input formControlName="brand_name" class="form-control m-input" placeholder="Marca"/>
                                        </div>
                                    </div>
                                    <div class="form-group m-form__group row">
                                        <label class="col-lg-2 col-form-label">Año Modelo:</label>
                                        <div class="col-lg-6">
                                            <input formControlName="model_year" class="form-control m-input" placeholder="Año Modelo"/>
                                        </div>
                                    </div>
                                    <div class="form-group m-form__group row">
                                        <label class="col-lg-2 col-form-label">Color:</label>
                                        <div class="col-lg-6">
                                            <input formControlName="color" class="form-control m-input" placeholder="Color"/>
                                        </div>
                                    </div>
                                    <div class="form-group m-form__group row">
                                        <label class="col-lg-2 col-form-label">Chasis:</label>
                                        <div class="col-lg-6">
                                            <input formControlName="chassis" class="form-control m-input" placeholder="Chasis"/>
                                        </div>
                                    </div>
                                    <div class="form-group m-form__group row" >
                                        <label class="col-lg-2 col-form-label">Placa:</label>
                                        <div class="col-lg-6">
                                            <input formControlName="license_plate" class="form-control m-input" placeholder="Placa"/>
                                        </div>
                                    </div>
                                    <div class="form-group m-form__group row" >
                                        <label class="col-lg-2 col-form-label">Versión:</label>
                                        <div class="col-lg-6">
                                            <input formControlName="version" class="form-control m-input" placeholder="Versión"/>
                                        </div>
                                    </div>
                                    <div class="form-group m-form__group row" >
                                        <label class="col-lg-2 col-form-label">Aro:</label>
                                        <div class="col-lg-6">
                                            <input formControlName="tire_rim" class="form-control m-input" placeholder="Aro"/>
                                        </div>
                                    </div>
                                    <div class="form-group m-form__group row" >
                                        <label class="col-lg-2 col-form-label">Equipamiento:</label>
                                        <div class="col-lg-6">
                                            <input formControlName="equipment" class="form-control m-input" placeholder="Equipamiento"/>
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>

                            <ng-container *ngIf="!product.id">
                                <div class="form-group m-form__group row">
                                    <label class="col-lg-2 col-form-label">Costo Inicial:</label>
                                    <div class="col-lg-6">
                                        <input formControlName="cost" class="form-control m-input"
                                               placeholder="Costo Inicial"
                                               appCurrencyInput
                                               [ngClass]="{ 'is-invalid': submitted && f.cost.errors }">
                                        <div *ngIf="submitted && f.cost.errors" class="invalid-feedback">
                                            <div *ngIf="f.cost.errors.required">Costo inicial obligatorio</div>
                                            <div *ngIf="f.cost.errors.min">El stock mínimo es 0</div>
                                        </div>
                                    </div>
                                </div>

                                <ng-container *ngIf="setting.kipu_mode == 'retail' || setting.kipu_mode == 'vehicle'">
                                    <div *ngIf="!setting.multi_site && !setting.product_location"
                                         class="form-group m-form__group row">
                                        <label class="col-lg-2 col-form-label">Stock:</label>
                                        <div class="col-lg-6">
                                            <input formControlName="stock" class="form-control m-input"
                                                   placeholder="Stock"
                                                   appOnlyIntNumber
                                                   [ngClass]="{ 'is-invalid': submitted && f.stock.errors }">
                                            <div *ngIf="submitted && f.stock.errors" class="invalid-feedback">
                                                <div *ngIf="f.stock.errors.min">El stock mínimo es 0</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="setting.multi_site || setting.product_location" class="row">
                                        <label class="col-lg-2 col-form-label">Stock por Sede/Almacén:</label>
                                        <div class="col-lg-6">
                                            <div class="table-responsive">
                                                <table class="table table-bordered table-hover">
                                                    <thead>
                                                    <tr>
                                                        <th>Sucursal / Almacén</th>
                                                        <th *ngIf="setting.product_location">Ubicación</th>
                                                        <th>Stock</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <ng-container *ngIf="setting.product_location">
                                                        <ng-container *ngFor="let branch of branches; let i = index">
                                                            <tr *ngFor="let location of branch.locations; let y = index">
                                                                <td>{{branch.name}}</td>
                                                                <td>{{location.name}}</td>
                                                                <td>
                                                                    <input [(ngModel)]="location.stock"
                                                                           [ngModelOptions]="{standalone: true}"
                                                                           class="form-control m-input"
                                                                           placeholder="Stock"
                                                                           appOnlyIntNumber>
                                                                </td>
                                                            </tr>
                                                        </ng-container>
                                                    </ng-container>
                                                    <ng-container *ngIf="!setting.product_location">
                                                        <tr *ngFor="let branch of branches; let i = index">
                                                            <td>{{branch.name}}</td>
                                                            <td>
                                                                <input [(ngModel)]="branch.stock"
                                                                       [ngModelOptions]="{standalone: true}"
                                                                       class="form-control m-input" placeholder="Stock"
                                                                       appOnlyIntNumber>
                                                            </td>
                                                        </tr>
                                                    </ng-container>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>

                            <ng-container *ngIf="setting.kipu_mode == 'retail'">
                                <div class="form-group m-form__group row">
                                    <label class="col-lg-2 col-form-label">Stock Mínimo:</label>
                                    <div class="col-lg-6">
                                        <input formControlName="minimum_stock" class="form-control m-input" appCurrencyInput placeholder="Stock Mínimo"/>
                                    </div>
                                </div>
                            </ng-container>

                            <div class="form-group m-form__group row">
                                <label class="col-lg-2 col-form-label">Estado:</label>
                                <div class="col-lg-6">
                                    <select formControlName="status" class="form-control m-input"
                                            [ngClass]="{ 'is-invalid': submitted && f.status.errors }">
                                        <option [ngValue]="1">Activo</option>
                                        <option [ngValue]="0">Inactivo</option>
                                    </select>
                                    <div *ngIf="submitted && f.status.errors" class="invalid-feedback">
                                        <div *ngIf="f.status.errors.required">Estado obligatorio</div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group m-form__group row">
                                <label class="col-lg-2 col-form-label">Foto:</label>
                                <div class="col-lg-6">
                                    <div class="custom-file">
                                        <input type="file" class="custom-file-input" id="customFile" accept="image/*"
                                               (change)="onSelectFile($event)">
                                        <label class="custom-file-label" for="customFile">{{pathFile}}</label>
                                    </div>
                                    <div *ngIf="submitted && f.photo.errors" class="invalid-feedback"
                                         style="display: block">
                                        <div *ngIf="f.photo.errors.required">Foto obligatoria</div>
                                    </div>
                                    <div *ngIf="product.photo_url" class="text-center" style="padding-top: 1rem">
                                        <img [src]="product.photo_url" [alt]="product.name" class="img-fluid">
                                    </div>
                                </div>
                            </div>

                            <ng-container *ngIf="setting.kipu_mode == 'restaurant'">
                                <div class="form-group m-form__group row">
                                    <label class="col-lg-2 col-form-label">Detalles:</label>
                                    <div class="col-lg-6">
                                        <table class="table table-hover table-bordered">
                                            <thead>
                                            <tr>
                                                <th>Nombre</th>
                                                <th>Acción</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr *ngFor="let detail of details; let i = index">
                                                <td><input [(ngModel)]="detail.name"
                                                           [ngModelOptions]="{standalone: true}"
                                                           class="form-control m-input"/></td>
                                                <td>
                                                    <button class="btn btn-danger mr-2" title="Borrar" type="button"
                                                            *ngIf="i != 0"
                                                            (click)="removeItem(details, i)">
                                                        <i class="la la-trash"></i>
                                                    </button>
                                                    <button class="btn btn-accent" title="Agregar" type="button"
                                                            (click)="addItem(details, i)">
                                                        <i class="la la-plus"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div class="form-group m-form__group row">
                                    <label class="col-lg-2 col-form-label">Extras:</label>
                                    <div class="col-lg-6">
                                        <table class="table table-hover table-bordered">
                                            <thead>
                                            <tr>
                                                <th>Nombre</th>
                                                <th>Precio</th>
                                                <th>Acción</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr *ngFor="let extra of extras; let i = index">
                                                <td><input [(ngModel)]="extra.name"
                                                           [ngModelOptions]="{standalone: true}"
                                                           class="form-control m-input"/></td>
                                                <td><input [(ngModel)]="extra.price" appCurrencyInput
                                                           [ngModelOptions]="{standalone: true}"
                                                           class="form-control m-input"/></td>
                                                <td>
                                                    <button class="btn btn-danger mr-2" title="Borrar" type="button"
                                                            *ngIf="i != 0"
                                                            (click)="removeItem(extras, i)">
                                                        <i class="la la-trash"></i>
                                                    </button>
                                                    <button class="btn btn-accent" title="Agregar" type="button"
                                                            (click)="addItem(extras, i)">
                                                        <i class="la la-plus"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                    <div class="m-portlet__foot m-portlet__foot--fit">
                        <div class="m-form__actions">
                            <div class="row">
                                <div class="col-2">
                                </div>
                                <div class="col-7">
                                    <button type="submit" [disabled]="loading"
                                            class="btn btn-accent m-btn m-btn--air m-btn--custom"
                                            [ngClass]="{'m-loader m-loader--light m-loader--right': loading}">
                                        Guardar Cambios
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
