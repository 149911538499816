<!-- BEGIN: Subheader -->
<div class="m-subheader ">
    <div class="d-flex align-items-center">
        <div class="mr-auto">
            <h3 class="m-subheader__title m-subheader__title--separator">Nuevo Traslado</h3>
            <ul class="m-subheader__breadcrumbs m-nav m-nav--inline">
                <li class="m-nav__item m-nav__item--home">
                    <a [routerLink]="''" class="m-nav__link m-nav__link--icon">
                        <i class="m-nav__link-icon la la-home"></i>
                    </a>
                </li>
                <li class="m-nav__separator">-</li>
                <li class="m-nav__item">
                    <a [routerLink]="'/transfers/create'" class="m-nav__link">
                        <span class="m-nav__link-text">Nuevo Traslado</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>


<div class="m-content">
    <div class="row">
        <div class="col-md-12">
            <!--begin::Portlet-->
            <div class="m-portlet m-portlet--mobile">
                <div class="m-portlet__head">
                    <div class="m-portlet__head-caption">
                        <div class="m-portlet__head-title">
                            <h3 class="m-portlet__head-text">
                                Nuevo Traslado
                            </h3>
                        </div>
                    </div>
                </div>
                <div class="m-form m-form--label-align-right">
                    <app-message></app-message>
                    <div class="m-portlet__body">
                        <div class="m-form__section m-form__section--first">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Fecha:</label>
                                        <input [(ngModel)]="form.date" class="form-control m-input" placeholder="Fecha"
                                               [matDatepicker]="datePicker" (click)="datePicker.open()">
                                        <mat-datepicker #datePicker></mat-datepicker>
                                        <div *ngIf="submitted && !form.date" class="invalid-feedback">
                                            <div *ngIf="!form.date">Fecha obligatoria</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Sede / Almacén de Origen:</label>
                                        <ng-select2
                                                [placeholder]="'Selecciona Sede / Almacén'"
                                                [data]="branches"
                                                [width]="'100%'"
                                                [(ngModel)]="form.branch_origin_id">
                                        </ng-select2>
                                        <div *ngIf="submitted && !form.branch_origin_id" class="invalid-feedback" style="display: block">
                                            <div *ngIf="!form.branch_origin_id">Sede / Almacén de origen obligatoria
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Sede / Almacén de Destino:</label>
                                        <ng-select2
                                                [placeholder]="'Selecciona Sede / Almacén'"
                                                [data]="branches"
                                                [width]="'100%'"
                                                [(ngModel)]="form.branch_destiny_id">
                                        </ng-select2>
                                        <div *ngIf="submitted && !form.branch_destiny_id" class="invalid-feedback" style="display: block">
                                            <div *ngIf="!form.branch_destiny_id">Sede / Almacén de destino obligatoria
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Comentario:</label>
                                        <textarea [(ngModel)]="form.comment" class="form-control m-input"
                                                  placeholder="Comentario"
                                                  rows="5"></textarea>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>Busca productos</label>
                                        <ng-select2
                                                [(ngModel)]="productSearchText"
                                                #select2ProductSearch
                                                [options]="optionsSelect2Product">
                                        </ng-select2>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <table class="table table-bordered table-hover">
                                            <thead>
                                            <tr>
                                                <th style="width: 20%">Código</th>
                                                <th style="width: 20%">Nombre</th>
                                                <th style="width: 25%">Descripción</th>
                                                <th style="width: 25%">Cantidad</th>
                                                <th style="width: 10%">Acciones</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr *ngFor="let item of form.items; let i = index">
                                                <td>{{item.code}}</td>
                                                <td>{{item.name}}</td>
                                                <td>{{item.description}}</td>
                                                <td>
                                                    <input [(ngModel)]="item.quantity" class="form-control m-input"
                                                           appCurrencyInput placeholder="Cantidad">
                                                </td>
                                                <td>
                                                    <button (click)="deleteItem(i)" class="btn btn-danger">
                                                        <i class="fa fa-trash" aria-hidden="true"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group text-center">
                                        <button class="btn btn-success" [disabled]="loading"
                                                (click)="sendForm()"
                                                [ngClass]="{'m-loader m-loader--light m-loader--right': loading}">
                                            Transferir
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
