<div class="container">
    <h1 class="text-center my-4">Gerar nota de crédito</h1>
    <div class="my-5">
        <h3>Busca Venta</h3>
        <div>
            <form action="" class="py-5">
                <div class="form-group row">
                    <div class="col-sm-6">

                        <input class="form-control" type="text" placeholder="Nombre o código">
                    </div>
                    <button class="btn">Buscar</button>
                </div>
                <div class="form-group">
                    <select class="form-control" id="exampleFormControlSelect1">
                      <option>Actualización</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                    </select>
                </div>
            </form>
        </div>
        <table class="table table-bordered bg-white">
            <thead>
              <tr>
                <th style="width: 10px">Código</th>
                <th>Nombre</th>
                <th>Descripción</th>
                <th>Cantidad</th>
                <th>Unidad de medida</th>
              </tr>
            </thead>
            <tbody>
            </tbody>
        </table>
    </div>
</div>
