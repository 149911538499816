import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SaleSendEmailComponent} from '../sale-send-email/sale-send-email.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
    selector: 'app-sale-result',
    templateUrl: './sale-result.component.html',
    styleUrls: ['./sale-result.component.scss']
})
export class SaleResultComponent implements OnInit {

    @Input() saleCreated: any;
    @Input() businessDataVoucher: any;

    @Output() resetFormEvent = new EventEmitter<string>();

    constructor(
        private dialog: MatDialog,
    ) {
    }

    ngOnInit(): void {
        this.printVoucher();
    }

    downloadVoucher(): void {
        const link = document.createElement('a');
        link.target = '_blank';
        link.href = this.saleCreated.pdf_url;
        link.download = this.baseName(this.saleCreated.pdf_url);
        link.dispatchEvent(new MouseEvent('click'));
    }

    printVoucher(): void {
        // fetch('http://localhost/print-voucher/index.php', {
        fetch('http://localhost:8090', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                "Content-Type": "text/plain",
            },
            body: JSON.stringify({sale: this.saleCreated, business: this.businessDataVoucher})
        }).then(response => {
            console.log(response);
        }).catch(err => {
            console.error(err)
        });
    }

    sendEmail(): void {
        this.dialog.open(SaleSendEmailComponent, {
            data: {
                sale: this.saleCreated,
            },
            width: '60%'
        });
    }

    resetForm(): void {
        this.resetFormEvent.emit();
    }

    baseName(str): string {
        let base = new String(str).substring(str.lastIndexOf('/') + 1);
        if(base.lastIndexOf(".") != -1) {
            base = base.substring(0, base.lastIndexOf("."));
        }
        return base;
    }
}
