import { Pipe, PipeTransform } from '@angular/core';
import {Util} from '../helpers/util';

@Pipe({
  name: 'hasRole'
})
export class HasRolePipe implements PipeTransform {

    transform(userRoles: any[], ...args: any[]): boolean {
        let roles = [];
        if (!Array.isArray(args[0])) {
            roles = [args[0]];
        } else {
            roles = args[0];
        }
        if (roles.length == 0) {
            return true;
        }
        return Util.hasAnyRole(userRoles, roles);
    }
}
