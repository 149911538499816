import {Component, OnInit, ViewChild} from '@angular/core';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DataTableDirective} from 'angular-datatables';
import {MatDialog} from '@angular/material/dialog';
import {BranchService} from '../../../services/branch.service';
import {ToastrService} from 'ngx-toastr';
import {MessageService} from '../../../services/message.service';
import {BranchBoxService} from '../../../services/branch-box.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {SmallBoxService} from '../../../services/small-box.service';
import {first} from 'rxjs/operators';
import {CashReportDetailComponent} from './cash-report-detail/cash-report-detail.component';
import * as FileSaver from 'file-saver';

declare var swal: any;

export const DATEPICKER_FORMAT = {
    parse: {
        dateInput: 'LL',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@Component({
    selector: 'app-cash-report',
    templateUrl: './cash-report.component.html',
    styleUrls: ['./cash-report.component.scss'],
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        },

        {provide: MAT_DATE_FORMATS, useValue: DATEPICKER_FORMAT},
    ]
})
export class CashReportComponent implements OnInit {

    dtOptions: DataTables.Settings = {};
    items: any[];
    @ViewChild(DataTableDirective) private dtElement;

    form = {
        open_from_date: '',
        open_to_date: '',
        close_from_date: '',
        close_to_date: '',
        branch_id: '-',
        branch_box_id: '-',
    };
    exporting = false;

    constructor(
        private dialog: MatDialog,
        private branchService: BranchService,
        private toastrService: ToastrService,
        private messageService: MessageService,
        private smallBoxService: SmallBoxService,
        private branchBoxService: BranchBoxService,
        private ngxSpinnerService: NgxSpinnerService,
    ) {
    }

    ngOnInit(): void {
        this.dtOptions = {
            pagingType: 'full_numbers',
            pageLength: 10,
            serverSide: true,
            processing: true,
            order: [[0, 'desc']],
            ajax: (dataTablesParameters: any, callback) => {
                const form: any = Object.assign({}, this.form);
                if (form.branch_id == '-') {
                    form.branch_id = null;
                }
                if (form.branch_box_id == '-') {
                    form.branch_box_id = null;
                }
                if (form.open_from_date) {
                    form.open_from_date = form.open_from_date.format('YYYY-MM-DD');
                }
                if (form.open_to_date) {
                    form.open_to_date = form.open_to_date.format('YYYY-MM-DD');
                }
                if (form.close_from_date) {
                    form.close_from_date = form.close_from_date.format('YYYY-MM-DD');
                }
                if (form.close_to_date) {
                    form.close_to_date = form.close_to_date.format('YYYY-MM-DD');
                }
                dataTablesParameters = {...dataTablesParameters, ...form};
                this.smallBoxService.datatable(dataTablesParameters)
                    .pipe(first())
                    .subscribe(response => {
                        this.items = response.data;
                        callback({
                            recordsTotal: response.recordsTotal,
                            recordsFiltered: response.recordsFiltered,
                            data: []
                        });
                    });
            },
            columns: [
                {data: 'opening_date_time'},
                {data: 'closing_date_time'},
                {data: 'user_name'},
                {data: 'branch_name'},
                {data: 'branch_box_name'},
                {data: 'final_amount'},
            ]
        };
    }

    async reloadTable() {
        const dtInstance = await this.dtElement.dtInstance;
        dtInstance.ajax.reload();
    }

    showDetail(item): void {
        this.ngxSpinnerService.show();
        this.smallBoxService.show(item.id).subscribe(response => {
            this.ngxSpinnerService.hide();
            this.dialog.open(CashReportDetailComponent, {
                data: response.data,
                width: '50%'
            });
        }, error1 => {
            this.ngxSpinnerService.hide();
            this.toastrService.error(error1);
            this.messageService.error(error1);
        });
    }


    exportList(): void {
        this.exporting = true;
        this.smallBoxService.export().subscribe(blob => {
            this.exporting = false;
            FileSaver.saveAs(blob, 'Reporte de Caja.xlsx');
        }, error1 => {
            this.exporting = false;
            this.toastrService.error(error1);
        });
    }
}
