import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';
import {BranchService} from '../../../services/branch.service';
import {Select2OptionData} from 'ng-select2';
import {first} from 'rxjs/operators';
import {MessageService} from '../../../services/message.service';
import {webServiceConfig} from '../../../config/web-service.config';
import {AuthenticationService} from '../../../services/authentication.service';
import {TransferService} from '../../../services/transfer.service';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from '@angular/material-moment-adapter';

declare var swal: any;

export const DATEPICKER_FORMAT = {
    parse: {
        dateInput: 'LL',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@Component({
    selector: 'app-transfer-create',
    templateUrl: './transfer-create.component.html',
    styleUrls: ['./transfer-create.component.scss'],
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        },
        {provide: MAT_DATE_FORMATS, useValue: DATEPICKER_FORMAT},
    ]
})
export class TransferCreateComponent implements OnInit, AfterViewInit {

    branches: Array<Select2OptionData> = [];
    submitted = false;
    loading = false;

    form: any = {
        date: null,
        branch_origin_id: '',
        branch_destiny_id: '',
        comment: null,
        items: [],
    };

    @ViewChild('select2ProductSearch') select2ProductSearch: any;
    optionsSelect2Product = {
        width: '100%',
        language: 'es',
        delay: 250,
        placeholder: 'Busca productos',
        allowClear: true,
        ajax: {
            headers: {
                Authorization: `Bearer ${this.authenticationService.currentUserValue.token}`,
            },
            method: 'get',
            url: `${webServiceConfig.webServiceUrl}products/search`,
            dataType: 'json'
        },
    };

    productSearchText = '';

    constructor(
        private toastrService: ToastrService,
        private branchService: BranchService,
        private messageService: MessageService,
        private transferService: TransferService,
        private ngxSpinnerService: NgxSpinnerService,
        private authenticationService: AuthenticationService,
    ) {
    }

    ngOnInit(): void {
        this.ngxSpinnerService.show();
        this.branchService.all(1, null).pipe(first()).subscribe(response => {
            this.ngxSpinnerService.hide();
            this.branches = response.data.map(item => {
                return {...item, ...{text: item.name}};
            });
        }, error1 => {
            this.ngxSpinnerService.hide();
            this.toastrService.error(error1);
            this.messageService.error(error1);
        });
    }

    ngAfterViewInit(): void {
        jQuery(this.select2ProductSearch._element.nativeElement).on('select2:select', (e: any) => {
            const newItem = e.params.data;
            const itemFound = this.form.items.find(item => item.id == newItem.id);
            if (itemFound) {
                itemFound.quantity++;
            } else {
                newItem.quantity = 1;
                this.form.items.push(newItem);
            }
            setTimeout(() => {
                this.productSearchText = null;
            }, 300);
        });
    }

    deleteItem(i: number): void {
        this.form.items.splice(i, 1);
    }

    validateForm(): boolean {
        if (!this.form.date) {
            swal.fire({
                title: 'Complete la fecha',
                type: 'error'
            });
            return false;
        }
        if (!this.form.branch_origin_id) {
            return false;
        }
        if (!this.form.branch_destiny_id) {
            return false;
        }
        if (this.form.branch_origin_id == this.form.branch_destiny_id) {
            swal.fire({
                title: 'El origen y destino no pueden ser el mismo',
                type: 'error'
            });
            return false;
        }
        if (this.form.items.length == 0) {
            swal.fire({
                title: 'Debe agregar productos',
                type: 'error'
            });
            return false;
        }
        return true;
    }

    sendForm(): void {
        this.submitted = true;
        if (this.validateForm()) {
            this.loading = true;
            const form = Object.assign({}, this.form);
            form.date = this.form.date.format('YYYY-MM-DD');
            this.transferService.store(form).pipe(first()).subscribe(response => {
                this.loading = false;
                this.resetForm();
                this.messageService.success(response.message);
                this.toastrService.success(response.message);
                setTimeout(() => this.messageService.clear(), 4000);
            }, error1 => {
                this.loading = false;
                this.messageService.error(error1);
                this.toastrService.error(error1);
            });
        }
    }

    resetForm(): void {
        this.form = {
            date: null,
            branch_origin_id: '',
            branch_destiny_id: '',
            comment: null,
            items: [],
        };
        this.submitted = false;
    }
}
