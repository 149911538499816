import {Component, Output, EventEmitter, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {CustomerService} from '../../../services/customer.service';
import {first} from 'rxjs/operators';

@Component({
    selector: 'app-customer-fragment-add',
    templateUrl: './customer-fragment-add.component.html',
    styleUrls: ['./customer-fragment-add.component.scss']
})
export class CustomerFragmentAddComponent implements OnInit {

    form: FormGroup;
    submitted = false;
    loading = false;

    @Output() cancel = new EventEmitter<string>();
    @Output() newCustomer = new EventEmitter<string>();

    constructor(
        private formBuilder: FormBuilder,
        private toastrService: ToastrService,
        private customerService: CustomerService,
    ) {
    }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            document_type: ['1', Validators.required],
            document_number: ['', Validators.required],
            address: [null],
            name: ['', Validators.required],
            phone: [''],
            email: ['', [Validators.email]],
            status: [1, Validators.required],
        });
    }

    get f() {
        return this.form.controls;
    }

    sendForm(): void {
        this.submitted = true;
        if (!this.form.valid) {
            return;
        }
        this.loading = true;
        let client = Object.assign({}, this.form.value);
        this.customerService.store(client).pipe(first()).subscribe(response => {
            this.toastrService.success(response.message, 'Éxito!');
            this.newCustomer.emit(response.data);
        }, error => {
            this.toastrService.error(error);
            this.loading = false;
        });
    }

    onChangeDocument(): void {
        if (this.f.document_type.value == '6') {
            this.f.address.setValidators([Validators.required]);
            this.f.address.reset();
        } else {
            this.f.address.setValidators([]);
            this.f.address.reset();
        }
    }

    cancelCreateCustomer(): void {
        this.cancel.emit();
    }
}
