import {Component, OnInit} from '@angular/core';
import {SmallBoxService} from '../../services/small-box.service';
import {ToastrService} from 'ngx-toastr';
import {MessageService} from '../../services/message.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {first} from 'rxjs/operators';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {BranchBoxService} from '../../services/branch-box.service';
import {AuthenticationService} from '../../services/authentication.service';
import {SmallBoxOpenCloseComponent} from './small-box-open-close/small-box-open-close.component';

@Component({
    selector: 'app-finances',
    templateUrl: './finances.component.html',
    styleUrls: ['./finances.component.scss']
})
export class FinancesComponent implements OnInit {

    smallBox: any = null;
    form: FormGroup;
    submitted = false;
    loading = false;
    boxes: any[] = [];
    loaded = false;
    currentUser: any = {};

    constructor(
        private dialog: MatDialog,
        private formBuilder: FormBuilder,
        private toastrService: ToastrService,
        private messageService: MessageService,
        private smallBoxService: SmallBoxService,
        private branchBoxService: BranchBoxService,
        private ngxSpinnerService: NgxSpinnerService,
        private authenticationService: AuthenticationService,
    ) {
    }

    ngOnInit(): void {
        this.currentUser = this.authenticationService.currentUserValue;
        this.form = this.formBuilder.group({
            starting_amount: [0, Validators.required],
        });
        this.loadData();
    }

    get f() {
        return this.form.controls;
    }

    private loadData(): void {
        this.ngxSpinnerService.show();
        this.branchBoxService.all().subscribe(response => {
            this.ngxSpinnerService.hide();
            this.boxes = response.data;
            this.loaded = true;
        }, error1 => {
            this.ngxSpinnerService.hide();
            this.toastrService.error(error1);
        });
    }

    selectBox(box): void {
        if (!box.opened || (box.opened && this.currentUser.id == box.user_id)) {
            const boxUser = this.boxes.find(boxItem => boxItem.user_id == this.currentUser.id);
            if (boxUser && boxUser.id != box.id) {
                return;
            }

            if (box.opened) {

                this.ngxSpinnerService.show();
                this.smallBoxService.current(0).subscribe(response => {
                    this.ngxSpinnerService.hide();
                    const dialogRef = this.dialog.open(SmallBoxOpenCloseComponent, {
                        data: {
                            box,
                            current_small_box: response.data
                        },
                        width: '50%',
                    });
                    dialogRef.afterClosed().subscribe(resp => {
                        if (resp?.closed) {
                            // this.smallBox = null;
                            this.loadData();
                        }
                    });
                }, error1 => {
                    this.ngxSpinnerService.hide();
                    this.toastrService.error(error1);
                });
            } else {
                const dialogRef = this.dialog.open(SmallBoxOpenCloseComponent, {
                    data: {
                        box,
                    },
                    width: '25%',
                });
                dialogRef.afterClosed().subscribe(resp => {
                    if (resp?.closed) {
                        // this.smallBox = null;
                        this.loadData();
                    }
                });
            }
        }
    }

    /*closeSmallBox(): void {
        const dialogRef = this.dialog.open(CloseSmallBoxModalComponent, {
            data: this.smallBox,
            width: '50%'
        });
        dialogRef.afterClosed().subscribe(resp => {
            if (resp?.closed) {
                this.smallBox = null;
            }
        });
    }*/

    sendForm(): void {
        this.submitted = true;
        if (!this.form.valid) {
            return;
        }
        this.loading = true;
        this.smallBoxService.store(this.form.value).pipe(first()).subscribe(response => {
            this.loading = false;
            this.smallBox = response.data;
            this.toastrService.success(response.message);
            this.messageService.success(response.message);
        }, error1 => {
            this.loading = false;
            this.toastrService.error(error1);
            this.messageService.error(error1);
        });
    }
}
