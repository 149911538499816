<!-- BEGIN: Subheader -->
<div class="m-subheader ">
    <div class="d-flex align-items-center">
        <div class="mr-auto">
            <h3 class="m-subheader__title m-subheader__title--separator">Reporte de Apertura y Cierre de Caja</h3>
            <ul class="m-subheader__breadcrumbs m-nav m-nav--inline">
                <li class="m-nav__item m-nav__item--home">
                    <a [routerLink]="''" class="m-nav__link m-nav__link--icon">
                        <i class="m-nav__link-icon la la-home"></i>
                    </a>
                </li>
                <li class="m-nav__separator">-</li>
                <li class="m-nav__item">
                    <a [routerLink]="'/report/cash-report'" class="m-nav__link">
                        <span class="m-nav__link-text">Reporte de Apertura y Cierre de Caja</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>

<div class="m-content">
    <div class="row">
        <div class="col-md-12">
            <!--begin::Portlet-->
            <div class="m-portlet m-portlet--mobile">
                <div class="m-portlet__head">
                    <div class="m-portlet__head-caption">
                        <div class="m-portlet__head-title">
                            <h3 class="m-portlet__head-text">
                                Reporte de Caja
                            </h3>
                        </div>
                    </div>
                    <div class="m-portlet__head-tools">
                        <ul class="m-portlet__nav">
                            <li class="m-portlet__nav-item">
                                <button (click)="exportList()"
                                        [disabled]="exporting"
                                        [ngClass]="{'m-loader m-loader--light m-loader--right': exporting}"
                                        class="btn btn-success m-btn m-btn--pill m-btn--custom m-btn--icon m-btn--air">
                                    <span>
                                       <i class="la la-file-excel-o"></i>
                                        <span>Exportar</span>
                                    </span>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="m-portlet__body">
                    <div class="m-form__section m-form__section--first">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>Fecha de apertura:</label>
                                            <input [(ngModel)]="form.open_from_date" class="form-control m-input"
                                                   placeholder="dd/mm/yyyy"
                                                   [matDatepicker]="openFromDatePicker"
                                                   (click)="openFromDatePicker.open()" (ngModelChange)="reloadTable()">
                                            <mat-datepicker #openFromDatePicker></mat-datepicker>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group" style="padding-top: 2rem">
                                            <input [(ngModel)]="form.open_to_date" class="form-control m-input"
                                                   placeholder="dd/mm/yyyy"
                                                   [matDatepicker]="pickerOpenToDate" (click)="pickerOpenToDate.open()"
                                                   (ngModelChange)="reloadTable()">
                                            <mat-datepicker #pickerOpenToDate></mat-datepicker>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>Fecha de Cierre:</label>
                                            <input [(ngModel)]="form.close_from_date" class="form-control m-input"
                                                   placeholder="dd/mm/yyyy"
                                                   [matDatepicker]="openCloseFromDate"
                                                   (click)="openCloseFromDate.open()" (ngModelChange)="reloadTable()">
                                            <mat-datepicker #openCloseFromDate></mat-datepicker>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group" style="padding-top: 2rem">
                                            <input [(ngModel)]="form.close_to_date" class="form-control m-input"
                                                   placeholder="dd/mm/yyyy"
                                                   [matDatepicker]="openCloseToDate"
                                                   (click)="openCloseToDate.open()" (ngModelChange)="reloadTable()">
                                            <mat-datepicker #openCloseToDate></mat-datepicker>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="table-responsive">
                                <table datatable [dtOptions]="dtOptions"
                                       class="table table-striped- table-bordered table-hover">
                                    <thead>
                                    <tr>
                                        <th>Fecha Apertura</th>
                                        <th>Fecha Cierre</th>
                                        <th>Usuario</th>
                                        <th>Sucursal</th>
                                        <th>Caja</th>
                                        <th>Total de Cierre</th>
                                        <th>Acciones</th>
                                    </tr>
                                    </thead>
                                    <tbody *ngIf="items?.length != 0">
                                    <tr *ngFor="let item of items">
                                        <td>{{item.opening_date_time | date:'dd/MM/yyyy'}}</td>
                                        <td>{{item.closing_date_time | date:'dd/MM/yyyy'}}</td>
                                        <td>{{item.user_name}}</td>
                                        <td>{{item.branch_name}}</td>
                                        <td>{{item.branch_box_name}}</td>
                                        <td>{{item.final_amount | currency:'S/ '}}</td>
                                        <td>
                                            <button (click)="showDetail(item)"
                                                    class="m-portlet__nav-link btn m-btn m-btn--hover-accent m-btn--icon m-btn--icon-only m-btn--pill"
                                                    title="Ver Detalle">
                                                <i class="flaticon-eye"></i>
                                            </button>
                                        </td>
                                    </tr>
                                    </tbody>
                                    <tbody *ngIf="items?.length == 0">
                                    <tr>
                                        <td colspan="7" class="no-data-available">No data!</td>
                                    </tr>
                                    <tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
