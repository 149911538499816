import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {webServiceConfig} from '../config/web-service.config';
import {map} from 'rxjs/operators';
import {DataTablesResponse} from '../models/data-tables-response';

@Injectable({
    providedIn: 'root'
})
export class SaleService {

    constructor(private http: HttpClient) {
    }

    store(data: any): Observable<any> {
        return this.http.post(`${webServiceConfig.webServiceUrl}sales`, data)
            .pipe(map(response => {
                return response;
            }));
    }

    emitVoucherRestaurant(data: any): Observable<any> {
        return this.http.patch(`${webServiceConfig.webServiceUrl}sales/restaurant/emit-voucher/${data.id}`, data)
            .pipe(map(response => {
                return response;
            }));
    }

    datatable(dataTablesParameters): Observable<any> {
        return this.http.post<DataTablesResponse>(`${webServiceConfig.webServiceUrl}sales/report/datatable`, dataTablesParameters, {})
            .pipe(map(response => {
                return response;
            }));
    }

    show(id: number, withProductRelationShip = 0): Observable<any> {
        return this.http.get(`${webServiceConfig.webServiceUrl}sales/${id}`, {
            params: {
                with_product_relationship: withProductRelationShip + ''
            }
        }).pipe(map(response => {
            return response;
        }));
    }

    sendEmail(id, data): Observable<any> {
        return this.http.post(`${webServiceConfig.webServiceUrl}sales/send-voucher/${id}`, data)
            .pipe(map(response => {
                return response;
            }));
    }

    getIndicatorsForAdmin(form): Observable<any> {
        return this.http.get(`${webServiceConfig.webServiceUrl}sales/indicators`, {
            params: form
        }).pipe(map(response => {
            return response;
        }));
    }

    export(): Observable<Blob> {
        return this.http.get(`${webServiceConfig.webServiceUrl}sales/export`, {responseType: 'blob'});
    }

    exportByProducts(): Observable<Blob> {
        return this.http.get(`${webServiceConfig.webServiceUrl}sales/products/export`, {responseType: 'blob'});
    }
}
