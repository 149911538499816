import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {webServiceConfig} from '../config/web-service.config';
import {map} from 'rxjs/operators';
import {Setting} from '../models/setting';
import {SmallBox} from '../models/small-box';
import {DataTablesResponse} from '../models/data-tables-response';

@Injectable({
    providedIn: 'root'
})
export class SmallBoxService {

    public currentSmallBoxSubject: BehaviorSubject<Setting>;

    constructor(private http: HttpClient) {
        this.currentSmallBoxSubject = new BehaviorSubject<Setting>(JSON.parse(localStorage.getItem('small_box')));
    }

    updateCurrentSmallBox(smallBox): void {
        localStorage.setItem('small_box', JSON.stringify(smallBox));
        this.currentSmallBoxSubject.next(smallBox);
    }

    public get currentSettingsValue(): SmallBox {
        return new SmallBox().deserialize(this.currentSmallBoxSubject.value);
    }

    store(data: any): Observable<any> {
        return this.http.post(`${webServiceConfig.webServiceUrl}small-boxes`, data)
            .pipe(map((response: any) => {
                localStorage.setItem('small_box', JSON.stringify(response.data));
                this.currentSmallBoxSubject.next(response.data);
                return response;
            }));
    }

    storeMovement(data: any): Observable<any> {
        return this.http.post(`${webServiceConfig.webServiceUrl}small-boxes/movement`, data)
            .pipe(map((response: any) => {
                localStorage.setItem('small_box', JSON.stringify(response.data));
                this.currentSmallBoxSubject.next(response.data);
                return response;
            }));
    }

    current(withDetails): Observable<any> {
        return this.http.get(`${webServiceConfig.webServiceUrl}small-boxes/current?with_details=${withDetails}`)
            .pipe(map(response => {
                return response;
            }));
    }

    close(data: any): Observable<any> {
        return this.http.patch(`${webServiceConfig.webServiceUrl}small-boxes/close`, data)
            .pipe(map(response => {
                localStorage.setItem('small_box', null);
                this.currentSmallBoxSubject.next(null);
                return response;
            }));
    }

    datatable(dataTablesParameters): Observable<any> {
        return this.http.post<DataTablesResponse>(`${webServiceConfig.webServiceUrl}small-boxes/report/datatable`, dataTablesParameters, {})
            .pipe(map(response => {
                return response;
            }));
    }

    show(id): Observable<any> {
        return this.http.get(`${webServiceConfig.webServiceUrl}small-boxes/${id}`)
            .pipe(map(response => {
                return response;
            }));
    }

    export(): Observable<Blob> {
        return this.http.get(`${webServiceConfig.webServiceUrl}small-boxes/export`, {responseType: 'blob'});
    }
}
