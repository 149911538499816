import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

declare var swal: any;

@Component({
    selector: 'app-product-cart-modal',
    templateUrl: './product-cart-modal.component.html',
    styleUrls: ['./product-cart-modal.component.scss']
})
export class ProductCartModalComponent implements OnInit {

    locationSelected = null;

    constructor(
        private dialogRef: MatDialogRef<ProductCartModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
    }

    ngOnInit(): void {
        if (this.data.item.extras) {
            this.data.item.extras.forEach(extra => {
                if (!extra.quantity) {
                    extra.quantity = 0;
                }
            });
        }
        if (this.data.item.branch_stock) {
            this.locationSelected = this.data.item.branch_stock.id;
        }
    }

    closeModal(): void {
        this.dialogRef.close(null);
    }

    storeItem(): void {
        if (this.data.branches_stock) {
            if (!this.locationSelected) {
                swal.fire({
                    title: 'Selecciona de donde va salir el stock',
                    type: 'info'
                });
                return;
            }
            // const branchStock = this.data.branches_stock.find(branchStockItem => branchStockItem.id == this.locationSelected);
            this.data.item.branch_stock = this.data.branches_stock.find(branchStockItem => branchStockItem.id == this.locationSelected);
        }

        if (this.data.item.discount > this.data.price) {
            swal.fire({
                title: 'Descuento inválido',
                type: 'info'
            });
            return;
        }
        if (this.data.item.discount == null || this.data.item.discount == '') {
            this.data.item.discount = 0;
        }
        this.dialogRef.close({
            item: this.data.item
        });
    }

    removeQuantity(): void {
        if (this.data.item.quantity > 1) {
            this.data.item.quantity--;
        }
    }

    addQuantity(): void {
        this.data.item.quantity++;
    }

    removeQuantityExtra(extra): void {
        if (extra.quantity > 0) {
            extra.quantity--;
        }
    }

    addQuantityExtra(extra): void {
        extra.quantity++;
    }

    get totalItem(): number {
        if (this.data.currentSettings.kipu_mode == 'restaurant') {
            let subtotal = (this.data.item.price - this.data.item.discount) * this.data.item.quantity;
            this.data.item.extras.forEach(extra => {
                subtotal += extra.price * extra.quantity;
            });
            return subtotal;
        } else {
            return (this.data.item.price - this.data.item.discount) * this.data.item.quantity;
        }
    }
}
