<h1 mat-dialog-title>Enviar Email</h1>
<mat-dialog-content>
    <form [formGroup]="form" (ngSubmit)="sendForm()">
        <div class="row">
            <div class="col-md-8">
                <div class="form-group">
                    <label>Email</label>
                    <input formControlName="email" class="form-control m-input" placeholder="Email"
                           [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                        <div *ngIf="f.email.errors.email">Formato incorrecto</div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group" style="padding-top: 2rem">
                    <button type="submit" [disabled]="loading"
                            class="btn btn-accent m-btn m-btn--air m-btn--custom"
                            [ngClass]="{'m-loader m-loader--light m-loader--right': loading}">
                        Enviar email
                    </button>
                </div>
            </div>
        </div>
    </form>
</mat-dialog-content>
