import {Component, OnInit, ViewChild} from '@angular/core';
import {DataTableDirective} from 'angular-datatables';
import {SettingService} from '../../services/setting.service';
import {first} from 'rxjs/operators';
import {PurchasePaymentMethodService} from '../../services/purchase-payment-method.service';

declare var swal: any;

@Component({
  selector: 'app-purchase-payment-methods',
  templateUrl: './purchase-payment-methods.component.html',
  styleUrls: ['./purchase-payment-methods.component.scss']
})
export class PurchasePaymentMethodsComponent implements OnInit {

    dtOptions: DataTables.Settings = {};
    items: any[];
    @ViewChild(DataTableDirective) private dtElement;

    constructor(
        private settingService: SettingService,
        private purchasePaymentMethodService: PurchasePaymentMethodService,
    ) {
    }

    ngOnInit(): void {
        this.dtOptions = {
            pagingType: 'full_numbers',
            pageLength: 10,
            serverSide: true,
            processing: true,
            ajax: (dataTablesParameters: any, callback) => {
                this.purchasePaymentMethodService.datatable(dataTablesParameters)
                    .pipe(first())
                    .subscribe(response => {
                        this.items = response.data;
                        callback({
                            recordsTotal: response.recordsTotal,
                            recordsFiltered: response.recordsFiltered,
                            data: []
                        });
                    });
            },
            columns: [
                {data: 'id'},
                {data: 'name'},
            ]
        };
    }

    private async reloadTable() {
        let dtInstance = await this.dtElement.dtInstance;
        dtInstance.ajax.reload();
    }

    delete(category: any): void {
        swal.fire({
            title: '¿Estás seguro que deseas eliminar este registro?',
            showCancelButton: true,
            confirmButtonText: 'Si, elimínalo!',
            showLoaderOnConfirm: true,
            preConfirm: (arg) => {
                return this.purchasePaymentMethodService.delete(category.id)
                    .subscribe(response => {
                        swal.fire({
                            title: 'Elemento eliminado con éxito',
                            type: 'success'
                        });
                        this.reloadTable();
                    }, error => {
                        swal.fire({
                            title: error,
                            type: 'error'
                        });
                        return error;
                    });
            },
            allowOutsideClick: () => !swal.isLoading()
        }).then((result) => {
        });
    }
}
