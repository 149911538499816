import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthenticationService} from '../../../services/authentication.service';
import {ActivatedRoute, Router} from '@angular/router';
import {first} from 'rxjs/operators';
import {MessageService} from '../../../services/message.service';
import {BranchService} from '../../../services/branch.service';
import {Select2OptionData} from 'ng-select2';
import {ToastrService} from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';
import {SettingService} from '../../../services/setting.service';
import {SmallBoxService} from '../../../services/small-box.service';
import {User} from '../../../models/user';
import {Util} from '../../../helpers/util';

declare var loadLogin: any;

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    loginForm: FormGroup;
    loading = false;
    submitted = false;
    branches: Array<Select2OptionData> = [];

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private formBuilder: FormBuilder,
        private branchService: BranchService,
        private toastrService: ToastrService,
        private settingService: SettingService,
        private messageService: MessageService,
        private smallBoxService: SmallBoxService,
        private ngxSpinnerService: NgxSpinnerService,
        private authenticationService: AuthenticationService,
    ) {
    }

    ngOnInit(): void {
        loadLogin();
        this.loginForm = this.formBuilder.group({
            email: [null, Validators.required],
            password: [null, Validators.required],
            branch_id: [null],
        });
        this.ngxSpinnerService.show();
        this.branchService.all(1, 0).pipe(first()).subscribe(response => {
            this.branches = response.data.map(item => {
                return {...item, ...{text: item.name}};
            });
            this.f.branch_id.setValidators(Validators.required);
            this.f.branch_id.setValue(1);
            this.ngxSpinnerService.hide();
        }, error1 => {
            this.toastrService.error(error1);
            this.messageService.error(error1);
        });
    }

    get f() {
        return this.loginForm.controls;
    }

    onSubmit(): void {
        this.submitted = true;
        if (!this.loginForm.valid) {
            return;
        }
        this.loading = true;
        this.authenticationService.login(this.loginForm.value).subscribe(response => {
            if (response) {
                this.settingService.updateCurrentSettings(response.settings);
                this.smallBoxService.updateCurrentSmallBox(response.small_box);
                const currentUser: User = this.authenticationService.currentUserValue;
                if (Util.hasAnyRole(currentUser.roles, ['admin'])) {
                    this.router.navigate(['/']);
                } else {
                    if (response.small_box) {
                        this.router.navigate(['/']);
                    } else {
                        this.router.navigate(['/small-box/create-close']);
                    }
                }
            } else {
                this.messageService.error('No Autorizado');
                this.loading = false;
            }
        }, error => {
            this.loading = false;
            this.messageService.error(error);
        });
    }
}
