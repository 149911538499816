<!-- BEGIN: Subheader -->
<div class="m-subheader">
    <div class="d-flex align-items-center">
        <div class="mr-auto">
            <h3 class="m-subheader__title">Resumen</h3>
        </div>
    </div>
</div>

<div class="m-content">
    <div class="row">
        <div class="col-md-12">
            <div class="m-portlet m-portlet--mobile">
                <div class="m-portlet__body">
                    <h4 class="title">{{currentSmallBox.name}}</h4>
                    <div class="row">
                        <div class="col-md-6 border-r-small-box">
                            <div class="container-left-table">
                                <table class="table table-kipu-summary">
                                    <tr class="table-color-blue">
                                        <td>Monto Actual</td>
                                        <td>{{currentSmallBox.current_amount | currency: 'S/ '}}</td>
                                    </tr>
                                    <tr>
                                        <td>Monto apertura</td>
                                        <td>{{currentSmallBox.starting_amount | currency: 'S/ '}}</td>
                                    </tr>
                                    <tr class="table-kipu-summary-last">
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th>Diferencia</th>
                                        <th>
                                            <i class="la la-arrow-up class-up"
                                               *ngIf="(currentSmallBox.current_amount - currentSmallBox.starting_amount) >= 0"></i>
                                            <i class="la la-arrow-down class-down"
                                               *ngIf="(currentSmallBox.current_amount - currentSmallBox.starting_amount) < 0"></i>
                                            {{(currentSmallBox.current_amount - currentSmallBox.starting_amount) | currency: 'S/ '}}
                                        </th>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="container-right-table">
                                <table class="table table-kipu-summary">
                                    <tbody>
                                    <tr>
                                        <td>Monto en tarjeta</td>
                                        <td>{{currentSmallBox.card_amount | currency: 'S/ '}}</td>
                                    </tr>
                                    <tr>
                                        <td>Monto en efectivo</td>
                                        <td>{{currentSmallBox.cash_amount | currency: 'S/ '}}</td>
                                    </tr>
                                    </tbody>
                                </table>
                                <div class="pt-4">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <h6 class="title-2">Información de apertura</h6>
                                            <p class="text-open">{{currentSmallBox.created_at | date: 'dd/MM/yyyy hh:mm a'}}</p>
                                        </div>
                                        <div class="col-md-6">
                                            <button class="btn btn-danger btn-close w-100" (click)="closeSmallBox()">
                                                <i class="fa fa-lock mx-2"></i>
                                                Cerrar caja
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-3">
            <div class="m-portlet m-portlet--mobile">
                <div class="m-portlet__body">
                    <h4 class="title">Ventas del día</h4>
                    <div class="height-card">
                        <ngx-charts-pie-grid
                                *ngIf="single.length > 0"
                                [view]="view"
                                [scheme]="colorScheme"
                                [results]="single"
                                [designatedTotal]=designatedTotal>
                        </ngx-charts-pie-grid>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="m-portlet m-portlet--mobile">
                <div class="m-portlet__body">
                    <h4 class="title">Ingresos/Egresos</h4>
                    <div class="height-card">
                        <div class="py-4 w-100">
                            <table class="table table-kipu-summary">
                                <tr>
                                    <td>Ingresos:</td>
                                    <td class="text-right">{{currentSmallBox.total_money_income | currency: 'S/ '}}</td>
                                </tr>
                                <tr>
                                    <td>Egresos:</td>
                                    <td class="text-right">{{currentSmallBox.expenses | currency: 'S/ '}}</td>
                                </tr>
                                <tr>
                                    <td>Diferencia:</td>
                                    <td class="text-right">{{(currentSmallBox.total_money_income - currentSmallBox.expenses) | currency: 'S/ '}}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="m-portlet m-portlet--mobile">
                <div class="m-portlet__body">
                    <h4 class="title">Transacciones</h4>
                    <div class="height-card">
                        <div class="py-4 w-100">
                            <table class="table table-kipu-summary">
                                <tr>
                                    <td>Aprobadas:</td>
                                    <td class="text-right">{{currentSmallBox.total_sales | currency: 'S/ '}}</td>
                                </tr>
                                <tr>
                                    <td>Notas de crédito:</td>
                                    <td class="text-right">{{0 | currency: 'S/ '}}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="m-portlet m-portlet--mobile">
                <div class="m-portlet__body">
                    <h4 class="title">Tipo de pago</h4>
                    <div class="height-card">
                        <div class="py-4 w-100">
                            <table class="table table-kipu-summary">
                                <tr>
                                    <td>Efectivo:</td>
                                    <td class="text-right">{{currentSmallBox.cash_amount | currency: 'S/ '}}</td>
                                </tr>
                                <tr>
                                    <td>Tarjeta:</td>
                                    <td class="text-right">{{currentSmallBox.card_amount | currency: 'S/ '}}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
