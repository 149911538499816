import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {webServiceConfig} from '../config/web-service.config';
import {map} from 'rxjs/operators';
import {DataTablesResponse} from '../models/data-tables-response';

@Injectable({
    providedIn: 'root'
})
export class LocationService {

    constructor(private http: HttpClient) {
    }

    all(): Observable<any> {
        return this.http.get(`${webServiceConfig.webServiceUrl}locations`).pipe(map(response => {
            return response;
        }));
    }

    datatable(dataTablesParameters): Observable<any> {
        return this.http.post<DataTablesResponse>(`${webServiceConfig.webServiceUrl}locations/datatable`, dataTablesParameters, {})
            .pipe(map(response => {
                return response;
            }));
    }

    show(id: number): Observable<any> {
        return this.http.get(`${webServiceConfig.webServiceUrl}locations/${id}`).pipe(map(response => {
            return response;
        }));
    }

    store(item: any): Observable<any> {
        return this.http.post(`${webServiceConfig.webServiceUrl}locations`, item)
            .pipe(map(response => {
                return response;
            }));
    }

    update(item: any): Observable<any> {
        return this.http.put(`${webServiceConfig.webServiceUrl}locations/${item.id}`, item)
            .pipe(map(response => {
                return response;
            }));
    }

    delete(id: number): Observable<any> {
        return this.http.delete(`${webServiceConfig.webServiceUrl}locations/${id}`)
            .pipe(map(response => {
                return response;
            }));
    }
}
