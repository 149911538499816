<h1 mat-dialog-title>Cerrar Caja</h1>
<mat-dialog-content>
    <div class="col-md-12">
        <form [formGroup]="form" (ngSubmit)="sendForm()">
            <div class="form-group">
                <label>Monto de cierre</label>
                <input formControlName="final_amount" class="form-control m-input"
                       placeholder="S/ 0.00" appCurrencyInput
                       [ngClass]="{ 'is-invalid': submitted && f.final_amount.errors }">
                <div *ngIf="submitted && f.final_amount.errors" class="invalid-feedback">
                    <div *ngIf="f.final_amount.errors.required">Monto de cierre obligatorio</div>
                </div>
            </div>
            <div class="form-group">
                <button type="submit" [disabled]="loading"
                        class="btn btn-accent m-btn m-btn--air m-btn--custom"
                        [ngClass]="{'m-loader m-loader--light m-loader--right': loading}">
                    Cerrar Caja
                </button>
            </div>
        </form>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button class="btn btn-outline-dark mx-3" mat-dialog-close>Cancelar</button>
</mat-dialog-actions>
