<!-- BEGIN: Subheader -->
<div class="m-subheader">
    <div class="d-flex align-items-center">
        <div class="mr-auto">
            <h3 class="m-subheader__title">Resumen</h3>
        </div>
        <div>
            <span class="m-subheader__daterange">
                <input type="text"
                       class="form-control m-input input-daterange__picker"
                       ngxDaterangepickerMd
                       [opens]="'left'"
                       [(ngModel)]="rangeSelected"
                       (ngModelChange)="rangeSelectedChange($event)"
                       [showCustomRangeLabel]="true"
                       [alwaysShowCalendars]="true"
                       [ranges]="ranges"
                       [locale]="locale"
                       [linkedCalendars]="true"/>
            </span>
        </div>
    </div>
</div>

<div class="m-content">
    <div class="row">
        <div class="col-md-3">
            <div class="m-portlet m-portlet--mobile m-portlet--border-bottom-brand__kipu">
                <div class="m-portlet__body">
                    <h4 class="title">Utilidad {{defaultCurrency.currency.alphabetic_code}}</h4>
                    <div class="height-card">
                        <h4 class="title">{{totalUtilityPeriod | currency: defaultCurrency.currency.symbol}}</h4>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-3" *ngIf="exchangeRate">
            <div class="m-portlet m-portlet--mobile m-portlet--border-bottom-brand__kipu">
                <div class="m-portlet__body">
                    <h4 class="title">Utilidad {{exchangeRate.currency.alphabetic_code}}</h4>
                    <div class="height-card">
                        <h4 class="title">{{(totalUtilityPeriod * exchangeRate.sale) | currency: exchangeRate.currency.symbol}}</h4>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="m-portlet m-portlet--mobile m-portlet--border-bottom-brand__kipu">
                <div class="m-portlet__body">
                    <h4 class="title">Ventas del día</h4>
                    <div class="height-card">
                        <apx-chart
                                *ngIf="chartOptionsSalesDay.series.length > 0"
                                [series]="chartOptionsSalesDay.series"
                                [colors]="chartOptionsSalesDay.colors"
                                [chart]="chartOptionsSalesDay.chart"
                                [plotOptions]="chartOptionsSalesDay.plotOptions"
                                [labels]="chartOptionsSalesDay.labels"
                        ></apx-chart>
                        <div class="pt-1">
                            <p class="text-center text-b-card">
                                Monto Vendido: {{totalSaleDay | currency: defaultCurrency.currency.symbol}}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="m-portlet m-portlet--mobile m-portlet--border-bottom-brand__kipu">
                <div class="m-portlet__body">
                    <h4 class="title">Ventas del Periodo</h4>
                    <div class="height-card">
                        <apx-chart
                                *ngIf="chartOptionsSalesPeriod.series.length > 0"
                                [series]="chartOptionsSalesPeriod.series"
                                [colors]="chartOptionsSalesPeriod.colors"
                                [chart]="chartOptionsSalesPeriod.chart"
                                [plotOptions]="chartOptionsSalesPeriod.plotOptions"
                                [labels]="chartOptionsSalesPeriod.labels"
                        ></apx-chart>
                        <div class="pt-1">
                            <p class="text-center text-b-card">
                                Monto Vendido: {{totalSalePeriod | currency: defaultCurrency.currency.symbol}} <br>
                                <span class="title-2">
                                    Meta Mensual: {{totalGoalPeriod | currency: defaultCurrency.currency.symbol}}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="m-portlet m-portlet--mobile m-portlet--border-bottom-brand__kipu">
                <div class="m-portlet__body">
                    <h4 class="title">Ticket promedio</h4>
                    <div class="height-card">
                        <h4 class="title">{{averageSale | currency: defaultCurrency.currency.symbol}}</h4>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="m-portlet m-portlet--mobile m-portlet--border-bottom-brand__kipu">
                <div class="m-portlet__body">
                    <h4 class="title">Total de ventas</h4>
                    <div class="height-card">
                        <h4 class="title">
                            <i class="fa fa-arrow-up class-up"></i>
                            {{cumulativeTotalSales | currency: defaultCurrency.currency.symbol}}
                        </h4>
                        <div class="">
                            <p class="text-center text-b-card">Total acumulado</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="m-portlet m-portlet--mobile m-portlet--border-bottom-brand__kipu">
                <div class="m-portlet__body">
                    <h4 class="title">Ventas</h4>
                    <div class="height-card">
                        <table class="table-sales">
                            <tr>
                                <td class="title-2">Total</td>
                                <td class="title-3">Anuladas</td>
                            </tr>
                            <tr>
                                <td class="title-2">
                                    <i class="fa fa-arrow-up class-up"></i>
                                    <span>{{numTotalSales}}</span>
                                </td>
                                <td class="title-3">
                                    <i class="fa fa-arrow-down class-down"></i>
                                    <span>{{numVoidedSales}}</span>
                                </td>
                            </tr>
                        </table>
                        <div class="pt-3">
                            <p class="text-center text-b-card">
                                Cantidad de {{rangeSelected.startDate.format('DD/MM/YYYY')}}
                                al {{rangeSelected.endDate.format('DD/MM/YYYY')}}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="m-portlet m-portlet--mobile m-portlet--border-bottom-brand__kipu">
                <div class="m-portlet__body">
                    <h4 class="title">Tipo de pago</h4>
                    <div class="height-card">
                        <apx-chart
                                *ngIf="chartOptionsPaymentType.series.length > 0"
                                [series]="chartOptionsPaymentType.series"
                                [colors]="chartOptionsPaymentType.colors"
                                [chart]="chartOptionsPaymentType.chart"
                                [stroke]="chartOptionsPaymentType.stroke"
                                [plotOptions]="chartOptionsPaymentType.plotOptions"
                                [labels]="chartOptionsPaymentType.labels"
                                [legend]="chartOptionsPaymentType.legend"
                                [dataLabels]="chartOptionsPaymentType.dataLabels"
                                [responsive]="chartOptionsPaymentType.responsive"
                        ></apx-chart>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="m-portlet m-portlet--mobile m-portlet--border-bottom-brand__kipu">
                <div class="m-portlet__body">
                    <h4 class="title">Tipo de comprobante</h4>
                    <div class="height-card">
                        <apx-chart
                                *ngIf="chartOptionsVoucherType.series.length > 0"
                                [series]="chartOptionsVoucherType.series"
                                [colors]="chartOptionsVoucherType.colors"
                                [chart]="chartOptionsVoucherType.chart"
                                [stroke]="chartOptionsVoucherType.stroke"
                                [plotOptions]="chartOptionsVoucherType.plotOptions"
                                [labels]="chartOptionsVoucherType.labels"
                                [legend]="chartOptionsVoucherType.legend"
                                [dataLabels]="chartOptionsVoucherType.dataLabels"
                                [responsive]="chartOptionsVoucherType.responsive"
                        ></apx-chart>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="m-portlet m-portlet--mobile m-portlet--border-bottom-brand__kipu">
                <div class="m-portlet__body">
                    <h4 class="title">Canal de Venta</h4>
                    <div class="height-card">
                        <apx-chart
                                *ngIf="chartOptionsChannel.series.length > 0"
                                [series]="chartOptionsChannel.series"
                                [colors]="chartOptionsChannel.colors"
                                [chart]="chartOptionsChannel.chart"
                                [stroke]="chartOptionsChannel.stroke"
                                [plotOptions]="chartOptionsChannel.plotOptions"
                                [labels]="chartOptionsChannel.labels"
                                [legend]="chartOptionsChannel.legend"
                                [dataLabels]="chartOptionsChannel.dataLabels"
                                [responsive]="chartOptionsChannel.responsive"
                        ></apx-chart>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-12">
            <div class="m-portlet m-portlet--border-bottom-brand__kipu">
                <div class="m-portlet__body">
                    <div class="chart-wrapper">
                        <canvas baseChart
                                height="100vh"
                                [datasets]="lineChartData"
                                [labels]="lineChartLabels"
                                [options]="lineChartOptions"
                                [colors]="lineChartColors"
                                [legend]="lineChartLegend"
                                [chartType]="lineChartType"
                                [plugins]="lineChartPlugins">
                        </canvas>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="m-portlet m-portlet--border-bottom-brand__kipu">
                <div class="m-portlet__body">
                    <h4 class="title">Top Cajeros</h4>
                    <div class="table-responsive">
                        <table class="table table-hover table-bordered">
                            <thead>
                            <tr>
                                <th>Cajero</th>
                                <th>Vendido</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let item of sellersRanking">
                                <td>
                                    <span class="m-topbar__userpic">
                                        <img class="m--img-rounded m--marginless img-user"
                                             [src]="item.seller_photo_url"
                                             [alt]="item.seller_name"></span>
                                    <span class="seller-name">{{item.seller_name}}</span>
                                </td>
                                <td class="seller-name table-middle-align">{{item.total_sold | currency: defaultCurrency.currency.symbol}}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6" *ngIf="currentSettings.kipu_mode == 'retail'">
            <div class="m-portlet m-portlet--border-bottom-brand__kipu">
                <div class="m-portlet__body">
                    <div class="container-table-ranking">
                        <h4 class="title title-3">Poco stock</h4>
                        <div class="table-responsive">
                            <table class="table table-hover table-bordered">
                                <thead>
                                <tr>
                                    <th>Código</th>
                                    <th>Producto</th>
                                    <th>En stock</th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let item of productsLowStock">
                                    <td>{{item.code}}</td>
                                    <td><span class="product-name">{{item.name}}</span></td>
                                    <td>{{item.current_stock}}</td>
                                    <td>
                                        <button (click)="showDetail(item)"
                                                class="m-portlet__nav-link btn m-btn m-btn--hover-accent m-btn--icon m-btn--icon-only m-btn--pill"
                                                title="Ver Detalle">
                                            <i class="flaticon-eye"></i>
                                        </button>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="m-portlet m-portlet--border-bottom-brand__kipu">
                <div class="m-portlet__body">
                    <h4 class="title">Mas Vendidos</h4>
                    <div class="table-responsive">
                        <table class="table table-hover table-bordered">
                            <thead>
                            <tr>
                                <th>Código</th>
                                <th>Producto</th>
                                <th>Importe</th>
                                <th>% ventas</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let item of productsRankingBestSellers">
                                <td>{{item.item_code}}</td>
                                <td><span class="product-name">{{item.item_name}}</span></td>
                                <td>{{item.item_total | currency: defaultCurrency.currency.symbol}}</td>
                                <td>{{((item.total_quantity_sold / totalQuantitySold) * 100) | number:'1.0-0' }}%</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="m-portlet m-portlet--border-bottom-brand__kipu">
                <div class="m-portlet__body">
                    <h4 class="title">Mas facturados</h4>
                    <div class="table-responsive">
                        <table class="table table-hover table-bordered">
                            <thead>
                            <tr>
                                <th>Código</th>
                                <th>Producto</th>
                                <th>Importe</th>
                                <th>% ventas</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let item of productsRankingBestInvoiced">
                                <td>{{item.item_code}}</td>
                                <td><span class="product-name">{{item.item_name}}</span></td>
                                <td>{{item.item_total | currency: defaultCurrency.currency.symbol}}</td>
                                <td>{{((item.item_total / totalSalePeriod) * 100) | number:'1.0-0' }}%</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
