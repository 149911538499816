<!-- begin:: Page -->
<div class="m-grid m-grid--hor m-grid--root m-page m-grid-login-layout">
    <div class="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-grid--tablet-and-mobile m-grid--hor-tablet-and-mobile m-login m-login--1 m-login--signin app-section-responsive-full-height"
         id="m_login">
        <div class="m-grid__item m-grid__item--fluid m-grid m-grid--center m-grid--hor m-grid__item--order-tablet-and-mobile-1 m-grid-item--center app-section-responsive bg-login column-login">
            <div class="logo-in-image">
                <img src="../../../../assets/core/images/login/logo-kipu.svg"
                     class="logo-in-image-style" alt="">
            </div>
        </div>
        <div class="m-grid__item m-grid__item--order-tablet-and-mobile-2 m-login__aside column-login">
            <div class="m-stack m-stack--hor m-stack--desktop">
                <div class="m-stack__item m-stack__item--fluid">
                    <div class="m-login__wrapper">
                        <div class="m-login__logo">
                            <a [routerLink]="'/login'">
                                <img src="../../../../assets/core/images/login/logo-kipu.svg" alt="Kipu" class="logo">
                            </a>
                        </div>
                        <div class="m-login__signin">
                            <div class="m-login__head">
                                <h3 class="m-login__title">Iniciar Sesión</h3>
                            </div>
                            <!--begin::Form-->
                            <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="m-login__form m-form">
                                <app-message></app-message>
                                <div class="form-group m-form__group form-styles-accommodate">
                                    <input formControlName="email" class="form-control m-input form-control-lg" type="email"
                                           placeholder="Email" autocomplete="off"
                                           [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                        <div *ngIf="f.email.errors.required">Email es obligatorio</div>
                                    </div>
                                </div>

                                <div class="form-group m-form__group form-styles-accommodate">
                                    <input formControlName="password"
                                           class="form-control m-input m-login__form-input--last form-control-lg"
                                           type="password"
                                           placeholder="Contraseña"
                                           [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
                                    <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                        <div *ngIf="f.password.errors.required">Password es obligatorio</div>
                                    </div>
                                </div>

                                <div *ngIf="branches.length > 1" class="form-group m-form__group form-styles-accommodate">
                                    <ng-select2
                                            [data]="branches"
                                            [width]="'100%'"
                                            [placeholder]="'Selecciona una sede'"
                                            formControlName="branch_id">
                                    </ng-select2>
                                    <div *ngIf="submitted && f.branch_id.errors" class="invalid-feedback" style="display: block">
                                        <div *ngIf="f.branch_id.errors.required">Sede obligatoria</div>
                                    </div>
                                </div>

                                <div class="row m-login__form-sub">
                                    <div class="col m--align-left">
                                        <a href="javascript:;" id="m_login_forget_password">¿Olvidaste tu
                                            Contraseña ?</a>
                                    </div>
                                </div>

                                <!--begin::Action-->
                                <div class="m-login__form-action">
                                    <button [disabled]="loading" type="submit"
                                            [ngClass]="{'m-loader m-loader--light m-loader--right': loading}"
                                            class="btn m-btn m-btn--pill m-btn--custom m-btn--air btn-lg btn-styles">
                                        Iniciar Sesión
                                    </button>
                                </div>

                                <!--end::Action-->
                            </form>
                        </div>
                        <div class="m-login__forget-password">
                            <!--                                <app-forgot-password></app-forgot-password>-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- end:: Page -->
