import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Select2OptionData} from 'ng-select2';
import {CategoryService} from '../../../services/category.service';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute, Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {first} from 'rxjs/operators';
import {ProductService} from '../../../services/product.service';
import {ResourceService} from '../../../services/resource.service';
import {BranchService} from '../../../services/branch.service';
import {SettingService} from '../../../services/setting.service';
import {Setting} from '../../../models/setting';
import {MenuService} from '../../../services/menu.service';

declare var swal: any;

@Component({
    selector: 'app-product-add-edit',
    templateUrl: './product-add-edit.component.html',
    styleUrls: ['./product-add-edit.component.scss']
})
export class ProductAddEditComponent implements OnInit {

    form: FormGroup;
    product: any = {};
    submitted = false;
    loading = false;
    id: number;
    menus: Array<Select2OptionData> = [];
    categories: Array<Select2OptionData> = [];
    branches: any[] = [];

    pathFile = 'Elegir Foto';
    countRequests = 0;
    maxCountRequests = 1;
    setting: Setting;
    details: any[] = [];
    extras: any[] = [];

    constructor(
        private router: Router,
        private menuService: MenuService,
        private formBuilder: FormBuilder,
        private spinner: NgxSpinnerService,
        private toastrService: ToastrService,
        private branchService: BranchService,
        private settingService: SettingService,
        private productService: ProductService,
        private activatedRoute: ActivatedRoute,
        private categoryService: CategoryService,
        private resourceService: ResourceService,
    ) {
    }

    ngOnInit(): void {
        this.setting = this.settingService.currentSettingsValue;
        this.id = this.activatedRoute.snapshot.params['id'];

        if (this.setting.kipu_mode == 'retail') {
            this.form = this.formBuilder.group({
                name: ['', Validators.required],
                comment: [''],
                code: ['', [Validators.required, Validators.maxLength(20)]],
                category_id: [null, Validators.required],
                description: [''],
                price: [0, [Validators.required, Validators.min(0.1)]],
                cost: [0],
                stock: [0],
                minimum_stock: [0],
                photo: [null],
                photo_url: [null],
                status: [1, Validators.required],
            });

            this.spinner.show();
            if (this.id) {
                this.maxCountRequests = 2;
                this.productService.show(this.id).subscribe(response => {
                    this.countRequests++;
                    this.form.patchValue(response.data);
                    this.product = response.data;
                    if (this.countRequests == this.maxCountRequests) {
                        this.spinner.hide();
                    }
                }, error1 => {
                    this.spinner.hide();
                    this.toastrService.error(error1);
                    this.router.navigate(['/']);
                });
            }
            this.loadDataRetail();
        } else if (this.setting.kipu_mode == 'restaurant') {
            this.form = this.formBuilder.group({
                name: ['', Validators.required],
                comment: [''],
                code: ['', [Validators.required, Validators.maxLength(20)]],
                menu_id: [null, Validators.required],
                category_id: [null, Validators.required],
                description: [''],
                price: [0, [Validators.required, Validators.min(1)]],
                cost: [0],
                photo: [null],
                photo_url: [null],
                status: [1, Validators.required],
            });

            this.spinner.show();
            if (this.id) {
                this.maxCountRequests = 2;
                this.productService.show(this.id).subscribe(response => {
                    this.countRequests++;
                    this.form.patchValue(response.data);
                    this.product = response.data;
                    if (this.countRequests == this.maxCountRequests) {
                        this.spinner.hide();
                    }
                    this.details = [];
                    this.extras = [];
                    if (this.product.details) {
                        this.details = this.product.details;
                    }
                    if (this.product.extras) {
                        this.extras = this.product.extras;
                    }
                    if (this.details.length == 0) {
                        this.details.push({
                            name: null

                        });
                    }
                    if (this.extras.length == 0) {
                        this.extras.push({
                            name: null,
                            price: null,
                        });
                    }
                }, error1 => {
                    this.spinner.hide();
                    this.toastrService.error(error1);
                    this.router.navigate(['/']);
                });
            } else {
                this.details.push({
                    name: null
                });
                this.extras.push({
                    name: null,
                    price: null,
                });
            }
            this.loadDataRestaurant();
        } else {
            this.form = this.formBuilder.group({
                name: ['', Validators.required],
                comment: [''],
                code: ['', [Validators.required, Validators.maxLength(20)]],
                type: ['replacement', Validators.required],
                category_id: [null, Validators.required],
                description: [''],
                currency_id: [null, Validators.required],
                price: [0, [Validators.required, Validators.min(1)]],
                cost: [0],
                stock: [0],
                minimum_stock: [0],
                photo: [null],
                photo_url: [null],
                license_plate: [null],
                brand_name: [null],
                origin_name: [null],
                unit_of_measurement: [null],
                model_name: [null],
                color: [null],
                chassis: [null],
                version: [null],
                tire_rim: [null],
                equipment: [null],
                status: [1, Validators.required],
            });
            this.spinner.show();
            if (this.id) {
                this.maxCountRequests = 2;
                this.productService.show(this.id).subscribe(response => {
                    this.countRequests++;
                    this.form.patchValue(response.data);
                    this.product = response.data;
                    if (this.countRequests == this.maxCountRequests) {
                        this.spinner.hide();
                    }
                }, error1 => {
                    this.spinner.hide();
                    this.toastrService.error(error1);
                    this.router.navigate(['/']);
                });
            }
            this.loadDataVehicle();
        }
    }

    get f() {
        return this.form.controls;
    }

    loadDataRetail(): void {
        this.categoryService.all().subscribe(response => {
            this.categories = response.data.map(item => {
                return {...item, ...{text: item.name}};
            });
            this.countRequests++;
            if (this.countRequests == this.maxCountRequests) {
                this.spinner.hide();
            }
        }, error1 => {
            this.spinner.hide();
            this.toastrService.error(error1, 'Error');
        });
        if (!this.id) {

            this.f.cost.setValidators([Validators.required, Validators.min(0)]);

            if (this.setting.multi_site) {
                this.branchService.all(1, null, 1).pipe(first()).subscribe(response => {
                    this.branches = response.data;
                }, error1 => {
                    this.toastrService.error(error1, 'Error');
                });
            } else if (this.setting.product_location) {
                this.branchService.getDefault(1).pipe(first()).subscribe(response => {
                    this.branches = [response.data];
                }, error1 => {
                    this.toastrService.error(error1, 'Error');
                });
            } else {
                this.f.stock.setValidators([Validators.required, Validators.min(0)]);
            }

            this.form.reset();
            this.f.price.setValue(0);
            this.f.cost.setValue(0);
            this.f.stock.setValue(0);
            this.f.status.setValue(1);
        }
    }

    loadDataRestaurant(): void {
        this.menuService.all().subscribe(response => {
            this.menus = response.data.map(item => {
                return {...item, ...{text: item.name}};
            });
            this.countRequests++;
            if (this.countRequests == this.maxCountRequests) {
                this.spinner.hide();
            }
        }, error1 => {
            this.spinner.hide();
            this.toastrService.error(error1);
        });
    }

    loadDataVehicle(): void {
        this.categoryService.all().subscribe(response => {
            this.categories = response.data.map(item => {
                return {...item, ...{text: item.name}};
            });
            this.countRequests++;
            if (this.countRequests == this.maxCountRequests) {
                this.spinner.hide();
            }
        }, error1 => {
            this.spinner.hide();
            this.toastrService.error(error1, 'Error');
        });
        if (!this.id) {

            this.f.cost.setValidators([Validators.required, Validators.min(0)]);

            if (this.setting.multi_site) {
                this.branchService.all(1, null, 1).pipe(first()).subscribe(response => {
                    this.branches = response.data;
                }, error1 => {
                    this.toastrService.error(error1, 'Error');
                });
            } else if (this.setting.product_location) {
                this.branchService.getDefault(1).pipe(first()).subscribe(response => {
                    this.branches = [response.data];
                }, error1 => {
                    this.toastrService.error(error1, 'Error');
                });
            } else {
                this.f.stock.setValidators([Validators.required, Validators.min(0)]);
            }

            this.form.reset();
            this.f.price.setValue(0);
            this.f.cost.setValue(0);
            this.f.stock.setValue(0);
            this.f.status.setValue(1);
            this.f.type.setValue('replacement');
            this.f.currency_id.setValue(this.setting.setting_currencies.find(setting_currency => setting_currency.principal).currency_id);
        }
    }

    sendForm(): void {
        this.submitted = true;
        if (!this.form.valid) {
            return;
        }
        this.loading = true;
        let product = Object.assign({}, this.form.value);
        if (this.id != null) {
            product.id = this.id;
            if (this.setting.kipu_mode == 'restaurant') {
                const details = [];
                this.details.forEach(detail => {
                    if (detail.name && detail.name.trim()) {
                        details.push(detail);
                    }
                });
                product.details = details;

                const extras = [];
                this.extras.forEach(extra => {
                    if (extra.name && extra.name.trim() && extra.price) {
                        extras.push(extra);
                    }
                });
                product.extras = extras;
            }
            this.productService.update(product).pipe(first()).subscribe(response => {
                this.loading = false;
                this.toastrService.success(response.message, 'Éxito!');
                this.router.navigate(['/products']);
            }, error => {
                this.toastrService.error(error);
                this.loading = false;
            });
        } else {
            if (this.setting.kipu_mode == 'retail' || this.setting.kipu_mode == 'vehicle') {
                if (this.setting.multi_site || this.setting.product_location) {
                    product.branches = this.branches;
                }
            }
            if (this.setting.kipu_mode == 'restaurant') {
                const details = [];
                this.details.forEach(detail => {
                    if (detail.name && detail.name.trim()) {
                        details.push(detail);
                    }
                });
                product.details = details;

                const extras = [];
                this.extras.forEach(extra => {
                    if (extra.name && extra.name.trim() && extra.price) {
                        extras.push(extra);
                    }
                });
                product.extras = extras;
            }
            this.productService.store(product).pipe(first()).subscribe(response => {
                this.loading = false;
                this.toastrService.success(response.message, 'Éxito!');
                this.router.navigate(['/products']);
            }, error => {
                this.toastrService.error(error);
                this.loading = false;
            });
        }
    }

    onSelectFile(fileInput: any) {
        let photoFile = <File> fileInput.target.files[0];
        if (photoFile) {
            const formData = new FormData();
            formData.append('file', photoFile);
            formData.append('path', 'images/products');
            this.spinner.show();
            this.resourceService.uploadFile(formData).pipe(first()).subscribe(response => {
                this.f.photo.setValue(response.file);
                this.f.photo_url.setValue(response.file_url);
                this.product['photo'] = response.file;
                this.product['photo_url'] = response.file_url;
                this.pathFile = photoFile.name;
                this.spinner.hide();
            }, error => {
                this.spinner.hide();
                this.toastrService.error(error);
            });
        }
    }

    getCategoriesByMenuId(): void {
        if (this.f.menu_id.value) {
            this.spinner.show();
            this.categoryService.all(1, this.f.menu_id.value).subscribe(response => {
                this.spinner.hide();
                this.categories = response.data.map(item => {
                    return {...item, ...{text: item.name}};
                });
            }, error1 => {
                this.spinner.hide();
                this.toastrService.error(error1);
            });
        }
    }

    removeItem(items, i): void {
        items.splice(i, 1);
    }

    addItem(items, i: number): void {
        items.splice(i + 1, 0, {
            name: null
        });
    }
}
