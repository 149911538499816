import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {webServiceConfig} from '../config/web-service.config';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {DataTablesResponse} from '../models/data-tables-response';


@Injectable({
    providedIn: 'root'
})
export class ProductService {

    constructor(private http: HttpClient) {
    }

    getAll(): Observable<any> {
        return this.http.get(`${webServiceConfig.webServiceUrl}products`).pipe(map(response => {
            return response;
        }));
    }

    productAllBranches(productId): Observable<any> {
        return this.http.get(`${webServiceConfig.webServiceUrl}products/all-branches/${productId}`).pipe(map(response => {
            return response;
        }));
    }

    datatable(dataTablesParameters): Observable<any> {
        return this.http.post<DataTablesResponse>(`${webServiceConfig.webServiceUrl}products/datatable`, dataTablesParameters, {})
            .pipe(map(response => {
                return response;
            }));
    }

    show(id: number): Observable<any> {
        return this.http.get(`${webServiceConfig.webServiceUrl}products/${id}`).pipe(map(response => {
            return response;
        }));
    }

    store(product: any): Observable<any> {
        return this.http.post(`${webServiceConfig.webServiceUrl}products`, product)
            .pipe(map(response => {
                return response;
            }));
    }

    update(product: any): Observable<any> {
        return this.http.put(`${webServiceConfig.webServiceUrl}products/${product.id}`, product)
            .pipe(map(response => {
                return response;
            }));
    }

    delete(id: number): Observable<any> {
        return this.http.delete(`${webServiceConfig.webServiceUrl}products/${id}`)
            .pipe(map(response => {
                return response;
            }));
    }

    getByCode(code): Observable<any> {
        return this.http.get(`${webServiceConfig.webServiceUrl}products/search/code`, {
            params: {
                code
            }
        }).pipe(map(response => {
            return response;
        }));
    }

    export(): Observable<Blob> {
        return this.http.get(`${webServiceConfig.webServiceUrl}products/export`, {responseType: 'blob'});
    }
}
