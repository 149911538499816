<!-- BEGIN: Subheader -->
<div class="m-subheader ">
    <div class="d-flex align-items-center">
        <div class="mr-auto">
            <h3 class="m-subheader__title m-subheader__title--separator">Categorías</h3>
            <ul class="m-subheader__breadcrumbs m-nav m-nav--inline">
                <li class="m-nav__item m-nav__item--home">
                    <a [routerLink]="''" class="m-nav__link m-nav__link--icon">
                        <i class="m-nav__link-icon la la-home"></i>
                    </a>
                </li>
                <li class="m-nav__separator">-</li>
                <li class="m-nav__item">
                    <a [routerLink]="'/categories'" class="m-nav__link">
                        <span class="m-nav__link-text">Categorías</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>


<div class="m-content">
    <div class="row">
        <div class="col-md-12">
            <!--begin::Portlet-->
            <div class="m-portlet m-portlet--mobile">
                <div class="m-portlet__head">
                    <div class="m-portlet__head-caption">
                        <div class="m-portlet__head-title">
                            <h3 class="m-portlet__head-text">
                                Categorías
                            </h3>
                        </div>
                    </div>
                    <div class="m-portlet__head-tools">
                        <ul class="m-portlet__nav">
                            <li class="m-portlet__nav-item">
                                <button (click)="exportList()"
                                        [disabled]="exporting"
                                        [ngClass]="{'m-loader m-loader--light m-loader--right': exporting}"
                                        class="btn btn-success m-btn m-btn--pill m-btn--custom m-btn--icon m-btn--air">
                                    <span>
                                       <i class="la la-file-excel-o"></i>
                                        <span>Exportar</span>
                                    </span>
                                </button>
                            </li>
                            <li class="m-portlet__nav-item">
                                <a [routerLink]="['create']"
                                   class="btn btn-primary m-btn m-btn--pill m-btn--custom m-btn--icon m-btn--air">
                                    <span>
                                        <i class="la la-plus"></i>
                                        <span>Nuevo Categoría</span>
                                    </span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <app-message></app-message>
                <div class="m-portlet__body">
                    <div class="table-responsive">
                        <!--begin: Datatable -->
                        <table datatable [dtOptions]="dtOptions"
                               class="table table-striped- table-bordered table-hover table-checkable">
                            <thead>
                            <tr>
                                <th>ID</th>
                                <th *ngIf="currentSettings.kipu_mode == 'restaurant'">Menú</th>
                                <th>Nombre</th>
                                <th>Descripción</th>
                                <th>Estado</th>
                                <th>Acciones</th>
                            </tr>
                            </thead>
                            <tbody *ngIf="categories?.length != 0">
                            <tr *ngFor="let category of categories">
                                <td>{{ category.id }}</td>
                                <td *ngIf="currentSettings.kipu_mode == 'restaurant'">{{ category.menu?.name }}</td>
                                <td>{{ category.name }}</td>
                                <td>{{ category.description }}</td>
                                <td>{{ category.status_nice }}</td>
                                <td>
                                    <button [routerLink]="['edit', category.id]"
                                            class="m-portlet__nav-link btn m-btn m-btn--hover-accent m-btn--icon m-btn--icon-only m-btn--pill"
                                            title="Edit details">
                                        <i class="la la-edit"></i>
                                    </button>
                                    <button (click)="delete(category)"
                                            class="m-portlet__nav-link btn m-btn m-btn--hover-danger m-btn--icon m-btn--icon-only m-btn--pill"
                                            title="Delete"> <i class="la la-trash"></i> </button>
                                </td>
                            </tr>
                            </tbody>
                            <tbody *ngIf="categories?.length == 0">
                            <tr>
                                <td [attr.colspan]="currentSettings.kipu_mode == 'restaurant' ? 6 : 5" class="no-data-available">No data!</td>
                            </tr>
                            <tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
