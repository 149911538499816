<!-- BEGIN: Subheader -->
<div class="m-subheader ">
    <div class="d-flex align-items-center">
        <div class="mr-auto">
            <h3 class="m-subheader__title m-subheader__title--separator" *ngIf="category.id">Editar Categoria</h3>
            <h3 class="m-subheader__title m-subheader__title--separator" *ngIf="!category.id">Nueva Categoria</h3>
            <ul class="m-subheader__breadcrumbs m-nav m-nav--inline">
                <li class="m-nav__item m-nav__item--home">
                    <a [routerLink]="''" class="m-nav__link m-nav__link--icon">
                        <i class="m-nav__link-icon la la-home"></i>
                    </a>
                </li>
                <li class="m-nav__separator">-</li>
                <li class="m-nav__item">
                    <a [routerLink]="'/categories'" class="m-nav__link">
                        <span class="m-nav__link-text">Categorias</span>
                    </a>
                </li>
                <li class="m-nav__separator">-</li>
                <li class="m-nav__item">
                    <a *ngIf="category.id == null" [routerLink]="'/categories/create'" class="m-nav__link">
                        <span class="m-nav__link-text">Nueva Categoria</span>
                    </a>
                    <a *ngIf="category.id != null" [routerLink]="['/categories/edit', category.id]" class="m-nav__link">
                        <span class="m-nav__link-text">Editar Categoria</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>


<div class="m-content">
    <div class="row">
        <div class="col-md-12">
            <!--begin::Portlet-->
            <div class="m-portlet m-portlet--mobile">
                <div class="m-portlet__head">
                    <div class="m-portlet__head-caption">
                        <div class="m-portlet__head-title">
                            <h3 *ngIf="category.id == null" class="m-portlet__head-text">
                                Nueva Categoria
                            </h3>
                            <h3 *ngIf="category.id != null" class="m-portlet__head-text">
                                Editar Categoria - {{category.name}}
                            </h3>
                        </div>
                    </div>
                    <div class="m-portlet__head-tools">
                        <ul class="m-portlet__nav">
                            <li class="m-portlet__nav-item">
                                <a [routerLink]="'/categories'"
                                   class="m-portlet__nav-link btn btn-lg btn-secondary m-btn m-btn--outline-2x m-btn--air m-btn--icon m-btn--icon-only m-btn--pill  m-dropdown__toggle">
                                    <i class="flaticon-reply"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <form [formGroup]="form" (ngSubmit)="sendForm()" class="m-form m-form--label-align-right">
                    <div class="m-portlet__body">
                        <div class="m-form__section m-form__section--first">
                            <div *ngIf="currentSettings.kipu_mode == 'restaurant'" class="form-group m-form__group row">
                                <label class="col-lg-2 col-form-label">Menú</label>
                                <div class="col-lg-6">
                                    <ng-select2
                                            [data]="menus"
                                            [width]="'100%'"
                                            [placeholder]="'Selecciona un menú'"
                                            formControlName="menu_id">
                                    </ng-select2>
                                    <div *ngIf="submitted && f.menu_id.errors != null" class="invalid-feedback"
                                         style="display: block">
                                        <div *ngIf="f.menu_id.errors.required">Menú obligatorio</div>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group m-form__group row">
                                <label class="col-lg-2 col-form-label">Nombre:</label>
                                <div class="col-lg-6">
                                    <input formControlName="name" class="form-control m-input" placeholder="Nombre"
                                           [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
                                    <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                        <div *ngIf="f.name.errors.required">Nombre obligatorio</div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group m-form__group row">
                                <label class="col-lg-2 col-form-label">Decripción:</label>
                                <div class="col-lg-6">
                                     <textarea formControlName="description" class="form-control m-input"
                                               placeholder="Descripción"
                                               [ngClass]="{ 'is-invalid': submitted && f.description.errors }"
                                               rows="5"></textarea>
                                    <!-- <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                        <div *ngIf="f.name.errors.required">Nombre obligatorio</div>
                                    </div> -->
                                </div>
                            </div>
                            <!--<div class="form-group m-form__group row">
                                <label class="col-lg-2 col-form-label">Color:</label>
                                <div class="col-lg-6">
                                    <div class="m-badge-margin">
                                        <span (click)="selectClass('m-badge m-badge&#45;&#45;primary')"
                                              class="m-badge m-badge&#45;&#45;primary m-badge&#45;&#45;wide"
                                              [ngClass]="{'selected': f.class_color.value == 'm-badge m-badge&#45;&#45;primary'}"></span>
                                        <span (click)="selectClass('m-badge m-badge&#45;&#45;brand')"
                                              class="m-badge m-badge&#45;&#45;brand m-badge&#45;&#45;wide"
                                              [ngClass]="{'selected': f.class_color.value == 'm-badge m-badge&#45;&#45;brand'}"></span>
                                        <span (click)="selectClass('m-badge m-badge&#45;&#45;metal')"
                                              class="m-badge m-badge&#45;&#45;metal m-badge&#45;&#45;wide"
                                              [ngClass]="{'selected': f.class_color.value == 'm-badge m-badge&#45;&#45;metal'}"></span>
                                        <span (click)="selectClass('m-badge m-badge&#45;&#45;success')"
                                              class="m-badge m-badge&#45;&#45;success m-badge&#45;&#45;wide"
                                              [ngClass]="{'selected': f.class_color.value == 'm-badge m-badge&#45;&#45;success'}"></span>
                                        <span (click)="selectClass('m-badge m-badge&#45;&#45;info')"
                                              class="m-badge m-badge&#45;&#45;info m-badge&#45;&#45;wide"
                                              [ngClass]="{'selected': f.class_color.value == 'm-badge m-badge&#45;&#45;info'}"></span>
                                        <span (click)="selectClass('m-badge m-badge&#45;&#45;warning')"
                                              class="m-badge m-badge&#45;&#45;warning m-badge&#45;&#45;wide"
                                              [ngClass]="{'selected': f.class_color.value == 'm-badge m-badge&#45;&#45;warning'}"></span>
                                        <span (click)="selectClass('m-badge m-badge&#45;&#45;danger')"
                                              class="m-badge m-badge&#45;&#45;danger m-badge&#45;&#45;wide"
                                              [ngClass]="{'selected': f.class_color.value == 'm-badge m-badge&#45;&#45;danger'}"></span>
                                        <span (click)="selectClass('m-badge m-badge&#45;&#45;focus')"
                                              class="m-badge m-badge&#45;&#45;focus m-badge&#45;&#45;wide"
                                              [ngClass]="{'selected': f.class_color.value == 'm-badge m-badge&#45;&#45;focus'}"></span>
                                        <span (click)="selectClass('m-badge m-badge&#45;&#45;accent')"
                                              class="m-badge m-badge&#45;&#45;accent m-badge&#45;&#45;wide"
                                              [ngClass]="{'selected': f.class_color.value == 'm-badge m-badge&#45;&#45;accent'}"></span>
                                    </div>
                                </div>
                            </div>-->
                            <div class="form-group m-form__group row">
                                <label class="col-lg-2 col-form-label">Estado:</label>
                                <div class="col-lg-6">
                                    <select formControlName="status" class="form-control m-input"
                                            [ngClass]="{ 'is-invalid': submitted && f.status.errors }">
                                        <option value="1">Activo</option>
                                        <option value="0">Inactivo</option>
                                    </select>
                                    <div *ngIf="submitted && f.status.errors" class="invalid-feedback">
                                        <div *ngIf="f.status.errors.required">Estado obligatorio</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="m-portlet__foot m-portlet__foot--fit">
                        <div class="m-form__actions">
                            <div class="row">
                                <div class="col-2">
                                </div>
                                <div class="col-7">
                                    <button type="submit" [disabled]="loading"
                                            class="btn btn-accent m-btn m-btn--air m-btn--custom"
                                            [ngClass]="{'m-loader m-loader--light m-loader--right': loading}">
                                        Guardar Cambios
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
