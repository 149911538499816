import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {animate, AnimationBuilder, AnimationPlayer, state, style, transition, trigger} from '@angular/animations';
import {NavigationEnd, NavigationStart, Router} from '@angular/router';

declare var mQuickSidebar: any;
declare var mLayout: any;

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit, AfterViewInit {

    @ViewChild('mContentWrapper', {read: ElementRef, static: true}) contentWrapper: ElementRef;
    private player: AnimationPlayer;

    constructor(
        private animationBuilder: AnimationBuilder,
        private router: Router,
    ) {
        // animate page load
        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                if (this.contentWrapper) {
                    // hide content
                    this.contentWrapper.nativeElement.style.display = 'none';
                }
            }
            if (event instanceof NavigationEnd) {
                if (this.contentWrapper) {
                    // show content back
                    this.contentWrapper.nativeElement.style.display = '';
                    this.animate(this.contentWrapper.nativeElement);
                }
            }
        });
    }

    ngOnInit(): void {

    }

    ngAfterViewInit(): void {
        mQuickSidebar.init();
        mLayout.init();
    }

    /**
     * Animate page load
     */
    animate(element): void {
        this.player = this.animationBuilder
            .build([
                style({opacity: 0, transform: 'translateY(15px)'}),
                animate('500ms ease', style({opacity: 1, transform: 'translateY(0)'})),
                style({transform: 'none'}),
            ]).create(element);
        this.player.play();
    }
}
