<!-- BEGIN: Subheader -->
<div class="m-subheader ">
    <div class="d-flex align-items-center">
        <div class="mr-auto">
            <h3 class="m-subheader__title m-subheader__title--separator" *ngIf="item.id">Editar Ubicación</h3>
            <h3 class="m-subheader__title m-subheader__title--separator" *ngIf="!item.id">Nueva Ubicación</h3>
            <ul class="m-subheader__breadcrumbs m-nav m-nav--inline">
                <li class="m-nav__item m-nav__item--home">
                    <a [routerLink]="''" class="m-nav__link m-nav__link--icon">
                        <i class="m-nav__link-icon la la-home"></i>
                    </a>
                </li>
                <li class="m-nav__separator">-</li>
                <li class="m-nav__item">
                    <a [routerLink]="'/locations'" class="m-nav__link">
                        <span class="m-nav__link-text">Productos</span>
                    </a>
                </li>
                <li class="m-nav__separator">-</li>
                <li class="m-nav__item">
                    <a *ngIf="item.id == null" [routerLink]="'/locations/create'" class="m-nav__link">
                        <span class="m-nav__link-text">Nuevo Producto</span>
                    </a>
                    <a *ngIf="item.id != null" [routerLink]="['/locations/edit', item.id]" class="m-nav__link">
                        <span class="m-nav__link-text">Editar Producto</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>

<div class="m-content">
    <div class="row">
        <div class="col-md-12">
            <!--begin::Portlet-->
            <div class="m-portlet m-portlet--mobile">
                <div class="m-portlet__head">
                    <div class="m-portlet__head-caption">
                        <div class="m-portlet__head-title">
                            <h3 *ngIf="item.id == null" class="m-portlet__head-text">
                                Nueva Ubicación
                            </h3>
                            <h3 *ngIf="item.id != null" class="m-portlet__head-text">
                                Editar Ubicación - {{item.name}}
                            </h3>
                        </div>
                    </div>
                    <div class="m-portlet__head-tools">
                        <ul class="m-portlet__nav">
                            <li class="m-portlet__nav-item">
                                <a [routerLink]="'/locations'"
                                   class="m-portlet__nav-link btn btn-lg btn-secondary m-btn m-btn--outline-2x m-btn--air m-btn--icon m-btn--icon-only m-btn--pill  m-dropdown__toggle">
                                    <i class="flaticon-reply"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <form [formGroup]="form" (ngSubmit)="sendForm()" class="m-form m-form--label-align-right">
                    <div class="m-portlet__body">
                        <div class="m-form__section m-form__section--first">

                            <div class="form-group m-form__group row">
                                <label class="col-lg-2 col-form-label">Sucursal / Almacén</label>
                                <div class="col-lg-6">
                                    <ng-select2
                                            [data]="branches"
                                            [width]="'100%'"
                                            [placeholder]="'Selecciona una Sucursal / Almacén'"
                                            formControlName="branch_id">
                                    </ng-select2>
                                    <div *ngIf="submitted && f.branch_id.errors != null" class="invalid-feedback"
                                         style="display: block">
                                        <div *ngIf="f.branch_id.errors.required">Sucursal / Almacén obligatorio</div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group m-form__group row">
                                <label class="col-lg-2 col-form-label">Nombre:</label>
                                <div class="col-lg-6">
                                    <input formControlName="name" class="form-control m-input" placeholder="Nombre"
                                           [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
                                    <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                        <div *ngIf="f.name.errors.required">Nombre obligatorio</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="m-portlet__foot m-portlet__foot--fit">
                        <div class="m-form__actions">
                            <div class="row">
                                <div class="col-2">
                                </div>
                                <div class="col-7">
                                    <button type="submit" [disabled]="loading"
                                            class="btn btn-accent m-btn m-btn--air m-btn--custom"
                                            [ngClass]="{'m-loader m-loader--light m-loader--right': loading}">
                                        Guardar Cambios
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
