import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute, Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {first} from 'rxjs/operators';
import {UserService} from '../../../services/user.service';
import {EqualPasswordsValidator} from '../../../validators/equal-passwords.validator';
import {ResourceService} from '../../../services/resource.service';
import {SettingService} from '../../../services/setting.service';
import {Setting} from '../../../models/setting';

@Component({
    selector: 'app-user-add-edit',
    templateUrl: './user-add-edit.component.html',
    styleUrls: ['./user-add-edit.component.scss']
})
export class UserAddEditComponent implements OnInit {

    form: FormGroup;
    user: any = {};
    submitted = false;
    loading = false;
    id: number;
    roles: any[] = [
        {id: null, name: 'Selecciona un rol'},
        {id: 2, name: 'Administrador'},
        {id: 3, name: 'Vendedor'},
    ];
    pathFile = 'Elegir Foto';

    currentSettings: Setting;
    hasSeller = false;

    constructor(
        private router: Router,
        private formBuilder: FormBuilder,
        private userService: UserService,
        private toastrService: ToastrService,
        private activatedRoute: ActivatedRoute,
        private settingService: SettingService,
        private resourceService: ResourceService,
        private ngxSpinnerService: NgxSpinnerService,
    ) {
    }

    ngOnInit(): void {
        this.currentSettings = this.settingService.currentSettingsValue;
        if (this.currentSettings.kipu_mode == 'restaurant') {
            this.form = this.formBuilder.group({
                waiter_code: ['', Validators.required],
                name: ['', Validators.required],
                email: ['', [Validators.required, Validators.email]],
                role_id: [''],
                status: [1, Validators.required],
                password: [''],
                password_confirmation: [''],
                photo: [''],
                photo_url: [''],
            }, {validators: EqualPasswordsValidator.validate('password', 'password_confirmation')});
        } else {
            this.form = this.formBuilder.group({
                name: ['', Validators.required],
                email: ['', [Validators.required, Validators.email]],
                role_id: [''],
                status: [1, Validators.required],
                password: [''],
                password_confirmation: [''],
                photo: [''],
                photo_url: [''],
            }, {validators: EqualPasswordsValidator.validate('password', 'password_confirmation')});
        }
        this.id = this.activatedRoute.snapshot.params['id'];
        if (this.id) {
            this.ngxSpinnerService.show();
            this.userService.show(this.id).pipe(first()).subscribe(response => {
                this.ngxSpinnerService.hide();
                this.form.patchValue(response.data);
                this.user = response.data;
                const roleFound = this.user.roles.find(role => role.name == 'seller');
                if (roleFound) {
                    this.hasSeller = true;
                    this.f.waiter_code.setValidators([Validators.required]);
                }
            }, error => {
                this.ngxSpinnerService.hide();
                this.router.navigate(['']);
            });
        } else {
            this.f.role_id.setValidators([Validators.required]);
            this.f.password.setValidators([Validators.required]);
            this.f.password_confirmation.setValidators([Validators.required]);
        }
    }

    get f() {
        return this.form.controls;
    }

    sendForm(): void {
        this.submitted = true;
        if (!this.form.valid) {
            return;
        }
        this.loading = true;
        let user = Object.assign({}, this.form.value);
        if (this.id != null) {
            user.id = this.id;
            this.userService.update(user).subscribe(response => {
                this.loading = false;
                this.toastrService.success(response.message, 'Éxito!');
                this.router.navigate(['/users']);
            }, error => {
                this.toastrService.error(error);
                this.loading = false;
            });
        } else {
            this.userService.store(user).subscribe(response => {
                this.loading = false;
                this.toastrService.success(response.message, 'Éxito!');
                this.router.navigate(['/users']);
            }, error => {
                this.toastrService.error(error);
                this.loading = false;
            });
        }
    }

    onSelectFile(fileInput: any) {
        let photoFile = <File> fileInput.target.files[0];
        if (photoFile) {
            const formData = new FormData();
            formData.append('file', photoFile);
            formData.append('path', 'images/users');
            this.ngxSpinnerService.show();
            this.resourceService.uploadFile(formData).pipe(first()).subscribe(response => {
                this.f.photo.setValue(response.file);
                this.f.photo_url.setValue(response.file_url);
                this.user['photo'] = response.file;
                this.user['photo_url'] = response.file_url;
                this.pathFile = photoFile.name;
                this.ngxSpinnerService.hide();
            }, error => {
                this.ngxSpinnerService.hide();
                this.toastrService.error(error);
            });
        }
    }
}
