import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {webServiceConfig} from '../../../config/web-service.config';
import {ToastrService} from 'ngx-toastr';
import {MessageService} from '../../../services/message.service';
import {AuthenticationService} from '../../../services/authentication.service';
import {Setting} from '../../../models/setting';
import {SettingService} from '../../../services/setting.service';
import {first} from 'rxjs/operators';
import {NgxSpinnerService} from 'ngx-spinner';
import {ResourceService} from '../../../services/resource.service';
import {PromotionService} from '../../../services/promotion.service';
import {ActivatedRoute, Router} from '@angular/router';

export const DATEPICKER_FORMAT = {
    parse: {
        dateInput: 'LL',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@Component({
    selector: 'app-promotion-add-edit',
    templateUrl: './promotion-add-edit.component.html',
    styleUrls: ['./promotion-add-edit.component.scss'],
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        },
        {provide: MAT_DATE_FORMATS, useValue: DATEPICKER_FORMAT},
    ]
})
export class PromotionAddEditComponent implements OnInit, AfterViewInit {

    form: FormGroup;
    promotion: any = {};
    submitted = false;
    loading = false;
    id: number;

    @ViewChild('select2ProductSearch') select2ProductSearch: any;
    optionsSelect2Product = {
        width: '100%',
        language: 'es',
        delay: 250,
        placeholder: 'Busca productos',
        allowClear: true,
        ajax: {
            headers: {
                Authorization: `Bearer ${this.authenticationService.currentUserValue.token}`,
            },
            method: 'get',
            url: `${webServiceConfig.webServiceUrl}products/search`,
            dataType: 'json'
        },
    };

    pathFile = 'Elegir Foto';
    productSearchText = '';

    items: any[] = [];
    discount = 0;
    total = 0;

    constructor(
        private router: Router,
        private formBuilder: FormBuilder,
        private toastrService: ToastrService,
        private messageService: MessageService,
        private activatedRoute: ActivatedRoute,
        private resourceService: ResourceService,
        private promotionService: PromotionService,
        private ngxSpinnerService: NgxSpinnerService,
        private authenticationService: AuthenticationService,
    ) {
    }

    ngOnInit(): void {
        this.id = this.activatedRoute.snapshot.params['id'];
        this.form = this.formBuilder.group({
            name: ['', Validators.required],
            code: ['', [Validators.required, Validators.maxLength(20)]],
            description: [''],
            discount: [0],
            photo: [null],
            photo_url: [null],
            end_promotion: [null],
            status: [1, Validators.required],
        });

        if (this.id) {
            this.ngxSpinnerService.show();
            this.promotionService.show(this.id).subscribe(response => {
                this.form.patchValue(response.data);
                this.promotion = response.data;
                this.discount = this.promotion.discount;
                this.promotion.details.forEach(item => {
                    if (item.product) {
                        const product = JSON.parse(JSON.stringify(item.product));
                        product.quantity = item.quantity;
                        this.items.push(product);
                    }
                });
                this.calculateTotals();
                this.ngxSpinnerService.hide();
            }, error1 => {
                this.ngxSpinnerService.hide();
                this.toastrService.error(error1);
                this.router.navigate(['/']);
            });
        }
    }

    get f() {
        return this.form.controls;
    }

    ngAfterViewInit(): void {
        jQuery(this.select2ProductSearch._element.nativeElement).on('select2:select', (e: any) => {
            const newItem = e.params.data;
            const itemFound = this.items.find(item => item.id == newItem.id);
            if (itemFound) {
                itemFound.quantity++;
            } else {
                newItem.quantity = 1;
                this.items.push(newItem);
            }
            this.calculateTotals();
            setTimeout(() => {
                this.productSearchText = null;
            }, 300);
        });
    }

    deleteItem(i: number): void {
        this.items.splice(i, 1);
        this.calculateTotals();
    }

    calculateTotals(): void {
        this.total = 0;
        this.items.forEach(item => {
            let subtotalItem = item.price * item.quantity;
            this.total += subtotalItem;
        });
        if (this.discount > 0 && this.discount < this.total) {
            this.total = this.total - this.discount;
        }
    }

    onSelectFile(fileInput: any) {
        let photoFile = <File> fileInput.target.files[0];
        if (photoFile) {
            const formData = new FormData();
            formData.append('file', photoFile);
            formData.append('path', 'images/promotions');
            this.ngxSpinnerService.show();
            this.resourceService.uploadFile(formData).pipe(first()).subscribe(response => {
                this.f.photo.setValue(response.file);
                this.f.photo_url.setValue(response.file_url);
                this.promotion['photo'] = response.file;
                this.promotion['photo_url'] = response.file_url;
                this.pathFile = photoFile.name;
                this.ngxSpinnerService.hide();
            }, error => {
                this.ngxSpinnerService.hide();
                this.toastrService.error(error);
            });
        }
    }

    sendForm(): void {
        this.submitted = true;
        if (!this.form.valid) {
            return;
        }
        this.loading = true;
        let promotion = Object.assign({}, this.form.value);
        promotion.items = this.items;
        promotion.discount = this.discount;
        if (this.id != null) {
            promotion.id = this.id;
            this.promotionService.update(promotion).subscribe(response => {
                this.loading = false;
                this.toastrService.success(response.message, 'Éxito!');
                this.router.navigate(['/promotions']);
            }, error1 => {
                this.messageService.error(error1);
                this.toastrService.error(error1);
                this.loading = false;
            });
        } else {
            this.promotionService.store(promotion).subscribe(response => {
                this.loading = false;
                this.toastrService.success(response.message, 'Éxito!');
                this.router.navigate(['/promotions']);
            }, error1 => {
                this.messageService.error(error1);
                this.toastrService.error(error1);
                this.loading = false;
            });
        }
    }
}
