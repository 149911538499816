<h4 class="m-portlet__head-text title">Productos</h4>
<div class="m-portlet__body">
    <div class="row">
        <div class="col-md-12">
            <div class="m-portlet m-portlet--mobile m-portlet-no-default-styles pt-3">
                <div class="container-items-cart">
                    <div class="item-card-product" *ngFor="let item of form.items; let i = index"
                         (click)="selectProductCart(item, i)">
                        <div class="item-card-product-img">
                            <img [defaultImage]="'/assets/core/images/icons/product-icon.jpg'"
                                 [lazyLoad]="item.photo_url" class="img-item"
                                 [alt]="item.name">
                        </div>
                        <div class="item-card-product-data">
                            <app-cart-item [item]="item" [currentSettings]="currentSettings"
                                           [form]="form"></app-cart-item>
                            <!--<p class="name">{{item.name}}</p>
                            <p class="quantity">
                                {{item.quantity}} und
                                <span class="price">{{((item.price - item.discount) * item.quantity) | currency: 'S/ '}}</span>
                            </p>-->
                        </div>
                        <div class="item-card-product-icon">
                            <a (click)="deleteItemToSale(i)">
                                <i class="la la-close"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="section-footer-sale">
                <div class="row">
                    <div class="col-sm-6 text-left">
                        <p>Subtotal</p>
                        <p>IGV {{currentSettings.igv_percentage}}%</p>
                    </div>
                    <div class="col-sm-6 text-right">
                        <p><b>{{form.subtotal | currency: form.currency_symbol}}</b></p>
                        <p><b>{{form.igv_amount | currency: form.currency_symbol}}</b></p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="section-footer-sale-totals">
                            <div class="row">
                                <div class="col-sm-6 text-left">
                                    <h5>Total</h5>
                                </div>
                                <div class="col-sm-6 text-right">
                                    <h5><b>{{form.total | currency: form.currency_symbol}}</b></h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 pt-2">
                        <button class="btn btn-sale btn-block btn-lg"
                                (click)="nextStep()">
                            <span class="text-center">{{textsBySteps[form.currentStep]}}</span>
                        </button>
                        <!--<button *ngIf="currentSettings.kipu_mode == 'restaurant' && form.status == 6"
                                class="btn btn-update-sale btn-block btn-lg"
                                (click)="updateOrder()">
                            <span class="text-center">
                                Actualizar pedido
                            </span>
                        </button>-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
