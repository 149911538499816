import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';

@Component({
    selector: 'app-sale-select-currency-modal',
    templateUrl: './sale-select-currency-modal.component.html',
    styleUrls: ['./sale-select-currency-modal.component.scss']
})
export class SaleSelectCurrencyModalComponent implements OnInit {

    constructor(
        private dialogRef: MatDialogRef<SaleSelectCurrencyModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private router: Router,
    ) {
    }

    ngOnInit(): void {
    }

    selectCurrency(currency): void {
        this.data.form.currency_id = currency.currency_id;
        this.data.form.currency_alphabetic_code = currency.currency.alphabetic_code;
        this.data.form.currency_numeric_code = currency.currency.numeric_code;
        this.data.form.currency_name = currency.currency.name;
        this.data.form.currency_symbol = currency.currency.symbol;
        this.dialogRef.close();
    }

    toDashboard(): void {
        this.dialogRef.close();
        this.router.navigate(['/']);
    }
}
