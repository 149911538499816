import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute, Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {SalonService} from '../../../services/salon.service';

@Component({
    selector: 'app-salon-add-edit',
    templateUrl: './salon-add-edit.component.html',
    styleUrls: ['./salon-add-edit.component.scss']
})
export class SalonAddEditComponent implements OnInit {

    form: FormGroup;
    salon: any = {};
    submitted = false;
    loading = false;
    id: number;

    constructor(
        private formBuilder: FormBuilder,
        private salonService: SalonService,
        private toastrService: ToastrService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private spinner: NgxSpinnerService,
    ) {
    }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            name: ['', Validators.required],
            status: ['1', Validators.required],
        });
        this.id = this.activatedRoute.snapshot.params['id'];
        if (this.id) {
            this.spinner.show();
            this.salonService.show(this.id).subscribe(response => {
                this.spinner.hide();
                this.form.patchValue(response.data);
                this.salon = response.data;
            }, error1 => {
                this.spinner.hide();
                this.router.navigate(['/']);
            });
        }
    }

    get f() {
        return this.form.controls;
    }

    sendForm(): void {
        this.submitted = true;
        if (!this.form.valid) {
            return;
        }
        this.loading = true;
        let salon = Object.assign({}, this.form.value);
        if (this.id != null) {
            salon.id = this.id;
            this.salonService.update(salon).subscribe(response => {
                this.loading = false;
                this.toastrService.success(response.message, 'Éxito!');
                this.router.navigate(['/salons']);
            }, error => {
                this.toastrService.error(error);
                this.loading = false;
            });
        } else {
            this.salonService.store(salon).subscribe(response => {
                this.loading = false;
                this.toastrService.success(response.message, 'Éxito!');
                this.router.navigate(['/salons']);
            }, error => {
                this.toastrService.error(error);
                this.loading = false;
            });
        }
    }
}
