import {Component, Input, OnInit} from '@angular/core';
import {CustomerService} from '../../../services/customer.service';
import {ToastrService} from 'ngx-toastr';

declare var swal: any;

@Component({
    selector: 'app-customer-sale-fragment',
    templateUrl: './customer-sale-fragment.component.html',
    styleUrls: ['./customer-sale-fragment.component.scss']
})
export class CustomerSaleFragmentComponent implements OnInit {

    @Input() documentTypes: any = {};
    @Input() allInputsDisabled = false;
    @Input() customer: any = null;
    @Input() submitted = false;
    documentTypesLength = 0;

    customerSearched = false;
    searching = false;
    formLoaded = false;

    constructor(
        private toastrService: ToastrService,
        private customerService: CustomerService,
    ) {
    }

    ngOnInit(): void {
        this.documentTypesLength = Object.keys(this.documentTypes).length;
        if (this.documentTypesLength == 1) {
            // this.f.document_type.setValue(Object.keys(this.documentTypes)[0]);
            this.customer.document_type = Object.keys(this.documentTypes)[0];
        }
    }

    searchCustomer(): void {
        if (!this.customer.document_number) {
            swal.fire({
                title: 'Ingresa un valor',
                type: 'info'
            });
            return;
        }
        this.searching = true;
        const documentNumber = this.customer.document_number;
        const documentType = this.customer.document_type;
        this.customerService.searchDocumentNumber(documentNumber, documentType).subscribe(response => {
            this.searching = false;
            if (response.data) {
                this.customer.id = response.data.id;
                this.customer.name = response.data.name;
                this.customer.document_type = response.data.document_type;
                this.customer.document_number = response.data.document_number;
                this.customer.address = response.data.address;
                this.customer.phone = response.data.phone;
                this.customer.email = response.data.email;
                this.customer.status = response.data.status;
            } else {
                this.customer.id = null;
                this.customer.name = null;
                if (!this.customer.document_type) {
                    this.customer.document_type = documentType;
                }
                this.customer.document_number = documentNumber;
                this.customer.address = null;
                this.customer.phone = null;
                this.customer.email = null;
                this.customer.status = null;
            }

            this.allInputsDisabled = false;
            this.formLoaded = true;
            this.customerSearched = true;
        }, error1 => {
            this.toastrService.error(error1);
            this.searching = false;
        });
    }

}
