import {Component, OnInit} from '@angular/core';
import {SmallBoxService} from '../../../services/small-box.service';
import {ToastrService} from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';
import {Router} from '@angular/router';
import {SmallBoxOpenCloseComponent} from '../../finances/small-box-open-close/small-box-open-close.component';
import {MatDialog} from '@angular/material/dialog';
import {AuthenticationService} from '../../../services/authentication.service';

@Component({
    selector: 'app-seller-dashboard',
    templateUrl: './seller-dashboard.component.html',
    styleUrls: ['./seller-dashboard.component.scss']
})
export class SellerDashboardComponent implements OnInit {

    currentSmallBox: any = {};

    single: any[] = [];
    view: any[] = [250, 200];
    designatedTotal = 0;

    colorScheme = {
        domain: ['#0066ff']
    };

    currentBranch;

    constructor(
        private router: Router,
        private dialog: MatDialog,
        private toastrService: ToastrService,
        private smallBoxService: SmallBoxService,
        private ngxSpinnerService: NgxSpinnerService,
        private authenticationService: AuthenticationService,
    ) {
    }

    ngOnInit(): void {
        this.currentBranch = this.authenticationService.currentUserValue.branch;
        this.designatedTotal = this.currentBranch.sales_goal;
        this.ngxSpinnerService.show();
        this.smallBoxService.current(0).subscribe(response => {
            this.ngxSpinnerService.hide();
            if (response.data) {
                this.currentSmallBox = response.data;
                this.currentSmallBox.opened = true;
                // this.single[0].value = this.currentSmallBox.total_sales;
                this.single.push({
                    name: 'Ventas del día',
                    value: this.currentSmallBox.total_sales
                });
            } else {
                this.router.navigate(['/small-box/create-close']);
            }
        }, error1 => {
            this.ngxSpinnerService.hide();
            this.toastrService.error(error1);
        });
    }

    closeSmallBox(): void {
        const dialogRef = this.dialog.open(SmallBoxOpenCloseComponent, {
            data: this.currentSmallBox,
            width: '50%'
        });
        dialogRef.afterClosed().subscribe(resp => {
            if (resp?.closed) {
                this.router.navigate(['/small-box/create-close']);
            }
        });
    }
}
