import {Component, OnInit} from '@angular/core';
import {ExchangeRateService} from '../../../services/exchange-rate.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';
import {SettingService} from '../../../services/setting.service';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {Router} from '@angular/router';
import moment from 'moment';

declare var swal: any;

export const DATEPICKER_FORMAT = {
    parse: {
        dateInput: 'LL',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@Component({
    selector: 'app-exchange-rate-add',
    templateUrl: './exchange-rate-add.component.html',
    styleUrls: ['./exchange-rate-add.component.scss'],
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        },
        {provide: MAT_DATE_FORMATS, useValue: DATEPICKER_FORMAT},
    ]
})
export class ExchangeRateAddComponent implements OnInit {

    submitted = false;
    loading = false;
    form: any = {
        date: moment(),
        rates: []
    };
    currentCurrencies: any[] = [];

    constructor(
        private router: Router,
        private toastrService: ToastrService,
        private settingService: SettingService,
        private ngxSpinnerService: NgxSpinnerService,
        private exchangeRateService: ExchangeRateService,
    ) {
    }

    ngOnInit(): void {
        this.currentCurrencies = this.settingService.currentSettingsValue.setting_currencies.filter(item => !item.principal);
        this.currentCurrencies.forEach(currencySetting => {
            this.form.rates.push({
                currency_id: currencySetting.currency_id,
                currency: currencySetting.currency,
                sale: 0,
                purchase: 0,
            });
        });
    }

    sendForm(): void {
        this.submitted = true;
        if (!this.form.date) {
            swal.fire({
                title: 'Selecciona una fecha',
                type: 'info'
            });
        }
        const form: any[] = [];
        this.form.rates.forEach(rate => {
            form.push({
                currency_id: rate.currency_id,
                date: this.form.date.format('YYYY-MM-DD'),
                sale: rate.sale ? rate.sale : 0,
                purchase: rate.purchase ? rate.purchase : 0,
            });
        });
        this.loading = true;
        this.exchangeRateService.store({rates: form}).subscribe(response => {
            this.toastrService.success(response.message);
            this.router.navigate(['/exchange-rates']);
        }, error1 => {
            this.loading = false;
            this.toastrService.error(error1);
        });
    }
}
