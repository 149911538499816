<h1 mat-dialog-title *ngIf="data.box.opened">Cerrar {{data.box.name}}</h1>
<h1 mat-dialog-title *ngIf="!data.box.opened">Abrir {{data.box.name}}</h1>
<form [formGroup]="form" (ngSubmit)="sendForm()">
    <mat-dialog-content>
        <ng-container *ngIf="data.box.opened">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label>Monto de cierre</label>
                        <input formControlName="final_amount" class="form-control m-input"
                               placeholder="S/ 0.00" appCurrencyInput
                               [ngClass]="{ 'is-invalid': submitted && f.final_amount.errors }">
                        <div *ngIf="submitted && f.final_amount.errors" class="invalid-feedback">
                            <div *ngIf="f.final_amount.errors.required">Monto de cierre obligatorio</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <table class="table-box">
                        <tr>
                            <td style="padding-bottom: .5rem"><h5>MONTO DE APERTURA</h5></td>
                            <td class="text-right"><h5>{{data.box.starting_amount | currency: 'S/ '}}</h5></td>
                        </tr>
                        <tr>
                            <td style="padding-bottom: .5rem"><h5>TOTAL DE VENTAS</h5></td>
                            <td class="text-right"><h5>{{data.current_small_box.total_sales | currency: 'S/ '}}</h5></td>
                        </tr>
                        <tr style="border-bottom: 2px solid #E6E5E5">
                            <td style="padding-bottom: .5rem"><h5>MONTO DE CIERRE</h5></td>
                            <td class="text-right"><h5>{{f.final_amount.value | currency: 'S/ '}}</h5></td>
                        </tr>
                        <!--<tr>
                            <td style="padding-top: .5rem"><h5>TOTAL</h5></td>
                            <td class="text-right" style="color: #1ADE5E;"><h5>{{((data.starting_amount | toNumber) + (f.final_amount.value | toNumber)) | currency: 'S/ '}}</h5></td>
                        </tr>-->
                    </table>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="!data.box.opened">
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <label>Monto de apertura</label>
                        <input formControlName="starting_amount" class="form-control m-input"
                               placeholder="S/ 0.00" appCurrencyInput
                               [ngClass]="{ 'is-invalid': submitted && f.starting_amount.errors }">
                        <div *ngIf="submitted && f.starting_amount.errors" class="invalid-feedback">
                            <div *ngIf="f.starting_amount.errors.required">Monto de apertura obligatorio</div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button type="button" class="btn btn-outline-dark mx-3" mat-dialog-close>Cancelar</button>
        <button type="submit" [disabled]="loading" *ngIf="data.box.opened"
                class="btn btn-danger m-btn m-btn--custom"
                [ngClass]="{'m-loader m-loader--light m-loader--right': loading}">
            Cerrar Caja
        </button>
        <button type="submit" [disabled]="loading" *ngIf="!data.box.opened"
                class="btn btn-success m-btn m-btn--custom"
                [ngClass]="{'m-loader m-loader--light m-loader--right': loading}">
            Abrir Caja
        </button>
    </mat-dialog-actions>
</form>
