import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {webServiceConfig} from '../config/web-service.config';
import {map} from 'rxjs/operators';
import {Util} from '../helpers/util';

@Injectable({
    providedIn: 'root'
})
export class GridUserProductService {

    constructor(private http: HttpClient) {
    }

    update(data: any): Observable<any> {
        return this.http.put(`${webServiceConfig.webServiceUrl}grid-user-product/update`, data)
            .pipe(map(response => {
                return response;
            }));
    }

    getByUser(getCategories = 0): Observable<any> {
        return this.http.get(`${webServiceConfig.webServiceUrl}grid-user-product`, {params: Util.objectToHttpParams({getCategories})})
            .pipe(map(response => {
                return response;
            }));
    }
}
