import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {webServiceConfig} from '../config/web-service.config';
import {map} from 'rxjs/operators';
import {DataTablesResponse} from '../models/data-tables-response';

@Injectable({
    providedIn: 'root'
})
export class BranchService {

    constructor(private http: HttpClient) {
    }

    all(status, is_warehouse, with_locations = null): Observable<any> {
        let stringWareHouse = '';
        if (is_warehouse != null) {
            stringWareHouse += `&is_warehouse=${is_warehouse}`;
        }
        if (with_locations != null) {
            stringWareHouse += `&with_locations=${with_locations}`;
        }
        return this.http.get(`${webServiceConfig.webServiceUrl}branches?status=${status}${stringWareHouse}`).pipe(map(response => {
            return response;
        }));
    }

    getDefault(with_locations = 0): Observable<any> {
        return this.http.get(`${webServiceConfig.webServiceUrl}branches/default?with_locations=${with_locations}`).pipe(map(response => {
            return response;
        }));
    }

    datatable(dataTablesParameters): Observable<any> {
        return this.http.post<DataTablesResponse>(`${webServiceConfig.webServiceUrl}branches/datatable`, dataTablesParameters, {})
            .pipe(map(response => {
                return response;
            }));
    }

    show(id: number): Observable<any> {
        return this.http.get(`${webServiceConfig.webServiceUrl}branches/${id}`).pipe(map(response => {
            return response;
        }));
    }

    store(local: any): Observable<any> {
        return this.http.post(`${webServiceConfig.webServiceUrl}branches`, local)
            .pipe(map(response => {
                return response;
            }));
    }

    update(local: any): Observable<any> {
        return this.http.put(`${webServiceConfig.webServiceUrl}branches/${local.id}`, local)
            .pipe(map(response => {
                return response;
            }));
    }

    delete(id: number): Observable<any> {
        return this.http.delete(`${webServiceConfig.webServiceUrl}branches/${id}`)
            .pipe(map(response => {
                return response;
            }));
    }

    export(): Observable<Blob> {
        return this.http.get(`${webServiceConfig.webServiceUrl}branches/export`, {responseType: 'blob'});
    }
}
