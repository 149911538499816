import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ProductCartModalComponent} from '../product-cart-modal/product-cart-modal.component';
import {MatDialog} from '@angular/material/dialog';
import {Util} from '../../../../helpers/util';
import {ProductService} from '../../../../services/product.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';
import {SaleItemServiceModalComponent} from '../sale-item-service-modal/sale-item-service-modal.component';

@Component({
    selector: 'app-cart-items',
    templateUrl: './cart-items.component.html',
    styleUrls: ['./cart-items.component.scss'],
})
export class CartItemsComponent implements OnInit {

    @Input() currentUser: any;
    @Input() form: any;
    @Input() currentSettings: any;
    @Input() textsBySteps: any;

    @Output() nextStepEvent = new EventEmitter<string>();
    @Output() updateOrderEvent = new EventEmitter<string>();
    withDiscount = false;

    constructor(
        private dialog: MatDialog,
        private toastrService: ToastrService,
        private productService: ProductService,
        private ngxSpinnerService: NgxSpinnerService,
    ) {
    }

    ngOnInit(): void {
        let rolesDiscount = [];
        if (this.currentSettings.discounts_enabled_admin) {
            rolesDiscount.push('admin');
        }
        if (this.currentSettings.discounts_enabled_seller) {
            rolesDiscount.push('seller');
        }
        this.withDiscount = Util.hasAnyRole(this.currentUser.roles, rolesDiscount);
    }

    nextStep(): void {
        this.nextStepEvent.emit();
    }

    updateOrder(): void {
        this.updateOrderEvent.emit();
    }

    deleteItemToSale(i: number): void {
        this.form.items.splice(i, 1);
        this.calculateTotals();
    }

    calculateTotals(): void {
        this.form.subtotal = 0;
        this.form.igv_amount = 0;
        this.form.total = 0;
        if (this.currentSettings.kipu_mode == 'restaurant') {
            this.form.items.forEach(item => {
                let subtotalItem = (item.price - item.discount) * item.quantity;
                item.extras.forEach(extra => {
                    if (extra.quantity) {
                        subtotalItem += extra.price * extra.quantity;
                    }
                });
                if (this.currentSettings.igv_included) {
                    this.form.total += subtotalItem;
                } else {
                    this.form.subtotal += subtotalItem;
                }
            });
        } else {
            this.form.items.forEach(item => {
                let subtotalItem = (item.price - item.discount) * item.quantity;
                if (this.currentSettings.igv_included) {
                    this.form.total += subtotalItem;
                } else {
                    this.form.subtotal += subtotalItem;
                }
            });
        }

        let igvFactor = this.currentSettings.igv_percentage / 100;
        if (this.currentSettings.igv_included) {
            igvFactor = igvFactor + 1;
            this.form.subtotal = this.form.total / igvFactor;
            this.form.igv_amount = this.form.total - this.form.subtotal;
        } else {
            this.form.igv_amount = this.form.subtotal * igvFactor;
            this.form.total = this.form.subtotal + this.form.igv_amount;
        }
    }

    selectProductCart(item: any, i: number): void {
        let dialogRef = null;
        if (this.currentSettings.kipu_mode == 'restaurant') {
            dialogRef = this.dialog.open(ProductCartModalComponent, {
                data: {
                    // item: Object.assign({}, item),
                    item: JSON.parse(JSON.stringify(item)),
                    withDiscount: this.withDiscount,
                    kipuMode: this.currentSettings.kipu_mode,
                    currentSettings: this.currentSettings,
                    form: this.form
                },
                width: '100%'
            });
        } else {
            if (item.result_type == 'service') {
                dialogRef = this.dialog.open(SaleItemServiceModalComponent, {
                    data: {
                        item: JSON.parse(JSON.stringify(item)),
                        index: i
                    },
                    width: '100%'
                });

                dialogRef.afterClosed().subscribe(resp => {
                    if (resp?.item) {
                        this.form.items[resp.index] = resp.item;
                        this.calculateTotals();
                    }
                });
                return;
            }
            if (this.currentSettings.product_location) {
                this.ngxSpinnerService.show();
                this.productService.productAllBranches(item.id_value).subscribe(response => {
                    this.ngxSpinnerService.hide();
                    dialogRef = this.dialog.open(ProductCartModalComponent, {
                        data: {
                            item: JSON.parse(JSON.stringify(item)),
                            withDiscount: this.withDiscount,
                            kipuMode: this.currentSettings.kipu_mode,
                            currentSettings: this.currentSettings,
                            form: this.form,
                            branches_stock: response.data.branches_stock,
                        },
                        width: '50%',
                    });

                    dialogRef.afterClosed().subscribe(resp => {
                        if (resp?.item) {
                            const indexFound = this.form.items.findIndex(itemAdded => itemAdded.id == item.id);
                            if (indexFound >= 0) {
                                this.form.items[indexFound] = resp.item;
                                this.calculateTotals();
                            }
                        }
                    });
                }, error1 => {
                    this.ngxSpinnerService.hide();
                    this.toastrService.error(error1);
                });
                return;
            } else {
                dialogRef = this.dialog.open(ProductCartModalComponent, {
                    data: {
                        // item: Object.assign({}, item),
                        item: JSON.parse(JSON.stringify(item)),
                        withDiscount: this.withDiscount,
                        kipuMode: this.currentSettings.kipu_mode,
                        currentSettings: this.currentSettings,
                        form: this.form
                    },
                    width: '50%'
                });
            }
        }

        dialogRef.afterClosed().subscribe(resp => {
            if (resp?.item) {
                /*if (this.currentSettings.kipu_mode == 'restaurant') {
                    const indexFound = this.form.items.findIndex(itemAdded => itemAdded.id == item.id);
                    if (indexFound >= 0) {
                        this.form.items[indexFound] = resp.item;
                        this.calculateTotals();
                    }
                } else {
                    const indexFound = this.form.items.findIndex(itemAdded => itemAdded.id == item.id);
                    if (indexFound >= 0) {
                        this.form.items[indexFound] = resp.item;
                        this.calculateTotals();
                    }
                }*/
                const indexFound = this.form.items.findIndex(itemAdded => itemAdded.id == item.id);
                if (indexFound >= 0) {
                    this.form.items[indexFound] = resp.item;
                    this.calculateTotals();
                }
            }
        });
    }
}
