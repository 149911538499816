<div class="row">
    <div class="col-md-4 section-payment-method-options">
        <button class="btn btn-secondary btn-payment-method w-100 mb-3"
                [ngClass]="{ 'active': form.payment_method == 1 }"
                (click)="selectPaymentMethod(1)">Efectivo
        </button>
        <button class="btn btn-secondary btn-payment-method w-100 mb-3"
                [ngClass]="{ 'active': form.payment_method == 2}"
                (click)="selectPaymentMethod(2)">
            Tarjeta
        </button>
        <button class="btn btn-secondary btn-payment-method w-100 mb-3"
                [ngClass]="{ 'active': form.payment_method == 4}"
                (click)="selectPaymentMethod(4)">
            Billetera
        </button>
        <button class="btn btn-secondary btn-payment-method w-100 mb-3"
                [ngClass]="{ 'active': form.payment_method == 9}"
                (click)="selectPaymentMethod(9)">
            Transferencia
        </button>
        <button class="btn btn-secondary btn-payment-method w-100 mb-3"
                [ngClass]="{ 'active': form.payment_method == 3}"
                (click)="selectPaymentMethod(3)">
            Combinado
        </button>
    </div>
    <div class="col-md-8">
        <ng-container *ngIf="form.payment_method == 1">
            <h4>Pago con</h4>
            <div class="container-quick-payment">
                <div class="row">
                    <div class="col-sm-4">
                        <button class="btn btn-secondary btn-payment-method w-100 btn-lg mb-4"
                                [ngClass]="{ 'active': form.cashAmountSelected == 10}"
                                [disabled]="10 < this.form.total"
                                (click)="selectAmountPaid('exact_more', 10)">
                            {{10 | currency: form.currency_symbol}}
                        </button>
                    </div>
                    <div class="col-sm-4">
                        <button class="btn btn-secondary btn-payment-method w-100 btn-lg mb-4"
                                [ngClass]="{ 'active': form.cashAmountSelected == 20}"
                                [disabled]="20 < this.form.total"
                                (click)="selectAmountPaid('exact_more', 20)">
                            {{20 | currency: form.currency_symbol}}
                        </button>
                    </div>
                    <div class="col-sm-4">
                        <button class="btn btn-secondary btn-payment-method w-100 btn-lg mb-4"
                                [ngClass]="{ 'active': form.cashAmountSelected == 50}"
                                [disabled]="50 < this.form.total"
                                (click)="selectAmountPaid('exact_more', 50)">
                            {{50 | currency: form.currency_symbol}}
                        </button>
                    </div>
                    <div class="col-sm-4">
                        <button class="btn btn-secondary btn-payment-method w-100 btn-lg mb-4"
                                [ngClass]="{ 'active': form.cashAmountSelected == 100}"
                                [disabled]="100 < this.form.total"
                                (click)="selectAmountPaid('exact_more', 100)">
                            {{100 | currency: form.currency_symbol}}
                        </button>
                    </div>
                    <div class="col-sm-4">
                        <button class="btn btn-secondary btn-payment-method w-100 btn-lg mb-4"
                                [ngClass]="{ 'active': form.cashAmountSelected == 200}"
                                [disabled]="200 < this.form.total"
                                (click)="selectAmountPaid('exact_more', 200)">
                            {{200 | currency: form.currency_symbol}}
                        </button>
                    </div>
                    <div class="col-sm-4">
                        <button class="btn btn-secondary btn-payment-method w-100 btn-lg mb-4"
                                [ngClass]="{ 'active': form.cashAmountSelected == this.form.total}"
                                (click)="selectAmountPaid('exact_more', this.form.total)">
                            Exacto
                        </button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-4">
                        <button class="btn btn-secondary btn-payment-method w-100 btn-lg mb-4"
                                [ngClass]="{ 'active': form.otherAmountCash}"
                                (click)="selectAmountPaid('other')">
                            Otro monto
                        </button>
                    </div>
                    <div class="col-sm-8">
                        <input [disabled]="!form.otherAmountCash" appCurrencyInput
                               [(ngModel)]="form.otherAmountCashValue"
                               (ngModelChange)="calculateCashReturnedOther()"
                               class="form-control m-input form-control-lg" placeholder="S/">
                    </div>
                </div>
            </div>
            <div class="container-quick-payment-return">
                <h4>Vuelto</h4>
                <h2>{{form.cashAmountReturnedSelected | currency: form.currency_symbol}}</h2>
            </div>
        </ng-container>
        <ng-container *ngIf="form.payment_method == 2">
            <div class="container-payment-method">
                <h4>1. Utiliza el POS para recibir pagos con tarjeta.</h4>
                <h4>2. Una vez efectuado el pago darle a emitir comprobante.</h4>
            </div>
        </ng-container>
        <ng-container *ngIf="form.payment_method == 9">
            <div class="container-payment-method">
                <div class="row">
                    <div class="col-sm-4">
                        <div class="form-group">
                            <label class="label-lg label-cash-card">Número de Operación</label>
                        </div>
                    </div>
                    <div class="col-sm-8">
                        <div class="form-group">
                            <input [(ngModel)]="form.operation_number"
                                   class="form-control m-input form-control-lg">
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="form.payment_method == 3">
            <div class="row">
                <div class="col-sm-4">
                    <div class="form-group">
                        <label class="label-lg label-cash-card">Monto en efectivo</label>
                    </div>
                </div>
                <div class="col-sm-8">
                    <div class="form-group">
                        <input [(ngModel)]="form.cash_amount" appCurrencyInput
                               class="form-control m-input form-control-lg" [placeholder]="form.currency_symbol">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-4">
                    <div class="form-group">
                        <label class="label-lg label-cash-card">Monto en tarjeta</label>
                    </div>
                </div>
                <div class="col-sm-8">
                    <div class="form-group">
                        <input [(ngModel)]="form.card_amount" appCurrencyInput
                               class="form-control m-input form-control-lg" [placeholder]="form.currency_symbol">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-4">
                    <div class="form-group">
                        <label class="label-lg label-cash-card">Monto en transferencia</label>
                    </div>
                </div>
                <div class="col-sm-8">
                    <div class="form-group">
                        <input [(ngModel)]="form.wire_transfer_amount" appCurrencyInput
                               class="form-control m-input form-control-lg" [placeholder]="form.currency_symbol">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-4">
                    <div class="form-group">
                        <label class="label-lg label-cash-card">Monto en Yape</label>
                    </div>
                </div>
                <div class="col-sm-8">
                    <div class="form-group">
                        <input [(ngModel)]="form.yape_amount" appCurrencyInput
                               class="form-control m-input form-control-lg" [placeholder]="form.currency_symbol">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-4">
                    <div class="form-group">
                        <label class="label-lg label-cash-card">Monto en Plin</label>
                    </div>
                </div>
                <div class="col-sm-8">
                    <div class="form-group">
                        <input [(ngModel)]="form.plin_amount" appCurrencyInput
                               class="form-control m-input form-control-lg" [placeholder]="form.currency_symbol">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-4">
                    <div class="form-group">
                        <label class="label-lg label-cash-card">Monto en Tunki</label>
                    </div>
                </div>
                <div class="col-sm-8">
                    <div class="form-group">
                        <input [(ngModel)]="form.tunki_amount" appCurrencyInput
                               class="form-control m-input form-control-lg" [placeholder]="form.currency_symbol">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-4">
                    <div class="form-group">
                        <label class="label-lg label-cash-card">Monto en Lukita</label>
                    </div>
                </div>
                <div class="col-sm-8">
                    <div class="form-group">
                        <input [(ngModel)]="form.lukita_amount" appCurrencyInput
                               class="form-control m-input form-control-lg" [placeholder]="form.currency_symbol">
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container
                *ngIf="
                    form.payment_method == 4 ||
                    form.payment_method == 5 ||
                    form.payment_method == 6 ||
                    form.payment_method == 7 ||
                    form.payment_method == 8 ">
            <div class="row">
                <div class="col-md-6">
                    <img src="../../../../../assets/core/images/logos/yape.png"
                         [ngClass]="{ 'active': form.payment_method == 5}"
                         (click)="selectPaymentMethod(5)"
                         class="img-fluid mb-4 img-wallet" alt="Yape">
                </div>
                <div class="col-md-6">
                    <img src="../../../../../assets/core/images/logos/plin.png"
                         [ngClass]="{ 'active': form.payment_method == 6}"
                         (click)="selectPaymentMethod(6)"
                         class="img-fluid mb-4 img-wallet" alt="Plin">
                </div>
                <div class="col-md-6">
                    <img src="../../../../../assets/core/images/logos/tunki.png"
                         [ngClass]="{ 'active': form.payment_method == 7}"
                         (click)="selectPaymentMethod(7)"
                         class="img-fluid mb-4 img-wallet" alt="Tunki">
                </div>
                <div class="col-md-6">
                    <img src="../../../../../assets/core/images/logos/lukita.png"
                         [ngClass]="{ 'active': form.payment_method == 8}"
                         (click)="selectPaymentMethod(8)"
                         class="img-fluid mb-4 img-wallet" alt="Lukita">
                </div>
            </div>
        </ng-container>
    </div>
</div>
