import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
import {MessageService} from '../../../services/message.service';
import {SmallBoxService} from '../../../services/small-box.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {CashMovementModalComponent} from './cash-movement-modal/cash-movement-modal.component';
import {Router} from '@angular/router';

@Component({
    selector: 'app-cash-movements',
    templateUrl: './cash-movements.component.html',
    styleUrls: ['./cash-movements.component.scss']
})
export class CashMovementsComponent implements OnInit {

    smallBox: any = {};

    constructor(
        private router: Router,
        private dialog: MatDialog,
        private toastrService: ToastrService,
        private messageService: MessageService,
        private smallBoxService: SmallBoxService,
        private ngxSpinnerService: NgxSpinnerService,
    ) {
    }

    ngOnInit(): void {
        this.loadSmallBox();
    }

    loadSmallBox(): void {
        this.ngxSpinnerService.show();
        this.smallBoxService.current(1).subscribe(response => {
            this.ngxSpinnerService.hide();
            if (!response.data) {
                this.toastrService.info('Debes abrir caja');
                this.router.navigate(['/small-box/create-close']);
            } else {
                this.smallBox = response.data;
            }
        }, error1 => {
            this.ngxSpinnerService.hide();
            this.toastrService.error(error1);
            this.messageService.error(error1);
        });
    }

    openModalMovement(type: string): void {
        const dialogRef = this.dialog.open(CashMovementModalComponent, {
            data: {type},
            width: '50%'
        });
        dialogRef.afterClosed().subscribe(resp => {
            if (resp) {
                this.loadSmallBox();
            }
        });
    }
}
