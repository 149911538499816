<!-- BEGIN: Subheader -->
<div class="m-subheader ">
    <div class="d-flex align-items-center">
        <div class="mr-auto">
            <h3 class="m-subheader__title m-subheader__title--separator">Nuevo Tipo de Cambio</h3>
            <ul class="m-subheader__breadcrumbs m-nav m-nav--inline">
                <li class="m-nav__item m-nav__item--home">
                    <a [routerLink]="''" class="m-nav__link m-nav__link--icon">
                        <i class="m-nav__link-icon la la-home"></i>
                    </a>
                </li>
                <li class="m-nav__separator">-</li>
                <li class="m-nav__item">
                    <a [routerLink]="'/menus'" class="m-nav__link">
                        <span class="m-nav__link-text">Tipos de Cambio</span>
                    </a>
                </li>
                <li class="m-nav__separator">-</li>
                <li class="m-nav__item">
                    <a [routerLink]="'/menus/create'" class="m-nav__link">
                        <span class="m-nav__link-text">Nuevo Tipo de Cambio</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>

<div class="m-content">
    <div class="row">
        <div class="col-md-12">
            <!--begin::Portlet-->
            <div class="m-portlet m-portlet--mobile">
                <div class="m-portlet__head">
                    <div class="m-portlet__head-caption">
                        <div class="m-portlet__head-title">
                            <h3 class="m-portlet__head-text">
                                Nuevo Tipo de Cambio
                            </h3>

                        </div>
                    </div>
                    <div class="m-portlet__head-tools">
                        <ul class="m-portlet__nav">
                            <li class="m-portlet__nav-item">
                                <a [routerLink]="'/menus'"
                                   class="m-portlet__nav-link btn btn-lg btn-secondary m-btn m-btn--outline-2x m-btn--air m-btn--icon m-btn--icon-only m-btn--pill  m-dropdown__toggle">
                                    <i class="flaticon-reply"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="m-portlet__body">
                    <div class="m-form__section m-form__section--first">
                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">Fecha:</label>
                            <div class="col-lg-6">
                                <input [(ngModel)]="form.date" class="form-control m-input" placeholder="Fecha"
                                       [matDatepicker]="datePicker" (click)="datePicker.open()">
                                <mat-datepicker #datePicker></mat-datepicker>
                            </div>
                        </div>
                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label"></label>
                            <div class="col-lg-6">
                                <div class="table-responsive">
                                    <table class="table table-bordered table-hover">
                                        <thead>
                                        <tr>
                                            <th>Moneda</th>
                                            <th>Venta</th>
                                            <th>Compra</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr *ngFor="let rate of form.rates">
                                            <td>{{rate.currency.name}}</td>
                                            <td>
                                                <input [(ngModel)]="rate.sale" appCurrencyInput class="form-control m-input"
                                                       placeholder="Venta">
                                            </td>
                                            <td>
                                                <input [(ngModel)]="rate.purchase" appCurrencyInput class="form-control m-input"
                                                       placeholder="Compra">
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="m-portlet__foot m-portlet__foot--fit">
                    <div class="m-form__actions">
                        <div class="row">
                            <div class="col-2">
                            </div>
                            <div class="col-7">
                                <button type="button" [disabled]="loading" (click)="sendForm()"
                                        class="btn btn-accent m-btn m-btn--air m-btn--custom my-2"
                                        [ngClass]="{'m-loader m-loader--light m-loader--right': loading}">
                                    Guardar Cambios
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
