<div id="div-pre-venta-general" class="anchoContent p-4">
    <div class="page-header text-center">
        <h2>Ventas</h2>
    </div>
    <div class="row">
        <div class="col-md-7">
            <div class="box">
                <div class="box-body panel-izquierdo-grilla">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="input-group">
                                <input type="text" class="form-control form-control-lg"
                                       placeholder="Busca productos, categorías y promociones..."
                                       id="input-busqueda">
                                <span class="input-group-btn" id="btn-group-input">
                                    <button id="btn-cambio" value="0" class="btn btn-default btn-flat btn-lg">
                                        <i class="fa fa-th"></i>
                                    </button>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div id="row-grilla" class="row row-grilla mt-4">
                        <div class="col-md-3 col-xs-3">
                            <!-- small box -->
                            <div class="small-box producto-cuadro bg-navy">
                                <div class="box-tools pull-right btn-borrar">
                                    <!-- <button type="button" class="btn btn-box-tool btn-default btn-circle">
                                        <i class="fa fa-times fa-2x"></i>
                                    </button> -->
                                </div>

                                <div class="inner">
                                    <p>CERVEZAS</p>
                                    <input type="hidden" value='ghj'>
                                </div>
                                <div class="icon">
                                    <i class="fa"></i>
                                </div>
                                <a href="javascript:void(0)"
                                        class="small-box-footer  ">
                                         
                                </a>
                            </div>
                        </div>
                        <div class="col-md-3 col-xs-3">
                            <!-- small box -->
                            <div class="small-box producto-cuadro bg-info">
                                <div class="box-tools pull-right btn-borrar">
                                    <!-- <button type="button" class="btn btn-box-tool btn-default btn-circle">
                                        <i class="fa fa-times fa-2x"></i>
                                    </button> -->
                                </div>

                                <div class="inner">
                                    <p>VINOS</p>
                                    <input type="hidden" value='ghj'>
                                </div>
                                <div class="icon">
                                    <i class="fa"></i>
                                </div>
                                <!-- <a href="javascript:void(0)"
                                        class="small-box-footer  ">
                                         
                                </a> -->
                            </div>
                        </div>
                        <div class="col-md-3 col-xs-3">
                            <!-- small box -->
                            <div class="small-box producto-cuadro bg-green">
                                <div class="box-tools pull-right btn-borrar">
                                    <!-- <button type="button" class="btn btn-box-tool btn-default btn-circle">
                                        <i class="fa fa-times fa-2x"></i>
                                    </button> -->
                                </div>

                                <div class="inner">
                                    <p>CIGARROS</p>
                                    <input type="hidden" value='ghj'>
                                </div>
                                <div class="icon">
                                    <i class="fa"></i>
                                </div>
                                <a href="javascript:void(0)"
                                        >
                                         
                                </a>
                            </div>
                        </div>
                        <div class="col-md-3 col-xs-3">
                            <!-- small box -->
                            <div class="small-box producto-cuadro bg-white">
                                <div class="box-tools pull-right btn-borrar">
                                    <!-- <button type="button" class="btn btn-box-tool btn-default btn-circle">
                                        <i class="fa fa-times fa-2x"></i>
                                    </button> -->
                                </div>

                                <div class="inner">
                                    <p>Inca Kola</p>
                                    <input type="hidden" value='ghj'>
                                </div>
                                <div class="icon">
                                    <i class="fa"></i>
                                </div>
                                <a href="" class="small-box-footer bg-green py-2 text-white">
                                         S/ 2.50
                                </a>
                            </div>
                        </div>
                        <div class="col-md-3 col-xs-3">
                            <!-- small box -->
                            <div class="small-box producto-cuadro bg-white">
                                <div class="box-tools pull-right btn-borrar">
                                    <!-- <button type="button" class="btn btn-box-tool btn-default btn-circle">
                                        <i class="fa fa-times fa-2x"></i>
                                    </button> -->
                                </div>

                                <div class="inner">
                                    <p>Inca Kola</p>
                                    <input type="hidden" value='ghj'>
                                </div>
                                <div class="icon">
                                    <i class="fa"></i>
                                </div>
                                <a href="" class="small-box-footer bg-orange py-2 text-warning">
                                         S/ 2.50
                                </a>
                            </div>
                        </div>
                        <div class="col-md-3 col-xs-3">
                            <!-- small box -->
                            <div class="small-box producto-cuadro bg-white">
                                <div class="box-tools pull-right btn-borrar">
                                    <!-- <button type="button" class="btn btn-box-tool btn-default btn-circle">
                                        <i class="fa fa-times fa-2x"></i>
                                    </button> -->
                                </div>

                                <div class="inner">
                                    <p>Inca Kola</p>
                                    <input type="hidden" value='ghj'>
                                </div>
                                <div class="icon">
                                    <i class="fa"></i>
                                </div>
                                <a href="" class="small-box-footer bg-warning py-2 text-white">
                                         S/ 2.50
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-5">
            <div class="box">
                <div class="box-sales">
                    <div class="box-header with-border">
                        <div class="d-flex flex-row">
                            <div class="form-group">
                                <select class="form-control select2 select2-hidden-accessible" style="width: 100%;" data-select2-id="1" tabindex="-1" aria-hidden="true">
                                  <option selected="selected" data-select2-id="3">Alabama</option>
                                  <option data-select2-id="54">Alaska</option>
                                  <option data-select2-id="55">California</option>
                                  <option data-select2-id="56">Delaware</option>
                                  <option data-select2-id="57">Tennessee</option>
                                  <option data-select2-id="58">Texas</option>
                                  <option data-select2-id="59">Washington</option>
                                </select><span class="select2 select2-container select2-container--default select2-container--below select2-container--open select2-container--focus" dir="ltr" data-select2-id="2" style="width: 100%;"><span class="selection"><span class="select2-selection select2-selection--single" role="combobox" aria-haspopup="true" aria-expanded="true" tabindex="0" aria-disabled="false" aria-labelledby="select2-mzqf-container" aria-owns="select2-mzqf-results" aria-activedescendant="select2-mzqf-result-2t1q-Texas"><span class="select2-selection__rendered" id="select2-mzqf-container" role="textbox" aria-readonly="true" title="Alabama">Alabama</span><span class="select2-selection__arrow" role="presentation"><b role="presentation"></b></span></span></span><span class="dropdown-wrapper" aria-hidden="true"></span></span>
                            </div>
                            <button class="btn btn-newclient">Nuevo cliente</button>
                        </div>
                        <div class="box-footer">
                            <div class="">
                                <div class="">
                                    <button class="btn btn-descuento d-inline-flex w-100">
                                        <div class="col-md-6 text-left">
                                            <p>Descuento Total</p>
                                        </div>
                                        <div class="col-md-6 text-right">
                                            <p id="p-descuento">-S/.0.00</p>
                                            <p class="hidden" id="p-descuento-oculto">0</p>
                                        </div>
                                    </button>
                                </div>
                                <div class="d-inline-flex w-100">
                                    <div class="col-md-6 text-left">
                                        <p>Subtotal</p>
                                        <p>IGV</p>
                                    </div>
                                    <div class="col-md-6 text-right">
                                        <p id="p-subtotal">S/.0.00</p>
                                        <p id="p-igv">S/.0.00</p>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <button class="btn btn-success btn-block btn-lg">
                                        <span class="pull-left">COBRAR</span>
                                        <span id="span-total-final" class="pull-right">S/.0.00</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>