<div class="m-5">
    <h1 class="text-center">
        Control de asistencia
    </h1>
    <div class="form-group row mb-4">
        <label for="" class="col-sm-4 col-form-label">Código de fotocheck</label>
        <div class="col-sm-6">
          <input type="text" class="form-control" id="">
        </div>
    </div>
</div>