<h1 mat-dialog-title>
    Añadir Servicio
</h1>
<mat-dialog-content>
    <form [formGroup]="form" (ngSubmit)="sendForm()">
        <div class="row">
            <div class="col-md-3">
                <div class="form-group">
                    <label class="label-lg">Nombre</label>
                    <input type="text" formControlName="name" placeholder="Nombre" maxlength="255"
                           [ngClass]="{ 'is-invalid': submitted && f.name.errors }"
                           class="form-control form-control-lg">
                    <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                        <div *ngIf="f.name.errors.required">Nombre obligatorio</div>
                        <div *ngIf="f.name.errors.maxLength">Solo puede contener 255 caracteres</div>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label class="label-lg">Cantidad</label>
                    <input type="number" formControlName="quantity" min="1" placeholder="Cantidad"
                           [ngClass]="{ 'is-invalid': submitted && f.quantity.errors }"
                           class="form-control form-control-lg">
                    <div *ngIf="submitted && f.quantity.errors" class="invalid-feedback">
                        <div *ngIf="f.quantity.errors.required">Cantidad obligatorio</div>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label class="label-lg">Precio</label>
                    <input type="text" formControlName="price" placeholder="Precio" appCurrencyInput
                           [ngClass]="{ 'is-invalid': submitted && f.price.errors }"
                           class="form-control form-control-lg">
                    <div *ngIf="submitted && f.price.errors" class="invalid-feedback">
                        <div *ngIf="f.price.errors.required">Precio obligatorio</div>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label class="label-lg">Costo</label>
                    <input type="text" formControlName="cost" placeholder="Costo" appCurrencyInput
                           [ngClass]="{ 'is-invalid': submitted && f.cost.errors }"
                           class="form-control form-control-lg">
                    <div *ngIf="submitted && f.cost.errors" class="invalid-feedback">
                        <div *ngIf="f.cost.errors.required">Costo obligatorio</div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="form-group text-center">
                    <button class="btn btn-success btn-lg" type="submit">Continuar</button>
                </div>
            </div>
        </div>
    </form>
</mat-dialog-content>
