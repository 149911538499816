import {Component, OnInit, ViewChild} from '@angular/core';
import {DataTableDirective} from 'angular-datatables';
import {first} from 'rxjs/operators';
import {CustomerService} from '../../services/customer.service';
import * as FileSaver from 'file-saver';
import {ToastrService} from 'ngx-toastr';

declare var swal: any;

@Component({
    selector: 'app-clients',
    templateUrl: './customers.component.html',
    styleUrls: ['./customers.component.scss']
})
export class CustomersComponent implements OnInit {

    dtOptions: DataTables.Settings = {};
    clients: any[];
    @ViewChild(DataTableDirective) private dtElement;
    exporting = false;

    constructor(
        private toastrService: ToastrService,
        private customerService: CustomerService
    ) {
    }

    ngOnInit(): void {
        this.dtOptions = {
            pagingType: 'full_numbers',
            pageLength: 10,
            serverSide: true,
            processing: true,
            ajax: (dataTablesParameters: any, callback) => {
                this.customerService.datatable(dataTablesParameters)
                    .pipe(first())
                    .subscribe(response => {
                        this.clients = response.data;
                        callback({
                            recordsTotal: response.recordsTotal,
                            recordsFiltered: response.recordsFiltered,
                            data: []
                        });
                    });
            },
            columns: [
                {data: 'id'},
                {data: 'name'},
                {data: 'document_type_text'},
                {data: 'document_number'},
                {data: 'address'},
                {data: 'phone'},
                {data: 'email'},
                {data: 'status'},
            ]
        };

    }

    private async reloadTable() {
        let dtInstance = await this.dtElement.dtInstance;
        dtInstance.ajax.reload();
    }

    delete(client: any): void {
        swal.fire({
            title: '¿Estás seguro que deseas eliminar este registro?',
            showCancelButton: true,
            confirmButtonText: 'Si, elimínalo!',
            showLoaderOnConfirm: true,
            preConfirm: (arg) => {
                return this.customerService.delete(client.id)
                    .pipe(first())
                    .subscribe(response => {
                        swal.fire({
                            title: 'Elemento eliminado con éxito',
                            type: 'success'
                        });
                        this.reloadTable();
                    }, error => {
                        swal.fire({
                            title: error,
                            type: 'error'
                        });
                        return error;
                    });
            },
            allowOutsideClick: () => !swal.isLoading()
        }).then((result) => {
        });
    }

    exportList(): void {
        this.exporting = true;
        this.customerService.export().subscribe(blob => {
            this.exporting = false;
            FileSaver.saveAs(blob, 'Clientes.xlsx');
        }, error1 => {
            this.exporting = false;
            this.toastrService.error(error1);
        });
    }
}
