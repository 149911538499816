<div [hidden]="form.currentStep == 4" class="m-content py-0" #contentSale>
    <div class="row">
        <div class="col-md-8">
            <div class="container-sale-left">
                <div [hidden]="form.currentStep != 1">
                    <h3 class="m-portlet__head-text title">Venta Rápida</h3>
                    <div class="m-portlet__body">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="m-portlet m-portlet--mobile m-portlet-no-default-styles">
                                    <div class="m-portlet__body">
                                        <div class="form-group mb-4">
                                            <!--<ng-select2
                                                    [(ngModel)]="searchText"
                                                    #select2Search
                                                    class="input-lg input-sale"
                                                    [options]="optionsSelect2">
                                            </ng-select2>-->
                                            <input type="text" class="form-control m-input form-control-lg"
                                                   placeholder="Busca productos" [(ngModel)]="searchText"
                                                   (keydown.enter)="searchProductByCode()" (ngModelChange)="searchProducts()">
                                        </div>
                                        <div class="mb-4">
                                            <div class="filters">
                                                <span class="text-filters">Filtros:</span>
                                                <!--<button class="btn btn-info-kipu"
                                                         [ngClass]="{ 'active': favoritesSelected }">
                                                     Favoritos
                                                 </button>-->
                                                <button class="btn btn-info-kipu" (click)="selectPromotions()"
                                                        [ngClass]="{ 'active': promotionsSelected }">
                                                    Promociones
                                                </button>
                                                <button *ngFor="let category of categories"
                                                        (click)="selectCategory(category)"
                                                        [ngClass]="{ 'active': category.id == categorySelected?.id }"
                                                        class="btn btn-info-kipu">{{category.name}}</button>
                                            </div>
                                        </div>
                                        <div class="mb-4">
                                            <div class="container-items-sale">
                                                <div class="row">
                                                    <ng-container *ngIf="currentSettings.sale_of_services">
                                                        <div class="col-md-3 col-sm-3">
                                                            <div class="item-card item-card-service"
                                                                 (click)="addService()">
                                                                <div class="item-card-content">
                                                                    <div class="item-card-img item-card-img-service">
                                                                        <i class="la la-cog"></i>
                                                                    </div>
                                                                    <div class="item-card-body item-card-body-service">
                                                                        <p>Añadir Mano de Obra / Servicio</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ng-container>

                                                    <div *ngFor="let item of grid" class="col-md-3 col-sm-3">
                                                        <div class="item-card" (click)="selectItemSearch(item)">
                                                            <div class="item-card-content">
                                                                <div class="item-card-img">
                                                                    <button (click)="showDetail(item)"
                                                                            class="m-portlet__nav-link btn m-btn m-btn--hover-accent m-btn--icon m-btn--icon-only m-btn--pill btn-show"
                                                                            title="Ver Detalle">
                                                                        <i class="flaticon-eye"></i>
                                                                    </button>
                                                                    <img
                                                                        [defaultImage]="'/assets/core/images/icons/product-icon.jpg'"
                                                                        [lazyLoad]="item.photo_url" class="img-item"
                                                                        [alt]="item.name">
                                                                </div>
                                                                <div class="item-card-body">
                                                                    <p class="price">{{item.price | currency: item.currency_symbol}}</p>
                                                                    <p class="name">{{item.name}}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="form.currentStep == 2">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="float-left">
                                <h3 class="m-portlet__head-text title">
                                    <a (click)="prevStep()"
                                       class="m-portlet__nav-link btn btn-lg btn-secondary btn-back m-btn m-btn--outline-2x m-btn--icon m-btn--icon-only m-btn--pill">
                                        <i class="la la-angle-left"></i>
                                    </a>
                                    Comprobante de Venta
                                </h3>
                            </div>
                            <div class="float-right">
                                <button class="btn btn-secondary btn-lg btn-voucher-type mx-2"
                                        (click)="setVoucherType(0)"
                                        [ngClass]="{ 'active': form.voucher_type == 0 }">Recaudación
                                </button>
                                <button class="btn btn-secondary btn-lg btn-voucher-type mx-2"
                                        (click)="setVoucherType(2)"
                                        [ngClass]="{ 'active': form.voucher_type == 2 }">Boleta
                                </button>
                                <button class="btn btn-secondary btn-lg btn-voucher-type mx-2"
                                        (click)="setVoucherType(1)"
                                        [ngClass]="{ 'active': form.voucher_type == 1 }">Factura
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="m-portlet__body pt-4">
                        <div class="row">
                            <div class="col-md-12">
                                <div
                                    class="m-portlet m-portlet--mobile m-portlet-no-default-styles m-portlet-no-default-styles-customer">
                                    <ng-container *ngIf="form.voucher_type == 0 || form.voucher_type == 2">
                                        <div class="m-portlet__body">
                                            <div class="from-group">
                                                <div class="m-radio-inline">
                                                    <label class="m-radio m-radio--solid m-radio--info m-radio--kipu ">
                                                        <input type="radio" [(ngModel)]="inDataCustomer"
                                                               [disabled]="disabledDefaultCustomer"
                                                               (ngModelChange)="setCustomer()"
                                                               [value]="true"> Boleta Simple
                                                        <span></span>
                                                    </label>
                                                    <label class="m-radio m-radio--solid m-radio--info m-radio--kipu">
                                                        <input type="radio" [(ngModel)]="inDataCustomer"
                                                               (ngModelChange)="setCustomer()"
                                                               [value]="false"> Boleta con DNI
                                                        <span></span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <app-customer-sale-fragment
                                                        [allInputsDisabled]="inDataCustomer"
                                                        [documentTypes]="documentTypesTicket"
                                                        [customer]="form.customer"
                                                        [submitted]="submittedCustomer"
                                                    ></app-customer-sale-fragment>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="form.voucher_type == 1">
                                        <div class="m-portlet__body">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <app-customer-sale-fragment
                                                        [customer]="form.customer"
                                                        [submitted]="submittedCustomer"
                                                        [documentTypes]="documentTypesRuc"
                                                    ></app-customer-sale-fragment>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="form.currentStep == 3">
                    <h3 class="m-portlet__head-text title">
                        <a (click)="prevStep()"
                           class="m-portlet__nav-link btn btn-lg btn-secondary btn-back m-btn m-btn--outline-2x m-btn--icon m-btn--icon-only m-btn--pill">
                            <i class="la la-angle-left"></i>
                        </a>
                        Método de pago
                    </h3>
                    <div class="m-portlet__body pt-4">
                        <app-sale-payment-method [form]="form"></app-sale-payment-method>
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="col-md-4 section-products">
            <div class="container-sale-right">
                <app-cart-items #cartItems *ngIf="form.currency_id"
                                [currentUser]="currentUser" [form]="form" [currentSettings]="currentSettings"
                                [textsBySteps]="textsBySteps" (nextStepEvent)="nextStep()">
                </app-cart-items>
            </div>
        </div>
    </div>
</div>
<ng-container *ngIf="form.currentStep == 4" class="m-content">
    <app-sale-result [saleCreated]="saleCreated" [businessDataVoucher]="businessDataVoucher" (resetFormEvent)="resetForm()"></app-sale-result>
</ng-container>
