import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SettingService} from '../../../services/setting.service';
import {Setting} from '../../../models/setting';

@Component({
    selector: 'app-product-detail',
    templateUrl: './product-detail.component.html',
    styleUrls: ['./product-detail.component.scss']
})
export class ProductDetailComponent implements OnInit {

    currentSettings: Setting;

    constructor(
        private dialogRef: MatDialogRef<ProductDetailComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private settingService: SettingService,
    ) {
    }

    ngOnInit(): void {
        this.currentSettings = this.settingService.currentSettingsValue;
    }

}
