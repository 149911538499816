import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BranchService} from '../../../services/branch.service';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute, Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {first} from 'rxjs/operators';
import {LocationService} from '../../../services/location.service';
import {Select2OptionData} from 'ng-select2';

@Component({
    selector: 'app-location-add-edit',
    templateUrl: './location-add-edit.component.html',
    styleUrls: ['./location-add-edit.component.scss']
})
export class LocationAddEditComponent implements OnInit {

    form: FormGroup;
    item: any = {};
    submitted = false;
    loading = false;
    id: number;
    branches: Array<Select2OptionData> = [];
    countRequests = 0;
    maxCountRequests = 1;

    constructor(
        private router: Router,
        private formBuilder: FormBuilder,
        private spinner: NgxSpinnerService,
        private branchService: BranchService,
        private toastrService: ToastrService,
        private activatedRoute: ActivatedRoute,
        private locationService: LocationService,
    ) {
    }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            branch_id: [null, Validators.required],
            name: ['', Validators.required],
        });
        this.id = this.activatedRoute.snapshot.params['id'];
        if (this.id) {
            this.maxCountRequests = 2;
            this.spinner.show();
            this.locationService.show(this.id).pipe(first()).subscribe(response => {
                this.form.patchValue(response.data);
                this.item = response.data;
                this.countRequests++;
                if (this.maxCountRequests == this.countRequests) {
                    this.spinner.hide();
                }
            }, error1 => {
                this.spinner.hide();
                this.router.navigate(['/']);
            });
        }
        this.loadData();
    }

    get f() {
        return this.form.controls;
    }

    loadData(): void {
        this.branchService.all(1, null).pipe(first()).subscribe(response => {
            this.branches = response.data.map(item => {
                return {...item, ...{text: item.name}};
            });
            this.countRequests++;
            if (this.maxCountRequests == this.countRequests) {
                this.spinner.hide();
            }
        }, error1 => {
            this.spinner.hide();
            this.toastrService.error(error1, 'Error');
        });

    }

    sendForm(): void {
        this.submitted = true;
        if (!this.form.valid) {
            return;
        }
        this.loading = true;
        let item = Object.assign({}, this.form.value);
        if (this.id != null) {
            item.id = this.id;
            this.locationService.update(item).pipe(first()).subscribe(response => {
                this.loading = false;
                this.toastrService.success(response.message, 'Éxito!');
                this.router.navigate(['/locations']);
            }, error => {
                this.toastrService.error(error);
                this.loading = false;
            });
        } else {
            this.locationService.store(item).pipe(first()).subscribe(response => {
                this.loading = false;
                this.toastrService.success(response.message, 'Éxito!');
                this.router.navigate(['/locations']);
            }, error => {
                this.toastrService.error(error);
                this.loading = false;
            });
        }
    }
}
