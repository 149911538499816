<h1 mat-dialog-title>
    Selecciona una moneda
</h1>
<mat-dialog-content>
    <div class="row">
        <div *ngFor="let currency of data.setting_currencies" class="col-md-4 mx-auto">
            <div class="text-center">
                <a (click)="selectCurrency(currency)" class="btn btn-outline-success btn-lg m-btn m-btn--icon">
                    <span>
                        {{currency.currency.name}} ({{currency.currency.alphabetic_code}})
                    </span>
                </a>
            </div>
        </div>
    </div>
    <div class="row mt-5">
        <div class="col-md-12 text-center">
            <a (click)="toDashboard()" class="btn btn-outline-primary btn-lg m-btn">Ir al Dashboard</a>
        </div>
    </div>
</mat-dialog-content>
