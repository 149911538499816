import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CategoryService} from '../../../services/category.service';
import {ActivatedRoute, Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {first} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {Setting} from '../../../models/setting';
import {SettingService} from '../../../services/setting.service';
import {ResourceService} from '../../../services/resource.service';
import {MenuService} from '../../../services/menu.service';
import {Select2OptionData} from 'ng-select2';

@Component({
    selector: 'app-category-add-edit',
    templateUrl: './category-add-edit.component.html',
    styleUrls: ['./category-add-edit.component.scss']
})
export class CategoryAddEditComponent implements OnInit {

    form: FormGroup;
    category: any = {};
    submitted = false;
    loading = false;
    id: number;
    currentSettings: Setting = new Setting();
    maxCounterRequest = 1;
    counterRequest = 0;
    menus: Array<Select2OptionData> = [];

    constructor(
        private router: Router,
        private formBuilder: FormBuilder,
        private menuService: MenuService,
        private spinner: NgxSpinnerService,
        private toastrService: ToastrService,
        private settingService: SettingService,
        private activatedRoute: ActivatedRoute,
        private resourceService: ResourceService,
        private categoryService: CategoryService,
    ) {
    }

    ngOnInit(): void {
        this.currentSettings = this.settingService.currentSettingsValue;
        this.id = this.activatedRoute.snapshot.params['id'];
        if (this.currentSettings.kipu_mode == 'restaurant') {
            if (this.id) {
                this.maxCounterRequest = 2;
            }
            this.spinner.show();
            this.menuService.all().subscribe(response => {
                this.counterRequest++;
                this.menus = response.data.map(item => {
                    return {...item, ...{text: item.name}};
                });
                if (this.counterRequest == this.maxCounterRequest) {
                    this.spinner.hide();
                }
            }, error1 => {
                this.spinner.hide();
                this.toastrService.error(error1);
            });

            this.form = this.formBuilder.group({
                menu_id: [null, Validators.required],
                name: ['', Validators.required],
                description: [''],
                status: ['1', Validators.required],
            });
        } else {
            this.form = this.formBuilder.group({
                name: ['', Validators.required],
                description: [''],
                status: ['1', Validators.required],
            });
        }

        if (this.id) {
            this.spinner.show();
            this.categoryService.show(this.id).pipe(first()).subscribe(response => {
                this.form.patchValue(response.data);
                this.category = response.data;
                this.counterRequest++;
                if (this.counterRequest == this.maxCounterRequest) {
                    this.spinner.hide();
                }
            }, error1 => {
                this.spinner.hide();
                this.router.navigate(['/']);
            });
        }
    }

    get f() {
        return this.form.controls;
    }

    /*selectClass(classColor): void {
        this.f.class_color.setValue(classColor);
    }*/

    sendForm(): void {
        this.submitted = true;
        if (!this.form.valid) {
            return;
        }
        this.loading = true;
        let category = Object.assign({}, this.form.value);
        if (this.id != null) {
            category.id = this.id;
            this.categoryService.update(category).subscribe(response => {
                this.loading = false;
                this.toastrService.success(response.message, 'Éxito!');
                this.router.navigate(['/categories']);
            }, error => {
                this.toastrService.error(error);
                this.loading = false;
            });
        } else {
            this.categoryService.store(category).subscribe(response => {
                this.loading = false;
                this.toastrService.success(response.message, 'Éxito!');
                this.router.navigate(['/categories']);
            }, error => {
                this.toastrService.error(error);
                this.loading = false;
            });
        }
    }
}
