<div class="m-5">
    <h1 class="text-center">Inventario</h1>
    <div class="d-flex flex-row align-items-center justify-content-between">
        <h3>Seleccione una fecha</h3>
        <button class="btn bg-green">Exportar en excel</button>
    </div>
    <div class="p-3 mt-4 bg-white">
        <table class="table table-bordered bg-white">
            <thead>
              <tr>
                <th>Código</th>
                <th>Nombre</th>
                <th>escripción</th>
                <th>Stock</th>
                <th>Valorización E-106/107</th>
                <th>Valorización Total</th>
              </tr>
            </thead>
            <tbody>
            </tbody>
        </table>
    </div>
</div>