<div class="m-5">
    <h1 class="text-center">Reporte de asistencia</h1>
    <div class="d-flex flex-row align-items-center justify-content-between">
        <h3>Seleccione una fecha</h3>
        <button class="btn bg-green">Exportar en excel</button>
    </div>
    <form action="">
        <div class="col-md-6">
            <div class="form-group row mb-4">
                <div class="input-group date col-sm-8" id="reservationdate" data-target-input="nearest">
                    <input type="text" class="form-control datetimepicker-input" data-target="#reservationdate">
                    <div class="input-group-append" data-target="#reservationdate" data-toggle="datetimepicker">
                        <div class="input-group-text"><i class="fa fa-calendar"></i></div>
                    </div>
                </div>
                <button class="btn btn-primary">Buscar</button>
            </div>
        </div>
    </form>
    <div class="p-3 bg-white">
        <table class="table table-bordered bg-white">
            <thead>
              <tr>
                <th>Ingreso</th>
                <th>Nombre</th>
                <th>DNI</th>
                <th>Salida</th>
              </tr>
            </thead>
            <tbody>
            </tbody>
        </table>
    </div>
</div>
