<!-- BEGIN: Subheader -->
<div class="m-subheader ">
    <div class="d-flex align-items-center">
        <div class="mr-auto">
            <h3 class="m-subheader__title m-subheader__title--separator">Reporte de Ventas</h3>
            <ul class="m-subheader__breadcrumbs m-nav m-nav--inline">
                <li class="m-nav__item m-nav__item--home">
                    <a [routerLink]="''" class="m-nav__link m-nav__link--icon">
                        <i class="m-nav__link-icon la la-home"></i>
                    </a>
                </li>
                <li class="m-nav__separator">-</li>
                <li class="m-nav__item">
                    <a [routerLink]="'/reports/sales'" class="m-nav__link">
                        <span class="m-nav__link-text">Reporte de Ventas</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>

<div class="m-content">
    <div class="row">
        <div class="col-md-12">
            <!--begin::Portlet-->
            <div class="m-portlet m-portlet--mobile">
                <div class="m-portlet__head">
                    <div class="m-portlet__head-caption">
                        <div class="m-portlet__head-title">
                            <h3 class="m-portlet__head-text">
                                Reporte de Ventas
                            </h3>
                        </div>
                    </div>
                    <div class="m-portlet__head-tools">
                        <ul class="m-portlet__nav">
                            <li class="m-portlet__nav-item">
                                <button (click)="exportListByProducts()"
                                        [disabled]="exportingProducts"
                                        [ngClass]="{'m-loader m-loader--light m-loader--right': exportingProducts}"
                                        class="btn btn-success m-btn m-btn--pill m-btn--custom m-btn--icon m-btn--air">
                                    <span>
                                       <i class="la la-file-excel-o"></i>
                                        <span>Exportar por productos</span>
                                    </span>
                                </button>
                            </li>
                            <li class="m-portlet__nav-item">
                                <button (click)="exportList()"
                                        [disabled]="exporting"
                                        [ngClass]="{'m-loader m-loader--light m-loader--right': exporting}"
                                        class="btn btn-success m-btn m-btn--pill m-btn--custom m-btn--icon m-btn--air">
                                    <span>
                                       <i class="la la-file-excel-o"></i>
                                        <span>Exportar</span>
                                    </span>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="m-portlet__body">
                    <div class="m-form__section m-form__section--first">
                        <div class="row">
                            <!--<div class="col-md-3">
                                <div class="form-group">
                                    <label>N° de Documento del Cliente</label>

                                </div>
                            </div>-->
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label>Buscar desde:</label>
                                    <input [(ngModel)]="form.from_date" class="form-control m-input" placeholder="Desde"
                                           [matDatepicker]="picker" (click)="picker.open()"
                                           (ngModelChange)="reloadTable()">
                                    <mat-datepicker #picker></mat-datepicker>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label>Buscar hasta:</label>
                                    <input [(ngModel)]="form.to_date" class="form-control m-input" placeholder="Hasta"
                                           [matDatepicker]="toPicker" (click)="toPicker.open()"
                                           (ngModelChange)="reloadTable()">
                                    <mat-datepicker #toPicker></mat-datepicker>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label>Sucursal:</label>
                                    <ng-select2
                                            [data]="branches"
                                            [width]="'100%'"
                                            [placeholder]="'Selecciona una sucurlsa'"
                                            [(ngModel)]="form.branch_id" (ngModelChange)="onSelectBranchId()">
                                    </ng-select2>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label>Caja:</label>
                                    <ng-select2
                                            [data]="branchBoxes"
                                            [width]="'100%'"
                                            [placeholder]="'Selecciona una caja'"
                                            [(ngModel)]="form.branch_box_id" (ngModelChange)="reloadTable()">
                                    </ng-select2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="table-responsive">
                                <table datatable [dtOptions]="dtOptions"
                                       class="table table-striped- table-bordered table-hover">
                                    <thead>
                                    <tr>
                                        <th>Fecha</th>
                                        <th>Vendedor</th>
                                        <th>Nombre de Cliente</th>
                                        <th>N° Documento de Cliente</th>
                                        <th>Sucursal</th>
                                        <th>Método de Pago</th>
                                        <th>Tipo de Comprobante</th>
                                        <th>N° Comprobante</th>
                                        <th>Total</th>
                                        <th>Estado</th>
                                        <th>¿Es Comprobante Electrónico?</th>
                                        <th>Acciones</th>
                                    </tr>
                                    </thead>
                                    <tbody *ngIf="items?.length != 0">
                                    <tr *ngFor="let item of items">
                                        <td>{{ item.created_at | date:'dd/MM/yyyy' }}</td>
                                        <td>{{item.seller_name}}</td>
                                        <td>{{item.customer_name}}</td>
                                        <td>{{item.customer_document_number}}</td>
                                        <td>{{item.branch_name}}</td>
                                        <td>{{item.payment_method_text}}</td>
                                        <td>{{item.voucher_type_text}}</td>
                                        <td>{{item.voucher_number}}</td>
                                        <td>{{item.total | currency: item.currency_symbol}}</td>
                                        <td>{{item.status_text}}</td>
                                        <td>{{item.electronic_voucher ? 'Si' : 'No'}}</td>
                                        <td>
                                            <ng-container *ngIf="item.electronic_voucher">
                                                <ng-container *ngIf="item.status == 1">
                                                    <a [href]="item.pdf_url" target="_blank"
                                                       class="m-portlet__nav-link btn m-btn m-btn--hover-danger m-btn--icon m-btn--icon-only m-btn--pill"
                                                       title="Descargar PDF">
                                                        <i class="la la-file-pdf-o"></i>
                                                    </a>
                                                    <a [href]="item.xml_url"
                                                       [download]="item.serial+'-'+item.correlative + '.xml'"
                                                       style="line-height: 2.25;"
                                                       class="m-portlet__nav-link btn m-btn m-btn--hover-warning m-btn--icon m-btn--icon-only m-btn--pill"
                                                       title="Descargar XML">XML</a>
                                                    <button class="m-portlet__nav-link btn m-btn m-btn--hover-danger m-btn--icon m-btn--icon-only m-btn--pill"
                                                            (click)="cancelSale(item)" title="Anular Factura">
                                                        <i class="la la-close"></i>
                                                    </button>
                                                </ng-container>
                                                <ng-container *ngIf="item.status == 2">
                                                    <a [href]="item.cancel_pdf_url" target="_blank"
                                                       class="m-portlet__nav-link btn m-btn m-btn--hover-danger m-btn--icon m-btn--icon-only m-btn--pill"
                                                       title="Descargar PDF">
                                                        <i class="la la-file-pdf-o"></i>
                                                    </a>
                                                    <a [href]="item.cancel_xml_url"
                                                       [download]="item.serial+'-'+item.correlative + '.xml'"
                                                       style="line-height: 2.25;"
                                                       class="m-portlet__nav-link btn m-btn m-btn--hover-warning m-btn--icon m-btn--icon-only m-btn--pill"
                                                       title="Descargar XML">XML</a>
                                                </ng-container>
                                            </ng-container>
                                            <ng-container *ngIf="item.status == 1">
                                                <a *ngIf="item.pdf_url" [href]="item.pdf_url" target="_blank"
                                                   class="m-portlet__nav-link btn m-btn m-btn--hover-danger m-btn--icon m-btn--icon-only m-btn--pill"
                                                   title="Descargar PDF">
                                                    <i class="la la-file-pdf-o"></i>
                                                </a>
                                            </ng-container>
                                            <ng-container *ngIf="!item.electronic_voucher && item.status == 1">
                                                <button class="m-portlet__nav-link btn m-btn m-btn--hover-danger m-btn--icon m-btn--icon-only m-btn--pill"
                                                        (click)="cancelSale(item)" title="Anular Factura">
                                                    <i class="la la-close"></i>
                                                </button>
                                            </ng-container>
                                            <button (click)="showDetail(item)"
                                                    class="m-portlet__nav-link btn m-btn m-btn--hover-accent m-btn--icon m-btn--icon-only m-btn--pill"
                                                    title="Ver Detalle">
                                                <i class="flaticon-eye"></i>
                                            </button>
                                        </td>
                                    </tr>
                                    </tbody>
                                    <tbody *ngIf="items?.length == 0">
                                    <tr>
                                        <td colspan="12" class="no-data-available">No data!</td>
                                    </tr>
                                    <tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

