import {Component, OnInit, ViewChild} from '@angular/core';
import {UserService} from '../../services/user.service';
import {DataTableDirective} from 'angular-datatables';
import {first} from 'rxjs/operators';

@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

    dtOptions: DataTables.Settings = {};
    users: any[];
    @ViewChild(DataTableDirective) private dtElement;

    constructor(
        private userService: UserService
    ) {
    }

    ngOnInit(): void {
        this.dtOptions = {
            pagingType: 'full_numbers',
            pageLength: 10,
            serverSide: true,
            processing: true,
            ajax: (dataTablesParameters: any, callback) => {
                this.userService.datatable(dataTablesParameters)
                    .pipe(first())
                    .subscribe(response => {
                        this.users = response.data;
                        callback({
                            recordsTotal: response.recordsTotal,
                            recordsFiltered: response.recordsFiltered,
                            data: []
                        });
                    });
            },
            columns: [
                {data: 'name', name: 'name'},
                {data: 'email', name: 'email'},
                {data: 'photo_url', name: 'photo_url', orderable: false, searchable: false},
                {data: 'status', name: 'status'},
            ]
        };
    }

}
