import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {NgxSpinnerService} from 'ngx-spinner';
import {SalonService} from '../../../../services/salon.service';
import {ToastrService} from 'ngx-toastr';
import {MenuService} from '../../../../services/menu.service';
import {Setting} from '../../../../models/setting';
import {SettingService} from '../../../../services/setting.service';
import {AuthenticationService} from '../../../../services/authentication.service';
import {User} from '../../../../models/user';
import {webServiceConfig} from '../../../../config/web-service.config';
import {Router} from '@angular/router';
import {SaleService} from '../../../../services/sale.service';
import {CustomerService} from '../../../../services/customer.service';
import {first} from 'rxjs/operators';
import {MessageService} from '../../../../services/message.service';
import {SaleSelectCurrencyModalComponent} from '../sale-select-currency-modal/sale-select-currency-modal.component';
import {MatDialog} from '@angular/material/dialog';

declare var swal: any;

@Component({
    selector: 'app-sale-create-restaurant',
    templateUrl: './sale-create-restaurant.component.html',
    styleUrls: ['./sale-create-restaurant.component.scss']
})
export class SaleCreateRestaurantComponent implements OnInit {

    maxCountRequests = 3;
    countRequests = 0;
    salons = [];
    menus = [];

    form = {
        id: null,
        salon_id: null,
        salon_obj: null,
        salon_table_id: null,
        salon_table_obj: null,
        menu_obj: null,
        category_obj: null,
        items: [],
        subtotal: 0,
        igv_amount: 0,
        total: 0,
        currentStep: 1,
        from_device: 1,
        status: null,
        payment_method: 1,
        voucher_type: 2,
        cash_paid: null,
        cash_returned: null,
        customer: null,
        customer_id: null,

        //Combined
        cash_amount: 0,
        card_amount: 0,
        yape_amount: 0,
        plin_amount: 0,
        tunki_amount: 0,
        lukita_amount: 0,


        //Payment Method Component
        //Cash
        cashAmountSelected: 0,
        cashAmountReturnedSelected: 0,
        otherAmountCash: false,
        otherAmountCashValue: null,

        //currency
        currency_id: null,
        currency_alphabetic_code: null,
        currency_numeric_code: null,
        currency_name: null,
        currency_symbol: null,
    };

    inDataCustomer = true;
    defaultCustomer = null;
    disabledDefaultCustomer = false;
    submittedCustomer = false;
    saleCreated = null;

    documentTypesTicket = {
        1: 'DNI',
        6: 'RUC',
        4: 'CARNET DE EXTRANJERÍA',
        7: 'PASAPORTE',
        A: 'CÉDULA DIPLOMÁTICA DE IDENTIDAD',
    };
    documentTypesRuc = {
        6: 'RUC',
    };

    currentUser: User;
    currentSettings: Setting;
    @ViewChild('cartItems') cartItems: ElementRef;

    textsBySteps: any = {
        1: 'Continuar con la compra',
        2: 'Pedir',
        3: 'Emitir comprobante',
    };

    constructor(
        private router: Router,
        private dialog: MatDialog,
        private saleService: SaleService,
        private menuService: MenuService,
        private salonService: SalonService,
        private toastrService: ToastrService,
        private messageService: MessageService,
        private settingService: SettingService,
        private customerService: CustomerService,
        private ngxSpinnerService: NgxSpinnerService,
        private authenticationService: AuthenticationService,
    ) {
    }

    ngOnInit(): void {
        this.settingService.currentSettingsSubject.asObservable().subscribe((settings: Setting) => {
            this.currentSettings = settings;
        });
        this.currentUser = this.authenticationService.currentUserValue;
        this.ngxSpinnerService.show();

        this.loadSalonsData();
        this.loadCurrencies();

        this.menuService.all(1, 1).subscribe(response => {
            this.countRequests++;
            if (this.countRequests == this.maxCountRequests) {
                this.ngxSpinnerService.hide();
            }
            this.menus = response.data;
        }, error1 => {
            this.ngxSpinnerService.hide();
            this.toastrService.error(error1);
        });

        this.customerService.getDefault().subscribe(response => {
            this.countRequests++;
            if (this.countRequests == this.maxCountRequests) {
                this.ngxSpinnerService.hide();
            }
            this.defaultCustomer = response.data;
            this.setDefaultCustomerSelected();
        }, error1 => {
            this.ngxSpinnerService.hide();
            this.toastrService.error(error1);
        });
    }

    loadSalonsData(showSpinner = false): void {
        if (showSpinner) {
            this.ngxSpinnerService.show();
        }
        this.salonService.all(1, 1).subscribe(response => {
            this.countRequests++;

            if (showSpinner) {
                this.ngxSpinnerService.hide();
            } else {
                if (this.countRequests == this.maxCountRequests) {
                    this.ngxSpinnerService.hide();
                }
            }
            this.salons = response.data;
            if (this.salons.length > 0) {
                this.salons[0].active = true;
                this.form.salon_obj = this.salons[0];
                this.form.salon_id = this.form.salon_obj.id;
            }
        }, error1 => {
            this.ngxSpinnerService.hide();
            this.toastrService.error(error1);
        });
    }

    loadCurrencies(): void {
        if (this.currentSettings.setting_currencies.length > 1) {
            // const dialogRef = this.dialog.open(SaleSelectCurrencyModalComponent, {
            //     data: {
            //         setting_currencies: this.currentSettings.setting_currencies,
            //         form: this.form,
            //     },
            //     width: '50%',
            //     disableClose: true
            // });
            // dialogRef.afterClosed().subscribe(resp => {
            //     this.filterCurrency();
            // });
        } else {
            this.form.currency_id = this.currentSettings.setting_currencies[0].currency_id;
            this.form.currency_alphabetic_code = this.currentSettings.setting_currencies[0].currency.alphabetic_code;
            this.form.currency_numeric_code = this.currentSettings.setting_currencies[0].currency.numeric_code;
            this.form.currency_name = this.currentSettings.setting_currencies[0].currency.name;
            this.form.currency_symbol = this.currentSettings.setting_currencies[0].currency.symbol;
            // this.filterCurrency();
        }
    }

    filterCurrency(): void {
        // this.grid = this.grid.filter(gridItem => gridItem.currency_id == this.form.currency_id);
    }

    setSalonSelected(salon): void {
        this.salons.forEach(salonItem => {
            salonItem.active = salonItem.id == salon.id;
        });
        this.form.salon_obj = salon;
    }

    selectTable(table): void {
        this.form.salon_table_obj = table;
        this.form.salon_table_id = this.form.salon_table_obj.id;
        this.form.items = [];
        if (this.form.salon_table_obj.attention_status != 0) {
            this.ngxSpinnerService.show();
            this.saleService.show(this.form.salon_table_obj.current_sale_id, 1).subscribe(response => {
                this.loadFormFromServer(response.data);

                if (this.form.id) {
                    if (this.form.status == 6) {
                        this.textsBySteps = {
                            1: 'Continuar con la compra',
                            2: 'Generar pre-cuenta',
                            3: 'Continuar',
                            4: 'Emitir comprobante',
                        };
                    } else if (this.form.status == 7) {
                        this.textsBySteps = {
                            1: 'Continuar con la compra',
                            2: 'Continuar',
                            3: 'Continuar',
                            4: 'Emitir comprobante',
                        };
                        this.form.currentStep++;
                        this.form.currentStep++;

                        setTimeout(() => {
                            // @ts-ignore
                            this.cartItems.calculateTotals();
                        }, 500);
                        this.ngxSpinnerService.hide();
                        return;
                    }
                }

                this.nextStep();
                setTimeout(() => {
                    // @ts-ignore
                    this.cartItems.calculateTotals();
                }, 500);
                this.ngxSpinnerService.hide();
            }, error1 => {
                this.ngxSpinnerService.hide();
            });
        } else {
            this.nextStep();
        }
    }

    loadFormFromServer(data): void {
        this.form.id = data.id;
        this.form.status = data.status;
        data.details.forEach(item => {
            if (!item.is_extra) {
                const productItem = JSON.parse(JSON.stringify(item.product));
                productItem.quantity = item.quantity;
                productItem.discount = item.discount;
                productItem.price = item.price;

                if (productItem.details) {
                    productItem.details.forEach(productDetail => {
                        const itemDetailFound = item.details.find(itemDetail => itemDetail.name == productDetail.name);
                        if (itemDetailFound) {
                            productDetail.added = true;
                        }
                    });
                }
                if (productItem.extras) {
                    productItem.extras.forEach(productDetail => {
                        const itemDetailFound = item.extras.find(itemDetail => itemDetail.name == productDetail.name);
                        if (itemDetailFound) {
                            productDetail.quantity = itemDetailFound.quantity;
                            productDetail.price = itemDetailFound.price;
                        }
                    });
                }
                this.form.items.push(productItem);
            }
        });
    }

    selectMenu(menu): void {
        this.form.menu_obj = menu;
    }

    nextStep(): void {
        if (this.form.currentStep == 2) {
            if (this.form.id) {
                const form = {
                    waiter_code: null
                };
                swal.fire({
                    title: '¿Generar Precuenta?',
                    input: 'text',
                    inputAttributes: {
                        autocapitalize: 'off'
                    },
                    type: 'question',
                    showCancelButton: true,
                    confirmButtonText: 'Si',
                    cancelButtonText: 'Cerrar',
                    showLoaderOnConfirm: true,
                    inputPlaceholder: 'Código de Mesero',
                    preConfirm: (arg) => {
                        if (!arg) {
                            return false;
                        }
                        form.waiter_code = arg;
                        return fetch(`${webServiceConfig.webServiceUrl}sales/restaurant/pre-check-in/${this.form.id}`, {
                            method: 'PATCH',
                            headers: {
                                Accept: 'application/json',
                                Authorization: `Bearer ${this.authenticationService.currentUserValue.token}`,
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify(form)
                        }).then(response => {
                            if (response.status == 401) {
                                // logout
                                this.authenticationService.logout();
                                this.router.navigate(['login']);
                                location.reload();
                            }
                            if (!response.ok) {
                                response.json().then(result => {
                                    swal.fire({
                                        title: result.message,
                                        type: 'error',
                                    });
                                });
                                return false;
                            }
                            return response.json();
                        }).catch(error => {
                            console.error(error);
                            swal.fire({
                                title: error,
                                type: 'error'
                            });
                            return false;
                        });
                    },
                    allowOutsideClick: () => !swal.isLoading()
                }).then((result) => {
                    if (result.value) {
                        swal.fire({title: result.value.message, type: 'success'});
                        this.form.status = result.value.sale.status;
                        this.form.currentStep++;
                    } else if (result.value == '') {
                        swal.fire({title: 'El código de mesero es obligatorio', type: 'success'});
                    }
                });
                return;
            } else {
                if (this.form.items.length == 0) {
                    swal.fire({
                        title: 'Aún no has agregado productos',
                        type: 'info'
                    });
                    return;
                }

                const form = JSON.parse(JSON.stringify(this.form));
                form.items.forEach(item => {
                    item.details = item.details.filter(itemDetail => itemDetail.added);
                    item.extras = item.extras.filter(itemDetail => itemDetail.quantity > 0);
                });

                swal.fire({
                    title: '¿Está seguro de mandar este pedido?',
                    input: 'text',
                    inputAttributes: {
                        autocapitalize: 'off'
                    },
                    type: 'question',
                    showCancelButton: true,
                    confirmButtonText: 'Pedir',
                    cancelButtonText: 'Cerrar',
                    showLoaderOnConfirm: true,
                    inputPlaceholder: 'Código de Mesero',
                    preConfirm: (arg) => {
                        if (!arg) {
                            return false;
                        }
                        form.waiter_code = arg;
                        return fetch(`${webServiceConfig.webServiceUrl}sales/restaurant`, {
                            method: 'POST',
                            headers: {
                                Accept: 'application/json',
                                Authorization: `Bearer ${this.authenticationService.currentUserValue.token}`,
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify(form)
                        }).then(response => {
                            if (response.status == 401) {
                                // logout
                                this.authenticationService.logout();
                                this.router.navigate(['login']);
                                location.reload();
                            }
                            if (!response.ok) {
                                response.json().then(result => {
                                    swal.fire({
                                        title: result.message,
                                        type: 'error',
                                    });
                                });
                                return false;
                            }
                            return response.json();
                        }).catch(error => {
                            console.error(error);
                            swal.fire({
                                title: error,
                                type: 'error'
                            });
                            return false;
                        });
                    },
                    allowOutsideClick: () => !swal.isLoading()
                }).then((result) => {
                    if (result.value) {
                        swal.fire({title: result.value.message, type: 'success'});
                        this.resetForm();
                    } else if (result.value == '') {
                        swal.fire({title: 'El código de mesero es obligatorio', type: 'success'});
                    }
                });
                return;
            }
        } else if (this.form.currentStep == 3) {
            this.submittedCustomer = true;
            if (
                !this.form.customer_id &&
                (
                    !this.form.customer ||
                    !this.form.customer.name ||
                    !this.form.customer.document_type ||
                    !this.form.customer.document_number ||
                    (this.form.customer.document_type == 6 && !this.form.customer.address)
                )) {
                swal.fire({
                    title: 'Complete los datos del cliente',
                    type: 'info'
                });
                return;
            }
        } else if (this.form.currentStep == 4) {
            if (this.form.payment_method == 1) {
                if (this.form.otherAmountCash) {
                    if (!this.form.otherAmountCashValue) {
                        swal.fire({
                            title: 'Debe ingresar un monto',
                            type: 'info'
                        });
                        return;
                    }
                    if (this.form.otherAmountCashValue < this.form.total) {
                        swal.fire({
                            title: 'Monto insuficiente',
                            type: 'info'
                        });
                        return;
                    }
                    this.form.cash_paid = Math.round(this.form.otherAmountCashValue * 100) / 100;
                    this.form.cash_returned = Math.round(this.form.cashAmountReturnedSelected * 100) / 100;
                } else {
                    if (!this.form.cashAmountSelected) {
                        swal.fire({
                            title: 'Debe ingresar un monto',
                            type: 'info'
                        });
                        return;
                    }

                    if (this.form.cashAmountSelected < this.form.total) {
                        swal.fire({
                            title: 'Monto insuficiente',
                            type: 'info'
                        });
                        return;
                    }
                    this.form.cash_paid = Math.round(this.form.cashAmountSelected * 100) / 100;
                    this.form.cash_returned = Math.round(this.form.cashAmountReturnedSelected * 100) / 100;
                }
                this.storeSale();
            } else if (this.form.payment_method == 3) {

                let amountEntered = 0;
                if (this.form.cash_amount && this.form.cash_amount > 0) {
                    amountEntered += Number(this.form.cash_amount);
                }
                if (this.form.card_amount && this.form.card_amount > 0) {
                    amountEntered += Number(this.form.card_amount);
                }
                if (this.form.yape_amount && this.form.yape_amount > 0) {
                    amountEntered += Number(this.form.yape_amount);
                }
                if (this.form.plin_amount && this.form.plin_amount > 0) {
                    amountEntered += Number(this.form.plin_amount);
                }
                if (this.form.tunki_amount && this.form.tunki_amount > 0) {
                    amountEntered += Number(this.form.tunki_amount);
                }
                if (this.form.lukita_amount && this.form.lukita_amount > 0) {
                    amountEntered += Number(this.form.lukita_amount);
                }
                if (amountEntered < this.form.total) {
                    swal.fire({
                        title: 'Monto insuficiente',
                        type: 'info'
                    });
                    return;
                }
                this.form.cash_amount = this.form.cash_amount ? Number(this.form.cash_amount) : 0;
                this.form.card_amount = this.form.card_amount ? Number(this.form.card_amount) : 0;
                this.form.yape_amount = this.form.yape_amount ? Number(this.form.yape_amount) : 0;
                this.form.plin_amount = this.form.plin_amount ? Number(this.form.plin_amount) : 0;
                this.form.tunki_amount = this.form.tunki_amount ? Number(this.form.tunki_amount) : 0;
                this.form.lukita_amount = this.form.lukita_amount ? Number(this.form.lukita_amount) : 0;
                this.storeSale();
            } else {
                this.storeSale();
            }
            return;
        }
        this.form.currentStep++;
    }

    prevStep(): void {
        this.form.currentStep--;
    }

    updateSale(): void {

    }

    selectCategory(category): void {
        this.form.category_obj = category;
    }

    selectItemSearch(item): void {
        const itemFound = this.form.items.find(itemAdded => itemAdded.id == item.id);
        if (itemFound) {
            itemFound.quantity++;
        } else {
            item.quantity = 1;
            item.discount = 0;
            this.form.items.push(item);
        }
        // @ts-ignore
        this.cartItems.calculateTotals();
    }

    setVoucherType(voucherType): void {
        this.form.voucher_type = voucherType;
        if (this.form.voucher_type == 0 || this.form.voucher_type == 2) {
            if (this.form.total < this.currentSettings.max_amount_sunat_ticket) {
                this.form.customer = this.defaultCustomer;
                this.form.customer_id = this.defaultCustomer.id;
            }
        } else {
            this.form.customer = {
                document_type: 6
            };
            this.form.customer_id = null;
        }
    }

    setCustomer(): void {
        if (this.inDataCustomer) {
            this.form.customer_id = this.defaultCustomer.id;
            this.form.customer = this.defaultCustomer;
        } else {
            this.form.customer_id = null;
            this.form.customer = {
                document_type: 1
            };
        }
    }

    setDefaultCustomerSelected(): void {
        this.form.customer_id = this.defaultCustomer.id;
        this.form.customer = this.defaultCustomer;
    }

    storeSale(): void {
        let form = Object.assign({}, this.form);
        this.ngxSpinnerService.show();
        this.saleService.emitVoucherRestaurant(form).pipe(first()).subscribe(response => {
            this.ngxSpinnerService.hide();
            this.messageService.success(response.message);
            setTimeout(() => this.messageService.clear(), 4000);
            this.toastrService.success(response.message);

            this.saleCreated = response.sale;
            this.form.id = response.sale_id;

            this.form.currentStep++;
        }, error1 => {
            this.messageService.error(error1);
            this.toastrService.error(error1);
            this.ngxSpinnerService.hide();
        });
    }

    resetForm(): void {
        this.form = {
            id: null,
            salon_id: null,
            salon_obj: null,
            salon_table_id: null,
            salon_table_obj: null,
            menu_obj: null,
            category_obj: null,
            items: [],
            subtotal: 0,
            igv_amount: 0,
            total: 0,
            currentStep: 1,
            from_device: 1,
            status: null,
            payment_method: 1,
            voucher_type: 2,
            cash_paid: null,
            cash_returned: null,
            customer: null,
            customer_id: null,

            //Combined
            cash_amount: 0,
            card_amount: 0,
            yape_amount: 0,
            plin_amount: 0,
            tunki_amount: 0,
            lukita_amount: 0,


            //Payment Method Component
            //Cash
            cashAmountSelected: 0,
            cashAmountReturnedSelected: 0,
            otherAmountCash: false,
            otherAmountCashValue: null,

            //currency
            currency_id: null,
            currency_alphabetic_code: null,
            currency_numeric_code: null,
            currency_name: null,
            currency_symbol: null,
        };
        this.loadSalonsData(true);
        this.setDefaultCustomerSelected();
        this.loadCurrencies();
    }
}
