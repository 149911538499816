import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {webServiceConfig} from '../config/web-service.config';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {DataTablesResponse} from '../models/data-tables-response';


@Injectable({
    providedIn: 'root'
})
export class CategoryService {

    constructor(private http: HttpClient) {
    }

    all(status = 1, menuId = null): Observable<any> {
        return this.http.get(`${webServiceConfig.webServiceUrl}categories`, {
            params: {
                status: status + '',
                menu_id: menuId ? menuId : ''
            }
        }).pipe(map(response => {
            return response;
        }));
    }

    datatable(dataTablesParameters): Observable<any> {
        return this.http.post<DataTablesResponse>(`${webServiceConfig.webServiceUrl}categories/datatable`, dataTablesParameters, {})
            .pipe(map(response => {
                return response;
            }));
    }

    show(id: number): Observable<any> {
        return this.http.get(`${webServiceConfig.webServiceUrl}categories/${id}`).pipe(map(response => {
            return response;
        }));
    }

    store(category: any): Observable<any> {
        return this.http.post(`${webServiceConfig.webServiceUrl}categories`, category)
            .pipe(map(response => {
                return response;
            }));
    }

    update(category: any): Observable<any> {
        return this.http.put(`${webServiceConfig.webServiceUrl}categories/${category.id}`, category)
            .pipe(map(response => {
                return response;
            }));
    }

    delete(id: number): Observable<any> {
        return this.http.delete(`${webServiceConfig.webServiceUrl}categories/${id}`)
            .pipe(map(response => {
                return response;
            }));
    }

    export(): Observable<Blob> {
        return this.http.get(`${webServiceConfig.webServiceUrl}categories/export`, {responseType: 'blob'});
    }
}
